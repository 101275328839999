import { useRef } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import Button from 'components/buttons/Button';
import classes from './InputWrapper.module.scss';

interface IInputFile {
  fileType?: '.pdf' | '.csv, .xlsx';
  fileName?: string;
  name?: string;
  isDisabled?: boolean;
  onClear?: () => void;
  onChange?: React.ChangeEventHandler;
  value?: any;
  error?: string;
}

export const InputFile = ({
  fileType,
  fileName,
  name,
  isDisabled,
  onClear,
  onChange,
  value,
  error
}: IInputFile) => {
  const inputRef = useRef(null) as any;

  return (
    <div className={classes['input__field--file']}>
      {fileName ? (
        <div className={classes['input__field--file--uploaded']}>
          <div className={classes['u-text--content']}>{fileName}</div>
          <TrashIcon
            className={classes['u-fill-primary']}
            onClick={onClear}
            width={24}
            height={24}
          />
        </div>
      ) : (
        <label className={classes['input__field--file--upload']}>
          <Button
            variant="link-contrast"
            icon={PlusIcon}
            onClick={() => inputRef.current.click()}
            isDisabled={isDisabled}
          >
            Attach Document
          </Button>
          <input
            ref={inputRef}
            type="file"
            accept={fileType}
            onChange={onChange}
            name={name}
            value={value}
          />
        </label>
      )}
      {!!error && <div className={classes['input__error']}>{error}</div>}
    </div>
  );
};
