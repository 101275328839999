import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userContext } from 'context/userContext';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Tabs from 'components/tabs/Tabs';
import CreatorReport from './CreatorReport';
import Claim from './Claim';
import Refunds from './Refunds';
import classes from './UserFunds.module.scss';

interface IAdminFunds {
  tab: 'claim' | 'transactions' | 'report';
}

const UserFunds = ({ tab }: IAdminFunds) => {
  const { userData } = useContext(userContext);
  const navigate = useNavigate();
  const tabs = [
    {
      id: 'transactions',
      name: 'My Purchases',
      route: 'user/purchases',
      component: () => <Refunds />
    }
  ];

  if (userData.is_creator) {
    tabs.unshift({
      id: 'claim',
      name: 'My Sales',
      route: 'user/sales',
      component: () => <Claim />
    });
    tabs[2] = {
      id: 'report',
      name: 'Report',
      route: 'user/report',
      component: () => <CreatorReport />
    };
  }

  useEffect(() => {
    if (tab === 'claim' && !userData.is_creator) {
      navigate('/user/purchases');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className={classes['tabs-wrapper']}>
      <Breadcrumbs skips={[0]} />
      <Tabs
        tabs={tabs}
        activeTabIndex={
          tab === 'claim' && !userData.is_creator
            ? 0
            : tabs.findIndex((t) => t.id === tab)
        }
        size={'sm'}
      />
    </div>
  );
};
export default UserFunds;
