import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAccount, useBalance, useDisconnect, useSwitchNetwork } from 'wagmi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatAddress } from 'utils/format';
import { ReactComponent as Arrow } from 'assets/icons/arrowShort.svg';
import { ReactComponent as LinkOut } from 'assets/icons/link.svg';
import { ReactComponent as Power } from 'assets/icons/power-off.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Matic } from 'assets/images/matic.svg';
import { windowContext } from 'context/windowsContext';
import { Web3Context } from 'context/web3Context';
import { userContext } from 'context/userContext';
import useComponentVisible from 'hooks/useComponentVisible';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_CHAIN_ID
} from 'utils/constants';
import Divider from 'components/divider/Divider';
import Button from 'components/buttons/Button';
import BlockiesComp from 'components/blockies/Blockies';
import { popupContext } from 'context/popupContext';
import DepositPopup from '../components/DepositPopup';
import ConnectWalletPopup from 'components/popup/ConnectWalletPopup';
import Loader from 'components/loader/Loader';
import CopyButton from 'components/buttons/CopyButton';
import { disconnectWalletMutationQuery } from 'query/acc-module/mutations';
import classes from './Wallet.module.scss';

const Wallet = () => {
  const queryClient = useQueryClient();
  const { balanceInUsd } = useContext(Web3Context);
  const { setPopup } = useContext(popupContext);
  const { isCorrectChain, pendingTransactions, isWalletUnique } =
    useContext(Web3Context);
  const { windowSize } = useContext(windowContext);
  const {
    userData: { is_creator }
  } = useContext(userContext);
  const { isLgMobile } = windowSize;
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);

  // Wagmi web3 hooks
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();
  const { data: balanceData } = useBalance({ address });

  const { mutate: disconnectWalletMutation } = useMutation({
    ...disconnectWalletMutationQuery(),
    onSuccess: () => {
      queryClient.invalidateQueries(['me-data']);
    }
  });

  const renderActions = () => {
    return (
      <>
        <CopyButton text={address as string} size="lg" />
        <Link
          to={`${REACT_APP_BLOCK_EXPLORER_URL}/address/${address}`}
          target="_blank"
        >
          <LinkOut className={classes['icon']} width={16} height={16} />
        </Link>
      </>
    );
  };

  const renderWalletDetails = () => {
    return (
      <div className={classes['details']}>
        <div className={classes['actions']}>
          <div>
            <BlockiesComp seed={address || ''} size={12} scale={3} />
            <div>{formatAddress(address || '')}</div>
            {renderActions()}
          </div>
          <Power onClick={handleDisconnectWallet} />
        </div>
        <div className={classes['amounts']}>
          <div className={`${classes['u-h4']} ${classes['u-semiBold']}}`}>
            <Matic height={24} width={24} />
            <b>
              {!!balanceData?.formatted && (+balanceData?.formatted).toFixed(4)}
            </b>{' '}
            MATIC
          </div>
          <div className={classes['u-body2']}>
            <b>${balanceInUsd}</b> USD
          </div>
        </div>
        {!isCorrectChain && (
          <div className={classes['network-msg']}>
            <Warning className={classes['warning-icon']} />
            <div className={classes['network-msg__body']}>
              <div className={classes['network-msg__body__title']}>
                Switch your network
              </div>
              <div className={classes['network-msg__body__msg']}>
                You are connected to an unsupported network. Please make sure
                you are connected to Polygon Mainnet.
              </div>
            </div>
          </div>
        )}
        {!isWalletUnique && (
          <div className={classes['network-msg']}>
            <Warning className={classes['warning-icon']} />
            <div className={classes['network-msg__body']}>
              <div className={classes['network-msg__body__title']}>
                Switch your wallet
              </div>
              <div className={classes['network-msg__body__msg']}>
                You cannot use this wallet address, because it is associated
                with another account.
              </div>
            </div>
          </div>
        )}
        {!isLgMobile && <Divider />}
        <div className={classes['btns-container']}>
          {!isCorrectChain && (
            <Button
              size="sm"
              onClick={() =>
                !!switchNetwork && switchNetwork(+REACT_APP_CHAIN_ID)
              }
            >
              Switch Network
            </Button>
          )}
          <Button
            variant={isCorrectChain ? 'contrast' : 'neutral'}
            size="sm"
            onClick={() => {
              setPopup(<DepositPopup />);
              setIsComponentVisible(false);
            }}
          >
            Add Funds
          </Button>
        </div>
      </div>
    );
  };

  const handleDisconnectWallet = () => {
    if (!is_creator) disconnectWalletMutation();
    disconnect();
    setIsComponentVisible(false);
  };

  if (!isConnected)
    return (
      <Button
        onClick={() => setPopup(<ConnectWalletPopup />)}
        variant="neutral"
      >
        Connect Wallet
      </Button>
    );

  return (
    <div className={classes['wrapper']} ref={ref}>
      <div
        className={classes['container']}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {pendingTransactions > 0 && (
          <div className={classes['transactions']}>
            <div>{pendingTransactions}</div>
            <div>Pending</div>
            <div className={classes['loader-wrapper']}>
              <Loader size="sm" loaderColor="neutral" />
            </div>
          </div>
        )}
        {pendingTransactions === 0 && (
          <>
            {(!isCorrectChain || !isWalletUnique) && (
              <Warning className={classes['warning-icon']} />
            )}
            <div>{formatAddress(address || '')}</div>
            <div className={classes['icons-container']}>
              {!isLgMobile && (
                <Arrow
                  className={`${classes['icon']} ${
                    !isComponentVisible ? classes['icon--rotated'] : ''
                  }`}
                />
              )}
            </div>
          </>
        )}
      </div>
      {isLgMobile && renderWalletDetails()}
      {!isLgMobile && isComponentVisible && renderWalletDetails()}
    </div>
  );
};

export default Wallet;
