export interface tokenData {
  token: string;
  exp: number;
}

export function setTokenInStorage(data: tokenData) {
  let expiration = new Date();
  data = {
    ...data,
    exp: expiration.setTime(data.exp * 1000)
  };
  localStorage.setItem('token', JSON.stringify(data));
}

export function clearTokenFromStorage() {
  localStorage.removeItem('token');
}

export function tokenExpired() {
  const token = localStorage.getItem('token');
  const now = Date.now();
  const expirationDate: tokenData = token && JSON.parse(token);
  const expDate = new Date(expirationDate?.exp);

  // There is no token, or the token has expired
  if (now > expDate.getTime() || !token) {
    return true;
  }

  // There is a token and it has not expired
  return false;
}
