import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { ROLE_FLAG, userContext } from 'context/userContext';
import Loader from 'components/loader/Loader';

export interface IPermissions {
  redirect?: boolean;
  role_flag: ROLE_FLAG;
  redirectPath?: string;
  children: React.ReactNode;
}

/*
  NOTE: We have 2 types of users - 1.Regular users and 2. Admin
  We DO NOT use permissions based component visibility.
  We simply render (or not) a component based on the   
  userData's role flag and passed role_flag prop... 
  role_flag = 1 ---> (user) 
  role_flag = 2 ---> (admin) 
*/

export default function PermissionsGate({
  redirect,
  role_flag,
  redirectPath,
  children
}: IPermissions) {
  const { userData } = useContext(userContext);

  // userData is not set
  if (!role_flag || !userData.role_flag) {
    return <Loader isFixed />;
  }

  // The user is NOT allowed to see the wrapped component
  if (!!role_flag && !!userData.role_flag && role_flag !== userData.role_flag) {
    if (!!redirect) return <Navigate to={redirectPath || '/'} />;
    return <></>;
  }

  // The user is allowed to see the wrapped component
  return <>{children}</>;
}
