import { ICourse } from 'query/course-module/dto';
import { COURSE_REVIEW_STATUS, COURSE_STATUS } from './constants';

export const isCourseStatus = (
  checkStatus:
    | 'isDraft'
    | 'isFinishedDraft'
    | 'isDraftOfPublished'
    | 'isSubmitted'
    | 'isSubmittedDraft'
    | 'isSubmittedForRepublish'
    | 'isApprovedInitial'
    | 'isApprovedForRepublish'
    | 'isRejected'
    | 'isRejectedDraft'
    | 'isRejectedForRepublish'
    | 'isTakedown'
    | 'isPublishing'
    | 'isRepublishing'
    | 'isPublished'
    | 'isPaused',

  course: ICourse
) => {
  let isTrue = false;

  const { status, review_status, not_finished } = course;

  switch (checkStatus) {
    // 1. Unfinished draft
    case 'isDraft':
      isTrue =
        status === COURSE_STATUS.DRAFT &&
        review_status === COURSE_REVIEW_STATUS.INITIAL &&
        !!not_finished;
      break;
    // 2. Finished draft
    case 'isFinishedDraft':
      isTrue =
        status === COURSE_STATUS.DRAFT &&
        review_status === COURSE_REVIEW_STATUS.INITIAL &&
        !not_finished;
      break;
    // 3. Draft of published
    case 'isDraftOfPublished':
      isTrue =
        course?.is_draft_copy &&
        course.review_status === COURSE_REVIEW_STATUS.INITIAL;
      break;
    case 'isSubmitted':
      isTrue = review_status === COURSE_REVIEW_STATUS.IN_REVIEW;
      break;
    case 'isSubmittedDraft':
      isTrue =
        status === COURSE_STATUS.DRAFT &&
        review_status === COURSE_REVIEW_STATUS.IN_REVIEW;
      break;
    case 'isSubmittedForRepublish':
      isTrue =
        (status === COURSE_STATUS.PAUSED ||
          status === COURSE_STATUS.PUBLISHED) &&
        review_status === COURSE_REVIEW_STATUS.IN_REVIEW;
      break;
    case 'isApprovedInitial':
      isTrue =
        status === COURSE_STATUS.DRAFT &&
        review_status === COURSE_REVIEW_STATUS.APPROVED;
      break;
    case 'isApprovedForRepublish':
      isTrue =
        (status === COURSE_STATUS.PUBLISHED ||
          status === COURSE_STATUS.PAUSED) &&
        review_status === COURSE_REVIEW_STATUS.APPROVED &&
        (course.is_draft_copy || course.has_draft_copy);
      break;
    case 'isRejected':
      isTrue =
        (status === COURSE_STATUS.DRAFT &&
          review_status === COURSE_REVIEW_STATUS.REJECTED) ||
        ((status === COURSE_STATUS.PUBLISHED ||
          status === COURSE_STATUS.PAUSED) &&
          review_status === COURSE_REVIEW_STATUS.REJECTED);
      break;
    case 'isRejectedDraft':
      isTrue =
        status === COURSE_STATUS.DRAFT &&
        review_status === COURSE_REVIEW_STATUS.REJECTED &&
        !course?.is_draft_copy;

      break;
    case 'isRejectedForRepublish':
      isTrue =
        (status === COURSE_STATUS.PUBLISHED ||
          status === COURSE_STATUS.PAUSED) &&
        review_status === COURSE_REVIEW_STATUS.REJECTED &&
        course.has_draft_copy;
      break;
    case 'isTakedown':
      isTrue = status === COURSE_STATUS.PAUSED;
      break;
    case 'isPublishing':
      isTrue = status === COURSE_STATUS.PUBLISHING;
      break;
    case 'isRepublishing':
      isTrue =
        (status === COURSE_STATUS.PUBLISHED ||
          status === COURSE_STATUS.PAUSED) &&
        review_status === COURSE_REVIEW_STATUS.APPROVED &&
        !!course?.update_tx_started_at;
      break;
    case 'isPublished':
      isTrue = status === COURSE_STATUS.PUBLISHED;
      break;

    case 'isPaused':
      isTrue = status === COURSE_STATUS.PAUSED;
      break;

    default:
      isTrue = false;
      break;
  }
  return isTrue;
};

export const getPriceInUsdc = (price: number) => {
  let stringPrice = price.toString();

  // If price is in the format XX.YYYYYYYYYY, then return XX.YY
  if (stringPrice.includes('.') && stringPrice.split('.')[1].length > 2) {
    stringPrice = `${stringPrice.split('.')[0]}.${stringPrice
      .split('.')[1]
      .slice(0, 2)}`;
  }

  return stringPrice;
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
