import { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { appContext } from 'context/appContext';
import { popupContext } from 'context/popupContext';
import { useEthersSigner } from 'hooks/useEthersSigner';
import useCurrencyContract from 'hooks/useCurrencyContract';
import { ModalWrapper } from './common/ModalWrapper';
import { InputWrapper } from 'components/input/InputWrapper';
import Divider from 'components/divider/Divider';
import { formatPrice, formatTokenDecimals } from 'utils/format';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/buttons/Button';
import GenericPopup from './GenericPopup';
import { IPlatformSettingsDto } from 'query/course-module/dto';
import { fetchApi, submitTx } from 'utils/requests';
import { REACT_APP_CURRENCY_CONTRACT_ADDRESS } from 'utils/constants';
import { getContractInstance } from 'contracts/contracts';
import classes from './PlatformFeePopup.module.scss';

const PlatformFeePopup = () => {
  const { clearPopup, setPopup } = useContext(popupContext);
  const { platformSettings } = useContext(appContext);
  const price = 100;
  const currency = 'USDC';
  const queryClient = useQueryClient();
  const [platformFee, setPlatformFee] = useState(platformSettings.platform_fee);
  const platformEarning = +formatTokenDecimals((platformFee / 100) * price);

  const { signer } = useEthersSigner();
  const { decimals: usdcDecimals } = useCurrencyContract(
    REACT_APP_CURRENCY_CONTRACT_ADDRESS
  );

  const { isLoading, mutate: setFeeMutation } = useMutation<
    boolean,
    Error,
    IPlatformSettingsDto
  >({
    mutationKey: ['platform-fee-mutation'],
    mutationFn: async (settings: IPlatformSettingsDto) => {
      const contract = getContractInstance('payment', signer);
      const fee = BigInt(
        (settings.platform_fee / 100) * Math.pow(10, usdcDecimals)
      );
      const txData = [fee];
      await submitTx(contract, 'updatePurchaseFeePercentage', txData);
      return settings as any;
    },
    onSuccess: async (settings: any) => {
      await fetchApi('courses', '/settings', {
        method: 'PUT',
        auth: true,
        data: settings
      });
      queryClient.invalidateQueries(['platform-settings']);
      setPopup(
        <GenericPopup msg={`Platform fee is now ${platformEarning}%`} />
      );
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  return (
    <ModalWrapper onClose={() => clearPopup()} size="md" isClosable={true}>
      <div className={classes['popup-container']}>
        <div className={classes['content']}>
          <h4 className={classes['u-semiBold']}>Set Platform Fee</h4>
          <InputWrapper
            type="number"
            className={classes['input']}
            variant="outline"
            onChange={setPlatformFee}
            value={platformFee}
            placeholder="0.00%"
            tooltipText="The % amount collected by the platform for every course sale"
            hasTooltip
            title="Set Platform fee"
          />
          <div className={classes['breakdown']}>
            <div className={classes['breakdown__header']}>
              <div>Example</div>
              <Tooltip
                id="breakdown"
                text="The example below demonstrates the platform earning breakdown based on a course listed for 100 USDC."
              >
                <Question />
              </Tooltip>
            </div>
            <div className={classes['price-box']}>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Course Price</div>
                <div className={classes['price-box__value']}>100 USDC</div>
              </div>
              <Divider dir="horizontal" />
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Platform Fee</div>
                <div className={classes['price-box__value']}>
                  {platformFee}%
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Platform Earning
                </div>
                <div className={classes['price-box__value']}>
                  {formatPrice(platformEarning)} {currency}
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Creator Earning
                </div>
                <div className={classes['price-box__value']}>
                  {formatPrice(price - platformEarning)} {currency}
                </div>
              </div>
            </div>
          </div>
          <div className={classes['btns-container']}>
            <Button
              size="md"
              isDisabled={platformFee < 0 || platformFee > 100}
              onClick={() =>
                setFeeMutation({
                  ...platformSettings,
                  platform_fee: +platformFee
                })
              }
              isLoading={isLoading}
              isWeb3
            >
              Save Settings
            </Button>
            <Button variant="link-neutral" size="md">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PlatformFeePopup;
