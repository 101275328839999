import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from '@tanstack/react-query';
import NoCourses from 'assets/images/no-courses.svg';
import Card from 'components/card/Card';
import UserCard from 'components/card/UserCard';
import Button from 'components/buttons/Button';
import Loader from 'components/loader/Loader';
import EmptyState from 'components/emptyState/EmptyState';
import SEO from 'components/SEO/SEO';
import {
  COURSE_CHANGES,
  ICourseAndCreatorsData
} from 'query/course-module/dto';
import { topCoursesAndCreatorsQuery } from 'query/course-module/queries';
import { ICreator } from 'query/acc-module/dto';
import classes from './Results.module.scss';

const Results = () => {
  const { search: urlParams } = useLocation();
  const { search } = queryString.parse(urlParams);
  const navigate = useNavigate();

  const queryParams = `?&limit=4&page=1${
    search && search.length > 0
      ? `&search[name]=${search}&search[first_name]=${search}&search[last_name]=${search}`
      : ''
  }`;

  const { isLoading, data } = useQuery<boolean, Error, ICourseAndCreatorsData>({
    ...topCoursesAndCreatorsQuery(queryParams),
    enabled: !!search
  });

  const { courses, creators } = data || {};
  const coursesData = courses?.result;
  const creatorsData = creators?.result;
  const hasCoursesData = !!coursesData?.length;
  const hasCreatorsData = !!creatorsData?.length;

  return (
    <div className={classes['results-wrapper']}>
      <SEO title="Explore All" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {hasCoursesData && (
            <div className={classes['results-wrapper__section']}>
              <div>
                <h4 className={classes['u-bold']}>Courses</h4>
                <h3 className={classes['u-body1']}>
                  {`${courses?.total_results} Results`}
                </h3>
              </div>
              <div className={classes['cards']}>
                {coursesData?.map((course) => (
                  <Card
                    key={course._id}
                    onClick={() =>
                      navigate({
                        pathname: `/courses/${course.slug}`,
                        search: `${createSearchParams({
                          changes: course.is_draft_copy
                            ? COURSE_CHANGES.ONLY_CHANGES
                            : COURSE_CHANGES.NO_CHANGES
                        })}`
                      })
                    }
                    hasPrice
                    hasRating
                    course={course}
                  />
                ))}
              </div>
              {courses?.total_results && courses.total_results > 4 && (
                <div>
                  <Button
                    variant="outline"
                    size="sm"
                    minWidth="sm"
                    onClick={() => navigate(`/courses?search=${search}`)}
                  >
                    Show more
                  </Button>
                </div>
              )}
            </div>
          )}
          {hasCreatorsData && (
            <div className={classes['results-wrapper__section']}>
              <div>
                <h4 className={classes['u-bold']}>Content Creators</h4>
                <h3 className={classes['u-body1']}>
                  {`${creators?.total_results} Results`}
                </h3>
              </div>
              <div className={classes['cards']}>
                {creatorsData?.map((user: ICreator) => (
                  <UserCard key={user._id} user={user} />
                ))}
              </div>
              {creators?.total_results && creators.total_results > 4 && (
                <div>
                  <Button
                    size="sm"
                    minWidth="sm"
                    variant="outline"
                    onClick={() => navigate(`/users?search=${search}`)}
                  >
                    Show more
                  </Button>
                </div>
              )}
            </div>
          )}
          {!hasCoursesData && !hasCreatorsData && (
            <EmptyState icon={NoCourses} title="No results found." />
          )}
        </>
      )}
    </div>
  );
};

export default Results;
