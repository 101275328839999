import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import Button from 'components/buttons/Button';
import { VIDEO_EDIT_STATUS } from 'utils/constants';
import classes from './ChildWithIndicator.module.scss';

interface IChildWithIndicatorProps {
  className?: string;
  indicatorType?: VIDEO_EDIT_STATUS;
  showIndicator: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const ChildWithIndicator = ({
  className,
  indicatorType,
  showIndicator,
  onClick,
  children
}: IChildWithIndicatorProps) => {
  const isNew = indicatorType === VIDEO_EDIT_STATUS.NEW;
  const isDeleted = indicatorType === VIDEO_EDIT_STATUS.DELETED;

  if (showIndicator) {
    return (
      <div className={`${classes['child-with-indicator']} ${className}`}>
        {children}
        <Button
          className={`
            ${classes['child-with-indicator__indicator']}     
            ${
              isNew
                ? classes['child-with-indicator__indicator--new']
                : isDeleted
                ? classes['child-with-indicator__indicator--deleted']
                : ''
            }     
           `}
          variant="neutral"
          icon={Clock}
          iconPosition="left"
          size="sm"
          onClick={onClick}
        >
          {isNew ? 'New' : isDeleted ? 'Deleted' : 'Updated'}
        </Button>
      </div>
    );
  }
  return <>{children}</>;
};

ChildWithIndicator.defaultProps = {
  indicatorType: VIDEO_EDIT_STATUS.INITIAL
};

export default ChildWithIndicator;
