import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ReactComponent as Question } from 'assets/icons/question-big.svg';
import { InputWrapper } from 'components/input/InputWrapper';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import GenericPopup from 'components/popup/GenericPopup';
import { ForgotPasswordSchema, IForgotPasswordFields } from 'utils/yupSchemas';
import { forgotPassMutation } from 'query/acc-module/mutations';
import classes from './Form.module.scss';

const initialValues: IForgotPasswordFields = {
  email: ''
};

const ForgotPassForm = () => {
  const navigate = useNavigate();
  const { setPopup } = useContext(popupContext);

  const { isLoading, mutate: handleForgotPassword } = useMutation({
    ...forgotPassMutation(),
    onSuccess: (data) => {
      setPopup(
        <GenericPopup
          icon={<Email />}
          size="sm"
          msg="You will receive an e-mail with the next steps if the entered e-mail is associated with an account in our system. Please follow the instructions in the e-mail to complete the password recovery process."
          redirectPath="/login"
          hideActionBtn
          bellowBtnComp={
            <Button
              variant="link-neutral"
              onClick={() => {
                setPopup(
                  <GenericPopup
                    icon={<Question />}
                    size="sm"
                    title="Didn't receive an email?"
                    buttonName="Resend"
                    buttonVariant="contrast"
                    buttonAction={() => handleForgotPassword(data)}
                    redirectPath="/login"
                    bellowBtnComp={
                      <p
                        className={`${classes['u-body2']} ${classes['u-text--center']}`}
                      >
                        Check if you have entered your email correctly and try
                        again. If you are still not receiving an email, please
                        contact our support.
                      </p>
                    }
                  />
                );
              }}
            >
              Didn't receive an email?
            </Button>
          }
        />
      );
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
          isClosable={false}
        />
      );
    }
  });

  return (
    <div className={classes['wrapper']}>
      <div className={classes['wrapper__title']}>
        <Button
          onClick={() => navigate('/login')}
          variant="link-neutral"
          isIconBtn
          icon={Arrow}
          iconRotate={270}
        />
        <h4 className={classes['u-bold']}>Forgot Password?</h4>
        <p>
          Forgot password? Please enter your email address. You will receive a
          link to create a new password via email.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values) => {
          if (isLoading) return;
          handleForgotPassword(values);
        }}
        validateOnBlur
        validateOnMount
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          submitForm,
          handleBlur
        }) => (
          <Form className={classes['form']}>
            <InputWrapper
              name="email"
              type="text"
              onChange={(value) => setFieldValue('email', value)}
              onBlur={(e) => handleBlur(e)}
              value={values.email}
              title="E-mail"
              placeholder="Enter E-mail ..."
              isRequired
              error={errors.email && touched.email ? errors.email : ''}
            />
            <Button
              type="submit"
              isDisabled={!!errors.email}
              isLoading={isLoading}
            >
              Reset Password
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassForm;
