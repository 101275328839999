import Tabs from 'components/tabs/Tabs';
import Courses from './Courses';
import Highlighted from '../highlighted/HighlightedCourses';

export interface IAdminCourses {
  tab: 'published' | 'submitted' | 'drafts' | 'highlighted';
}

const AdminCourses = ({ tab }: IAdminCourses) => {
  const tabs = [
    {
      id: 'published',
      name: 'Published',
      route: 'admin/courses',
      component: () => <Courses tab="published" />
    },
    {
      id: 'submitted',
      name: 'Submitted',
      route: 'admin/courses/submitted',
      component: () => <Courses tab="submitted" />
    },
    {
      id: 'drafts',
      name: 'Drafts',
      route: 'admin/courses/drafts',
      component: () => <Courses tab="drafts" />
    },
    {
      id: 'highlighted',
      name: 'Highlighted',
      route: 'admin/courses/highlighted',
      component: () => <Highlighted />
    }
  ];

  return (
    <Tabs
      tabs={tabs}
      activeTabIndex={tabs.findIndex((t) => t.id === tab)}
      size={'sm'}
    />
  );
};
export default AdminCourses;
