import { useContext, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Portal } from 'react-portal';
import { ReactComponent as Moon } from 'assets/icons/moon.svg';
import { ReactComponent as Rocket } from 'assets/icons/rocket.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Sun } from 'assets/icons/sun.svg';
import { ReactComponent as Person } from 'assets/icons/person.svg';
import { ReactComponent as LogIn } from 'assets/icons/log-in.svg';
import { ReactComponent as LogOut } from 'assets/icons/log-out.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrowShort.svg';
import { ReactComponent as Users } from 'assets/icons/users.svg';
import { ReactComponent as Hamburger } from 'assets/icons/hamburger.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import NoCourses from 'assets/images/no-courses-gray.svg';
import NoUsers from 'assets/images/no-users-gray.svg';
import { appContext } from 'context/appContext';
import { userContext } from 'context/userContext';
import { popupContext } from 'context/popupContext';
import useComponentVisible from 'hooks/useComponentVisible';
import { clearTokenFromStorage } from 'utils/storage';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import Wallet from 'components/header/wallet/Wallet';
import NavButton from './NavButton';
import Avatar from 'components/avatar/Avatar';
import CourseCard from '../components/CourseCard';
import Loader from 'components/loader/Loader';
import EmptyState from 'components/emptyState/EmptyState';
import PlatformFeePopup from 'components/popup/PlatformFeePopup';
import VideoFeePopup from 'components/popup/VideoFeePopup';
import { topCoursesAndCreatorsQuery } from 'query/course-module/queries';
import {
  ICourse,
  COURSE_CHANGES,
  ICourseAndCreatorsData
} from 'query/course-module/dto';
import { ICreator } from 'query/acc-module/dto';
import { formatNames } from 'utils/format';
import classes from './MobileMenu.module.scss';

const MobileMenu = () => {
  const { theme, switchTheme } = useContext(appContext);
  const { isTokenExpired, clearUser, userData, lastWatchedCourse } =
    useContext(userContext);
  const { setPopup } = useContext(popupContext);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const logOutHandler = () => {
    clearUser();
    clearTokenFromStorage();
    navigate('/login');
  };

  const queryParams = `?&limit=3&page=1${
    searchValue.length > 0
      ? `&search[name]=${searchValue}&search[first_name]=${searchValue}&search[last_name]=${searchValue}`
      : ''
  }`;

  const { isLoading, data } = useQuery<boolean, Error, ICourseAndCreatorsData>({
    ...topCoursesAndCreatorsQuery(queryParams),
    enabled: !!searchValue && searchValue.length > 1
  });

  const { courses, creators } = data || {};
  const coursesData = courses?.result;
  const creatorsData = creators?.result;
  const hasCoursesData = !!coursesData?.length;
  const hasCreatorsData = !!creatorsData?.length;

  return (
    <div ref={ref}>
      <Button
        variant="neutral"
        isIconBtn
        icon={isComponentVisible ? Cross : Hamburger}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      />
      <Portal node={document.getElementById('header-wrapper')}>
        {isComponentVisible && (
          <div className={classes['mobile-menu-backdrop']} />
        )}
        <div
          className={`${classes['wrapper']} ${
            isComponentVisible ? classes['wrapper--open'] : ''
          }`}
        >
          {!showSearch && (
            <div
              className={`${classes['mobile-menu']} ${
                isComponentVisible ? classes['mobile-menu--open'] : ''
              }`}
              ref={ref}
            >
              {!isTokenExpired && (
                <>
                  <Wallet />
                  <Divider />
                </>
              )}
              <div className={classes['nav-container']}>
                {!userData?.isAdmin && (
                  <>
                    <NavButton
                      icon={Search}
                      name="Search"
                      active={false}
                      onClick={() => {
                        setShowSearch(true);
                        setIsComponentVisible(false);
                      }}
                    />
                    <NavButton
                      icon={Rocket}
                      name="Explore Courses"
                      active={location.pathname === '/courses'}
                      onClick={() => {
                        navigate('/courses');
                        setIsComponentVisible(false);
                      }}
                    />
                    <NavButton
                      icon={Users}
                      name="Content Creators"
                      active={location.pathname === '/users'}
                      onClick={() => {
                        navigate('/users');
                        setIsComponentVisible(false);
                      }}
                    />
                  </>
                )}
                {!isTokenExpired && (
                  <>
                    {!!userData?.isAdmin && (
                      <>
                        <NavButton
                          icon={Dashboard}
                          name="Dashboard"
                          active={location.pathname === '/admin/dashboard'}
                          onClick={() => {
                            navigate('/admin/dashboard');
                            setIsComponentVisible(false);
                          }}
                        />
                        <NavButton
                          icon={Rocket}
                          name="Courses"
                          active={location.pathname === '/admin/courses'}
                          onClick={() => {
                            navigate('/admin/courses');
                            setIsComponentVisible(false);
                          }}
                        />
                        <NavButton
                          icon={Person}
                          name="Users"
                          active={location.pathname === '/admin/users'}
                          onClick={() => {
                            navigate('/admin/users');
                            setIsComponentVisible(false);
                          }}
                        />
                        <NavButton
                          icon={WalletIcon}
                          name="Transactions"
                          active={location.pathname === '/admin/transactions'}
                          onClick={() => {
                            navigate('/admin/transactions');
                            setIsComponentVisible(false);
                          }}
                        />
                      </>
                    )}
                    {!userData?.isAdmin && (
                      <>
                        {!!lastWatchedCourse && (
                          <NavButton
                            icon={Play}
                            name="Continue Watching"
                            active={
                              location.pathname ===
                              `/courses/${lastWatchedCourse?.slug}/${lastWatchedCourse?.purchased?.last_watched_video}`
                            }
                            onClick={() => {
                              navigate(
                                `/courses/${lastWatchedCourse?.slug}/${lastWatchedCourse?.purchased?.last_watched_video}?changes=0`
                              );
                              setIsComponentVisible(false);
                            }}
                          />
                        )}
                        <NavButton
                          icon={Person}
                          name="My Profile"
                          active={
                            location.pathname.startsWith('/user/') && !slug
                          }
                          onClick={() => {
                            navigate('/user');
                            setIsComponentVisible(false);
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
              {userData.isAdmin && (
                <>
                  <Divider />
                  <NavButton
                    name="Change Platform Fee"
                    active={false}
                    onClick={() => {
                      setPopup(<PlatformFeePopup />);
                      setIsComponentVisible(false);
                    }}
                    icon={Arrow}
                    iconRotate={90}
                    iconPosition="right"
                    iconFill="content"
                  />
                  <NavButton
                    name="Set Video Price"
                    active={false}
                    onClick={() => {
                      setPopup(<VideoFeePopup />);
                      setIsComponentVisible(false);
                    }}
                    icon={Arrow}
                    iconRotate={90}
                    iconPosition="right"
                    iconFill="content"
                  />
                  <NavButton
                    name="Static Pages"
                    active={location.pathname === '/admin/static-pages'}
                    onClick={() => {
                      navigate('/admin/static-pages');
                      setIsComponentVisible(false);
                    }}
                  />
                </>
              )}
              <Divider />
              <NavButton
                icon={theme === 'dark' ? Sun : Moon}
                name={`${theme === 'dark' ? 'Light' : 'Dark'} Theme`}
                active={false}
                onClick={switchTheme}
              />
              <Divider />
              <NavButton
                icon={isTokenExpired ? LogIn : LogOut}
                name={isTokenExpired ? 'Log In / Sign Up' : ' Log Out'}
                active={false}
                onClick={() => {
                  isTokenExpired ? navigate('/login') : logOutHandler();
                  setIsComponentVisible(false);
                }}
              />
            </div>
          )}
          {showSearch && (
            <div className={classes['search-popup-container']}>
              <div className={classes['header']}>
                <Button
                  variant="link-neutral"
                  isIconBtn
                  icon={ArrowLeft}
                  iconRotate={-90}
                  onClick={() => setShowSearch(!showSearch)}
                />
                <div>Search OpusEDU</div>
              </div>
              <InputWrapper
                type="search"
                variant="outline"
                name="header-search"
                placeholder="Search OpusEDU"
                value={searchValue}
                onChange={setSearchValue}
                isRequired
                isDebounced
                isClearable={!!searchValue}
                onClear={() => setSearchValue('')}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter' && searchValue) {
                    setShowSearch(false);
                    setIsComponentVisible(false);
                    navigate(`/results?search=${searchValue}`);
                    setSearchValue('');
                  }
                }}
              />
              {searchValue?.length > 1 && (
                <div className={classes['search-results-wrapper']}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className={classes['search-results']}>
                        <h5
                          className={`${classes['u-body2']} ${classes['u-semiBold']}`}
                        >
                          Courses
                        </h5>
                        {hasCoursesData ? (
                          coursesData.map((course: ICourse) => (
                            <CourseCard
                              key={course._id}
                              course={course}
                              onClick={() => {
                                setSearchValue('');
                                setShowSearch(false);
                                navigate({
                                  pathname: `/courses/${course.slug}`,
                                  search: `${createSearchParams({
                                    changes: COURSE_CHANGES.NO_CHANGES
                                  })}`
                                });
                              }}
                            />
                          ))
                        ) : (
                          <EmptyState
                            icon={NoCourses}
                            title="No Courses found"
                          />
                        )}
                      </div>
                      <div className={classes['search-results']}>
                        <h5
                          className={`${classes['u-body2']} ${classes['u-semiBold']}`}
                        >
                          Content Creators
                        </h5>
                        {hasCreatorsData ? (
                          creatorsData.map((user: ICreator) => (
                            <div
                              key={user._id}
                              className={classes['result']}
                              onClick={() => {
                                setSearchValue('');
                                navigate(`/user/${user.slug}/about/main-info`);
                              }}
                            >
                              <Avatar
                                size="sm"
                                isBorderless
                                src={user.pic}
                                onClick={() => navigate('/user')}
                              />
                              <div className={classes['u-semiBold']}>
                                {formatNames(
                                  user.first_name + ' ' + user.last_name
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <EmptyState
                            icon={NoUsers}
                            title="No Content Creators found"
                          />
                        )}
                      </div>
                      {(hasCoursesData || hasCreatorsData) && (
                        <h5 className={classes['u-body2']}>
                          Press enter to reveal more items
                        </h5>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Portal>
    </div>
  );
};

export default MobileMenu;
