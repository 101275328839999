import React, { useRef } from 'react';
import { ReactComponent as Attach } from 'assets/icons/attach.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import Label from './Label';
import Button from '../buttons/Button';
import { IVideoFile } from 'query/course-module/dto';
import { IInputWrapperProps } from './InputWrapper';
import classes from './InputFileAttachments.module.scss';

interface IInputFileNewProps
  extends Pick<
    IInputWrapperProps,
    | 'name'
    | 'title'
    | 'subtitle'
    | 'error'
    | 'hasTooltip'
    | 'tooltipText'
    | 'tooltipContent'
    | 'isDisabled'
    | 'isRequired'
  > {
  value: any[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: (fileName: string) => void;
  onPrevFileClear: (file: IVideoFile) => void;
  prevAttachments?: IVideoFile[];
  onClearError?: () => void;
}

export const InputFileAttachments = (props: IInputFileNewProps) => {
  const {
    name,
    title,
    subtitle,
    value,
    onChange,
    onClear,
    onPrevFileClear,
    prevAttachments,
    hasTooltip,
    tooltipText,
    tooltipContent,
    error,
    isRequired,
    isDisabled,
    onClearError
  } = props;

  const inputFileRef: any = useRef();

  const clearFileInput = (e: any) => {
    e.target.value = null;
  };

  const filesForPreview =
    prevAttachments && prevAttachments.length
      ? [...prevAttachments, ...value]
      : value;

  return (
    <div className={classes['wrapper']}>
      {!!title && (
        <Label
          name={name}
          title={title}
          subtitle={subtitle}
          isRequired={isRequired}
          hasTooltip={hasTooltip}
          tooltipText={!isDisabled ? tooltipText || '' : ''}
          tooltipContent={!isDisabled ? tooltipContent : null}
          isDisabled={isDisabled}
        />
      )}
      <div>
        <Button
          variant="link-neutral"
          size="sm"
          icon={Attach}
          iconPosition="left"
          onClick={() => inputFileRef.current.click()}
        >
          Attach File(s) to Video
        </Button>
      </div>
      <label>
        <input
          ref={inputFileRef}
          type="file"
          accept=".pdf, .xlsx, .xls, .csv, image/*, .doc, .docx, .ppt, .pptx, .txt"
          onChange={onChange}
          onClick={clearFileInput}
          disabled={isDisabled}
          multiple
        />
      </label>
      {filesForPreview.length > 0 && (
        <div className={classes['attachments-box']}>
          {filesForPreview.map((file, i) => (
            <div key={i} className={classes['attachments-box__row']}>
              <div className={classes['attachments-box__title']}>
                {file.name}
              </div>
              <Button
                variant="link-neutral"
                icon={Trash}
                isIconBtn
                onClick={() => {
                  if (file.data) {
                    // Clear newly attached file
                    onClear(file.name);
                  } else {
                    // Clear previously attached file
                    onPrevFileClear(file);
                  }
                }}
              />
            </div>
          ))}
        </div>
      )}
      {!!error && (
        <div className={classes['input__error__wrapper']}>
          <div className={classes['input__error']}>{error}</div>
          {!!onClearError && (
            <Button
              variant="link-neutral"
              icon={Trash}
              isIconBtn
              onClick={onClearError}
              withPadding={false}
            />
          )}
        </div>
      )}
    </div>
  );
};
