import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Loader from 'components/loader/Loader';
import ErrorComponent from 'components/error/Error';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import FormSteps from 'components/forms/formSteps/FormSteps';
import VideoForm from '../components/VideoForm';
import { buildCompletionScoreOptions, testOptions } from '../components/Step2';
import {
  ISelectOption,
  ISingleCourseVideo,
  IResourceVideo,
  ICourse
} from 'query/course-module/dto';
import { courseVideoDataQuery } from 'query/course-module/queries';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './EditVideoDetailsPopup.module.scss';

const formSteps = ['Name & Description', 'Test', 'Attachments', 'Summary'];

interface IEditVideoDetailsPopup {
  videoId: IResourceVideo['_id'];
  course: ICourse;
  isInitial?: boolean;
  generatedThumbnail?: string;
}

const EditVideoDetailsPopup = ({
  videoId,
  course,
  isInitial,
  generatedThumbnail
}: IEditVideoDetailsPopup) => {
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [finishedSteps, setFinishedSteps] = useState<number>(0);

  const { _id: courseId } = course;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ISingleCourseVideo
  >(courseVideoDataQuery(courseId, videoId, `?&changes=${changes}`));

  const hasData = data && !!data._id;

  const { title, description, thumbnail, exam, completion_score, files } =
    data || {};

  const defaultExamData = !!exam?.length
    ? exam.reduce((acc: { [key: string]: string | number }, current, index) => {
        acc[`exam_question_${index + 1}`] = current.label;
        acc[`exam_correctAnswer_${index + 1}`] = current.correct_answer;
        current.answers.map(
          (answer, answerIndex) =>
            (acc[`exam_answer_${index + 1}_${answerIndex + 1}`] = answer.value)
        );
        return acc;
      }, {})
    : isInitial
    ? {
        exam_question_1: '',
        exam_correctAnswer_1: 1,
        exam_answer_1_1: '',
        exam_answer_1_2: ''
      }
    : {};

  const defaultFormData = {
    title: title || '',
    description: description || '',
    thumbnail: generatedThumbnail
      ? generatedThumbnail
      : thumbnail
      ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
      : '',
    // Newly attached files should come here
    attached_files: [],
    // Already attached files, which should not be passed again. We use this prop just to visualize what files have been previously attached.
    hidden_files: files,
    completion_score: completion_score
      ? buildCompletionScoreOptions().find(
          (opt: ISelectOption) => opt.value === completion_score
        )
      : { label: '> 50%', value: 50 },
    has_test: !!exam?.length ? testOptions : [],
    ...defaultExamData
  };

  return (
    <ModalWrapper size="rectangle">
      <div className={classes['wrapper']}>
        <h4 className={`${classes['u-semiBold']} ${classes['u-text--center']}`}>
          Edit Video Info
        </h4>
        {isLoading && <Loader size="lg" hasText withPadding />}
        {error && <ErrorComponent error={error} />}
        {hasData && (
          <>
            <FormSteps
              steps={formSteps}
              currentStep={currentStep}
              finishedSteps={finishedSteps}
              setCurrentStep={(step: number) => setCurrentStep(step)}
            />
            <VideoForm
              currentStep={currentStep}
              setStep={(step: number) => {
                setCurrentStep(step);
                setFinishedSteps(step === 0 ? 0 : step);
              }}
              course={course}
              videoId={videoId}
              defaultFormData={defaultFormData}
            />
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default EditVideoDetailsPopup;
