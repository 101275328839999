import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import RatingComponent from 'components/rating/Rating';
import { InputWrapper } from 'components/input/InputWrapper';
import GenericPopup from 'components/popup/GenericPopup';
import { ICourse } from 'query/course-module/dto';
import { fetchApi } from 'utils/requests';
import classes from './FeedbackPopup.module.scss';

interface IFeedbackPopupProps {
  course: ICourse;
}

const limit = 500;

const FeedbackPopup = ({ course }: IFeedbackPopupProps) => {
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const queryClient = useQueryClient();
  const { setPopup, clearPopup } = useContext(popupContext);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const { _id: courseId, slug } = course;

  const { isLoading, mutate } = useMutation({
    mutationKey: ['rate-course'],
    mutationFn: async () => {
      return await fetchApi('courses', `/course/${courseId}/feedback`, {
        method: 'POST',
        auth: true,
        data: {
          rating: rating,
          content: feedback
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`course-reviews-${courseId}`]);
      queryClient.invalidateQueries([
        `courses-data`,
        { id: slug, changes: changes }
      ]);
      setPopup(
        <GenericPopup
          title="Thank you for your review!"
          msg="You have successfully submitted your rating."
        />
      );
    },
    onError: (e: Error) => {
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  return (
    <ModalWrapper size="md">
      <div className={classes['feedback__container']}>
        <h4>Rating & Feedback</h4>
        <div className={classes['feedback__rate']}>
          <div>Rate this course</div>
          <RatingComponent
            rating={rating}
            setRating={setRating}
            onlyStars
            isYellow
          />
        </div>
        <InputWrapper
          type="textarea"
          variant="outline"
          title="Feedback"
          subtitle={`${feedback.length} of ${limit} characters used`}
          limit={limit}
          placeholder="Tell us about your own personal experience taking this course."
          onChange={setFeedback}
          value={feedback}
        />
        <div className={classes['feedback__btns-container']}>
          <Button
            onClick={() => mutate()}
            minWidth="full"
            isLoading={isLoading}
            isDisabled={!feedback || !rating}
          >
            Submit
          </Button>
          <Button variant="neutral" onClick={clearPopup} minWidth="full">
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default FeedbackPopup;
