import React from 'react';
import { IProgressData } from 'routes/dashboard/courses/single/videos';
import classes from './Progress.module.scss';

interface IProgress extends Pick<IProgressData, 'progress'> {
  className?: string;
  title?: string;
}

const Progress = ({ progress, className, title }: IProgress) => {
  return (
    <div className={classes['progress__wrapper']}>
      {!!title && <div className={classes['u-text--content']}>{title}</div>}
      <div className={`${classes['progress__container']} ${className}`}>
        <div className={classes['progress__bar']}>
          <div
            style={{
              width: `${progress}%`
            }}
            className={`${classes['progress__bar--active']} ${
              progress === 100 ? classes['progress__bar--active--full'] : ''
            }`}
          />
        </div>
        {!!progress && (
          <div className={classes['progress__number']}>{`${progress?.toFixed(
            0
          )}%`}</div>
        )}
      </div>
    </div>
  );
};

Progress.defaultProps = {
  progress: 0
};

export default Progress;
