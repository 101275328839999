import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import NoUsers from 'assets/images/no-users-gray.svg';
import { IUser } from 'context/userContext';
import CardRow from 'components/card/CardRow';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import Table from 'components/table/Table';
import { AdminUserCols } from 'components/table/AdminUserCols';
import EmptyState from 'components/emptyState/EmptyState';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { PurchasesCols } from 'components/table/PurchasesCols';
import {
  IAdminStatsData,
  ICourseData,
  IUsersData
} from 'query/course-module/dto';
import {
  adminPurchasesDataQuery,
  adminStatsDataQuery,
  adminSubmittedCoursesDataQuery,
  adminUsersDataQuery
} from 'query/course-module/queries';
import classes from './AdminDashboard.module.scss';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const adminUsersLimit: number = 3;
  const recentPurchasesLimit: number = 3;

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    adminSubmittedCoursesDataQuery()
  );

  const {
    isLoading: statsLoading,
    error: statsError,
    data: statsData
  } = useQuery<boolean, Error, IAdminStatsData>(adminStatsDataQuery());

  const {
    isLoading: adminUsersLoading,
    error: adminUsersError,
    data: adminUsersData
  } = useQuery<boolean, Error, IUsersData>(
    adminUsersDataQuery(`?&limit=${adminUsersLimit}&page=1`)
  );

  const {
    isLoading: recentPurchasesLoading,
    error: recentPurchasesError,
    data: recentPurchasesData
  } = useQuery<boolean, Error, IUsersData>(
    adminPurchasesDataQuery(`?&limit=${recentPurchasesLimit}&page=1`)
  );

  const hasAdminUsers = !!adminUsersData && !!adminUsersData?.result.length;
  const hasRecentPurchases =
    !!recentPurchasesData && !!recentPurchasesData?.result.length;

  const userCols = useMemo(() => AdminUserCols({}), []);
  const purchasesCols = useMemo(
    () => PurchasesCols({ hideActionBtn: false, isDashboardPage: true }),
    []
  );

  const renderStat = useCallback(
    (
      stat?: number,
      toFixed?: number,
      loaderSize?: 'sm' | 'lg',
      preText?: string
    ) => {
      const statString = stat?.toString();
      if (statsLoading)
        return <Loader size={loaderSize} className={classes['loader']} />;
      if (statsError) return <Error error={statsError} />;
      if (!statString) return ' - ';
      return ` ${preText || ''}${(+statString).toFixed(toFixed || 0)}`;
    },
    [statsLoading, statsError]
  );

  const prepStatsData: any = useMemo(
    () => ({
      nfts: {
        title: 'Activated NFTs',
        value: renderStat(statsData?.activated_nfts, 0, 'lg'),
        change: renderStat(
          statsData?.nft_activated_change?.percentDifference,
          0,
          'sm'
        )
      },
      courses: {
        title: 'Published Courses',
        value: renderStat(statsData?.published_courses),
        change: renderStat(
          statsData?.courses_change?.percentDifference,
          0,
          'sm'
        )
      },
      users: {
        title: 'Total Users',
        value: renderStat(statsData?.total_users),
        change: renderStat(statsData?.user_change?.percentDifference, 0, 'sm')
      },
      fees: {
        title: 'Fees Collected',
        value: renderStat(statsData?.fee_collected, 2, 'lg', '$'),
        change: renderStat(0, 0, 'sm')
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statsData]
  );

  const renderStatistics = () => {
    const values = Object.keys(prepStatsData).map((stat: any) => {
      return (
        <div key={stat} className={classes['dashboard__card']}>
          <h5>{prepStatsData[stat].title}</h5>
          <h2 className={classes['dashboard__card__value']}>
            {prepStatsData[stat].value}
          </h2>
          <div className={classes['dashboard__card__change']}>
            {prepStatsData[stat].change}% since last month
          </div>
        </div>
      );
    });
    return values;
  };

  return (
    <div className={classes['dashboard']}>
      <div className={classes['dashboard__cards-container']}>
        <>{renderStatistics()}</>
      </div>
      <div className={classes['section']}>
        <div className={classes['section__heading']}>
          <h5 className={classes['u-bold']}>Submitted Courses</h5>
          <Link to={`/admin/courses?status=1&page=1`}>View All</Link>
        </div>
        <CardRow
          queryData={{ isLoading, error, data: data as ICourseData }}
          actionBtnText="View All"
          onClick={(slug: string) => navigate(`/admin/courses/${slug}`)}
          viewAllRoute="/admin/courses?status=1&page=1"
          cardProps={{
            hasPrice: false,
            hasBadge: false,
            hasActionBtn: true,
            actionBtnText: 'View Course'
          }}
        />
      </div>
      <div className={classes['section']}>
        <div className={classes['section__heading']}>
          <h5 className={classes['u-bold']}>Recent Users</h5>
          <Link to={`/admin/users`}>View All</Link>
        </div>
        {!!adminUsersData?.result.length && (
          <Table
            columns={userCols}
            data={adminUsersData.result}
            onClickRow={(user: IUser) =>
              navigate(`/user/${user.slug}/about/main-info`)
            }
            rowHeight={80}
          />
        )}
        {adminUsersLoading && (
          <TableSkeleton rows={adminUsersLimit} cols={userCols.length} />
        )}
        {adminUsersError && <Error error={adminUsersError} />}
        {!hasAdminUsers && !adminUsersLoading && !adminUsersError && (
          <EmptyState
            icon={NoUsers}
            title="All Done!"
            message="There are no new users at the moment."
          />
        )}
      </div>
      <div className={classes['section']}>
        <div className={classes['section__heading']}>
          <h5 className={classes['u-bold']}>Recent Transactions</h5>
          <Link to={`/admin/transactions`}>View All</Link>
        </div>
        {!!recentPurchasesData?.result.length && (
          <Table
            columns={purchasesCols}
            data={recentPurchasesData.result}
            rowHeight={80}
          />
        )}
        {recentPurchasesLoading && (
          <TableSkeleton
            rows={recentPurchasesLimit}
            cols={purchasesCols.length}
          />
        )}
        {recentPurchasesError && <Error error={adminUsersError} />}
        {!hasRecentPurchases &&
          !recentPurchasesLoading &&
          !recentPurchasesError && (
            <EmptyState
              icon={NoUsers}
              title="All Done!"
              message="There are no recent transactions."
            />
          )}
      </div>
    </div>
  );
};

export default AdminDashboard;
