import React from 'react';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import useComponentVisible from 'hooks/useComponentVisible';
import Button from './Button';
import classes from './ContextMenu.module.scss';

interface IContextMenuProps {
  children: React.ReactNode;
}

const ContextMenu = ({ children }: IContextMenuProps) => {
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);

  return (
    <div className={classes['context-menu']} ref={ref}>
      <Button
        isIconBtn
        icon={Dots}
        iconRotate={90}
        variant="neutral"
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      />
      {isComponentVisible && (
        <div className={classes['context-menu__dropdown']}>{children}</div>
      )}
    </div>
  );
};

export default ContextMenu;
