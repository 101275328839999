import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import { InputWrapper } from 'components/input/InputWrapper';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import { manualRefundMutation } from 'query/course-module/mutations';
import classes from './ManualRefundPopup.module.scss';

interface IManualRefundPopupProps {
  userId: string;
  courseId: string;
  walletAddress: string;
}

const ManualRefundPopup = ({
  userId,
  courseId,
  walletAddress
}: IManualRefundPopupProps) => {
  const queryClient = useQueryClient();
  const { setToast } = useContext(toastContext);
  const { clearPopup } = useContext(popupContext);
  const [transactionHash, setTransactionHash] = useState('');

  const { isLoading, mutate: manualRefundHandler } = useMutation({
    ...manualRefundMutation(userId, courseId, transactionHash),
    onSuccess: () => {
      queryClient.invalidateQueries(['creator-transactions-data']);
      clearPopup();
      setToast({
        type: 'success',
        title: 'Successfull Refund',
        msg: 'Successfully refunded.',
        position: 'top',
        autoClose: true
      });
    },
    onError: async (err: any) => {
      setToast({
        type: 'error',
        position: 'top',
        title: 'Manual Refund Failed',
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  return (
    <ModalWrapper size="sm">
      <div className={classes['wrapper']}>
        <h4 className={classes['u-semiBold']}>Refund Selected User</h4>
        <h5 className={`${classes['u-body2']} ${classes['subtitle']}`}>
          The Refunded Users will not be able to watch videos and content from
          your Course
        </h5>
        <div className={classes['content']}>
          <div>
            <InputWrapper
              type="number"
              variant="outline"
              onChange={() => {}}
              title="Wallet Address"
              placeholder={walletAddress}
              className={classes['input']}
            />
          </div>
          <div>
            <InputWrapper
              type="text"
              variant="outline"
              value={transactionHash}
              title="Transaction Hash"
              className={classes['input']}
              onChange={setTransactionHash}
              placeholder="Enter Transaction Hash"
            />
          </div>
        </div>
        <div className={classes['btns-container']}>
          <Button
            onClick={() => manualRefundHandler()}
            isLoading={isLoading}
            isDisabled={!transactionHash}
            variant="contrast"
            minWidth="full"
          >
            Confirm
          </Button>
          <Button variant="neutral" minWidth="full" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ManualRefundPopup;
