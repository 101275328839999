import React, { useContext } from 'react';
import Select, {
  components,
  OptionProps,
  SingleValueProps
} from 'react-select';
import { appContext } from 'context/appContext';
import { IReactSelectWrapper } from './ReactSelectWrapper';
import { ISelectOption } from 'query/course-module/dto';
import classes from './ReactSelect.module.scss';

interface IReactSelect
  extends Pick<
    IReactSelectWrapper,
    | 'name'
    | 'placeholder'
    | 'value'
    | 'options'
    | 'onChange'
    | 'onBlur'
    | 'isDisabled'
    | 'isLoading'
    | 'isMulti'
    | 'isClearable'
    | 'isSearchable'
    | 'width'
    | 'isCurrency'
    | 'noChevron'
    | 'noDropdown'
  > {
  hasError: boolean;
}

const ReactSelect = ({
  name,
  placeholder,
  value,
  options,
  onChange,
  onBlur,
  isDisabled,
  isLoading,
  isMulti,
  hasError,
  isClearable,
  isSearchable,
  width,
  isCurrency,
  noChevron,
  noDropdown
}: IReactSelect) => {
  const { theme } = useContext(appContext);
  const isDark = theme === 'dark';
  const isEmpty = !value;

  const buildCustomStyles = () => ({
    control: (styles: any) => ({
      ...styles,
      height: '48px',
      borderRadius: '8px',
      width: width || 'auto',
      color: '#050505',
      border: hasError
        ? `1px solid #ea1548 !important`
        : '1px solid var(--input-border-color)',
      boxShadow: 'none',
      background: 'transparent',
      ':hover': {
        ...styles[':hover'],
        border: hasError
          ? `1px solid #ea1548 !important`
          : '1px solid var(--input-border-color-hover)'
        // boxShadow: isDark
        //   ? `inset 0 0 0 1px rgb(${colors.greyishBlue60})`
        //   : `inset 0 0 0 1px rgb(${colors.lightBlue90})`
      },

      ':focus-within': {
        ...styles[':focus-within'],
        border: '1px solid var(--input-border-color-active)'
        // backgroundColor: isDark
        //   ? `rgb(${colors.greyishBlue80})`
        //   : `rgb(${colors.lightBlue20})`,
        // boxShadow: isDark
        //   ? `inset 0 0 0 2px rgb(${colors.greyishBlue60})`
        //   : `inset 0 0 0 2px rgb(${colors.lightBlue90})`
      },

      // Searchable text
      "[type='text']": {
        color: 'var(--input-text-active) !important'
      }
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: '14px',
      margin: '0px',
      color: isDisabled
        ? 'var(--input-text-disabled)'
        : isEmpty
        ? 'var(--input-text)'
        : 'var(--input-text-active)'
    }),
    valueContainer: (base: any) => ({
      ...base,
      display: isMulti ? 'flex' : 'grid',
      position: 'auto',
      marginLeft: '16px',
      height: 'auto',
      padding: 'auto'
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      padding: '',
      div: {
        padding: ''
      },
      svg: {
        display: 'flex',
        alignItems: 'center'
        // fill: isDark
        //   ? `rgb(${colors.greyishBlue50})`
        //   : `rgb(${colors.greyishBlue30})`,
        // ':hover': {
        //   fill: isDark
        //     ? `rgb(${colors.lightBlue10})`
        //     : `rgb(${colors.darkBlue100})`
        // }
      }
    }),
    singleValue: (base: any) => ({
      ...base,
      color: isDisabled
        ? 'var(--input-text-disabled)'
        : isEmpty
        ? 'var(--input-text)'
        : 'var(--input-text-active)'
    }),
    clearIndicator: (styles: any) => ({
      ...styles,
      cursor: 'pointer'
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      backgroundColor: 'transparent'
    }),
    dropdownIndicator: (styles: any, state: any) => ({
      ...styles,
      display: noChevron ? 'none' : 'auto'
      // color: `rgb(${colors.greyishBlue30})`,
      // transition: 'color 350ms, transform 150ms',
      // transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      // cursor: 'pointer'
      // ':hover': {
      //   ...styles[':hover'],
      //   color: `rgb(${colors.greyishBlue30})`
      // }
    }),
    menu: (styles: any) => ({
      ...styles,
      display: noDropdown ? 'none' : 'block',
      backgroundColor: isDark ? `#050505` : `#ffffff`,
      border: isDark ? '1px solid #2c2c2c' : '1px solid #dddddd',
      // color:
      //   isDark && !isDisabled
      //     ? `rgb(${colors.lightBlue10})`
      //     : isDark && isDisabled
      //     ? `rgb(${colors.greyishBlue70})`
      //     : !isDark && !isDisabled
      //     ? `rgb(${colors.darkBlue100})`
      //     : `rgb(${colors.greyishBlue20})`,
      borderRadius: '8px',
      boxShadow: 'none',
      zIndex: 10
    }),
    menuList: (styles: any) => ({
      ...styles,
      padding: '4px 8px',
      maxHeight: '220px'
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? isDark
            ? `#2c2c2c`
            : `#e5e5e5`
          : isFocused
          ? isDark
            ? `#1f1f1f`
            : `#f6f6f6`
          : undefined,
        color: isDisabled ? '#ccc' : isDark ? '#ffffff' : '#050505',
        padding: '12px 16px',
        margin: '4px 0',
        borderRadius: '8px',
        fontSize: '14px',
        fontFamily: 'Poppins Regular',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        // height: '48px',
        // display: 'flex',
        // alignItems: 'center',
        transition: 'all 150ms',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : isDark
              ? `#2c2c2c`
              : `#e5e5e5`
            : undefined
        }
        //
        // ':hover': {
        //   ...styles[':hover'],
        //   backgroundColor: !isDisabled
        //     ? isSelected
        //       ? isDark
        //         ? `rgb(${colors.greyishBlue70})`
        //         : `rgb(${colors.lightBlue30})`
        //       : isDark
        //       ? `rgb(${colors.greyishBlue70})`
        //       : `rgb(${colors.lightBlue30})`
        //     : undefined
        // }
      };
    }
    // multiValue: (styles: any) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isDark
    //       ? `rgb(${colors.greyishBlue70})`
    //       : `rgb(${colors.lightBlue50})`,
    //     borderRadius: '8px',
    //     padding: '0 6px'
    //   };
    // },
    // multiValueLabel: (styles: any, { isDisabled }: any) => ({
    //   ...styles,
    //   color: isDark
    //     ? isDisabled
    //       ? `rgb(${colors.greyishBlue60})`
    //       : `rgb(${colors.lightBlue10})`
    //     : isDisabled
    //     ? `rgb(${colors.greyishBlue20})`
    //     : `rgb(${colors.darkBlue100})`,
    //   fontSize: '14px'
    // }),
    // multiValueRemove: (styles: any, { data }: any) => ({
    //   ...styles,
    //   color: isDark
    //     ? isDisabled
    //       ? `rgb(${colors.greyishBlue60})`
    //       : `rgb(${colors.lightBlue10})`
    //     : isDisabled
    //     ? `rgb(${colors.greyishBlue20})`
    //     : `rgb(${colors.darkBlue100})`,
    //   cursor: 'pointer',
    //   ':hover': {
    //     backgroundColor: data.color,
    //     color: isDark
    //       ? `rgb(${colors.lightBlue10})`
    //       : `rgb(${colors.greyishBlue70})`
    //   }
    // })
  });

  const Option = (props: OptionProps<ISelectOption>) => (
    <div>
      <components.Option {...props}>
        <div className={classes['flex']}>
          <img
            src={props.data.symbol}
            alt={props.data.label}
            width={24}
            height={24}
          />
          {props.data.label}
        </div>
      </components.Option>
    </div>
  );

  const SingleValue = (props: SingleValueProps<ISelectOption>) => (
    <components.SingleValue {...props}>
      <div className={classes['flex']}>
        <img
          src={props.data.symbol}
          alt={props.data.label}
          width={24}
          height={24}
        />
        {props.data.label}
      </div>
    </components.SingleValue>
  );

  return (
    <Select
      className={''}
      defaultValue={value}
      value={value}
      isMulti={isMulti}
      name={name}
      placeholder={placeholder}
      options={options}
      components={{
        ...(isCurrency ? { Option, SingleValue } : {})
      }}
      onChange={onChange}
      onBlur={onBlur}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable={isSearchable || false}
      hideSelectedOptions={false}
      closeMenuOnSelect={!isMulti}
      styles={buildCustomStyles()}
      isClearable={isClearable}
      // menuPortalTarget={document.body}
      // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
    />
  );
};

export default ReactSelect;
