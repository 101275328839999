import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as TestIcon } from 'assets/images/no-test-questions.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as ArrowsCircle } from 'assets/icons/arrows-circle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-big.svg';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import Progress from 'components/progress/Progress';
import GenericPopup from 'components/popup/GenericPopup';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import { IOption, InputChoice } from 'components/input/InputChoice';
import { IExam, ITest } from 'query/course-module/dto';
import { submitTestMutation } from 'query/course-module/mutations';
import { colors } from 'utils/styles';
import classes from './VideoTestsPopup.module.scss';

interface IVideoTestsPopup {
  test: ITest;
  isPrivateView?: boolean;
}

const VideoTestsPopup = ({ test, isPrivateView }: IVideoTestsPopup) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [testData, setTestData] = useState(test);

  const {
    exam,
    video_id,
    course_id,
    test_passed,
    video_title,
    completion_score,
    last_submission
  } = testData;

  const [answers, setAnswers] = useState<IOption[]>([]);
  const { clearPopup, setPopup } = useContext(popupContext);
  const [currentExamIndex, setCurrentExamIndex] = useState(0);
  const [showSummary, setShowSummary] = useState(!!last_submission);
  const [checkMode, setCheckMode] = useState(!!isPrivateView);
  const [examStatus, setExamStatus] = useState('');

  const { isLoading, mutate: submitHandler } = useMutation({
    mutationKey: ['submit-test'],
    mutationFn: async () =>
      await submitTestMutation(
        course_id,
        video_id,
        answers.map((e) => Number(e.value))
      ).mutationFn(),
    onSuccess: (response) => {
      const { score, required_score, last_submission, status } = response;
      const updatedTest = {
        ...test,
        last_submission,
        test_passed: test_passed || (status === 'passed' ? true : false)
      };
      queryClient.invalidateQueries({
        queryKey: ['public-course-resources-data', { id: course_id }]
      });
      setTestData(updatedTest);
      if (score >= required_score) {
        setExamStatus('success');
      } else {
        setExamStatus('failed');
      }
    },
    onError: (e: any) => {
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  const submittedAnswer = (exam: IExam) => {
    let correctAnswer;
    // eslint-disable-next-line array-callback-return
    const findAnswer = exam.answers.filter((answer) => {
      if (
        (isPrivateView && answer.id === exam.correct_answer) ||
        (!isPrivateView &&
          answer.id ===
            last_submission?.answers[currentExamIndex].answer_submitted)
      ) {
        return answer;
      }
    })[0];
    correctAnswer = {
      label: findAnswer?.value,
      value: findAnswer?.id
    };
    return [correctAnswer];
  };

  return (
    <ModalWrapper size="lg" onClose={clearPopup}>
      <div className={classes['popup-container']}>
        <div className={classes['content']}>
          <TestIcon width={96} height={96} />
          <div className={classes['content__title-wrapper']}>
            <div className={`${classes['u-bold']} ${classes['u-h5']}`}>
              {video_title}
            </div>
            <div className={classes['content__subtitle-container']}>
              <span>Complete with at least {completion_score}% to pass</span>
              <Divider dir="vertical" />
              <span>{exam.length} Questions</span>
            </div>
          </div>
        </div>
        {showSummary ? (
          <>
            <Divider />
            <div className={classes['summary']}>
              <div className={classes['summary__grade-wrapper']}>
                <span
                  className={`${classes['summary__title']} ${classes['u-semiBold']}`}
                >
                  Your Grade
                </span>
                <div
                  className={`${
                    classes[
                      `summary__score${
                        last_submission?.score >= completion_score
                          ? '--success'
                          : '--error'
                      }`
                    ]
                  }
                  ${classes['u-bold']} ${classes['u-h5']}`}
                >
                  {last_submission?.score.toFixed(2)}%
                </div>
              </div>
              <Button
                size="md"
                minWidth="lg"
                onClick={() => {
                  setCheckMode(true);
                  setShowSummary(false);
                }}
              >
                View Feedback
              </Button>
            </div>
            <Divider />
            <Button
              size="md"
              minWidth="md"
              variant="link-contrast"
              onClick={() => setShowSummary(false)}
            >
              Try Test Again
            </Button>
          </>
        ) : (
          <>
            <div className={classes['exam']}>
              <div className={classes['exam__title']}>
                {`${currentExamIndex + 1}. ${exam[currentExamIndex].label}`}
              </div>
              <div
                className={`${classes['exam__input-wrapper']} ${
                  classes[checkMode || examStatus ? 'exam__inactive' : '']
                }`}
              >
                <InputChoice
                  type="radio"
                  options={exam[currentExamIndex].answers.map((answer) => ({
                    label: answer.value,
                    value: answer.id
                  }))}
                  // If the checkMode is on we show the if the submitted answer is correct or wrong
                  selectedOptions={
                    checkMode && (last_submission || isPrivateView)
                      ? submittedAnswer(exam[currentExamIndex])
                      : answers[currentExamIndex]
                      ? [answers[currentExamIndex]]
                      : []
                  }
                  onChange={(e) => {
                    const answersData = [...answers];
                    answersData[currentExamIndex] = e[0];
                    setAnswers(answersData);
                  }}
                  isCheckedInputBordered={!checkMode}
                  // If the checkmode is on we add styles to the answered input field
                  verifiedStatus={
                    checkMode && last_submission
                      ? last_submission?.answers[currentExamIndex].is_correct
                        ? 'success'
                        : 'error'
                      : isPrivateView
                      ? 'success'
                      : undefined
                  }
                />
              </div>
            </div>
            <div className={classes['progress-wrapper']}>
              <div className={classes['u-body3']}>{`${currentExamIndex + 1}/${
                exam.length
              }`}</div>
              <Progress
                progress={((currentExamIndex + 1) * 100) / exam.length}
                className={classes['progress']}
              />
            </div>
            {/* We update examStatus if the test is submitted */}
            {examStatus && currentExamIndex === exam.length - 1 && (
              <div
                className={`${classes['status-wrapper']} ${
                  classes[`status-wrapper--${examStatus}`]
                }`}
              >
                <div className={classes[`status-wrapper__icon-and-status`]}>
                  <div>
                    {examStatus === 'success' ? (
                      <CheckIcon
                        width={53}
                        height={53}
                        fill={colors.success40}
                      />
                    ) : (
                      <ErrorIcon width={53} height={53} />
                    )}
                  </div>
                  <div className={classes['status-wrapper__status']}>
                    <h5
                      className={`${classes['u-bold']} ${classes['u-body1']} ${
                        classes[`status-wrapper__status--${examStatus}`]
                      }`}
                    >
                      {examStatus === 'success' ? 'Success!' : 'Test Failed!'}
                    </h5>
                    {`Your score is: ${last_submission?.score.toFixed(0)}%. `}
                    {examStatus === 'failed' &&
                      `Get ${completion_score.toFixed(0)}% to pass the test.`}
                  </div>
                </div>
                <div>
                  {!checkMode && (
                    <Button
                      size="md"
                      minWidth="md"
                      iconRotate={90}
                      icon={ArrowIcon}
                      variant="link-neutral"
                      onClick={() => {
                        setCheckMode(true);
                        setShowSummary(false);
                        setCurrentExamIndex(0);
                      }}
                    >
                      View Feedback
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div className={classes['btns-container']}>
              {currentExamIndex > 0 && exam.length > 1 && !examStatus && (
                <Button
                  size="md"
                  minWidth="md"
                  iconRotate={270}
                  icon={ArrowIcon}
                  variant="neutral"
                  iconPosition="left"
                  onClick={() => setCurrentExamIndex(currentExamIndex - 1)}
                >
                  Previous
                </Button>
              )}
              {currentExamIndex + 1 < exam.length ? (
                <Button
                  size="md"
                  minWidth="md"
                  iconRotate={90}
                  icon={ArrowIcon}
                  isDisabled={!answers[currentExamIndex] && !checkMode}
                  className={classes['btns-container__right-button']}
                  onClick={() => setCurrentExamIndex(currentExamIndex + 1)}
                >
                  Next Question
                </Button>
              ) : (checkMode || examStatus) &&
                last_submission?.score < completion_score ? (
                <Button
                  size="md"
                  minWidth="md"
                  iconRotate={90}
                  icon={ArrowsCircle}
                  isLoading={isLoading}
                  onClick={() => {
                    setAnswers([]);
                    setExamStatus('');
                    setCheckMode(false);
                    setCurrentExamIndex(0);
                  }}
                  className={classes['btns-container__right-button']}
                >
                  Try again
                </Button>
              ) : !examStatus && !checkMode ? (
                <Button
                  size="md"
                  minWidth="md"
                  iconRotate={90}
                  icon={ArrowIcon}
                  isLoading={isLoading}
                  onClick={() => submitHandler()}
                  isDisabled={!answers[currentExamIndex]}
                  className={classes['btns-container__right-button']}
                >
                  Submit Question
                </Button>
              ) : (
                <Button
                  size="md"
                  minWidth="md"
                  iconRotate={90}
                  icon={
                    !!location?.state?.nextVideoLink ? ArrowIcon : undefined
                  }
                  onClick={() => {
                    clearPopup();
                    !!location?.state?.nextVideoLink &&
                      navigate(location.state.nextVideoLink, {
                        state: { autoPlay: true }
                      });
                  }}
                  className={classes['btns-container__right-button']}
                >
                  {!!location?.state?.nextVideoLink
                    ? 'Watch next video'
                    : 'Close'}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default VideoTestsPopup;
