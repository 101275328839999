import { useEffect } from 'react';

export const useScrollTo = (
  trigger: any[],
  condition: boolean,
  elementId: string
) => {
  useEffect(() => {
    if (condition) {
      const element = document.getElementById(elementId);
      return element?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [trigger, elementId, condition]);

  return null;
};

export const useScrollToTop = (trigger: any[], condition: boolean) => {
  useEffect(() => {
    if (condition) {
      const element = document.getElementById('app_wrapper');
      return element?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [trigger, condition]);

  return null;
};
