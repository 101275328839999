import { fetchApi } from 'utils/requests';
import { COURSE_SEGMENT } from './dto';

export const buildQueryKey = (params?: string) => {
  if (!params) return {};

  const splittedParams = params.split('&').slice(1);
  const arr = splittedParams.map((item) => {
    const [k, v] = item.split('=');
    if (v) {
      return {
        [k]: v
      };
    } else {
      return false;
    }
  });
  return arr.reduce(
    (a, v) => ({
      ...a,
      ...v
    }),
    {}
  );
};

export const creatorCoursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['creator-courses-data', queryKeyParams]
      : ['creator-courses-data'],
    queryFn: async () =>
      await fetchApi('courses', `/course/user/created${searchParams}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const userCoursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['user-courses-data', queryKeyParams]
      : ['user-courses-data'],
    queryFn: async () =>
      await fetchApi('courses', `/course${searchParams}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const purchasedCoursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['purchased-courses-data', queryKeyParams]
      : ['purchased-courses-data'],
    queryFn: async () =>
      await fetchApi('courses', `/course/user/purchased${searchParams}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const singleCourseDataQuery = (id: string, searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['courses-data', { id, ...queryKeyParams }]
      : ['courses-data', { id }],
    queryFn: async () =>
      await fetchApi('courses', `/course/${id}${searchParams || ''}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const courseCategoriesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['course-categories-data', queryKeyParams]
      : ['course-categories-data'],
    queryFn: async () =>
      await fetchApi('courses', `/course/category${searchParams || ''}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const courseSubcategoriesDataQuery = (ids: string) => {
  return {
    queryKey: ['course-subcategories-data', { ids }],
    queryFn: async () =>
      await fetchApi('courses', `/course/subcategory?category=${ids}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const adminCourseResourcesDataQuery = (
  id: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['admin-course-resources-data', { id, ...queryKeyParams }]
      : ['admin-course-resources-data', { id }],
    queryFn: async () =>
      await fetchApi(
        'courses',
        `/admin/course/${id}/resources${searchParams || ''}`,
        {
          method: 'GET',
          auth: true
        }
      )
  };
};

export const courseResourcesDataQuery = (id: string, searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['course-resources-data', { id, ...queryKeyParams }]
      : ['course-resources-data', { id }],
    queryFn: async () =>
      await fetchApi(
        'courses',
        `/course/user/created/${id}/resources${searchParams || ''}`,
        {
          method: 'GET',
          auth: true
        }
      )
  };
};

export const publicCourseResourcesDataQuery = (id: string) => {
  return {
    queryKey: ['public-course-resources-data', { id }],
    queryFn: async () =>
      await fetchApi('courses', `/course/${id}/resources`, {
        method: 'GET',
        auth: true
      })
  };
};

export const courseVideoDataQuery = (
  courseId: string,
  videoId: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['course-video-data', { courseId, videoId, ...queryKeyParams }]
      : ['course-video-data', { courseId, videoId }],
    queryFn: async () =>
      await fetchApi(
        'courses',
        `/course/user/created/${courseId}/video/${videoId}${
          searchParams || ''
        }`,
        {
          method: 'GET',
          auth: true
        }
      )
  };
};

export const adminCourseVideoDataQuery = (
  courseId: string,
  videoId: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['admin-course-video-data', { courseId, videoId, ...queryKeyParams }]
      : ['admin-course-video-data', { courseId, videoId }],
    queryFn: async () =>
      await fetchApi(
        'courses',
        `/admin/course/${courseId}/video/${videoId}${searchParams || ''}`,
        {
          method: 'GET',
          auth: true
        }
      )
  };
};

export const activatedCourseVideoDataQuery = (
  courseId: string,
  videoId: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? [
          'activated-course-video-data',
          { courseId, videoId, ...queryKeyParams }
        ]
      : ['activated-course-video-data', { courseId, videoId }],
    queryFn: async () =>
      await fetchApi('courses', `/course/${courseId}/video/${videoId}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const singleTemplateDataQuery = (id: string) => {
  return {
    queryKey: [`single-template`, { id }],
    queryFn: () =>
      fetchApi('courses', `/template/${id}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const allTemplatesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['templates-data', queryKeyParams]
      : ['templates-data'],
    queryFn: () =>
      fetchApi('courses', `/template${searchParams || ''}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const adminCoursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['admin-courses-data', queryKeyParams]
      : ['admin-courses-data'],
    queryFn: async () =>
      await fetchApi('courses', `/admin/course${searchParams || ''}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const adminSingleCourseDataQuery = (
  id: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['admin-courses-data', { id, ...queryKeyParams }]
      : ['admin-courses-data', { id }],
    queryFn: async () =>
      await fetchApi('courses', `/admin/course/${id}${searchParams || ''}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const tokenPairsDataQuery = () => {
  return {
    queryKey: ['token-pairs'],
    queryFn: async () =>
      await fetchApi('courses', '/course/swap/pairs', {
        method: 'GET',
        auth: true
      })
  };
};

export const adminTokenPairsDataQuery = () => {
  return {
    queryKey: ['admin-token-pairs'],
    queryFn: async () =>
      await fetchApi('courses', '/admin/pairs', {
        method: 'GET',
        auth: true
      })
  };
};

export const adminSubmittedCoursesDataQuery = () => {
  return {
    queryKey: ['admin-submitted-courses-data'],
    queryFn: async () => {
      return await fetchApi(
        'courses',
        '/admin/course?&limit=4&page=1&review_status=1',
        {
          method: 'GET',
          auth: true
        }
      );
    }
  };
};

export const adminCourseHistory = (slug: string, searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['admin-course-history', { slug, ...queryKeyParams }]
      : ['admin-course-history', { slug }],
    queryFn: async () =>
      await fetchApi(
        'courses',
        `/admin/course/${slug}/admin-history${searchParams || ''}`,
        {
          method: 'GET',
          auth: true
        }
      )
  };
};

export const adminStatsDataQuery = () => {
  return {
    queryKey: ['admin-statistics-data'],
    queryFn: async () => {
      return await fetchApi('courses', '/admin/statistics', {
        method: 'GET',
        auth: true
      });
    }
  };
};

export const adminUsersDataQuery = (searchParams: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams);
  return {
    queryKey: hasQueryKeyParams
      ? ['admin-users-data', queryKeyParams]
      : ['admin-users-data'],
    queryFn: async () => {
      return await fetchApi('courses', `/admin/user${searchParams}`, {
        method: 'GET',
        auth: true
      });
    }
  };
};

export const adminPurchasesDataQuery = (searchParams: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams);
  return {
    queryKey: hasQueryKeyParams
      ? ['admin-purchases-data', queryKeyParams]
      : ['admin-purchases-data'],
    queryFn: async () => {
      return await fetchApi('courses', `/admin/purchases${searchParams}`, {
        method: 'GET',
        auth: true
      });
    }
  };
};

export const creatorTransactionsDataQuery = (searchParams: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams);
  return {
    queryKey: hasQueryKeyParams
      ? ['creator-transactions-data', queryKeyParams]
      : ['creator-transactions-data'],
    queryFn: async () => {
      return await fetchApi(
        'courses',
        `/creator/transactions/${searchParams}`,
        {
          method: 'GET',
          auth: true
        }
      );
    }
  };
};

export const userPurchasesDataQuery = (searchParams: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams);
  return {
    queryKey: hasQueryKeyParams
      ? ['user-purchases-data', queryKeyParams]
      : ['user-purchases-data'],
    queryFn: async () => {
      return await fetchApi(
        'courses',
        `/course/user/purchased${searchParams}`,
        {
          method: 'GET',
          auth: true
        }
      );
    }
  };
};

export const contentCreatorReportQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['creator-report', queryKeyParams]
      : ['creator-report'],

    queryFn: async () => {
      return await fetchApi('courses', `/creator/report${searchParams}`, {
        method: 'GET',
        auth: true
      });
    }
  };
};

export const adminReportQuery = (queryKey: string, searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams ? [queryKey, queryKeyParams] : [queryKey],

    queryFn: async () => {
      return await fetchApi('courses', `/admin/report${searchParams}`, {
        method: 'GET',
        auth: true
      });
    }
  };
};

export const coursesDataQuery = (segment: COURSE_SEGMENT, amount?: number) => {
  const limitUrlParam = !!amount ? `&limit=${amount}` : '';
  return {
    queryKey: [
      'most-popular-courses-data',
      { limit: amount, segment: segment }
    ],
    queryFn: async () => {
      return await fetchApi(
        'courses',
        `/course?segment=${segment}${limitUrlParam}&page=1`,
        {
          method: 'GET',
          auth: true
        }
      );
    }
  };
};

export const categoriesDataQuery = () => {
  return {
    queryKey: ['categories-data'],
    queryFn: async () => {
      return await fetchApi('courses', '/course/category', {
        method: 'GET',
        auth: true
      });
    }
  };
};

export const topCategoriesDataQuery = () => {
  return {
    queryKey: ['top-categories-data'],
    queryFn: async () => {
      return await fetchApi(
        'courses',
        '/course/category?sort=-counter&limit=6',
        {
          method: 'GET',
          auth: true
        }
      );
    }
  };
};

export const singlePageDataQuery = (slug: string) => {
  return {
    queryKey: ['single-page', { slug }],
    queryFn: async () =>
      await fetchApi('courses', `/page/by/slug/${slug}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const allPagesDataQuery = () => {
  return {
    queryKey: ['all-pages'],
    queryFn: async () =>
      await fetchApi('courses', '/admin/page', {
        method: 'GET',
        auth: true
      })
  };
};

export const userCertificatesQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['certificates-data', queryKeyParams]
      : ['certificates-data'],
    queryFn: async () => {
      return await fetchApi(
        'courses',
        `/user-course/certificates${searchParams || ''}`,
        {
          method: 'GET',
          auth: true
        }
      );
    }
  };
};

export const userNotificationsQuery = () => {
  return {
    queryKey: ['user-notifications'],
    queryFn: async () => {
      return await fetchApi('courses', `/notification`, {
        method: 'GET',
        auth: true
      });
    }
  };
};

export const topCoursesAndCreatorsQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['courses-creators-data', queryKeyParams]
      : ['courses-creators-data'],
    queryFn: async () =>
      await fetchApi('courses', `/course/search${searchParams}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const usersQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ['users-data', queryKeyParams]
      : ['users-data'],
    queryFn: async () =>
      await fetchApi('courses', `/creator${searchParams}`, {
        method: 'GET',
        auth: true
      })
  };
};

export const platformSettingsQuery = () => {
  return {
    queryKey: ['platform-settings'],
    queryFn: async () =>
      await fetchApi('courses', '/settings', {
        method: 'GET'
      })
  };
};

export const publishFeesQuery = (id: string) => {
  return {
    queryKey: [`payment-data`, { id }],
    queryFn: async () => {
      return await fetchApi('courses', `/course/${id}/payments`, {
        method: 'GET',
        auth: true
      });
    }
  };
};
