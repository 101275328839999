import React from 'react';
import Tabs from 'components/tabs/Tabs';
import Published from './Published';
import Approved from './Approved';
import Submitted from './Submitted';
import Draft from './Draft';
import Rejected from './Rejected';
import { IProfile } from '../../index';
import Created from '../certificates/Created';

interface ICreatedCoursesProps extends Pick<IProfile, 'subTab'> {}

const CreatedCourses = ({ subTab }: ICreatedCoursesProps) => {
  const tabs = [
    {
      id: 'published',
      name: 'Published Courses',
      route: 'user/created-courses/published',
      component: () => <Published />
    },
    {
      id: 'approved',
      name: 'Approved Courses',
      route: 'user/created-courses/approved',
      component: () => <Approved />
    },
    {
      id: 'submitted',
      name: 'Submitted Courses',
      route: 'user/created-courses/submitted',
      component: () => <Submitted />
    },
    {
      id: 'draft',
      name: 'Draft Courses',
      route: 'user/created-courses/draft',
      component: () => <Draft />
    },
    {
      id: 'rejected',
      name: 'Rejected Courses',
      route: 'user/created-courses/rejected',
      component: () => <Rejected />
    },
    {
      id: 'certificate-templates',
      name: 'Certificate Templates',
      route: 'user/created-courses/certificates',
      component: () => <Created />,
      hasDivider: true
    }
  ];
  return (
    <Tabs
      tabs={tabs}
      activeTabIndex={tabs.findIndex((t) => t.id === subTab)}
      size={'sm'}
      isVertical
    />
  );
};

export default CreatedCourses;
