import React from 'react';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import Tooltip from '../tooltip/Tooltip';
import { IInputWrapperProps } from './InputWrapper';
import classes from './Label.module.scss';

interface ILabelProps
  extends Pick<
    IInputWrapperProps,
    | 'name'
    | 'title'
    | 'subtitle'
    | 'isRequired'
    | 'isDisabled'
    | 'hasTooltip'
    | 'tooltipContent'
  > {
  tooltipText: string;
}

const Label = ({
  name,
  title,
  isRequired,
  isDisabled,
  hasTooltip,
  tooltipText,
  tooltipContent,
  subtitle
}: ILabelProps) => (
  <div
    className={`${classes['label__wrapper']} ${
      isDisabled ? classes['label__wrapper--disabled'] : ''
    }`}
  >
    <div className={classes['label__title']}>
      <label>{`${title}${isRequired ? '*' : ''}`}</label>&nbsp;
      {hasTooltip && (
        <Tooltip
          id={`tooltip-${name}`}
          text={tooltipText}
          tooltipContent={tooltipContent}
        >
          <QuestionIcon className={classes['label__tooltip']} />
        </Tooltip>
      )}
    </div>
    {subtitle && <div className={classes['label__subtitle']}>{subtitle}</div>}
  </div>
);

Label.defaultProps = {
  name: '',
  title: '',
  subtitle: '',
  isRequired: false,
  isDisabled: false,
  hasTooltip: false,
  tooltipText: '',
  tooltipContent: null
};

export default Label;
