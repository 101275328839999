import { PROJECT_ID } from 'utils/constants';
import { fetchApi } from 'utils/requests';
import {
  IChangePasswordDto,
  IEditProfileDto,
  IForgotPasswordDto,
  IHiddenFields,
  ILoginDto,
  IRegisterDto,
  IResetPasswordExtendedDto,
  IVerifyAccountDto,
  IEditSocialDto,
  IVerifyNewsletterDto
} from './dto';
import {
  IEditProfileFormFields,
  IEditSocialFormFields,
  ILoginFormFields
} from 'utils/yupSchemas';

// Login mutation
const login = async (data: ILoginDto) => {
  const response = await fetchApi('account', '/user/login', {
    method: 'POST',
    data: {
      project_id: PROJECT_ID.OPUS_EDU,
      ...data
    }
  });
  const { accessToken } = response;
  return accessToken;
};

export const loginMutation = () => ({
  mutationKey: ['login'],
  mutationFn: async (data: ILoginFormFields) => {
    const rememberBool = !!data.remember.length
      ? data.remember[0].value === 'true'
      : false;
    const prepDate: ILoginDto = { ...data, remember: rememberBool };
    return await login(prepDate);
  }
});

// Forgot password mutation
const forgotPassword = async (data: IForgotPasswordDto) => {
  await fetchApi('account', '/user/forgetPass', {
    method: 'POST',
    data: {
      project_id: PROJECT_ID.OPUS_EDU,
      ...data
    }
  });
};

export const forgotPassMutation = () => ({
  mutationKey: ['forgot-password'],
  mutationFn: async (data: IForgotPasswordDto) => {
    await forgotPassword(data);
    return data;
  }
});

// Reset password mutation
const resetPassword = async ({
  new_pass,
  uid,
  hash
}: IResetPasswordExtendedDto) => {
  await fetchApi('account', `/user/resetPass/${uid}/${hash}`, {
    method: 'POST',
    data: {
      new_pass: new_pass
    }
  });
};

export const resetPassMutation = () => ({
  mutationKey: ['reset-password'],
  mutationFn: async ({ new_pass, uid, hash }: IResetPasswordExtendedDto) =>
    await resetPassword({ new_pass, uid, hash })
});

// Register mutation
const register = async (data: IRegisterDto) => {
  await fetchApi('account', '/user/register', {
    method: 'POST',
    data: {
      project_id: PROJECT_ID.OPUS_EDU,
      ...data
    }
  });
};

export const registerMutation = () => ({
  mutationKey: ['register'],
  mutationFn: async (data: IRegisterDto) => await register(data)
});

// Verify registration
const verify = async ({ uid, hash }: IVerifyAccountDto) => {
  await fetchApi('account', `/user/verify/${uid}/${hash}`, {
    method: 'PUT'
  });
};

export const verifyRegMutation = () => ({
  mutationKey: ['verify-reg'],
  mutationFn: async ({ uid, hash }: IVerifyAccountDto) =>
    await verify({ uid, hash })
});

// Verify newsletter
const verifyNewsletter = async ({ sub_id, hash }: IVerifyNewsletterDto) => {
  await fetchApi('account', `/user/newsletter/verify/${sub_id}/${hash}`, {
    method: 'PUT'
  });
};

export const verifyNewsletterMutation = () => ({
  mutationKey: ['verify-newsletter'],
  mutationFn: async ({ sub_id, hash }: IVerifyNewsletterDto) =>
    await verifyNewsletter({ sub_id, hash })
});

// Edit Auth Profile
export const editAuthMutation = () => ({
  mutationKey: ['edit-auth'],
  mutationFn: async (data: IChangePasswordDto) => {
    await fetchApi('account', '/user/changePass', {
      method: 'POST',
      auth: true,
      data: {
        old_pass: data.password,
        new_pass: data.confirm_new_pass
      }
    });
  }
});

// Edit Profile Info
export const editProfileMutation = () => ({
  mutationKey: ['edit-profile'],
  mutationFn: async (data: IEditProfileFormFields) => {
    const prepData: IEditProfileDto = {
      first_name: data.first_name,
      last_name: data.last_name,
      pic: data.pic,
      additional_info_edu: {
        about: data.about,
        banner: data.banner
      }
    };
    // If the pfp is the value fetched from the BE, then delete it
    if (prepData.pic && !prepData.pic.includes('data:image/'))
      delete prepData.pic;
    if (
      prepData.additional_info_edu.banner &&
      !prepData.additional_info_edu.banner.includes('data:image/')
    )
      delete prepData.additional_info_edu.banner;
    await fetchApi('account', '/user/me', {
      method: 'PUT',
      auth: true,
      data: prepData
    });
  }
});

export const editProfileVisibilityMutation = () => ({
  mutationKey: ['edit-profile-visibility'],
  mutationFn: async ({
    field,
    isPublic
  }: {
    field: keyof IHiddenFields;
    isPublic: boolean;
  }) => {
    await fetchApi('account', '/user/edu/me/visibility', {
      method: 'PUT',
      auth: true,
      data: {
        [field]: isPublic
      }
    });
  }
});

// Edit profile socials
export const editProfileSocialsMutation = () => ({
  mutationKey: ['edit-profile-socials'],
  mutationFn: async (data: IEditSocialFormFields) => {
    const prepData: IEditSocialDto = { ...data };
    await fetchApi('account', '/user/me', {
      method: 'PUT',
      auth: true,
      data: {
        additional_info_edu: {
          website: prepData.website,
          twitter: prepData.twitter,
          instagram: prepData.instagram,
          facebook: prepData.facebook
        }
      }
    });
  }
});

export const connectWalletMutationQuery = () => ({
  mutationKey: ['connect-wallet'],
  mutationFn: async (address: string) => {
    await fetchApi('account', `/user/edu/me/wallet/connect`, {
      auth: true,
      method: 'PUT',
      data: {
        wallet: address
      }
    });
  }
});

export const disconnectWalletMutationQuery = () => ({
  mutationKey: ['disconnect-wallet'],
  mutationFn: async () => {
    await fetchApi('account', `/user/edu/me/wallet/disconnect`, {
      auth: true,
      method: 'PUT'
    });
  }
});

export const verifyUserMutationQuery = () => ({
  mutationKey: ['verify-user'],
  mutationFn: async () => {
    return await fetchApi('account', `/kyc/get-verification-url`, {
      auth: true,
      method: 'POST'
    });
  }
});

export const subscribeToNewsletterMutationQuery = (email: string) => ({
  mutationKey: ['subscribe-to-newsletter'],
  mutationFn: async () => {
    return await fetchApi('account', `/user/newsletter`, {
      auth: true,
      method: 'POST',
      data: {
        email,
        project_id: PROJECT_ID.OPUS_EDU
      }
    });
  }
});
