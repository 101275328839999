import { ReactComponent as Check } from 'assets/icons/check.svg';
import classes from './PasswordTooltip.module.scss';

const tooltipConditions = {
  mustConditions: [
    'At least 8 characters',
    'At least 1 letter (a, A, b, B, c, C...)',
    'A mix of uppercase and lowercase letters',
    'At least 1 number (1, 2, 3...)',
    'At least 1 special symbol (!, @, #...)'
  ]
};

// Values extracted from PASSWORD_PATTERN
const tooltipChecks = [
  {
    value: /^(?=.{8,})/
  },
  { value: /^(?=.*[a-z])|(?=.*[A-Z])/ },
  { value: /^(?=.*[a-z])(?=.*[A-Z])/ },
  { value: /^(?=.*[0-9])/ },
  { value: /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{8,})/ }
];

interface IPasswordTooltip {
  value: string;
  isTemporaryTooltip?: boolean;
}

const PasswordTooltip = ({ value, isTemporaryTooltip }: IPasswordTooltip) => {
  return (
    <div className={classes['tooltip']}>
      <div className={classes['arrow']} />
      {isTemporaryTooltip ? (
        <>
          <div className={classes['tooltip__title']}>Check your email.</div>
          <div className={classes['tooltip__content']}>
            Fill in the automatically generated password we have sent to your
            email address.
          </div>
        </>
      ) : (
        <div>
          <div className={classes['tooltip__title']}>
            Password must contain:
          </div>
          {tooltipConditions.mustConditions.map((condition, i) => {
            return (
              <div className={classes['tooltip__content']} key={i}>
                {tooltipChecks[i].value.test(value) ? <Check /> : '• '}
                {condition}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

PasswordTooltip.defaultProps = {
  isTemporaryTooltip: false
};

export default PasswordTooltip;
