import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IUser, userContext } from 'context/userContext';
import Tabs from 'components/tabs/Tabs';
import MainInfo from './MainInfo';
import SocialInfo from './SocialInfo';
import { IProfile } from '../../index';

interface IAboutProps extends Pick<IProfile, 'subTab'> {
  data: IUser;
  isPublic?: boolean;
}

const About = ({ data, subTab, isPublic }: IAboutProps) => {
  const { userData } = useContext(userContext);
  const { slug } = useParams();
  const { first_name, last_name, email, additional_info, hidden_fields } = data;

  const buildTabs = () => {
    const allTabs = [
      {
        id: 'main-info',
        name: 'Main Information',
        route: isPublic
          ? `user/${slug}/about/main-info`
          : 'user/about/main-info',
        component: () => (
          <MainInfo
            first_name={first_name}
            last_name={last_name}
            email={email}
            additional_info={additional_info}
            hidden_fields={hidden_fields}
            isPublic={isPublic}
          />
        )
      },
      {
        id: 'social-info',
        name: 'Social Media Links',
        route: isPublic
          ? `user/${slug}/about/social-info`
          : 'user/about/social-info',
        component: () => (
          <SocialInfo
            additional_info={additional_info}
            hidden_fields={hidden_fields}
            isPublic={isPublic}
          />
        )
      }
    ];

    // If logged in as admin and viewing your profile, only show main info tab
    if (userData.isAdmin && !isPublic)
      return allTabs.filter((tab) => tab.id === 'main-info');
    // Else show all tabs
    return allTabs;
  };

  return (
    <Tabs
      tabs={buildTabs()}
      activeTabIndex={buildTabs().findIndex((t) => t.id === subTab)}
      size={'sm'}
      isVertical
    />
  );
};

export default About;
