import React, { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from '@tanstack/react-query';
import Loader from 'components/loader/Loader';
import ErrorComponent from 'components/error/Error';
import CourseForm from '../components/CourseForm';
import { ICourse } from 'query/course-module/dto';
import { singleCourseDataQuery } from 'query/course-module/queries';
import { ICourseFormFields } from 'utils/yupSchemas';
import {
  COURSE_REVIEW_STATUS,
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN
} from 'utils/constants';
import { currencyObj } from '../single';
import { expirationOptions } from '../components/Step3';
import { cpeOptions } from '../components/Step4';
import classes from './EditCourse.module.scss';

const formSteps = [
  'Main Information',
  'Thumbnails & Preview',
  'Pricing & Time Limit',
  'Certificate of Completion',
  'Confirm Creation'
];

const EditCourse = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [finishedSteps, setFinishedSteps] = useState<number>(0);
  const { slug } = useParams();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const [defaultFormData, setDefaultFormData] = useState<ICourseFormFields>();

  const { isLoading, error, data } = useQuery<boolean, Error, ICourse>({
    ...singleCourseDataQuery(slug as string, `?&changes=${changes}`),
    onSuccess: (data) => {
      const {
        name,
        category,
        subcategory,
        short_description,
        description,
        thumbnail,
        activation_nft_image,
        video_preview,
        hover_play,
        show_video_preview,
        thumbnail_is_generated,
        video_preview_is_generated,
        expiration,
        currency,
        price,
        template_id,
        not_finished,
        cpe
      } = data;

      setDefaultFormData({
        name: name,
        category: category
          ? {
              label: category.name,
              value: category._id
            }
          : null,
        subcategory: subcategory
          ? {
              label: subcategory.name,
              value: subcategory._id
            }
          : null,
        short_description,
        description,
        thumbnail: thumbnail
          ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
          : '',
        activation_nft_image: activation_nft_image
          ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + activation_nft_image
          : '',
        video_preview: video_preview
          ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + video_preview
          : '',
        hover_play: Boolean(hover_play),
        show_video_preview: Boolean(show_video_preview),
        expiration: expirationOptions.filter((o) => o.value === expiration),
        currency: currency
          ? {
              label: currencyObj[currency].name,
              value: currency,
              symbol: currencyObj[currency].symbol
            }
          : null,
        price,
        cpe: !cpe ? cpeOptions : [],
        template_id:
          cpe && template_id
            ? [{ label: 'Saved template', value: template_id }]
            : [],
        not_finished: Boolean(not_finished),
        thumbnail_is_generated,
        video_preview_is_generated
      });
    }
  });

  if (isLoading) return <Loader size="lg" hasText withPadding />;

  if (error) return <ErrorComponent error={error} />;

  const { review_status } = data;

  if (
    review_status === COURSE_REVIEW_STATUS['IN_REVIEW'] ||
    review_status === COURSE_REVIEW_STATUS['APPROVED']
  )
    return <Navigate to={`/courses/${slug}?changes=${changes}`} />;

  return (
    <div id="course-form__wrapper" className={classes['wrapper']}>
      <div className={classes['container']}>
        <h3 className={`${classes['u-semiBold']} ${classes['u-text--center']}`}>
          Edit Course
        </h3>
        {!!defaultFormData && (
          <CourseForm
            steps={formSteps}
            finishedSteps={finishedSteps}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setStep={(step: number) => {
              setCurrentStep(step <= 0 ? 0 : step >= 4 ? 4 : step);
              setFinishedSteps(step <= 0 ? 0 : step >= 4 ? 4 : step);
            }}
            defaultFormData={defaultFormData as ICourseFormFields}
            isEdit
            course={data}
          />
        )}
      </div>
    </div>
  );
};

export default EditCourse;
