import { Contract } from 'ethers';
import {
  REACT_APP_ACCOUNT_MANAGEMENT_API_ENDPOINT,
  REACT_APP_COURSES_API_ENDPOINT,
  REACT_APP_VIDEOS_API_ENDPOINT
} from './constants';

export type ReqMethods = 'POST' | 'GET' | 'PUT' | 'DELETE';

export interface fetchOpts {
  method: ReqMethods;
  auth?: boolean;
  data?: any;
  file?: Blob;
  isStream?: boolean;
  isProgress?: boolean;
  fileStream?: {
    stream: ReadableStream;
    size: number;
    name: string;
    type: 'video/mp4';
  };
  isBlob?: boolean;
}

export async function fetchApi(
  endpoint: 'account' | 'courses' | 'videos',
  path: string,
  opts: fetchOpts
) {
  const reqOpts: any = {};
  reqOpts.method = opts.method;
  if (opts.auth) {
    let token = localStorage.getItem('token');
    if (!!token) {
      token = JSON.parse(token).token;
      reqOpts.headers = {
        Authorization: `Bearer ${token}`
      };
    }
  }

  if (opts.data) {
    reqOpts.headers = {
      ...reqOpts.headers,
      'Content-Type': 'application/json'
    };
    reqOpts.body = JSON.stringify(opts.data);
  }

  if (opts.file) {
    reqOpts.body = opts.file;
  }

  if (opts.isStream) {
    reqOpts.headers = {
      ...reqOpts.headers,
      'Content-Type': 'application/octet-stream',
      'upload-size': opts.fileStream?.size,
      'video-title': opts.fileStream?.name,
      'upload-type': opts.fileStream?.type
    };
    reqOpts.body = opts.fileStream?.stream;
    reqOpts.duplex = 'half';
  }

  if (opts.isProgress) {
    reqOpts.body = opts.data;
    reqOpts.duplex = 'half';
  }

  let endpointVar: string;
  switch (endpoint) {
    case 'account':
      endpointVar = REACT_APP_ACCOUNT_MANAGEMENT_API_ENDPOINT;
      break;
    case 'courses':
      endpointVar = REACT_APP_COURSES_API_ENDPOINT;
      break;
    case 'videos':
      endpointVar = REACT_APP_VIDEOS_API_ENDPOINT;
      break;
    default:
      endpointVar = REACT_APP_ACCOUNT_MANAGEMENT_API_ENDPOINT;
      break;
  }

  const request = await fetch(endpointVar + path, reqOpts);
  const result = !opts.isBlob ? await request.json() : await request.blob();

  if (result.statusCode >= 400) {
    throw new Error(result.message);
  }

  return result;
}

// Get `MATIC` to `USD` rate from `CoinGecko API`
export const getExchangeRate = async () => {
  const url =
    'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd';
  const fetchPrice = async () => {
    try {
      const request = await fetch(url);
      const result = await request.json();
      return result;
    } catch (e) {
      console.log(e);
    }
  };
  const result = fetchPrice();
  return result;
};

export const submitTx = async (contract: Contract, fn: string, data: any) => {
  try {
    const tx = await contract[fn](...data);
    const receipt = await tx.wait();
    return receipt;
  } catch (e: any) {
    if (e?.info?.error?.code === 4001) {
      throw Error('Transaction rejected.', { cause: 4001 });
    }
    if (e?.data?.code === -32000 || e?.code === 'INSUFFICIENT_FUNDS') {
      throw Error('Insufficient funds.');
    }
    if (e?.reason) {
      throw Error(e.reason);
    }
    if (e?.message) {
      throw Error(e.message);
    }
  }
};

// export function tokenExpired() {
//   const now = Date.now();
//   const token = localStorage.getItem("token");
//   const expirationDate: tokenData = token && JSON.parse(token);
//   const expDate = new Date(expirationDate?.exp);
//
//   // token expired
//   if (now > expDate.getTime()) {
//     return true;
//   }
//
//   return false; // valid token
// }
