import { createContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IPlatformSettingsDto } from 'query/course-module/dto';
import { platformSettingsQuery } from 'query/course-module/queries';

interface IAppCtx {
  theme: 'dark' | 'light';
  switchTheme: () => void;
  platformSettings: IPlatformSettingsDto;
  setPlatformSettings: (value: IPlatformSettingsDto) => void;
  coursesView: string;
  setCoursesView: (view: 'cols' | 'cards') => void;
  showFilter: boolean;
  updateFilter: () => void;
  isTheatreMode: boolean;
  setIsTheatreMode: (isTheatre: boolean) => void;
}

const initialState = {
  theme: 'light' as IAppCtx['theme'],
  switchTheme: () => {},
  platformSettings: {
    free_video_count: 0,
    platform_fee: 0,
    video_fee: 0
  },
  setPlatformSettings: (value: IPlatformSettingsDto) => {},
  coursesView: 'cards',
  setCoursesView: (view: 'cols' | 'cards') => {},
  showFilter: false,
  updateFilter: () => {},
  isTheatreMode: false,
  setIsTheatreMode: () => {}
};

export const appContext = createContext<IAppCtx>(initialState);

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [platformSettings, setPlatformSettings] =
    useState<IPlatformSettingsDto>(initialState.platformSettings);
  const themeInStorage = localStorage.getItem('theme');
  const [theme, setTheme] = useState(
    (themeInStorage as IAppCtx['theme']) || 'light'
  );
  const [view, setView] = useState(
    localStorage.getItem('courses_view') || 'cards'
  );
  const [showFilter, setShowFilter] = useState(
    !!localStorage.getItem('show_filter_menu')
  );
  const [isTheatreMode, setIsTheatreMode] = useState(
    localStorage.getItem('theatre') === 'true'
  );

  useQuery<boolean, Error, IPlatformSettingsDto>({
    ...platformSettingsQuery(),
    onSuccess: (data) => {
      setPlatformSettings({
        free_video_count: data.free_video_count,
        platform_fee: data.platform_fee,
        video_fee: data.video_fee
      });
    }
  });

  const switchTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  const setCoursesView = (view: 'cols' | 'cards') => {
    localStorage.setItem('courses_view', view);
    setView(view);
  };

  const updateFilter = () => {
    if (!showFilter) {
      localStorage.setItem('show_filter_menu', 'true');
    } else {
      localStorage.removeItem('show_filter_menu');
    }
    setShowFilter(!showFilter);
  };

  const setTheatreModeHandler = (isTheatre: boolean) => {
    localStorage.setItem('theatre', isTheatre.toString());
    setIsTheatreMode(isTheatre);
  };

  return (
    <appContext.Provider
      value={{
        theme,
        switchTheme,
        platformSettings,
        setPlatformSettings,
        coursesView: view,
        setCoursesView,
        showFilter,
        updateFilter,
        isTheatreMode,
        setIsTheatreMode: setTheatreModeHandler
      }}
    >
      {children}
    </appContext.Provider>
  );
}
