import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import Button from '../buttons/Button';
import { navigateWithParams } from 'utils/format';
import { dateFormatCourses, dateFormatUrl } from 'utils/static';
import 'react-datepicker/dist/react-datepicker.css';
import classes from './Datepicker.module.scss';

interface IDatepickerRangeProps {
  onChange: (value: number) => void;
  isDisabled?: boolean;
  placeholder?: string;
  error?: string;
  name?: string;
  startDate?: number;
  endDate?: number;
  displayValue?: string;
  customHeader?: React.ReactNode;
  minDate?: Date | string;
  maxDate?: Date | string;
  isClearable?: boolean;
  isFilter?: boolean;
  clearFilter?: () => void;
  children?: React.ReactNode;
}

const DatepickerRange = (props: IDatepickerRangeProps) => {
  const {
    onChange,
    startDate,
    endDate,
    customHeader,
    minDate,
    maxDate,
    isClearable,
    isFilter,
    clearFilter
  } = props;

  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();

  let datepickerRef = useRef(null);

  const closeDatepicker = () => (datepickerRef.current as any).setOpen(false);

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => {
    return (
      <Button
        variant="outline"
        onClick={onClick}
        minWidth="full"
        className="button-wrapper"
      >
        <div className="text-wrapper">
          <CalendarIcon width={24} height={24} />
          {startDate && endDate
            ? `${moment(startDate).format(dateFormatUrl)} - ${moment(
                endDate
              ).format(dateFormatUrl)}`
            : 'Select Date'}
        </div>
      </Button>
    );
  });

  const CustomContainer = ({ className, children }: any) => {
    return (
      <div>
        <CalendarContainer className={className}>
          {customHeader}
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  const handleDateChange = (e: any) => {
    const formattedEndDate: any = [
      e[0],
      !!e[1] ? e[1].setHours(23, 59, 59, 999) : ''
    ];
    onChange(formattedEndDate);
    if (isFilter) {
      navigateWithParams(
        urlParams,
        {
          page: 1,
          startDate: moment(e[0]).format(dateFormatCourses),
          endDate: e[1] ? moment(e[1]).format(dateFormatCourses) : ''
        },
        navigate,
        pathname
      );
    }
  };

  return (
    <div className={classes['c-datepicker']}>
      <DatePicker
        ref={datepickerRef}
        calendarContainer={CustomContainer}
        onChange={handleDateChange}
        minDate={minDate as Date}
        maxDate={maxDate as Date}
        dateFormat={'MM/dd/yyyy'}
        customInput={<CustomInput />}
        selectsRange
        startDate={startDate as any}
        endDate={endDate as any}
        isClearable={isClearable}
      >
        {isFilter && (
          <div className={classes['children-wrapper']}>
            <Button
              onClick={() => {
                closeDatepicker();
                clearFilter && clearFilter();
                navigateWithParams(
                  urlParams,
                  { page: 1, startDate: '', endDate: '' },
                  navigate,
                  pathname
                );
              }}
              variant={'contrast'}
              size="md"
            >
              Clear
            </Button>
          </div>
        )}
      </DatePicker>
    </div>
  );
};

export default DatepickerRange;
