import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import HighlightedCourseCard from 'components/card/HighlightedCourseCard';
import DragAndDropCard from 'components/drag-and-drop/DragАndDropCard';
import Button from 'components/buttons/Button';
import { ICourse } from 'query/course-module/dto';
import classes from './HighlightedCourses.module.scss';

interface IDragAndDropProps {
  data: ICourse[];
  onOrderChange: (data: ICourse[]) => void;
  onClick: (course: ICourse) => void;
  onRemove: (course: ICourse) => void;
}

const DragAndDrop = ({
  data,
  onOrderChange,
  onClick,
  onRemove
}: IDragAndDropProps) => {
  const navigate = useNavigate();

  // In order Drag&Drop to work correctly, data indexes should be updated to start from `0`, not from `1`.
  // For future improvement BE could return indexes from `0` initially.
  const updateDataIndex = (data: ICourse) => {
    return { ...data };
  }; // this potentially is wrong

  const isMounted = useRef(false);
  const [cards, setCards] = useState(data.map(updateDataIndex));

  // Drag&Drop move card functionality
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }, []);

  // Trigger course order change mutation only on drop
  const onDrop = () => onOrderChange(cards);

  // Update course cards on remove from highlighted
  useEffect(() => {
    if (data.length !== cards.length) setCards(data.map(updateDataIndex));
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // NOTE: avoid automatically mutating courses order on initial render on local environment
    isMounted.current = true;
  }, []);

  // Update cards on data change
  useEffect(() => {
    if (isMounted.current) {
      setCards(data);
    }
  }, [data]);

  return (
    <div className={classes['dnd']}>
      <DndProvider backend={HTML5Backend}>
        {cards.map((card, i) => (
          <DragAndDropCard
            key={card._id}
            card={card}
            index={i}
            moveCard={moveCard}
            onDrop={onDrop}
            canDrag={true}
          >
            <HighlightedCourseCard
              key={card._id}
              course={card}
              hasRemoveBtn
              onRemove={onRemove}
              onClick={onClick}
              isDnd
              size="sm"
            />
          </DragAndDropCard>
        ))}
        <div className={classes['btn-container']}>
          <Button
            variant="neutral"
            minWidth="md"
            onClick={() => navigate('/admin/courses')}
          >
            Add Course
          </Button>
        </div>
      </DndProvider>
    </div>
  );
};

export default DragAndDrop;
