import React from 'react';
import ReactSelect from 'components/select/ReactSelect';
import Label from '../input/Label';
import { ISelectOption } from 'query/course-module/dto';
import classes from 'components/input/InputWrapper.module.scss';

export interface IReactSelectWrapper {
  title?: string;
  subtitle?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  tooltipContent?: React.ReactNode;
  isRequired?: boolean;
  name: string;
  placeholder: string;
  value: ISelectOption | ISelectOption[] | null;
  options: ISelectOption[];
  isDisabled?: boolean;
  error?: string | any[];
  onChange: (e: any) => any;
  onBlur: (e: React.FocusEvent<HTMLElement>) => void;
  isLoading?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isRow?: boolean;
  width?: string;
  isCurrency?: boolean;
  noChevron?: boolean;
  noDropdown?: boolean;
}

const ReactSelectWrapper = (props: IReactSelectWrapper) => {
  const {
    isDisabled,
    isRequired,
    options,
    value,
    placeholder,
    title,
    subtitle,
    hasTooltip,
    tooltipText,
    tooltipContent,
    onChange,
    onBlur,
    error,
    name,
    isLoading,
    isMulti,
    isClearable,
    isSearchable,
    isRow,
    width,
    isCurrency,
    noChevron,
    noDropdown
  } = props;

  return (
    <div
      className={`${classes['input__group']} ${
        isRow ? classes['input__group--row'] : ''
      }`}
    >
      {!!title && (
        <Label
          name={name}
          title={title}
          subtitle={subtitle}
          isRequired={isRequired}
          hasTooltip={hasTooltip}
          tooltipText={!isDisabled ? tooltipText || '' : ''}
          tooltipContent={!isDisabled ? tooltipContent : null}
          isDisabled={isDisabled}
        />
      )}
      <ReactSelect
        name={name}
        value={value}
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={isDisabled}
        isLoading={isLoading}
        hasError={!!error}
        isClearable={isClearable}
        isSearchable={isSearchable}
        width={width}
        isCurrency={isCurrency}
        noChevron={noChevron}
        noDropdown={noDropdown}
      />
      {!!error && <div className={classes['input__error']}>{error}</div>}
    </div>
  );
};

export default ReactSelectWrapper;
