import React from 'react';
import classes from './Error.module.scss';

interface IErrorProps {
  error: Error | null;
}

const Error = (props: IErrorProps) => {
  return <p className={classes['u-body2']}>{props.error?.message}</p>;
};

export default Error;
