import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import Badge from 'components/badge/Badge';
import { ICourse } from 'query/course-module/dto';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { formatNames } from 'utils/format';
import classes from './CourseCard.module.scss';

interface ICourseCard {
  course: ICourse;
  onClick?: () => void;
  hasTitleCharLimit?: boolean;
  imgSize: 'sm' | 'lg';
  noBadge: boolean;
}

const CourseCard = ({
  course,
  onClick,
  hasTitleCharLimit,
  imgSize,
  noBadge
}: ICourseCard) => {
  const { name, creator, category, thumbnail } = course;

  return (
    <div
      className={`${classes['container']} ${classes['u-semiBold']}`}
      onClick={onClick}
    >
      <img
        className={`${classes['thumb']} ${classes[`thumb--${imgSize}`]}`}
        src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail}
        alt={name}
      />
      <div className={classes['course']}>
        {!hasTitleCharLimit && <div>{name}</div>}
        {hasTitleCharLimit && (
          <TextWithCharLimit limit={25} text={name} hideBtn />
        )}
        {!noBadge && <Badge text={category.name} variant="outline" size="sm" />}
        <div>{formatNames(creator?.first_name + ' ' + creator?.last_name)}</div>
      </div>
    </div>
  );
};

CourseCard.defaultProps = {
  imgSize: 'lg',
  noBadge: false
};

export default CourseCard;
