import { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Portal } from 'react-portal';
import moment from 'moment';
import { ReactComponent as EmptyBell } from 'assets/images/no-notifications.svg';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { userContext } from 'context/userContext';
import { toastContext } from 'context/toastContext';
import useComponentVisible from 'hooks/useComponentVisible';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import { fetchApi } from 'utils/requests';
import classes from './Notifications.module.scss';

interface INotificationsProps {
  isMobile?: boolean;
}

const Notifications = ({ isMobile }: INotificationsProps) => {
  const navigate = useNavigate();
  const { notifications } = useContext(userContext);
  const { setToast } = useContext(toastContext);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const queryclient = useQueryClient();

  const { mutate: markAsSeen } = useMutation({
    mutationKey: ['notification-read'],
    mutationFn: async (notificationId: string) => {
      setIsComponentVisible(false);
      // Mark all as read
      if (!notificationId)
        return await fetchApi('courses', `/notification`, {
          auth: true,
          method: 'PUT'
        });

      // Mark selected notification as read
      return await fetchApi('courses', `/notification/${notificationId}`, {
        auth: true,
        method: 'PUT'
      });
    },
    onSuccess: () => {
      queryclient.invalidateQueries(['user-notifications']);
    },
    onError: (e: Error) =>
      setToast({ type: 'error', msg: e.message, autoClose: true })
  });

  const unseenNotifications = notifications.filter(
    (notification) => !notification.seen
  );

  const renderMsg = (msg: string, seen: boolean) => {
    const extractQuotedPart = (): string => {
      const match = msg.match(/"([^"]*)"/);
      return match ? match[1] : '';
    };

    const quotedPart = extractQuotedPart();
    const otherPart = msg.split(quotedPart);

    return (
      <div
        className={`
          ${classes['notifications__menu__item__text']} 
          ${seen ? classes['notifications__menu__item__text--seen'] : ''}
        `}
      >
        {otherPart[0]}
        <span>{quotedPart}</span>
        {otherPart[1]}
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div
        className={`${classes['notifications__menu__wrapper']}   ${
          classes[
            isMobile && isComponentVisible
              ? 'notifications__menu__wrapper--open'
              : ''
          ]
        }`}
      >
        <div
          className={`
        ${classes['notifications__menu']} 
      `}
        >
          <h5>Notifications</h5>
          <Divider />
          <div className={classes['notifications__menu__list']}>
            {!!notifications.length ? (
              notifications.map((notification, i) => (
                <Fragment key={i}>
                  <div
                    className={classes['notifications__menu__item']}
                    onClick={() => {
                      markAsSeen(notification._id);
                      navigate(notification.link);
                    }}
                  >
                    <>{renderMsg(notification.message, notification.seen)}</>

                    <div className={classes['notifications__menu__item__date']}>
                      {moment(notification.updated_at).format('MMM DD, YYYY')}
                    </div>
                  </div>
                </Fragment>
              ))
            ) : (
              <div className={classes['notifications__menu__list--empty']}>
                <EmptyBell />
                <div>
                  <h5>No Notifications Found</h5>
                  <p>
                    It looks like you're all caught up! There are no new
                    notifications at the moment.
                  </p>
                </div>
                <Divider />
              </div>
            )}
          </div>
          {!!unseenNotifications.length && (
            <Button
              variant="link-contrast"
              withPadding={false}
              onClick={() => markAsSeen('')}
            >
              Mark all as read
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className={classes['notifications']}
      onMouseEnter={() => setIsComponentVisible(true)}
      onMouseLeave={() => setIsComponentVisible(false)}
    >
      <div className={classes['notifications__btn']}>
        <Button
          variant="neutral"
          icon={Bell}
          isIconBtn
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        />
        {!!unseenNotifications.length && (
          <div className={classes['notifications__btn__counter']}>
            {unseenNotifications.length}
          </div>
        )}
      </div>
      <div className={classes['notifications__menu__support']} />

      {isMobile ? (
        <Portal node={document.getElementById('header-wrapper')}>
          {isComponentVisible && (
            <div className={classes['mobile-menu-backdrop']} />
          )}
          {renderMenu()}
        </Portal>
      ) : (
        <>{isComponentVisible && renderMenu()}</>
      )}
    </div>
  );
};

export default Notifications;
