import { useState } from 'react';
import { JsonRpcSigner, ethers } from 'ethers';
import { useQuery } from '@tanstack/react-query';
import Quoter from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json';
import { useEthersSigner } from './useEthersSigner';
import useCurrencyContract from './useCurrencyContract';
import { REACT_APP_CURRENCY_CONTRACT_ADDRESS } from 'utils/constants';

export const useQuote = (
  tokenIn: {
    label: string;
    value: string;
    poolFee: number;
    decimals: number;
  },
  requiredAmountOut: number // Item price
) => {
  const { signer } = useEthersSigner();
  const { decimals: usdcDecimals } = useCurrencyContract(
    REACT_APP_CURRENCY_CONTRACT_ADDRESS
  );
  const [amountIn, setAmountIn] = useState<string>('0');
  const [amountInFormatted, setAmountInFormatted] = useState(0);

  const { decimals } = tokenIn;

  // Uniswap quoter contract
  const quoterContract = new ethers.Contract(
    '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
    Quoter.abi,
    signer as JsonRpcSigner
  );

  const { isLoading, error } = useQuery({
    queryKey: ['quote', { id: tokenIn.value }],
    queryFn: async () => {
      const result = await quoterContract.quoteExactOutputSingle.staticCall(
        // tokenInContract,
        tokenIn.value,
        // tokenOutContract (USDC)
        REACT_APP_CURRENCY_CONTRACT_ADDRESS,
        // poolFee,
        tokenIn.poolFee,
        // Amount of USDC out,
        requiredAmountOut,
        // price impact
        0
      );
      return result;
    },
    // Enable the query when the signer is defined and the selected token is NOT USDC
    enabled:
      !!signer &&
      tokenIn.value.toLocaleLowerCase() !==
        REACT_APP_CURRENCY_CONTRACT_ADDRESS.toLocaleLowerCase(),
    onSuccess: (data: string) => {
      const amountInFormatted = +(+ethers.formatUnits(data, decimals));
      setAmountIn(data);
      setAmountInFormatted(amountInFormatted);
    },
    onError: (e) => {
      console.log('Error: Cannot fetch quote.');
    }
  });

  if (
    tokenIn.value.toLocaleLowerCase() ===
    REACT_APP_CURRENCY_CONTRACT_ADDRESS.toLocaleLowerCase()
  ) {
    return {
      amountIn: requiredAmountOut,
      amountInFormatted: +(+ethers.formatUnits(
        requiredAmountOut,
        usdcDecimals
      )).toFixed(4)
    };
  }

  // If the selected currency is USDC, just return

  return {
    amountIn: amountIn,
    amountInFormatted: amountInFormatted,
    quoteIsLoading: isLoading,
    quoteError: error
  };
};
