import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowShort.svg';
import classes from './Breadcrumbs.module.scss';

interface ICustomBreadrumbs {
  crumbs: { name: string; link: string }[];
  className?: string;
}

const CustomBreadcrumbs = ({ crumbs, className }: ICustomBreadrumbs) => {
  const navigate = useNavigate();

  return (
    <>
      {!!crumbs.length && (
        <div className={`${classes['breadcrumbs__wrapper']} ${className}`}>
          {crumbs?.map((item, i) => (
            <div
              key={i}
              className={`${classes['breadcrumb__item']} ${
                i === crumbs.length - 1
                  ? classes['breadcrumb__item--active']
                  : ''
              }`}
            >
              <span onClick={() => navigate(item.link)}>{item.name}</span>
              {i < crumbs.length - 1 && (
                <ArrowIcon className={classes['breadcrumb__item__arrow']} />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CustomBreadcrumbs;
