import { NavigateFunction } from 'react-router-dom';
import queryString from 'query-string';
import {
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN,
  REACT_APP_FILES_PUBLIC_DOMAIN
} from './constants';
import { IHiddenFields } from '../query/acc-module/dto';

export const getPasswordLabel = (password: string) => {
  let passwordLabel = '';
  for (let i = 0; i < password.length; i++) {
    passwordLabel += '*';
  }
  return passwordLabel;
};

export const renderPic = (pic: string, isEdu?: boolean) => {
  // No picture
  if (!pic) return '';
  // Uploaded picture
  if (pic.includes('data:image/')) return pic;
  // Fetched picture
  return (
    (!!isEdu
      ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN
      : REACT_APP_FILES_PUBLIC_DOMAIN) + pic
  );
};

export const formatAddress = (addr: string, start?: number, end?: number) =>
  `${addr.slice(0, start || 5)}...${addr.slice(end || -4)}`;

export const isFieldPublic = (
  hidden_fields: IHiddenFields,
  field: keyof IHiddenFields
) =>
  hidden_fields && Object.keys(hidden_fields).length > 0
    ? !hidden_fields[field]
    : true;

export const navigateWithParams = (
  urlParams: string,
  newParams: {
    [key: string]: string | number;
  },
  navigate: NavigateFunction,
  pathname: string
) => {
  const parsed = queryString.parse(urlParams);

  navigate({
    pathname,
    search: `?${queryString.stringify(
      {
        ...parsed,
        ...newParams
      },
      { skipEmptyString: true, sort: false }
    )}`
  });
};

export const formatTokenDecimals = (value: number, withSign?: boolean) => {
  if (!value) return (0).toFixed(2);
  if (value >= 0.001) {
    return `${withSign ? '~' : ''} ${value.toFixed(2)}`;
  } else {
    return `${withSign ? '<' : ''} 0.001`;
  }
};

export const truncateText = (text: string, limit: number) => {
  if (!text) {
    return '-';
  }

  // Check if the text is already within the limit
  if (text.length <= limit) {
    return text;
  }

  // Find the last empty space within the limit
  const lastSpaceIndex = text.lastIndexOf(' ', limit);

  // If there is no space within the limit, just truncate at the specified limit
  if (lastSpaceIndex === -1) {
    return `${text.substring(0, limit)}...`;
  }

  // Otherwise, truncate at the last empty space within the limit
  return text.substring(0, lastSpaceIndex) + '...';
};

export const capitalizeFirstLetter = (text: string) => {
  return `${text.charAt(0).toUpperCase() + text.slice(1)}`;
};

export const formatPrice = (text: number) => {
  return text.toLocaleString('en-US', {
    maximumFractionDigits: 2
  });
};

export const formatCurrencyAmount = (amount: number, decimals: number) => {
  // Convert the number to a fixed-point notation with a high number of decimal places
  let formattedNum = amount.toFixed(decimals);

  // Remove trailing zeroes and the decimal point if there are no digits after it
  formattedNum = formattedNum
    .replace(/(\.\d*?[1-9])0+$/g, '$1')
    .replace(/\.0*$/g, '');

  return formattedNum;
};

export const formatNames = (names: string) => {
  const words = names.split(' ');
  const processedWords = words.map((word) => {
    if (word.length > 20) {
      return word.charAt(0).toUpperCase() + '.';
    } else {
      return word;
    }
  });

  return processedWords.join(' ');
};
