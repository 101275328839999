import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { ReactComponent as USDC } from 'assets/images/usdc.svg';
import { popupContext } from 'context/popupContext';
import { ModalWrapper } from './common/ModalWrapper';
import { InputWrapper } from 'components/input/InputWrapper';
import Divider from 'components/divider/Divider';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/buttons/Button';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import GenericPopup from './GenericPopup';
import { platformSettingsQuery } from 'query/course-module/queries';
import { IPlatformSettingsDto } from 'query/course-module/dto';
import { platformSettingsMutation } from 'query/course-module/mutations';
import { getPriceInUsdc } from 'utils/helpers';
import classes from './PlatformFeePopup.module.scss';

const VideoFeePopup = () => {
  const { clearPopup, setPopup } = useContext(popupContext);
  const [freeVidsAmount, setFreeVidsAmount] = useState(0);
  const [videoFee, setVideoFee] = useState('');
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    IPlatformSettingsDto
  >(platformSettingsQuery());

  const { isLoading: setFeeIsLoading, mutate: setFeeMutation } = useMutation<
    boolean,
    Error,
    IPlatformSettingsDto
  >({
    ...platformSettingsMutation(),
    onSuccess: () => {
      queryClient.invalidateQueries(['platform-settings']);
      setPopup(
        <GenericPopup
          msg={`Free videos amount changed to ${freeVidsAmount}. Video fee changed to ${videoFee}. `}
        />
      );
    },
    onError: (err: Error) => {
      setPopup(<GenericPopup type="error" msg={err.message} />);
    }
  });

  useEffect(() => {
    if (!!data) {
      setFreeVidsAmount(data.free_video_count);
      setVideoFee(data.video_fee.toString());
    }
  }, [data]);

  if (isLoading) return <Loader />;
  if (error) return <Error error={error} />;

  const newVideosAmount = 5;
  const videosToBePaid =
    freeVidsAmount > newVideosAmount ? 0 : newVideosAmount - freeVidsAmount;
  const currency = 'USDC';

  return (
    <ModalWrapper onClose={() => clearPopup()} size="md" isClosable={true}>
      <div className={classes['popup-container']}>
        <div className={classes['content']}>
          <h4 className={classes['u-semiBold']}>Set Video Price</h4>
          <InputWrapper
            type="number"
            className={classes['input']}
            variant="outline"
            onChange={setFreeVidsAmount}
            value={freeVidsAmount}
            placeholder="0"
            tooltipText="Set the amount of free videos for each user."
            hasTooltip
            title="Number of Free Videos"
          />
          <div className={classes['price-per-video__wrapper']}>
            <div className={classes['price-per-video__title']}>
              Price per Video Upload
            </div>
            <div className={classes['price-per-video__container']}>
              <div className={classes['price-per-video__currency']}>
                <USDC width={24} height={24} /> USDC
              </div>
              <InputWrapper
                className={classes['input']}
                type="number"
                variant="outline"
                onChange={(e) => setVideoFee(getPriceInUsdc(e))}
                value={videoFee}
                placeholder="0"
                tooltipText="The price of each video upload after the free videos are used."
                hasTooltip
              />
            </div>
          </div>
          <div className={classes['breakdown']}>
            <div className={classes['breakdown__header']}>
              <div>Example</div>
              <Tooltip
                id="breakdown"
                text="The example below demonstrates the cost for a user adding 5 videos after they reached the free videos limit."
              >
                <Question />
              </Tooltip>
            </div>
            <div className={classes['price-box']}>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Amount of videos added to course
                </div>
                <div className={classes['price-box__value']}>
                  {newVideosAmount}
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Free videos</div>
                <div className={classes['price-box__value']}>
                  {freeVidsAmount}
                </div>
              </div>
              <Divider dir="horizontal" />
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Videos to be paid
                </div>
                <div className={classes['price-box__value']}>
                  {videosToBePaid}
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Price Per video
                </div>
                <div className={classes['price-box__value']}>
                  {videoFee} {currency}
                </div>
              </div>
              <Divider dir="horizontal" />
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Total</div>
                <div className={classes['price-box__value']}>
                  {getPriceInUsdc(+videoFee * videosToBePaid)} {currency}
                </div>
              </div>
            </div>
          </div>
          <div className={classes['btns-container']}>
            <Button
              size="md"
              isDisabled={
                freeVidsAmount < 0 || freeVidsAmount > 100 || +videoFee < 0
              }
              onClick={() =>
                setFeeMutation({
                  ...data,
                  free_video_count: +freeVidsAmount,
                  video_fee: +videoFee
                })
              }
              isLoading={setFeeIsLoading}
            >
              Save Settings
            </Button>
            <Button variant="link-neutral" size="md" onClick={clearPopup}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default VideoFeePopup;
