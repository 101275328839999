import Slider from 'rc-slider';
import { colors } from 'utils/styles';
import 'rc-slider/assets/index.css';

interface ISlider {
  minValue: number;
  maxValue: number;
  onChange: (value: number | number[]) => void;
  onChangeComplete: (value: number | number[]) => void;
}

const SliderComponent = ({
  minValue,
  maxValue,
  onChange,
  onChangeComplete
}: ISlider) => {
  const { defaultMinValue, defaultMaxValue } = {
    defaultMinValue: Number(localStorage.getItem('minCoursePrice')),
    defaultMaxValue: Number(localStorage.getItem('maxCoursePrice'))
  };
  return (
    <Slider
      range
      max={defaultMaxValue}
      value={[minValue, maxValue]}
      defaultValue={[defaultMinValue, defaultMaxValue]}
      onChange={onChange}
      onChangeComplete={onChangeComplete}
      styles={{
        track: { background: colors.purple70 },
        handle: {
          background: colors.purple70,
          border: 'none',
          boxShadow: 'none'
        }
      }}
      dotStyle={{
        background: colors.purple70
      }}
      activeDotStyle={{
        background: colors.purple70
      }}
    />
  );
};

export default SliderComponent;
