import { JsonRpcSigner, ethers } from 'ethers';
import {
  REACT_APP_ACCESS_CONTRACT_ADDRESS,
  REACT_APP_PAYMENT_CONTRACT_ADDRESS,
  REACT_APP_CURRENCY_CONTRACT_ADDRESS
} from 'utils/constants';
import { IPublishCourseDto } from 'query/course-module/dto';
import paymentContractAbi from './abi/paymentContract.abi.json';
import accessContractAbi from './abi/accessContract.abi.json';
import currencyContractAbi from './abi/currencyContract.abi.json';

export const paymentContract = {
  abi: paymentContractAbi,
  address: REACT_APP_PAYMENT_CONTRACT_ADDRESS
};

export const accessContract = {
  abi: accessContractAbi,
  address: REACT_APP_ACCESS_CONTRACT_ADDRESS
};

export const currencyContract = {
  abi: currencyContractAbi,
  address: REACT_APP_CURRENCY_CONTRACT_ADDRESS
};

export const payloadData: IPublishCourseDto = {
  payment_data: {
    token: currencyContract.address,
    amount: 0,
    allowedSlippage: 0,
    sqrtPriceLimitX96: 0,
    fee: 0,
    listingFee: 0
  }
};

// Contract instances
export const getContractInstance = (
  contract: 'payment' | 'access' | 'currency' | 'custom',
  signer: JsonRpcSigner | string,
  tokenAddress?: string
) => {
  let contractInstance;
  switch (contract) {
    case 'payment':
      contractInstance = new ethers.Contract(
        paymentContract.address,
        paymentContract.abi,
        signer as JsonRpcSigner
      );
      break;
    case 'access':
      contractInstance = new ethers.Contract(
        accessContract.address,
        accessContract.abi,
        signer as JsonRpcSigner
      );
      break;
    case 'currency':
      contractInstance = new ethers.Contract(
        tokenAddress || currencyContract.address,
        currencyContract.abi,
        signer as JsonRpcSigner
      );
      break;
    default:
      contractInstance = new ethers.Contract(
        paymentContract.address,
        paymentContract.abi,
        signer as JsonRpcSigner
      );
      break;
  }
  return contractInstance;
};
