import { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Actions from 'routes/dashboard/profile/components/Actions';
import { userContext } from 'context/userContext';
import Error from 'components/error/Error';
import Loader from 'components/loader/Loader';
import NoResults from 'components/noResults/NoResults';
import { IOption } from 'components/input/InputChoice';
import {
  ICourseData,
  IFilterData,
  IFilterOptions,
  IReportData
} from 'query/course-module/dto';
import {
  contentCreatorReportQuery,
  creatorCoursesDataQuery
} from 'query/course-module/queries';
import { COURSE_STATUS } from 'utils/constants';
import classes from './CreatorReport.module.scss';

const coursesFilter: IFilterData = {
  filterName: 'Course',
  filterOptions: []
};

const CreatorReport = () => {
  const { search: urlParams } = useLocation();
  const { userData } = useContext(userContext);
  const {
    startDate: startDateParam,
    endDate: endDateParam,
    course
  } = queryString.parse(urlParams);

  const [coursesValue, setCoursesValue] = useState<IOption[]>(
    coursesFilter.filterOptions.filter((option: IFilterOptions) => {
      return course?.includes(option.value as string);
    })
  );
  const [dateRange, setDateRange] = useState<any[]>([
    startDateParam ? new Date(startDateParam as string) : '',
    endDateParam ? new Date(endDateParam as string) : ''
  ]);
  const [startDateRange, endDateRange] = dateRange;

  const queryParams = `?${
    startDateRange && endDateRange
      ? `&buy_tx_confirmed_at[start]=${new Date(
          startDateRange
        ).getTime()}&buy_tx_confirmed_at[end]=${new Date(
          endDateRange
        ).getTime()}`
      : ''
  }${!!course ? `&course_id=${course}` : ''}`;

  const {
    isLoading: reportLoading,
    error: reportError,
    data: reportData
  } = useQuery<boolean, Error, IReportData>(
    contentCreatorReportQuery(queryParams)
  );

  const coursesQueryParams = `?&status=${COURSE_STATUS.PUBLISHED},${COURSE_STATUS.PUBLISHING}
  &creator_id=${userData._id}`;

  useQuery<boolean, Error, ICourseData>({
    ...creatorCoursesDataQuery(coursesQueryParams),
    onSuccess: (courses) => {
      coursesFilter.filterOptions = courses.result.map((category) => ({
        label:
          category.name.length > 25
            ? `${category.name.substring(0, 25)}...`
            : category.name,
        value: category._id
      }));
      setCoursesValue(
        coursesFilter.filterOptions.filter(
          (option: IFilterOptions) => course?.includes(option.value as string)
        )
      );
    }
  });

  const hasData = !!reportData && !!reportData?.result?.length;

  const filterHandler = (value: IOption[]) => {
    setCoursesValue(value);
  };

  const renderStat = useCallback(
    (
      stat?: number,
      toFixed?: number,
      loaderSize?: 'sm' | 'lg',
      preText?: string
    ) => {
      const statString = stat?.toString();
      if (reportLoading)
        return <Loader size={loaderSize} className={classes['loader']} />;
      if (reportError) return <Error error={reportError} />;
      if (!statString) return ' - ';
      return ` ${preText || ''}${(+statString).toFixed(toFixed || 0)}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportLoading, reportError, reportData]
  );

  const prepStatsData: any = useMemo(
    () => ({
      total_revenue: {
        title: 'Total revenue',
        value: renderStat(
          reportData?.result?.[0]?.total_revenue || 0,
          2,
          'sm',
          '$'
        )
      },
      creator_revenue: {
        title: 'Creator revenue',
        value: renderStat(
          reportData?.result?.[0]?.creator_revenue || 0,
          2,
          'sm',
          '$'
        )
      },
      platform_fees: {
        title: 'Platform fees',
        value: renderStat(
          reportData?.result?.[0]?.platform_fee || 0,
          2,
          'sm',
          '$'
        )
      },
      total_sold: {
        title: 'Total courses sold',
        value: renderStat(reportData?.result?.[0]?.total_sold || 0, 0, 'sm')
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportData]
  );

  const renderStatistics = () => {
    const values = Object.keys(prepStatsData).map((stat: any) => {
      return (
        <div key={stat} className={classes['creator-report__card']}>
          <h5>{prepStatsData[stat].title}</h5>
          <h2 className={classes['creator-report__card__value']}>
            {prepStatsData[stat].value}
          </h2>
        </div>
      );
    });
    return values;
  };

  return (
    <div className={classes['creator-report']}>
      <div className={classes['creator-report__table-wrapper']}>
        <div className={classes['creator-report__actions-wrapper']}>
          <Actions
            hasFilter
            showFilterMenu
            hideFilterButton
            passFilterValueUp={filterHandler}
            filterStartDateRange={startDateRange}
            filterEndDateRange={endDateRange}
            setDateRange={setDateRange}
            filters={[
              {
                ...coursesFilter,
                filterValue: coursesValue
              }
            ]}
          />
        </div>
        {(hasData || reportLoading) && (
          <div className={classes['creator-report__cards-container']}>
            {renderStatistics()}
          </div>
        )}
        {reportError && <Error error={reportError} />}
        {!hasData && !reportLoading && (
          <div className={classes['creator-report__no-results']}>
            <NoResults />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreatorReport;
