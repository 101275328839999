import { useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowShort.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { popupContext } from 'context/popupContext';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import Button from 'components/buttons/Button';
import Tooltip from 'components/tooltip/Tooltip';
import DeleteCategoryPopup from './DeleteCategoryPopup';
import CategoryPopup from './CategoryPopup';
import { ICourseCategory } from 'query/course-module/dto';
import { courseSubcategoriesDataQuery } from 'query/course-module/queries';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { colors } from 'utils/styles';
import { truncateText } from 'utils/format';
import classes from './CategoryRow.module.scss';

interface ICategoryRowProps {
  category: any;
}

const CategoryRow = ({ category }: ICategoryRowProps) => {
  const { theme } = useContext(appContext);
  const { setPopup } = useContext(popupContext);
  const [isOpen, setIsOpen] = useState(false);
  const { windowSize } = useContext(windowContext);
  const { isSmMobile } = windowSize;

  const { _id, name, counter, icon } = category;

  const { data: subcategoryData } = useQuery<boolean, Error, ICourseCategory[]>(
    { ...courseSubcategoriesDataQuery(category._id), enabled: isOpen }
  );

  return (
    <div>
      <div
        className={classes['category-field']}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={classes['category-field__name']}>
          <Button
            icon={ArrowIcon}
            variant="link-neutral"
            onClick={() => setIsOpen(!isOpen)}
            iconRotate={!isOpen ? 180 : 0}
          />
          <img
            src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + icon}
            alt={`category-${name}`}
          />
          <h5 className={classes['u-body2']}>
            {isSmMobile
              ? truncateText(name, 12)
              : name.length > 20
              ? truncateText(name, 20)
              : name}
          </h5>
          <h5 className={classes['u-body2']}>({counter})</h5>
        </div>
        <div className={classes['category-field__btns-container']}>
          <Tooltip id={`tooltip-add-subcategory`} text="Add Subcategory">
            <Button
              className={classes['category-field__btns-container__btn']}
              size="md"
              isIconBtn
              icon={PlusIcon}
              variant="link-neutral"
              withPadding={false}
              onClick={(e) => {
                setPopup(
                  <CategoryPopup
                    action="create"
                    categoryId={_id}
                    type="subcategory"
                  />
                );
                e.stopPropagation();
              }}
            />
          </Tooltip>
          <Button
            className={classes['category-field__btns-container__btn']}
            size="md"
            isIconBtn
            icon={EditIcon}
            variant="link-neutral"
            withPadding={false}
            onClick={(e) => {
              setPopup(
                <CategoryPopup
                  action="update"
                  type="category"
                  category={category}
                />
              );
              e.stopPropagation();
            }}
          />
          <Tooltip
            id={`tooltip-delete-category-${counter}`}
            text={counter > 0 ? 'Cannot be deleted while used by courses' : ''}
          >
            <Button
              className={`
              ${classes['category-field__btns-container__btn']} 
              ${
                !!counter
                  ? classes['category-field__btns-container__btn--disabled']
                  : ''
              }`}
              size="md"
              isIconBtn
              icon={DeleteIcon}
              variant="link-neutral"
              withPadding={false}
              onClick={() =>
                setPopup(
                  <DeleteCategoryPopup type="category" category={category} />
                )
              }
              isDisabled={!!counter}
            />
          </Tooltip>
        </div>
      </div>
      {isOpen &&
        subcategoryData?.map((subCategory: ICourseCategory, index: number) => {
          const { name, counter } = subCategory;
          return (
            <div
              key={index}
              className={`${classes['category-field']} ${classes['subcategory-field']}`}
            >
              <div className={classes['category-field__name']}>
                <h5 className={classes['u-body2']}>{name}</h5>
                <h5 className={classes['u-body2']}>({counter})</h5>
              </div>
              <div className={classes['category-field__btns-container']}>
                <EditIcon
                  width={20}
                  height={20}
                  cursor="pointer"
                  onClick={() =>
                    setPopup(
                      <CategoryPopup
                        action="update"
                        categoryId={_id}
                        type="subcategory"
                        category={subCategory}
                      />
                    )
                  }
                  fill={theme === 'dark' ? 'white' : colors.black100}
                />
                <Tooltip
                  id={`tooltip-delete-subcategory-${counter}`}
                  text={
                    counter > 0 ? 'Cannot be deleted while used by courses' : ''
                  }
                >
                  <DeleteIcon
                    cursor="pointer"
                    pointerEvents={counter > 0 ? `none` : ''}
                    fill={
                      theme === 'dark'
                        ? counter > 0
                          ? colors.white100
                          : 'white'
                        : counter > 0
                        ? colors.black10
                        : colors.black100
                    }
                    onClick={() =>
                      setPopup(
                        <DeleteCategoryPopup
                          type="subcategory"
                          categoryId={_id}
                          category={subCategory}
                        />
                      )
                    }
                  />
                </Tooltip>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CategoryRow;
