import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import { IEditAuthFormFields, EditAuthSchema } from 'utils/yupSchemas';
import { editAuthMutation } from 'query/acc-module/mutations';
import { popupContext } from 'context/popupContext';
import GenericPopup from 'components/popup/GenericPopup';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import { userContext } from 'context/userContext';
import classes from './Form.module.scss';

const initialValues: IEditAuthFormFields = {
  email: '',
  password: '',
  new_pass: '',
  confirm_new_pass: ''
};

const EditAuthForm = () => {
  const { setPopup } = useContext(popupContext);
  const { userData, userDataIsLoading, userDataError } =
    useContext(userContext);
  const navigate = useNavigate();

  const { isLoading, mutate: submitHandler } = useMutation({
    ...editAuthMutation(),
    onError: (e: Error) =>
      setPopup(<GenericPopup type="error" title="Error" msg={e.message} />)
  });

  return (
    <div className={classes['form']}>
      <h4 className={classes['u-light']}>Edit Main Information</h4>
      {userDataIsLoading && <Loader />}
      {userDataError && <Error error={userDataError} />}
      {userData.email && (
        <Formik
          initialValues={{ ...initialValues, email: userData.email }}
          validationSchema={EditAuthSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (isLoading) return;
            submitHandler(values, {
              onSuccess: () => {
                setPopup(
                  <GenericPopup
                    title="Password Changed!"
                    msg="You can now use your new password to log in."
                    buttonName="Go To Profile"
                    buttonAction={() => navigate('/user')}
                  />
                );
                setSubmitting(false);
                resetForm({
                  values: {
                    ...values,
                    password: '',
                    new_pass: '',
                    confirm_new_pass: ''
                  }
                });
              }
            });
          }}
          validateOnMount
        >
          {({
            errors,
            touched,
            values,
            setFieldValue,
            submitForm,
            handleBlur,
            isValid
          }) => (
            <Form className={classes['form']}>
              <InputWrapper
                name="email"
                type="text"
                variant="outline"
                onChange={(e) => setFieldValue('email', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.email}
                title="E-mail"
                placeholder="Enter E-mail"
                isRequired
                error={errors.email && touched.email ? errors.email : ''}
                isDisabled
              />
              <InputWrapper
                name="password"
                type="password"
                variant="outline"
                onChange={(e) => setFieldValue('password', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.password}
                title="Password"
                placeholder="Enter Password"
                hasTooltip
                tooltipText="Fill in your password."
                isRequired
                passwordTooltip="default"
                error={
                  errors.password && touched.password ? errors.password : ''
                }
              />
              <InputWrapper
                name="new_pass"
                type="password"
                variant="outline"
                onChange={(e) => setFieldValue('new_pass', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.new_pass}
                title="New Password"
                placeholder="Enter New Password"
                hasTooltip
                tooltipText="Enter your new password."
                isRequired
                passwordTooltip="default"
                error={
                  errors.new_pass && touched.new_pass ? errors.new_pass : ''
                }
              />
              <InputWrapper
                name="confirm_new_pass"
                type="password"
                variant="outline"
                onChange={(e) => setFieldValue('confirm_new_pass', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.confirm_new_pass}
                title="Confirm New Password"
                placeholder="Enter New Password Again"
                hasTooltip
                tooltipText="Confirm your new password. It must match the field above."
                isRequired
                passwordTooltip="default"
                error={
                  errors.confirm_new_pass && touched.confirm_new_pass
                    ? errors.confirm_new_pass
                    : ''
                }
              />
              <div
                className={`${classes['btns-wrapper']} ${classes['btns-wrapper--single']}`}
              >
                <Button
                  type="submit"
                  isDisabled={!isValid}
                  isLoading={isLoading}
                  icon={CheckIcon}
                  iconPosition="left"
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <Divider dir="horizontal" />
    </div>
  );
};

export default EditAuthForm;
