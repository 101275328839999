import classes from './SingleCourseSkeleton.module.scss';

const SingleCourseSkeleton = () => {
  return (
    <div className={classes['course']}>
      <div className={classes['course__breadcrumb-wrapper']}>
        {Array.from({ length: 4 }, (_, i) => (
          <div
            key={i}
            className={classes['course__breadcrumb-wrapper__label']}
          />
        ))}
      </div>
      <div className={classes['course__data']}>
        <div className={classes['course__data__img-wrapper']} />
        <div className={classes['course__data__content']}>
          <div className={classes['course__data__content__name']} />
          <div className={classes['course__data__content__category']} />
          <div className={classes['course__data__content__badge']} />
          <div className={classes['course__data__content__container']}>
            <div className={classes['course__data__content__label']} />
            <div className={classes['course__data__content__description']} />
          </div>
          <div className={classes['course__data__content__rating']} />
          <div className={classes['course__data__content__container']}>
            <div className={classes['course__data__content__label']} />
            <div className={classes['course__data__content__price']} />
          </div>
          <div className={classes['course__data__content__container']}>
            <div className={classes['course__data__content__label']} />
            <div className={classes['course__data__content__limit']} />
          </div>
          <div className={classes['course__data__content__button']} />
        </div>
      </div>
      <div className={classes['course__tabs-wrapper']}>
        {Array.from({ length: 5 }, (_, i) => (
          <div key={i} className={classes['course__tabs-wrapper__label']} />
        ))}
      </div>
    </div>
  );
};

export default SingleCourseSkeleton;
