import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logoLight from 'assets/images/logo-light.svg';
import logoDark from 'assets/images/logo-dark.svg';
import logoAdminLight from 'assets/images/logo-admin-light.svg';
import logoAdminDark from 'assets/images/logo-admin-dark.svg';
import { ReactComponent as Sun } from 'assets/icons/sun.svg';
import { ReactComponent as Moon } from 'assets/icons/moon.svg';
import { userContext } from 'context/userContext';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import Button from 'components/buttons/Button';
import Navigation from '../navigation/Navigation';
import HeaderButtons from './buttons/HeaderButtons';
import Divider from '../divider/Divider';
import MobileMenu from './mobile/MobileMenu';
import Notifications from './menus/Notifications';
import Search from './search/Search';
import classes from './Header.module.scss';

export interface IHeader {
  isSimple?: boolean;
}

const Header = ({ isSimple }: IHeader) => {
  const { theme, switchTheme } = useContext(appContext);
  const {
    userData: { isAdmin }
  } = useContext(userContext);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;
  const navigate = useNavigate();

  return (
    <div id="header-wrapper" className={classes['header-wrapper']}>
      <div
        className={`${classes['header']} ${
          isSimple ? classes['header--simple'] : ''
        }`}
      >
        <img
          className={classes['logo']}
          src={
            isAdmin
              ? theme === 'dark'
                ? logoAdminDark
                : logoAdminLight
              : theme === 'dark'
              ? logoLight
              : logoDark
          }
          onClick={() => navigate(`${isAdmin ? '/admin' : '/'}`)}
          alt="Logo"
        />
        {isSimple && !isLgMobile && (
          <Button
            icon={theme === 'dark' ? Sun : Moon}
            onClick={switchTheme}
            variant="neutral"
            isIconBtn
          />
        )}
        {!isSimple && (
          <>
            {!isLgMobile && (
              <>
                {!isAdmin && <Search />}
                <div className={classes['nav-wrapper']}>
                  <Navigation />
                  <Divider dir="vertical" />
                  <HeaderButtons />
                </div>
              </>
            )}
            {isLgMobile && (
              <div className={classes['btns-container']}>
                <Notifications isMobile />
                <MobileMenu />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

Header.defaultProps = {
  progress: 0
};

export default Header;
