import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from 'assets/icons/arrowShort.svg';
import { usePagination, DOTS } from 'hooks/usePagination';
import Button from 'components/buttons/Button';
import { navigateWithParams } from 'utils/format';
import classes from './Pagination.module.scss';

interface IPagination {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  siblingCount: number;
  onPageChange: (page: number) => void;
}

const Pagination = ({
  currentPage,
  pageSize,
  totalCount,
  siblingCount,
  onPageChange
}: IPagination) => {
  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();

  const navigateToPage = (page: number) => {
    navigateWithParams(urlParams, { page }, navigate, pathname);
  };

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  const onNext = () => {
    if (+lastPage > currentPage) {
      onPageChange(currentPage + 1);
      navigateToPage(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      onPageChange(currentPage - 1);
      navigateToPage(currentPage - 1);
    }
  };

  let lastPage = paginationRange![paginationRange!.length - 1];

  // Hide pagination if results are less than the page size
  if (totalCount <= pageSize) return <></>;

  return (
    <div className={classes['wrapper']}>
      <Button
        icon={Arrow}
        variant="link-neutral"
        isIconBtn
        iconRotate={270}
        size="sm"
        onClick={onPrevious}
        isDisabled={currentPage === 1}
      />
      {paginationRange?.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <div key={`${pageNumber}${i}`} className={classes['dots']}>
              &#8230;
            </div>
          );
        }

        return (
          <Button
            key={pageNumber}
            variant={pageNumber === currentPage ? 'contrast' : 'link-neutral'}
            size="sm"
            onClick={() => {
              onPageChange(pageNumber as number);
              navigateToPage(pageNumber as number);
            }}
          >
            {pageNumber}
          </Button>
        );
      })}
      <Button
        icon={Arrow}
        variant="link-neutral"
        isIconBtn
        iconRotate={90}
        size="sm"
        onClick={onNext}
        isDisabled={lastPage === currentPage}
      />
    </div>
  );
};

export default Pagination;
