import { useContext } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AdminUsersActionPopup from 'routes/dashboard/admin/users/components/AdminUsersActionPopup';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ReactComponent as BanIconGray } from 'assets/icons/banGray.svg';
import { ReactComponent as ActivateIconGray } from 'assets/icons/activateGray.svg';
import { IUser } from 'context/userContext';
import { popupContext } from 'context/popupContext';
import EmailPopup from 'components/popup/EmailPopup';
import Avatar from 'components/avatar/Avatar';
import WalletAddress from 'components/header/wallet/WalletAddress';
import Button from 'components/buttons/Button';
import ContextMenu from 'components/buttons/ContextMenu';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import { IndeterminateCheckbox } from './InterdeterminateCheckbox';
import { tableColumnsWidths } from './utils/table-utils';
import { dateFormatUrl } from 'utils/static';
import { formatNames, truncateText } from 'utils/format';
import classes from './AdminUserCols.module.scss';

interface IAdminUserCols {
  isSelectable?: boolean;
  isDashboardPage?: boolean;
}

export const AdminUserCols = ({
  isSelectable,
  isDashboardPage
}: IAdminUserCols) => {
  const cols: Column[] = [
    {
      Header: 'User Name',
      accessor: 'user_name',
      maxWidth: tableColumnsWidths.userNameAndPicMax,
      minWidth: tableColumnsWidths.userNameAndPicMin,
      Cell: ({ row }: { row: { original: IUser } }) => {
        return (
          <div className={classes['user']}>
            <Avatar src={row.original.pic} />
            {isDashboardPage ? (
              <div>
                {formatNames(
                  row.original?.first_name + ' ' + row.original?.last_name
                )}
              </div>
            ) : (
              <Link to={`/user/${row.original.slug}/about/main-info`}>
                {formatNames(
                  row.original?.first_name + ' ' + row.original?.last_name
                )}
              </Link>
            )}
          </div>
        );
      }
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ row }: { row: { original: IUser } }) => (
        <div className={classes['email']}>
          {truncateText(row.original.email, 25)}
        </div>
      )
    },
    {
      Header: 'Wallet',
      accessor: 'wallet',
      Cell: ({ row }: { row: { original: IUser } }) => (
        <WalletAddress address={row.original.wallet_edu} />
      )
    },
    {
      Header: 'Active since',
      accessor: 'active',
      Cell: ({ row }: { row: { original: IUser } }) => (
        <div>{moment(row.original?.date_created).format(dateFormatUrl)}</div>
      )
    },
    {
      Header: 'KYC Status',
      accessor: 'kyc_status',
      Cell: ({ row }: { row: { original: IUser } }) => {
        let status = '';
        if (!!row.original.kyc_status) {
          status =
            USER_KYC_STATUS[row.original.kyc_status].charAt(0) +
            USER_KYC_STATUS[row.original.kyc_status].slice(1).toLowerCase();
        } else {
          const noUnderscoreString = USER_KYC_STATUS[0].split('_').join(' ');
          status =
            noUnderscoreString.charAt(0) +
            noUnderscoreString.slice(1).toLowerCase();
        }
        return <>{status}</>;
      }
    }
  ];

  if (!isDashboardPage) {
    cols.push({
      Header: 'User Status',
      accessor: 'user_status',
      Cell: ({ row }: { row: { original: IUser } }) => (
        <div>{row.original.is_banned ? 'Banned' : 'Active'}</div>
      )
    });
  }

  // Add an element to the end of columns
  cols.push({
    Header: '',
    accessor: 'menu',
    maxWidth: tableColumnsWidths.selectionMax,
    minWidth: tableColumnsWidths.selectionMin,
    Cell: ({ row }: { row: { original: IUser } }) => {
      const { setPopup } = useContext(popupContext);

      return (
        <ContextMenu>
          {row.original?.is_banned ? (
            <Button
              variant="link-neutral"
              icon={ActivateIconGray}
              iconPosition="left"
              onClick={() =>
                setPopup(
                  <AdminUsersActionPopup
                    action="activate"
                    users={[row.original]}
                  />
                )
              }
            >
              Activate User
            </Button>
          ) : (
            <Button
              variant="link-neutral"
              icon={BanIconGray}
              iconPosition="left"
              onClick={() =>
                setPopup(
                  <AdminUsersActionPopup action="ban" users={[row.original]} />
                )
              }
            >
              Ban User
            </Button>
          )}
          <Button
            variant="link-neutral"
            icon={Email}
            iconPosition="left"
            onClick={() => setPopup(<EmailPopup users={[row.original]} />)}
          >
            Send Email
          </Button>
        </ContextMenu>
      );
    }
  });

  if (isSelectable) {
    cols.unshift({
      id: 'selection',
      Header: ({
        toggleRowSelected,
        isAllPageRowsSelected,
        selectedFlatRows,
        page,
        rows
      }) => {
        const modifiedOnChange = (event: any) => {
          page.forEach((row: any) => {
            // check each row if it is not disabled
            !row.original.disabled &&
              (selectedFlatRows[0]?.original?.is_banned
                ? row.original?.is_banned
                : !row.original?.is_banned) &&
              toggleRowSelected(row.id, event.currentTarget.checked);
          });
        };

        // Count number of selectable and selected rows in the current page
        // to determine the state of select all checkbox
        let selectableRowsInCurrentPage = 0;
        let selectedRowsInCurrentPage = 0;
        rows.forEach((row: any) => {
          row.isSelected && selectedRowsInCurrentPage++;
          (selectedFlatRows[0]?.original?.is_banned
            ? row.original?.is_banned
            : !row.original?.is_banned) && selectableRowsInCurrentPage++;
        });

        const checked =
          isAllPageRowsSelected ||
          selectableRowsInCurrentPage === selectedRowsInCurrentPage;

        return (
          <div>
            <IndeterminateCheckbox
              onChange={modifiedOnChange}
              checked={checked}
            />
          </div>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      Cell: ({ row, selectedFlatRows }) => {
        return (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              disabled={
                !!selectedFlatRows.length &&
                selectedFlatRows[0].original?.is_banned !==
                  row.original?.is_banned
              }
            />
          </div>
        );
      }
    });
  }

  return cols;
};
