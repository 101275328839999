import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IPopupCtx {
  popupData: React.ReactNode | null;
  popupIsLoading: boolean;
  setPopupLoading: (isLoading: boolean) => void;
  setPopup: (popup: React.ReactNode) => void;
  clearPopup: () => void;
  menuData: React.ReactNode | null;
  setMenu: (menu: React.ReactNode) => void;
  clearMenu: () => void;
}

const initialState = {
  popupData: null,
  popupIsLoading: false,
  setPopupLoading: (isLoading: boolean) => {},
  setPopup: (popup: React.ReactNode) => {},
  clearPopup: () => {},
  menuData: null,
  setMenu: (menu: React.ReactNode) => {},
  clearMenu: () => {}
};

export const popupContext = createContext<IPopupCtx>(initialState);

export function PopupProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const [popupData, setPopupData] = useState<IPopupCtx['popupData']>();
  const [popupIsLoading, setPopupIsLoading] = useState(false);
  const [menuData, setMenuData] = useState<IPopupCtx['menuData']>();

  const setPopup = useCallback(
    (popup: React.ReactNode) => {
      setPopupData(popup);
    },
    [setPopupData]
  );

  const clearPopup = useCallback(() => {
    setPopupData(null);
  }, [setPopupData]);

  const setPopupLoading = useCallback(
    (isLoading: boolean) => {
      setPopupIsLoading(isLoading);
    },
    [setPopupIsLoading]
  );

  const setMenu = useCallback(
    (menu: React.ReactNode) => setMenuData(menu),
    [setMenuData]
  );

  const clearMenu = useCallback(() => {
    setMenuData(null);
  }, [setMenuData]);

  // Lock background scroll on popups
  useEffect(() => {
    if (popupData) {
      document.body.style.overflow = 'hidden';
      (document.getElementById('app_wrapper') as HTMLElement).style.overflow =
        'hidden';
      return;
    }
    document.body.style.overflow = 'visible';
    (document.getElementById('app_wrapper') as HTMLElement).style.overflow =
      'auto';
  }, [popupData]);

  useEffect(() => {
    if (!!popupData) clearPopup();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <popupContext.Provider
      value={{
        popupData,
        popupIsLoading,
        setPopupLoading,
        setPopup,
        clearPopup,
        menuData,
        setMenu,
        clearMenu
      }}
    >
      {children}
    </popupContext.Provider>
  );
}
