import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import NoCertificates from 'assets/images/no-certificates.svg';
import { windowContext } from 'context/windowsContext';
import EmptyState from 'components/emptyState/EmptyState';
import NoResults from 'components/noResults/NoResults';
import Error from 'components/error/Error';
import Pagination from 'components/pagination/Pagination';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import ReceivedCertificate from 'components/certificate/ReceivedCertificate';
import { userCertificatesQuery } from 'query/course-module/queries';
import { IReceivedCertificatesListDto } from 'query/course-module/dto';
import classes from './Certificates.module.scss';

// const sortOptions = [
//   { label: 'Name A-Z', value: 'name' },
//   { label: 'Name Z-A', value: '-name' }
// ];

const Received = () => {
  const { search: urlParams } = useLocation();
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);
  const { page: pageNumber, search, sort } = queryString.parse(urlParams);
  const pageSize: number = 9;

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  // eslint-disable-next-line
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  // eslint-disable-next-line
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}`;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    IReceivedCertificatesListDto
  >(userCertificatesQuery(queryParams));

  const { result: certificates, total_results } = data || {};

  const hasData = !!certificates && certificates?.length > 0;

  return (
    <div className={classes['wrapper']}>
      {isLgMobile && (
        <h5 className={classes['u-light']}>Received Certificates</h5>
      )}
      {/* {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortValue) ||
        page > 1) && (
        <Actions
          hasSearch
          searchPlaceholder="Search by Course Name"
          searchValue={searchValue}
          passSearchValueUp={setSearchValue}
          hasSort
          sortOptions={sortOptions}
          sortValue={sortValue}
          passSortValueUp={setSortValue}
          setPage={setPage}
          isDebounced
        />
      )} */}
      {isLoading && (
        <div className={classes['cards']}>
          <CardSkeleton amount={3} variant="certificate" />
        </div>
      )}
      {error && <Error error={error} />}
      {hasData && !isLoading && (
        <>
          <div className={classes['cards']}>
            {certificates.map((cert) => {
              return <ReceivedCertificate key={cert._id} data={cert} />;
            })}
          </div>
          {!!total_results && total_results > pageSize && (
            <Pagination
              currentPage={page}
              totalCount={total_results as number}
              siblingCount={1}
              pageSize={pageSize}
              onPageChange={(page) => setPage(page)}
            />
          )}
        </>
      )}
      {!hasData && searchValue && !isLoading && !error && <NoResults />}
      {!hasData && !searchValue && !isLoading && !error && (
        <EmptyState
          icon={NoCertificates}
          title="You don't have any certificates yet."
        />
      )}
    </div>
  );
};

export default Received;
