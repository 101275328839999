import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { windowContext } from 'context/windowsContext';
import { InputWrapper } from 'components/input/InputWrapper';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { navigateWithParams } from 'utils/format';
import Button from 'components/buttons/Button';
import FilterMenu from 'components/actions/FilterMenu';
import { IOption } from 'components/input/InputChoice';
import classes from './Actions.module.scss';

interface ISortOption {
  label: string;
  value: string;
}

interface IFilter {
  filterValue: IOption[];
  filterOptions: IOption[];
  filterName: string;
}

const courseSortOptions: ISortOption[] = [
  { label: 'Name A-Z', value: 'name' },
  { label: 'Name Z-A', value: '-name' },
  { label: 'Price ascending', value: 'price' },
  { label: 'Price descending', value: '-price' },
  // { label: 'Rating - ASC', value: 'rating' },
  { label: 'Highest Rated', value: '-avg_rating' },
  { label: 'Oldest', value: 'date_created' },
  { label: 'Newest', value: '-date_created' }
  // { label: 'Date Submitted - ASC', value: 'applied_date' },
  // { label: 'Date Submitted - DESC', value: '-applied_date' }
];

export interface IActions {
  hasSearch?: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  passSearchValueUp?: (value: string) => void;
  hasSort?: boolean;
  sortOptions?: ISortOption[];
  sortValue?: string;
  passSortValueUp?: (value: string) => void;
  hasFilter?: boolean;
  showFilterMenu?: boolean;
  onFilterClick?: () => void;
  passFilterValueUp?: (value: IOption[], filterName: string) => void;
  setPage?: (i: number) => void;
  isInverted?: boolean;
  isDebounced?: boolean;
  filterStartDateRange?: number;
  filterEndDateRange?: number;
  setDateRange?: (value: string[]) => void;
  children?: React.ReactNode;
  filters?: IFilter[];
  hasPriceFilter?: boolean;
  hideFilterButton?: boolean;
  isSortClearable?: boolean;
}

const Actions = ({
  hasSearch,
  searchPlaceholder,
  searchValue,
  passSearchValueUp,
  hasSort,
  sortOptions,
  sortValue,
  passSortValueUp,
  hasFilter,
  showFilterMenu,
  onFilterClick,
  passFilterValueUp,
  filters,
  setPage,
  isInverted,
  isDebounced,
  filterStartDateRange,
  filterEndDateRange,
  setDateRange,
  children,
  hasPriceFilter,
  hideFilterButton,
  isSortClearable
}: IActions) => {
  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();
  const [searchParams] = useSearchParams();
  const sortOpts = sortOptions || courseSortOptions;
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;

  useEffect(() => {
    if (passSearchValueUp) {
      passSearchValueUp(searchValue as string);
    }
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSort = () => {
    return (
      <ReactSelectWrapper
        name="select1"
        placeholder="Sort by"
        isRequired
        options={sortOpts}
        value={sortValue ? sortOpts.filter((v) => v.value === sortValue) : []}
        onChange={(option: ISortOption) => {
          passSortValueUp && passSortValueUp(option ? option.value : '');
          setPage && setPage(1);
          navigateWithParams(
            urlParams,
            { sort: option ? option.value : '', page: 1 },
            navigate,
            pathname
          );
        }}
        onBlur={() => {}}
        width={isMdMobile ? '100%' : '256px'}
        isClearable={isSortClearable}
      />
    );
  };

  return (
    <div
      className={`${classes['wrapper']} ${
        !hideFilterButton || hasSort ? classes['wrapper__empty-space'] : ''
      }
    `}
    >
      <div
        className={`
        ${classes['actions']} 
        ${isInverted ? classes['actions__inverted'] : ''}
      `}
      >
        {isMdMobile ? (
          <div className={classes['actions__mobile']}>
            {hasFilter && !hideFilterButton && (
              <Button
                variant="neutral"
                isIconBtn
                icon={Filter}
                onClick={onFilterClick}
              />
            )}
            {hasSort && isInverted && (
              <div className={classes['actions__sort']}>{renderSort()}</div>
            )}
          </div>
        ) : (
          <>
            {hasFilter && !hideFilterButton && (
              <Button
                variant="neutral"
                isIconBtn
                icon={Filter}
                onClick={onFilterClick}
              />
            )}
            {hasSort && isInverted && (
              <div
                className={`
      ${showFilterMenu ? classes['actions__sort'] : ''}
    `}
              >
                {renderSort()}
              </div>
            )}
          </>
        )}
        {hasSearch && (
          <div className={classes['search']}>
            <InputWrapper
              name="search"
              type="search"
              variant="outline"
              value={searchValue}
              onChange={(e) => {
                passSearchValueUp && passSearchValueUp(e);
                setPage && setPage(1);
                navigateWithParams(
                  urlParams,
                  { search: e, page: 1 },
                  navigate,
                  pathname
                );
              }}
              placeholder={searchPlaceholder}
              isDebounced={isDebounced}
            />
          </div>
        )}
        {hasSort && !isInverted && renderSort()}
        {children}
      </div>
      {showFilterMenu && (
        <FilterMenu
          filters={filters && !!filters.length ? filters : []}
          passFilterValueUp={(filterOption, filterName) => {
            let updatedParams = urlParams;

            // Delete subcategory filter on removing category filter
            if (filterName === 'Category' && filterOption.length === 0) {
              searchParams.delete('subcategory');
              updatedParams = searchParams.toString();
            }

            passFilterValueUp && passFilterValueUp(filterOption, filterName);
            navigateWithParams(
              updatedParams,
              {
                [`${filterName
                  .split(' ')
                  .join('_')
                  .toLocaleLowerCase()}`]: `${filterOption.map(
                  (option) => option.value
                )}`,
                page: 1
              },
              navigate,
              pathname
            );
          }}
          filterStartDateRange={filterStartDateRange}
          filterEndDateRange={filterEndDateRange}
          setDateRange={setDateRange}
          hasPriceFilter={hasPriceFilter}
          setPage={setPage}
        />
      )}
    </div>
  );
};

Actions.defaultProps = {
  hasSearch: false,
  hasSort: false,
  isSortClearable: true
};

export default Actions;
