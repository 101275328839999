import React, { useContext } from 'react';
import { appContext } from 'context/appContext';
import { IToastData } from 'context/toastContext';
import 'react-toastify/dist/ReactToastify.css';
import classes from './Toast.module.scss';
import './Toastify.scss';

const Toast = ({ type, title, msg, linkMsg, linkTo }: IToastData) => {
  const { theme } = useContext(appContext);

  const renderTitle = () => {
    // Set title by type
    let titleIs = title;
    if (!title) {
      switch (type) {
        case 'success':
          titleIs = 'Success!';
          break;
        case 'warning':
          titleIs = 'Warning!';
          break;
        case 'error':
          titleIs = 'Error!';
          break;
        case 'info':
          titleIs = 'Info!';
          break;
        default:
          titleIs = title;
          break;
      }
    }
    return titleIs;
  };

  return (
    <div className={classes['toast-wrapper']} data-theme={theme}>
      <div className={classes['toast-content']}>
        <div className={classes['msg-wrapper']}>
          <div className={classes['text-wrapper']}>
            <div className={classes['title']}>{renderTitle()}</div>
            <div className={classes['subtitle']}>
              <p className={classes['msg']}>
                {msg}{' '}
                {linkMsg && linkTo && (
                  <a
                    className={classes['u-bold']}
                    href={linkTo}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {linkMsg}
                  </a>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Toast.defaultProps = {
  type: 'error',
  title: '',
  msg: '',
  position: 'bottom',
  isSelfClosing: true
};

export default Toast;
