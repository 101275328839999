import classes from './CardSkeleton.module.scss';

interface ICardSkeleton {
  amount: number;
  variant: 'course' | 'creator' | 'certificate';
}

const CardSkeleton = ({ amount, variant }: ICardSkeleton) => {
  // Create an array of div elements based on the amount prop
  const skeleton = Array.from({ length: amount }, (_, i) => (
    <div
      key={i}
      className={`${classes['card']} ${classes[`card__${variant}`]}`}
    >
      <div className={classes[`card__img-wrapper__${variant}`]} />
      <div className={classes[`card__content__${variant}`]}>
        {variant === 'creator' && (
          <div className={classes['card__content__creator__logo-wrapper']}>
            <div />
            <div />
          </div>
        )}
        {variant === 'course' && <div className={classes['card__badge']} />}
        <div className={classes[`card__title__${variant}`]}>
          <div />
          {variant === 'course' && <div />}
        </div>
        {variant === 'course' && (
          <>
            <div className={classes['card__badge']} />
            <div className={classes['card__price']}>
              <div />
              <div />
            </div>
          </>
        )}
        {variant === 'certificate' && (
          <>
            <div className={classes['card__name']} />
            <div className={classes['card__subtitle']} />
          </>
        )}
        {variant === 'creator' && <div className={classes['card__button']} />}
      </div>
    </div>
  ));

  return <>{skeleton}</>;
};

CardSkeleton.defaultProps = {
  amount: 1
};

export default CardSkeleton;
