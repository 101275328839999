import React, { useContext, useEffect } from 'react';
import usdc from 'assets/images/usdc.svg';
import { appContext } from 'context/appContext';
import { InputWrapper } from 'components/input/InputWrapper';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { InputGroup } from 'components/input/InputGroup';
import Divider from 'components/divider/Divider';
import { IFormik } from './CourseForm';
import { InputChoice } from 'components/input/InputChoice';
import { formatPrice } from 'utils/format';
import { COURSE_EXPIRY, REACT_APP_MIN_COURSE_PRICE } from 'utils/constants';
import { getPriceInUsdc } from 'utils/helpers';
import classes from './Form.module.scss';

interface IStep3
  extends Pick<
    IFormik,
    'values' | 'errors' | 'touched' | 'setFieldValue' | 'handleBlur'
  > {}

export const expirationOptions = [
  { value: COURSE_EXPIRY.YEAR, label: '1 Year' },
  {
    value: COURSE_EXPIRY.INDEFINITELY,
    label: 'Indefinitely'
  }
];

export const currencyOptions = [
  // { label: 'MATIC', value: 'matic', symbol: matic },
  { label: 'USDC', value: 'usdc', symbol: usdc }
];

export const Step3 = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur
}: IStep3) => {
  const courseCurrency = values.currency ? values.currency.label : '';
  const coursePrice = parseFloat(values.price) || 0;
  const {
    platformSettings: { platform_fee }
  } = useContext(appContext);
  const platformEarning = ((coursePrice * platform_fee) / 100).toFixed(2);
  const creatorEarning = ((coursePrice * (100 - platform_fee)) / 100).toFixed(
    2
  );

  // Set currency in form values
  useEffect(() => {
    setFieldValue('currency', currencyOptions[0] as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InputChoice
        name="expiration"
        type="radio"
        onChange={(e) => setFieldValue('expiration', e)}
        title="Course Time Limit"
        subtitle="After the limit expires, no one will be able to buy this course."
        isRequired
        options={expirationOptions}
        selectedOptions={values.expiration}
      />
      <InputGroup
        name="price-group"
        title="Course Price"
        subtitle="Select a token and enter the price amount of the course."
        isRequired
        type="1-2"
      >
        <ReactSelectWrapper
          name="currency"
          placeholder="Select"
          isRequired
          options={currencyOptions}
          isCurrency
          onChange={(e) => setFieldValue('currency', e)}
          onBlur={(e) => handleBlur(e)}
          value={currencyOptions[0]}
          error={
            errors.currency && touched.currency
              ? (errors.currency as string)
              : ''
          }
          noChevron
          noDropdown
        />
        <InputWrapper
          name="price"
          type="number"
          variant="outline"
          onChange={(e) => setFieldValue('price', getPriceInUsdc(e))}
          onBlur={(e) => handleBlur(e)}
          value={values.price}
          placeholder={`Min. ${REACT_APP_MIN_COURSE_PRICE}`}
          isRequired
          error={errors.price && touched.price ? errors.price : ''}
        />
      </InputGroup>
      <div className={classes['price-box']}>
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Course Price</div>
          <div className={classes['price-box__value']}>
            {`${formatPrice(coursePrice)} ${courseCurrency}` || '-'}
          </div>
        </div>
        <Divider dir="horizontal" />
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Platform Fee</div>
          <div
            className={classes['price-box__value']}
          >{`${platform_fee}%`}</div>
        </div>
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Platform Earning</div>
          <div className={classes['price-box__value']}>
            {`${formatPrice(+platformEarning)} ${courseCurrency}` || '-'}
          </div>
        </div>
        <div className={classes['price-box__row']}>
          <div className={classes['price-box__title']}>Creator Earning</div>
          <div className={classes['price-box__value']}>
            {`${formatPrice(+creatorEarning)} ${courseCurrency}` || '-'}
          </div>
        </div>
      </div>
    </>
  );
};

export default IStep3;
