import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoReviews from 'assets/images/no-reviews.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as EyeHidden } from 'assets/icons/eye-hidden.svg';
import { popupContext } from 'context/popupContext';
import { userContext } from 'context/userContext';
import { toastContext } from 'context/toastContext';
import RatingComponent from 'components/rating/Rating';
import Divider from 'components/divider/Divider';
import Button from 'components/buttons/Button';
import FeedbackPopup from '../components/popups/FeedbackPopup';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import Avatar from 'components/avatar/Avatar';
import EmptyState from 'components/emptyState/EmptyState';
import Pagination from 'components/pagination/Pagination';
import Tooltip from 'components/tooltip/Tooltip';
import Actions from 'routes/dashboard/profile/components/Actions';
import {
  COURSE_RELATIONSHIP,
  ICourse,
  ICourseReview,
  ICourseReviewDto
} from 'query/course-module/dto';
import { fetchApi } from 'utils/requests';
import { dateFormatUrl } from 'utils/static';
import { formatNames } from 'utils/format';
import classes from './Reviews.module.scss';

interface IReviewsProps {
  course: ICourse;
}

const sortOptions = [
  { label: 'Newest', value: '-date_created' },
  { label: 'Oldest', value: 'date_created' },
  { label: 'Highest Rating', value: '-rating' },
  { label: 'Lowest Rating', value: 'rating' }
];

const Reviews = ({ course }: IReviewsProps) => {
  const queryClient = useQueryClient();
  const ref = useRef<any>();
  const { search: urlParams, state } = useLocation();
  const navigate = useNavigate();
  const { page: pageNumber, sort } = queryString.parse(urlParams);
  const { setPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const {
    userData: { isAdmin }
  } = useContext(userContext);
  const [sortValue, setSortValue] = useState(
    (sort as string) || '-date_created'
  );
  const [page, setPage] = useState(parseInt(pageNumber as string) || 1);
  const pageSize = 4;

  const queryParams = `?&page=${page}&limit=${pageSize}${
    !!sortValue ? `&sort=${sortValue}` : ''
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseReviewDto>(
    {
      queryKey: [`course-reviews-${course._id}`, { page, sort }],
      queryFn: async () => {
        return await fetchApi(
          'courses',
          `/course/${course._id}/feedback${queryParams}`,
          {
            method: 'GET',
            auth: true
          }
        );
      }
    }
  );

  const { mutate: changeVisibility } = useMutation({
    mutationKey: ['change-visibility'],
    mutationFn: async (review: ICourseReview) => {
      await fetchApi(
        'courses',
        `/admin/course/${course._id}/feedback/${
          review._id
        }?hide=${!review.hidden}`,
        {
          method: 'PUT',
          auth: true
        }
      );
      return !review.hidden;
    },
    onSuccess: (isHide: boolean) => {
      queryClient.invalidateQueries([
        `course-reviews-${course._id}`,
        { page, sort }
      ]);
      setToast({
        type: 'success',
        title: 'Visibility Changed',
        msg: `This review  ${isHide ? 'was hidden' : 'is now visible'}.`,
        position: 'top',
        autoClose: true
      });
    }
  });

  const totalReviews = useMemo(
    () =>
      Object.values(course.ratings).reduce(
        (accumulator, value) => accumulator + value,
        0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, course.ratings]
  );

  const renderStars = (rating: number, count: ICourse['ratings']) => {
    return (
      <RatingComponent
        rating={rating}
        count={count}
        readOnly
        onlyStars
        isYellow
        withCountPerRating
      />
    );
  };

  useEffect(() => {
    if (state?.autoFocus) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [state?.autoFocus]);

  const { result, total_results } = data || {};
  const hasReviews = !!result?.length && !isLoading && !error;

  return (
    <div ref={ref} className={classes['reviews__container']}>
      <div className={classes['reviews__list']}>
        {hasReviews && totalReviews > 1 && (
          <div className={classes['reviews__list__sort-wrapper']}>
            <Actions
              hasSort
              sortValue={sortValue}
              isSortClearable={false}
              sortOptions={sortOptions}
              passSortValueUp={setSortValue}
            />
          </div>
        )}
        {isLoading && <Loader />}
        {!!error && <Error error={error} />}
        {!!result?.length &&
          result.map((review, i) => (
            <div
              key={review._id}
              className={classes['reviews__list__item__wrapper']}
            >
              <Avatar src={review.user.pic} isBorderless />
              <div className={classes['reviews__list__item__container']}>
                <div className={classes['reviews__list__item__heading']}>
                  <Button
                    className={classes['reviews__list__item__username']}
                    variant="link-contrast"
                    withPadding={false}
                    onClick={() =>
                      navigate(`/user/${review.user.slug}/about/main-info`)
                    }
                  >
                    {formatNames(
                      review.user.first_name + ' ' + review.user.last_name
                    )}
                  </Button>
                  <div className={classes['reviews__list__item__rating']}>
                    <RatingComponent
                      rating={review.rating}
                      readOnly
                      onlyStars
                      isYellow
                    />
                    <div
                      className={`${classes['u-body3']} ${classes['u-text--content']}`}
                    >
                      {moment(review.date_created).format(dateFormatUrl)}
                    </div>
                  </div>
                </div>
                <TextWithCharLimit limit={200} text={review.content} />
              </div>
              {isAdmin && (
                <>
                  {review.hidden && (
                    <div className={classes['visibility-btn']}>
                      <span className={classes['u-text--content']}>
                        (Hidden)
                      </span>
                      <Tooltip id="unhide" text="Unhide comment">
                        <Button
                          variant="link-neutral"
                          icon={Eye}
                          isIconBtn
                          withPadding={false}
                          onClick={() => changeVisibility(review)}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {!review.hidden && (
                    <div className={classes['visibility-btn']}>
                      <Tooltip id="hide" text="Hide comment">
                        <Button
                          variant="link-neutral"
                          icon={EyeHidden}
                          isIconBtn
                          withPadding={false}
                          onClick={() => changeVisibility(review)}
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        {hasReviews && (
          <Pagination
            currentPage={page}
            totalCount={total_results as number}
            siblingCount={1}
            pageSize={pageSize}
            onPageChange={(page: number) => setPage(page)}
          />
        )}
        {!hasReviews && !isLoading && !error && (
          <EmptyState
            title="There are no Reviews at the moment."
            message="We would love to hear your thoughts on this course! Please take a moment to leave a rating."
            icon={NoReviews}
          />
        )}
      </div>
      <div className={classes['reviews__summary__wrapper']}>
        <div className={classes['reviews__summary__container']}>
          <div className={classes['reviews__summary__heading']}>
            <div className={classes['u-semiBold']}>Total Rating</div>
            <h3 className={classes['u-semiBold']}>
              {course.avg_rating.toFixed(
                Number.isInteger(course.avg_rating) ? 0 : 1
              )}{' '}
              of 5
            </h3>
            <RatingComponent
              rating={course.avg_rating}
              readOnly
              onlyStars
              isYellow
            />
            Based on {totalReviews} review{totalReviews === 1 ? '' : 's'}
          </div>
          <Divider />
          <div className={classes['reviews__summary__ratings']}>
            {Object.keys(course.ratings)
              .reverse()
              .map((key) => (
                <div key={key} className={classes['bar__wrapper']}>
                  <div className={classes['bar__container']}>
                    <div
                      className={classes['bar__fill']}
                      style={{
                        width: `${
                          (course.ratings[
                            key as unknown as keyof ICourse['ratings']
                          ] /
                            totalReviews) *
                          100
                        }%`
                      }}
                    />
                  </div>
                  {/*// @ts-ignore */}
                  {renderStars(+key, course.ratings)}
                </div>
              ))}
          </div>
          {!isAdmin &&
            course.course_relationship !== COURSE_RELATIONSHIP.CREATOR &&
            course?.purchased?.active &&
            !course.hasRated && (
              <Button
                variant="neutral"
                onClick={() => setPopup(<FeedbackPopup course={course} />)}
              >
                Leave Review
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
