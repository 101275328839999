import { useContext } from 'react';
import { ReactComponent as Verified } from 'assets/icons/verified.svg';
import { userContext } from 'context/userContext';
import Badge from './Badge';
import classes from './VerifiedBadge.module.scss';

interface IVerifiedBadgeProps {
  isVisible?: boolean;
}

const VerifiedBadge = ({ isVisible }: IVerifiedBadgeProps) => {
  const {
    userData: { isAdmin }
  } = useContext(userContext);

  return (
    <div className={classes['verified']}>
      {isVisible && (
        <>
          <Verified />
          <p className={`${classes['u-body2']} ${classes['u-text--success']}`}>
            Verified User
          </p>
        </>
      )}
      {isAdmin && !isVisible && <Badge badgeType="contrast" text="Admin" />}
    </div>
  );
};
export default VerifiedBadge;
