import React from 'react';
import moment from 'moment';
import { ICourse } from 'query/course-module/dto';
import { dateFormatUrl } from 'utils/static';
import { useNavigate } from 'react-router-dom';
import classes from './Certificate.module.scss';

export interface IReceivedCertificate {
  data: ICourse['purchased'];
}

const ReceivedCertificate = ({ data }: IReceivedCertificate) => {
  const navigate = useNavigate();

  return (
    <div
      className={classes['certificate']}
      onClick={() =>
        navigate(`/courses/${data.course.slug}/certificate?changes=0`, {
          state: { autoFocus: true }
        })
      }
    >
      <div className={classes['certificate__img-wrapper']}>
        <img src={data?.diploma_link} alt={data?.course?.name || ''} />
      </div>
      <div className={classes['certificate__content']}>
        <div className={classes['certificate__title']}>
          {!data.seen && <span className={classes['notification-circle']} />}
          <span>{data?.course?.name || '(Untitled)'}</span>
        </div>
        <div className={classes['certificate__text']}>
          Received on: {moment(data?.updated_at).format(dateFormatUrl)}
        </div>
      </div>
    </div>
  );
};

export default ReceivedCertificate;
