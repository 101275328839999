import { useState } from 'react';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import Button from './Button';
import { IVideoFile } from 'query/course-module/dto';
import { fetchApi } from 'utils/requests';

interface IDownloadFileButton {
  file: IVideoFile;
  isAdminPage?: boolean;
  isDisabled?: boolean;
}

const DownloadFileButton = ({
  file,
  isAdminPage,
  isDisabled
}: IDownloadFileButton) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const getFile = async (file: IVideoFile) => {
    setIsDownloading(true);
    // Get the file from BE
    try {
      await fetchApi(
        'courses',
        isAdminPage
          ? `/admin/course/${file.course_id}/video/${file.video_id}/file/${file._id}`
          : `/course/${file.course_id}/video/${file.video_id}/file/${file._id}`,
        {
          method: 'GET',
          auth: true,
          isBlob: true
        }
      ).then((response: Blob) => {
        const url = window.URL.createObjectURL(response);
        // Create <a> that holds the download link
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        // Add the <a> to the document
        document.body.appendChild(link);
        // Click the <a> to trigger the download
        link.click();
        // Remove the <a> from the document
        document.body.removeChild(link);
      });
    } catch (e) {}
    setIsDownloading(false);
  };

  return (
    <Button
      variant="neutral"
      isIconBtn
      icon={Download}
      onClick={() => getFile(file)}
      isLoading={isDownloading}
      isDisabled={isDisabled}
    />
  );
};

export default DownloadFileButton;
