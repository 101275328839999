import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import VerifyAccountPopup from 'components/popup/VerifyAccountPopup';
import VisibilityAction from '../VisibilityAction';
import CopyButton from 'components/buttons/CopyButton';
import { IMeDataDto } from 'query/acc-module/dto';
import { truncateText, isFieldPublic } from 'utils/format';
import classes from './About.module.scss';

interface IMainInfoProps
  extends Pick<
    IMeDataDto,
    'first_name' | 'last_name' | 'email' | 'additional_info' | 'hidden_fields'
  > {
  isPublic?: boolean;
}

const MainInfo = ({
  first_name,
  last_name,
  email,
  additional_info,
  hidden_fields,
  isPublic
}: IMainInfoProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { kyc } = queryString.parse(search);
  const { setPopup } = useContext(popupContext);

  const isEmailPublic = isFieldPublic(hidden_fields, 'email');
  const isAboutPublic = isFieldPublic(hidden_fields, 'about');

  useEffect(() => {
    if (!!kyc) return setPopup(<VerifyAccountPopup />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes['wrapper']}>
      <div className={classes['title']}>
        <h5 className={classes['u-light']}>Main Information</h5>
        {!isPublic && (
          <Button
            onClick={() => navigate('/user/about/main-info/edit')}
            icon={Edit}
            iconPosition="left"
            variant="link-neutral"
            withPadding={false}
          >
            Edit
          </Button>
        )}
      </div>
      <div className={classes['details']}>
        <div>
          <div className={classes['details__title']}>Name</div>
          <div className={classes['details__content']}>
            {first_name + ' ' + last_name}
          </div>
        </div>
      </div>
      <div className={classes['details']}>
        <div>
          <div className={classes['details__title']}>
            About {!isAboutPublic && <span>(Hidden)</span>}
          </div>
          <div className={classes['details__content']}>
            {additional_info.about || '-'}
          </div>
        </div>
        {!isPublic && (
          <VisibilityAction
            tooltipId="tooltip-about-btn"
            field="about"
            isPublic={isAboutPublic}
          />
        )}
      </div>
      <div className={classes['details']}>
        <div>
          <div className={classes['details__title']}>
            Email {!isEmailPublic && <span>(Hidden)</span>}
          </div>
          <div
            className={`${classes['details__content']} ${classes['details__content__row']}`}
          >
            {truncateText(email, 40)}
            <CopyButton text={email} size="sm" />
          </div>
        </div>
        {!isPublic && (
          <VisibilityAction
            tooltipId="tooltip-email-btn"
            field="email"
            isPublic={isEmailPublic}
          />
        )}
      </div>
    </div>
  );
};

export default MainInfo;
