import { useContext, useEffect } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import queryString from 'query-string';
import NoTest from 'assets/images/no-test-questions.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { userContext } from 'context/userContext';
import { popupContext } from 'context/popupContext';
import { windowContext } from 'context/windowsContext';
import {
  COURSE_RELATIONSHIP,
  ICourseResourcesData,
  ITest
} from 'query/course-module/dto';
import Badge from 'components/badge/Badge';
import Divider from 'components/divider/Divider';
import Button from 'components/buttons/Button';
import EmptyState from 'components/emptyState/EmptyState';
import VideoTestsPopup from '../components/popups/VideoTestsPopup';
import { IResources } from '../components/Resources';
import classes from './TestQuestions.module.scss';

interface ITestProps {
  test: ITest;
  isAdmin: boolean;
  isCourseActivated: boolean;
  courseRelationship: COURSE_RELATIONSHIP;
}

const Test = ({
  test,
  isAdmin,
  isCourseActivated,
  courseRelationship
}: ITestProps) => {
  const {
    exam,
    video_id,
    creator_id,
    test_passed,
    video_title,
    completion_score
  } = test;
  const { videoId } = useParams();
  const { state } = useLocation();
  const { userData } = useContext(userContext);
  const isCreator = userData?._id === creator_id;
  const { setPopup } = useContext(popupContext);
  const { windowSize } = useContext(windowContext);
  const isMdMobile = windowSize.isMdMobile;

  // When navigated with state, auto open the test
  // for the video we are currently viewing
  useEffect(() => {
    if (!!state?.openTest && video_id === videoId) {
      setPopup(
        <VideoTestsPopup
          test={test}
          isPrivateView={
            isAdmin || courseRelationship === COURSE_RELATIONSHIP.CREATOR
          }
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const isRestricted = !(
    (isCourseActivated &&
      courseRelationship === COURSE_RELATIONSHIP.PURCHASED) ||
    isAdmin ||
    courseRelationship === COURSE_RELATIONSHIP.CREATOR
  );

  return (
    <div key={video_id} className={classes['test__wrapper']}>
      <div
        className={`
          ${classes['test__header']} 
          ${!isRestricted ? classes['u-cursor--pointer'] : ''}
        `}
        onClick={() => {
          if (!isRestricted) {
            setPopup(
              <VideoTestsPopup
                test={test}
                isPrivateView={
                  isAdmin || courseRelationship === COURSE_RELATIONSHIP.CREATOR
                }
              />
            );
          }
        }}
      >
        <div className={classes['test__header__title-wrapper']}>
          <div className={classes['test__header__title-container']}>
            <div className={classes['title-and-badge']}>
              <div className={`${classes['u-bold']} ${classes['u-h5']}`}>
                {video_title}
              </div>
              {!isAdmin && !isCreator && (
                <Badge
                  text={test_passed ? 'PASSED' : 'NOT PASSED'}
                  badgeType={test_passed ? 'success' : 'disabled'}
                />
              )}
            </div>
          </div>
          <div className={classes['test__header__subtitle-container']}>
            {completion_score !== undefined && (
              <>
                <span>Complete with at least {completion_score}% to pass</span>
                {!isMdMobile && <Divider dir="vertical" />}
              </>
            )}
            <span>{exam.length} Questions</span>
          </div>
        </div>
        {((isCourseActivated &&
          courseRelationship === COURSE_RELATIONSHIP.PURCHASED) ||
          isAdmin ||
          courseRelationship === COURSE_RELATIONSHIP.CREATOR) && (
          <Button
            size="sm"
            iconRotate={90}
            icon={ArrowIcon}
            variant="link-neutral"
          />
        )}
      </div>
    </div>
  );
};

interface ITestQuestionsProps {
  tests: ICourseResourcesData['tests'];
  courseProps: IResources['courseProps'];
  showEmptyStateBtn: boolean;
}

const TestQuestions = ({
  tests,
  courseProps,
  showEmptyStateBtn
}: ITestQuestionsProps) => {
  const { slug, videoId } = useParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);

  const {
    isCourseActivated,
    isVideoPage,
    isAdminPage,
    course: { course_relationship }
  } = courseProps;

  const renderTests = () => {
    let preparedTests = [];

    // On video page -- return only the current video's test
    if (isVideoPage)
      preparedTests = tests.filter((test) => test.video_id === videoId);
    // On course page -- return all videos' tests
    else preparedTests = tests;

    if (!preparedTests.length)
      return (
        <EmptyState
          title={`This ${
            isVideoPage ? 'video' : 'course'
          } does not have any tests attached to it.`}
          message={
            course_relationship === COURSE_RELATIONSHIP.CREATOR
              ? 'You can upload videos and add tests to them in the Videos tab.'
              : ''
          }
          buttonName={showEmptyStateBtn ? 'Go to Videos' : ''}
          onClick={() =>
            navigate({
              pathname: `/courses/${slug}`,
              search: `${createSearchParams({ changes: changes as string })}`
            })
          }
          icon={NoTest}
        />
      );

    return preparedTests.map((test) => (
      <Test
        test={test}
        key={test.video_id}
        isAdmin={isAdminPage}
        isCourseActivated={isCourseActivated}
        courseRelationship={course_relationship}
      />
    ));
  };

  return <div className={classes['test']}>{renderTests()}</div>;
};

export default TestQuestions;
