import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import Button, { IButtonProps } from 'components/buttons/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { colors } from 'utils/styles';
import classes from './InfoBox.module.scss';

interface IInfoBoxProps {
  className?: string;
  type?: 'info' | 'warning';
  title?: string;
  msg: string | React.ReactNode;
  button?: {
    text: string;
    variant?: IButtonProps['variant'];
    icon?: IButtonProps['icon'];
    onClick: IButtonProps['onClick'];
    isLoading?: IButtonProps['isLoading'];
    minWidth?: IButtonProps['minWidth'];
    isDisabled?: IButtonProps['isDisabled'];
    tooltip?: string;
  };
}

const InfoBox = ({ className, type, title, msg, button }: IInfoBoxProps) => {
  return (
    <div className={`${classes['info-box']} ${className}`}>
      <div
        className={`${classes['info']} ${
          type === 'warning' ? classes['msg--warning'] : ''
        }`}
      >
        {type === 'info' && <Info />}
        {type === 'warning' && <Warning fill={colors.error40} />}
        <div className={classes['info__text']}>
          {!!title && (
            <div className={classes['info__text__title']}>{title}</div>
          )}
          <p className={`${classes['u-body2']} ${classes['u-text--primary']}`}>
            {msg}
          </p>
        </div>
      </div>
      {!!button && (
        <Tooltip id={button.text} text={button.tooltip || ''}>
          <Button
            type="button"
            variant={button.variant || 'link-neutral'}
            size="sm"
            onClick={button.onClick}
            icon={button.icon}
            iconPosition="left"
            isLoading={button.isLoading || false}
            minWidth={button.minWidth}
            isDisabled={button.isDisabled}
          >
            {button.text}
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default InfoBox;
