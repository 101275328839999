import React, { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form, FormikErrors, FormikTouched } from 'formik';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import { popupContext } from 'context/popupContext';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import Summary from './Summary';
import GenericPopup from 'components/popup/GenericPopup';
import { InputMedia } from 'components/input/InputMedia';
import { IRegFormFields, RegisterSchema } from 'utils/yupSchemas';
import { handleMediaUpload } from 'utils/processing';
import { registerMutation } from 'query/acc-module/mutations';
import classes from './Form.module.scss';

const initialValues: IRegFormFields = {
  email: '',
  password: '',
  confirm_password: '',
  first_name: '',
  last_name: '',
  pic: ''
};

interface IRegisterForm {
  steps: string[];
  currentStep: number;
  setStep: (step: number) => void;
}

const RegisterForm = ({ steps, currentStep, setStep }: IRegisterForm) => {
  const { setPopup } = useContext(popupContext);

  // Register mutation
  const { isLoading, mutate: handleRegister } = useMutation({
    ...registerMutation(),
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
          isClosable={false}
        />
      );
    }
  });

  const renderPrevBtn = () => (
    <Button
      type="button"
      variant="neutral"
      onClick={() => setStep(currentStep - 1)}
      icon={Arrow}
      iconRotate={270}
      iconPosition="left"
    >
      Previous Step
    </Button>
  );

  const renderStep = (
    step: number,
    errors: FormikErrors<IRegFormFields>,
    touched: FormikTouched<IRegFormFields>,
    values: IRegFormFields,
    setFieldValue: (fieldName: string, value: string) => void,
    setFieldError: (fieldName: string, value: string) => void,
    setFieldTouched: (fieldName: string, value: boolean) => void,
    submitForm: () => void,
    handleBlur: (e: React.FocusEvent<HTMLElement>) => void,
    isValid: boolean
  ) => {
    if (step === 0) {
      return (
        <>
          <InputWrapper
            name="email"
            type="text"
            variant="outline"
            onChange={(e) => setFieldValue('email', e)}
            onBlur={(e) => handleBlur(e)}
            value={values.email}
            title="E-mail"
            placeholder="Enter E-mail"
            hasTooltip
            tooltipText="Fill in you email address."
            isRequired
            error={errors.email && touched.email ? errors.email : ''}
          />
          <InputWrapper
            name="password"
            type="password"
            variant="outline"
            onChange={(e) => setFieldValue('password', e)}
            onBlur={(e) => handleBlur(e)}
            value={values.password}
            title="Password"
            placeholder="Enter Password"
            hasTooltip
            tooltipText="Fill in your password."
            isRequired
            passwordTooltip="default"
            error={errors.password && touched.password ? errors.password : ''}
          />
          <InputWrapper
            name="confirm_password"
            type="password"
            variant="outline"
            onChange={(e) => setFieldValue('confirm_password', e)}
            onBlur={(e) => handleBlur(e)}
            value={values.confirm_password}
            title="Confirm  Password"
            placeholder="Enter Password Again"
            hasTooltip
            tooltipText="Confirm your password. It must match the field above."
            isRequired
            passwordTooltip="default"
            error={
              errors.confirm_password && touched.confirm_password
                ? errors.confirm_password
                : ''
            }
          />
          <div
            className={`${classes['btns-wrapper']} ${classes['btns-wrapper--single--end']}`}
          >
            <Button
              type="button"
              onClick={() => setStep(currentStep + 1)}
              icon={Arrow}
              iconRotate={90}
              isDisabled={
                !!errors.email || !!errors.password || !!errors.confirm_password
              }
            >
              Next Step
            </Button>
          </div>
        </>
      );
    }

    if (step === 1) {
      return (
        <>
          <InputWrapper
            name="first_name"
            type="text"
            variant="outline"
            onChange={(e) => setFieldValue('first_name', e)}
            onBlur={(e) => handleBlur(e)}
            value={values.first_name}
            title="First Name"
            placeholder="Enter First Name"
            hasTooltip
            tooltipText="Fill in your first name."
            isRequired
            error={
              errors.first_name && touched.first_name ? errors.first_name : ''
            }
            limit={50}
            subtitle={`${values.first_name?.length} of 50 characters used`}
          />
          <InputWrapper
            name="last_name"
            type="text"
            variant="outline"
            onChange={(e) => setFieldValue('last_name', e)}
            onBlur={(e) => handleBlur(e)}
            value={values.last_name}
            title="Last Name"
            placeholder="Enter Last Name"
            hasTooltip
            tooltipText="Fill in your last name."
            isRequired
            error={
              errors.last_name && touched.last_name ? errors.last_name : ''
            }
            limit={50}
            subtitle={`${values.last_name?.length} of 50 characters used`}
          />
          <InputMedia
            name="pic"
            title="Profile Picture"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleMediaUpload(
                e,
                'pic',
                setFieldValue,
                setFieldTouched,
                setFieldError
              );
            }}
            onClear={() => setFieldValue('pic', '')}
            pic={values.pic}
            error={errors.pic && touched.pic ? errors.pic : ''}
            isSquare
          />
          <div className={classes['btns-wrapper']}>
            {renderPrevBtn()}
            <Button
              type="button"
              onClick={() => setStep(currentStep + 1)}
              icon={Arrow}
              iconRotate={90}
              isDisabled={!!errors.first_name || !!errors.last_name}
            >
              Next Step
            </Button>
          </div>
        </>
      );
    }

    // Clear non-mandatory field errors on step 3
    delete errors.pic;

    return (
      <>
        <Summary data={values} />
        <div className={classes['btns-wrapper']}>
          {renderPrevBtn()}
          <Button
            type="submit"
            icon={Check}
            isDisabled={!isValid}
            isLoading={isLoading}
          >
            Register
          </Button>
        </div>
      </>
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegisterSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (isLoading) return;
        handleRegister(values, {
          onSuccess: () => {
            setPopup(
              <GenericPopup
                title="Registration Successful!"
                msg="Please check your email for a confirmation link to activate your account. In order to purchase or publish courses, you’ll need to verify your account. The account verification can be taken any time."
                redirectPath="/courses"
                buttonName="Explore Courses"
                buttonVariant="contrast"
                isClosable={false}
              />
            );
            resetForm();
            setStep(0);
          }
        });
        setSubmitting(false);
      }}
      validateOnBlur
      validateOnMount
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        submitForm,
        handleBlur,
        isValid
      }) => (
        <Form className={classes['form']}>
          <h4 className={classes['u-light']}>{steps[currentStep]}</h4>
          {renderStep(
            currentStep,
            errors,
            touched,
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            submitForm,
            handleBlur,
            isValid
          )}
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
