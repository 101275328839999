import Tabs from 'components/tabs/Tabs';
import Transactions from './Transactions';
import classes from './AdminFunds.module.scss';
import AdminBaseReport from './AdminBaseReport';
import AdminTaxReport from './AdminTaxReport';
import Currencies from './Currencies';

interface IAdminFunds {
  tab: 'transactions' | 'base' | 'tax' | 'currencies';
}

const AdminFunds = ({ tab }: IAdminFunds) => {
  const tabs = [
    {
      id: 'transactions',
      name: 'Transactions',
      route: 'admin/transactions',
      component: () => <Transactions />
    },
    {
      id: 'base',
      name: 'Base Report',
      route: 'admin/base',
      component: () => <AdminBaseReport />
    },
    {
      id: 'tax',
      name: 'Tax Report',
      route: 'admin/tax',
      component: () => <AdminTaxReport />
    },
    {
      id: 'currencies',
      name: 'Currencies',
      route: 'admin/currencies',
      component: () => <Currencies />
    }
  ];

  return (
    <div className={classes['tabs-wrapper']}>
      <Tabs
        tabs={tabs}
        activeTabIndex={tabs.findIndex((t) => t.id === tab)}
        size={'sm'}
      />
    </div>
  );
};
export default AdminFunds;
