import { createContext, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { tokenExpired } from 'utils/storage';
import { meDataQuery } from 'query/acc-module/queries';
import {
  singleCourseDataQuery,
  userCertificatesQuery,
  userNotificationsQuery
} from 'query/course-module/queries';
import { IMeDataDto, USER_KYC_STATUS } from 'query/acc-module/dto';
import {
  ICourse,
  IReceivedCertificatesListDto,
  IUserNotificationsDto
} from 'query/course-module/dto';
import { IReceivedCertificate } from 'components/certificate/ReceivedCertificate';

interface IUserCtx {
  userData: IUser;
  userDataIsLoading: boolean;
  userDataError: Error | null;
  isTokenExpired: boolean;
  newCertificates: IReceivedCertificate['data'][];
  lastWatchedCourse: ICourse | null;
  showContinueWatching: boolean;
  setShowContinueWatching: (show: boolean) => void;
  setUser: (data: IUser) => void;
  clearUser: () => void;
  notifications: IUserNotificationsDto[];
}

export enum ROLE_FLAG {
  'USER' = 1,
  'ADMIN' = 2
}

export interface IUser {
  _id: string;
  isAdmin: boolean;
  email: string;
  first_name: string;
  last_name: string;
  role_access: string;
  date_created?: number;
  role_flag: ROLE_FLAG | null;
  pic?: string;
  active: boolean;
  additional_info: IMeDataDto['additional_info'];
  hidden_fields: IMeDataDto['hidden_fields'];
  wallet_edu: string;
  kyc_status: USER_KYC_STATUS;
  slug: string;
  is_banned?: boolean;
  last_watched_course?: string;
  last_watched_video?: string;
  is_creator?: boolean;
  is_edu_admin?: boolean;
  course_count?: number;
}

const userDataInitialValues = {
  _id: '',
  isAdmin: false,
  email: '',
  first_name: '',
  last_name: '',
  role_access: '',
  role_flag: null,
  pic: '',
  active: false,
  wallet_edu: '',
  slug: '',
  kyc_status: USER_KYC_STATUS.NOT_STARTED,
  additional_info: {
    email: '',
    banner: '',
    about: '',
    website: '',
    twitter: '',
    instagram: '',
    facebook: ''
  },
  hidden_fields: {
    email: false,
    banner: false,
    about: false,
    website: false,
    twitter: false,
    instagram: false,
    facebook: false
  }
};

export const userContext = createContext<IUserCtx>({
  userData: userDataInitialValues,
  userDataIsLoading: true,
  userDataError: null,
  isTokenExpired: true,
  setUser: (data: IUser) => {},
  clearUser: () => {},
  newCertificates: [],
  lastWatchedCourse: null,
  showContinueWatching: false,
  setShowContinueWatching: (show: boolean) => {},
  notifications: []
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<IUser>(userDataInitialValues);
  const [isTokenExpired, setIsTokenExpired] = useState(tokenExpired());
  const [showContinueWatching, setShowContinueWatching] = useState(
    localStorage.getItem('showContinueWatching') === 'true'
  );
  const access_token = localStorage.getItem('token');

  const setUser = useCallback(
    (user: IUser) => {
      setUserData(user);
    },
    [setUserData]
  );

  const clearUser = useCallback(() => {
    setUserData(userDataInitialValues);
  }, [setUserData]);

  /* Fetch and set user data in context if access token has not expired */
  // Note: The variable userDataIsLoading continuously returns TRUE when the meDataQuery is disabled
  const { isLoading: userDataIsLoading, error: userDataError } = useQuery<
    boolean,
    Error,
    IMeDataDto
  >({
    ...meDataQuery(),
    enabled: !tokenExpired(),
    onSuccess: (data) => {
      setUserData({
        _id: data._id,
        isAdmin: ROLE_FLAG.ADMIN === data.role_flag,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role_flag: data.role_flag,
        role_access: data.role?.role_access,
        pic: data.pic,
        active: data.active,
        additional_info: data.additional_info,
        hidden_fields: data.hidden_fields,
        wallet_edu: data.wallet_edu,
        kyc_status: data.kyc_status,
        is_creator: !!data?.is_creator,
        slug: data.slug,
        last_watched_course: data.last_watched_course,
        last_watched_video: data.last_watched_video
      });
    }
  });

  const { data: certificatesData } = useQuery<
    boolean,
    Error,
    IReceivedCertificatesListDto
  >({
    ...userCertificatesQuery(),
    enabled: !tokenExpired()
  });

  const { data: notificationsData } = useQuery<
    boolean,
    Error,
    IUserNotificationsDto[]
  >({
    ...userNotificationsQuery(),
    enabled: !tokenExpired()
  });

  const { data: lastWatchedCourseData } = useQuery<boolean, Error, ICourse>({
    ...singleCourseDataQuery(userData.last_watched_course as string),
    enabled: !!userData?.last_watched_course
  });

  const newCertificates =
    (!tokenExpired() &&
      !!certificatesData?.result?.length &&
      certificatesData.result.filter((cert) => !cert.seen)) ||
    [];

  const lastWatchedCourse = lastWatchedCourseData || null;
  const notifications = notificationsData || [];

  useEffect(() => {
    setIsTokenExpired(tokenExpired());
  }, [access_token]);

  return (
    <userContext.Provider
      value={{
        userData,
        setUser,
        clearUser,
        userDataIsLoading,
        userDataError,
        isTokenExpired,
        newCertificates,
        lastWatchedCourse,
        showContinueWatching,
        setShowContinueWatching,
        notifications
      }}
    >
      {children}
    </userContext.Provider>
  );
};
