import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { popupContext } from 'context/popupContext';
import Loader from 'components/loader/Loader';
import NoResults from 'components/noResults/NoResults';
import Button from 'components/buttons/Button';
import CategoryPopup from './components/CategoryPopup';
import CategoryRow from './components/CategoryRow';
import Error from 'components/error/Error';
import Actions from 'routes/dashboard/profile/components/Actions';
import { ICourseCategory, ICourseCategoryData } from 'query/course-module/dto';
import { courseCategoriesDataQuery } from 'query/course-module/queries';
import classes from './AdminCategories.module.scss';

const Categories = () => {
  const { search: urlParams } = useLocation();
  const { search } = queryString.parse(urlParams);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const { setPopup } = useContext(popupContext);

  const queryParams =
    searchValue.length > 1 ? `?&search[name]=${searchValue}` : '';

  const { data, isLoading, error } = useQuery<
    boolean,
    Error,
    ICourseCategoryData
  >(courseCategoriesDataQuery(queryParams));

  const hasCategoriesData = !!data?.result?.length;
  const categories = data?.result;

  return (
    <div className={classes['categories-wrapper']}>
      <div className={classes['categories-header']}>
        <div className={classes['search-container']}>
          <Actions
            isInverted
            hasSearch
            searchValue={searchValue}
            passSearchValueUp={setSearchValue}
            searchPlaceholder="Search Categories"
            isDebounced
          />
        </div>
        <Button
          icon={PlusIcon}
          iconPosition="left"
          variant="link-neutral"
          onClick={() =>
            setPopup(<CategoryPopup action="create" type="category" />)
          }
        >
          Add new Category
        </Button>
      </div>
      <div className={classes['category-fields-wrapper']}>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Error error={error} />
        ) : hasCategoriesData ? (
          categories?.map((category: ICourseCategory) => {
            return <CategoryRow key={category._id} category={category} />;
          })
        ) : (
          <NoResults />
        )}
      </div>
    </div>
  );
};

export default Categories;
