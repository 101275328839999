import { useState } from 'react';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import classes from './CopyButton.module.scss';

interface ICopyButtonProps {
  text: string;
  size: 'sm' | 'md' | 'lg';
}

const CopyButton = ({ text, size }: ICopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <>
      {copied ? (
        <Check className={`${classes['icon']} ${classes[`icon--${size}`]}`} />
      ) : (
        <Copy
          className={`${classes['icon']} ${classes[`icon--${size}`]}`}
          width={16}
          height={16}
          onClick={() => {
            !!text && navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
          }}
        />
      )}
    </>
  );
};

export default CopyButton;
