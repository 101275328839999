import { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Actions from 'routes/dashboard/profile/components/Actions';
import { IReportData } from 'query/course-module/dto';
import { adminReportQuery } from 'query/course-module/queries';
import { appContext } from 'context/appContext';
import Error from 'components/error/Error';
import Loader from 'components/loader/Loader';
import NoResults from 'components/noResults/NoResults';
import classes from './AdminBaseReport.module.scss';

const AdminBaseReport = () => {
  const { search: urlParams } = useLocation();
  const { startDate: startDateParam, endDate: endDateParam } =
    queryString.parse(urlParams);

  const [dateRange, setDateRange] = useState<any[]>([
    startDateParam ? new Date(startDateParam as string) : '',
    endDateParam ? new Date(endDateParam as string) : ''
  ]);
  const [startDateRange, endDateRange] = dateRange;
  const { updateFilter } = useContext(appContext);

  const queryParams = `${
    startDateRange && endDateRange
      ? `?&buy_tx_confirmed_at[start]=${new Date(
          startDateRange
        ).getTime()}&buy_tx_confirmed_at[end]=${new Date(
          endDateRange
        ).getTime()}`
      : ''
  }`;

  const {
    isLoading: reportLoading,
    error: reportError,
    data: reportData
  } = useQuery<boolean, Error, IReportData>(
    adminReportQuery('report-query', queryParams)
  );

  const hasData = !!reportData && !!reportData?.result?.length;

  const renderStat = useCallback(
    (
      stat?: number,
      toFixed?: number,
      loaderSize?: 'sm' | 'lg',
      preText?: string
    ) => {
      const statString = stat?.toString();
      if (reportLoading)
        return <Loader size={loaderSize} className={classes['loader']} />;
      if (reportError) return <Error error={reportError} />;
      if (!statString) return ' - ';
      return ` ${preText || ''}${(+statString).toFixed(toFixed || 0)}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportLoading, reportError, reportData]
  );

  const prepStatsData: any = useMemo(
    () => ({
      total_revenue: {
        title: 'Total revenue',
        value: renderStat(
          reportData?.result?.[0]?.total_revenue || 0,
          2,
          'sm',
          '$'
        )
      },
      platform_fees: {
        title: 'Platform fees',
        value: renderStat(
          reportData?.result?.[0]?.platform_fee || 0,
          2,
          'sm',
          '$'
        )
      },
      total_sold: {
        title: 'Total courses sold',
        value: renderStat(reportData?.result?.[0]?.total_sold || 0, 0, 'sm')
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportData]
  );

  const renderStatistics = () => {
    const values = Object.keys(prepStatsData).map((stat: any) => {
      return (
        <div key={stat} className={classes['admin-report__card']}>
          <h5>{prepStatsData[stat].title}</h5>
          <h2 className={classes['admin-report__card__value']}>
            {prepStatsData[stat].value}
          </h2>
        </div>
      );
    });
    return values;
  };

  return (
    <div className={classes['admin-report']}>
      <div className={classes['admin-report__table-wrapper']}>
        <div className={classes['admin-report__actions-wrapper']}>
          <Actions
            hasFilter
            showFilterMenu
            hideFilterButton
            onFilterClick={updateFilter}
            filterStartDateRange={startDateRange}
            filterEndDateRange={endDateRange}
            setDateRange={setDateRange}
          />
        </div>
        {(hasData || reportLoading) && (
          <div className={classes['admin-report__cards-container']}>
            {renderStatistics()}
          </div>
        )}
        {reportError && <Error error={reportError} />}
        {!hasData && !reportLoading && (
          <div className={classes['admin-report__no-results']}>
            <NoResults />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminBaseReport;
