import React, { ReactNode } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import Divider from '../divider/Divider';
import Label from './Label';
import Player from 'components/player/Player';
import InfoBox from 'components/info-box/InfoBox';
import classes from './InputMedia.module.scss';

interface IInputImageProps {
  name?: string;
  title?: string;
  subtitle?: string;
  pic: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  isSquare?: boolean;
  instructions?: ReactNode;
  error?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  tooltipContent?: React.ReactNode;
  isRequired?: boolean;
  isDisabled?: boolean;
  isVideo?: boolean;
  isChangeOnly?: boolean;
  isImageCentered?: boolean;
  size?: 'sm' | 'md';
  tip?: string;
}

export const mediaFileFormats = {
  image: {
    formats: ['JPG, PNG, WEBP'],
    size: 5,
    accept: 'image/png, image/jpeg, image/jpg, image/webp, image/avif',
    error: (size: number) => {
      return `The file is too big (${size}MB). Please upload an image up to ${mediaFileFormats.image.size}MB.`;
    }
  },
  video: {
    formats: ['MP4'],
    size: 20,
    length: 15,
    accept: 'video/mp4',
    sizeError: (size: number) => {
      return `The file is too big (${size}MB). Please upload a video up to ${mediaFileFormats.video.size}MB.`;
    },
    lengthError: (length: number) => {
      return `The video is too long (${length}s). Please upload a video up to ${mediaFileFormats.video.length} seconds.`;
    }
  },
  file: {
    formats: ['*'],
    size: 10,
    accept: '*',
    error: (size: number) => {
      return `The file is too big (${size}MB). Please upload a file up to ${mediaFileFormats.file.size}MB.`;
    }
  }
};

export const InputMedia = (props: IInputImageProps) => {
  const {
    title,
    subtitle,
    pic,
    onChange,
    onClear,
    isSquare,
    instructions,
    hasTooltip,
    tooltipText,
    tooltipContent,
    error,
    isRequired,
    isDisabled,
    name,
    isVideo,
    isChangeOnly,
    isImageCentered,
    size,
    tip
  } = props;

  const fileFormat = isVideo ? mediaFileFormats.video : mediaFileFormats.image;

  const clearFileInput = (e: any) => {
    e.target.value = null;
  };

  return (
    <div className={classes['main-container']}>
      {!!title && (
        <Label
          name={name}
          title={title}
          subtitle={subtitle}
          isRequired={isRequired}
          hasTooltip={hasTooltip}
          tooltipText={!isDisabled ? tooltipText || '' : ''}
          tooltipContent={!isDisabled ? tooltipContent : null}
          isDisabled={isDisabled}
        />
      )}
      <div
        className={`${classes['main-container__input-wrapper']} ${
          isDisabled ? classes['main-container__input-wrapper--disabled'] : ''
        }
        ${
          !!pic && !!isImageCentered
            ? classes['main-container__input-wrapper--centered']
            : ''
        }`}
      >
        <div
          className={`${classes['input-container']} ${
            isSquare ? classes['square'] : ''
          } ${size ? classes[`${size}`] : ''}`}
        >
          <label
            className={`${classes['custom-file-upload']} ${
              !pic ? classes['custom-file-upload--empty'] : ''
            } ${!!error ? classes['custom-file-upload--error'] : ''} ${
              size && !pic ? classes[`custom-file-upload--${size}`] : ''
            }`}
          >
            {!pic && (
              <div className={classes['instructions']}>
                <PlusIcon />
                {instructions}
              </div>
            )}
            {!!pic && (
              <div className={classes['file-wrapper']}>
                <div className={`${classes['actions']}`}>
                  <EditIcon width={24} height={24} />
                  {!isChangeOnly && (
                    <>
                      <Divider dir="vertical" className={classes['divider']} />
                      <TrashIcon
                        onClick={(e) => {
                          e.preventDefault();
                          onClear();
                        }}
                        width={24}
                        height={24}
                      />
                    </>
                  )}
                </div>
                {!isVideo && (
                  <div
                    className={`${classes['image-comp']} ${
                      classes[`image-comp--${size}`]
                    }
                    ${isSquare ? classes['image-comp--square'] : ''}`}
                    style={{
                      backgroundImage: `url(${pic})`
                    }}
                  />
                )}
                {isVideo && <Player src={pic} height="192px" />}
              </div>
            )}
            <input
              type="file"
              accept={fileFormat.accept}
              onChange={onChange}
              onClick={clearFileInput}
              disabled={isDisabled}
            />
          </label>
        </div>
        <div className={classes['input-container__guidelines__wrapper']}>
          {!pic && (
            <>
              <div className={classes['input-container__guidelines']}>
                <div className={classes['u-text--content']}>
                  Max Size:{' '}
                  <span className={classes['u-text--primary']}>
                    {fileFormat.size}
                    MB
                  </span>
                </div>
                {!!isVideo && (
                  <div className={classes['u-text--content']}>
                    Max Length:{' '}
                    <span className={classes['u-text--primary']}>
                      <>
                        {(fileFormat as typeof mediaFileFormats.video).length}{' '}
                        seconds
                      </>
                    </span>
                  </div>
                )}
                <div className={classes['u-text--content']}>
                  Format:{' '}
                  <span className={classes['u-text--primary']}>
                    {fileFormat.formats}
                  </span>
                </div>
              </div>{' '}
            </>
          )}
          {tip && <InfoBox className={classes['tip']} msg={tip} />}
        </div>
      </div>
      {!!error && <div className={classes['input__error']}>{error}</div>}
    </div>
  );
};
InputMedia.defaultProps = {
  pic: '',
  size: 'md'
};
