import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { windowContext } from 'context/windowsContext';
import { userContext } from 'context/userContext';
import Button from 'components/buttons/Button';
import CardRow from 'components/card/CardRow';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import HorizontalCard from 'components/card/HorizontalCard';
import CustomCardSkeleton from 'components/skeleton/CustomCardSkeleton';
import HighlightedCourseCard from 'components/card/HighlightedCourseCard';
import Carousel from './carousel/Carousel';
import SEO from 'components/SEO/SEO';
import {
  topCategoriesDataQuery,
  activatedCourseVideoDataQuery,
  coursesDataQuery
} from 'query/course-module/queries';
import {
  COURSE_SEGMENT,
  ICourseCategoryData,
  ICourseData,
  ISingleCourseVideo
} from 'query/course-module/dto';
import classes from './Landing.module.scss';

const Landing = () => {
  const navigate = useNavigate();
  const [popularCoursesAmount, setPopularCoursesAmount] = useState(4);
  const [recommendedAmount, setRecommendedAmount] = useState(4);
  const [bestSellersIndex, setBestSellersIndex] = useState(0);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;
  const { isTokenExpired, lastWatchedCourse } = useContext(userContext);

  const {
    isLoading: mostPopularLoading,
    error: mostPopularError,
    data: mostPopularData
  } = useQuery<boolean, Error, ICourseData>(
    coursesDataQuery(COURSE_SEGMENT.MOST_POPULAR, popularCoursesAmount)
  );

  const {
    isLoading: recommendedLoading,
    error: recommendedError,
    data: recommendedData
  } = useQuery<boolean, Error, ICourseData>(
    coursesDataQuery(COURSE_SEGMENT.RECOMMENDED, recommendedAmount)
  );

  const { data: highlightedCoursesData } = useQuery<
    boolean,
    Error,
    ICourseData
  >(coursesDataQuery(COURSE_SEGMENT.BEST_SELLERS));

  const {
    isLoading: categoriesLoading,
    error: categoriesError,
    data: categoriesData
  } = useQuery<boolean, Error, ICourseCategoryData>(topCategoriesDataQuery());

  // Get last watched video
  const {
    isLoading: videoIsLoading,
    error: videoError,
    data: videoData
  } = useQuery<boolean, Error, ISingleCourseVideo>({
    ...activatedCourseVideoDataQuery(
      lastWatchedCourse?._id as string,
      lastWatchedCourse?.purchased?.last_watched_video as string
    ),
    enabled: !!lastWatchedCourse
  });

  useEffect(
    () => {
      const interval = setInterval(() => {
        setBestSellersIndex((nav) => {
          if (
            !!highlightedCoursesData?.result?.length &&
            nav === highlightedCoursesData?.result?.length - 1
          ) {
            return 0;
          }
          return nav + 1;
        });
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bestSellersIndex, highlightedCoursesData?.result?.length]
  );

  return (
    <div className={classes['landing']}>
      <SEO />

      {/* HERO SECTION */}
      <Carousel />
      {/* CONTINUE WATCHING */}
      {!!lastWatchedCourse &&
        lastWatchedCourse.progress < 100 &&
        !videoError && (
          <div className={classes['section']}>
            <div className={classes['section__continue-watching']}>
              <h4 className={classes['u-bold']}>Continue Watching</h4>
              {videoIsLoading && <CustomCardSkeleton />}
              {!videoIsLoading && !!videoData && (
                <HorizontalCard course={lastWatchedCourse} />
              )}
            </div>
          </div>
        )}

      {/* RECOMMENDED COURSES SECTION*/}
      {!!recommendedData?.result?.length && !isTokenExpired && (
        <div
          className={`${classes['section']} ${classes['scrollable-section']}`}
        >
          <CardRow
            title="Recommended for you"
            queryData={{
              isLoading: recommendedLoading,
              error: recommendedError,
              data: recommendedData as ICourseData
            }}
            actionBtnText="View All"
            onClick={(id: string) =>
              navigate(`/courses/${recommendedData.result[0].slug}?changes=0`)
            }
            viewAllRoute={`/courses?&page=1&segment=${COURSE_SEGMENT.RECOMMENDED}`}
            onShowMoreClick={(showMoreAmount: number) =>
              setRecommendedAmount(recommendedAmount + showMoreAmount)
            }
            cardProps={{
              hasPrice: true,
              hideChangesIcon: true
            }}
          />
        </div>
      )}

      {/* BEST SELLERS SECTION */}
      {!!highlightedCoursesData?.result.length && (
        <div
          className={`${classes['section']} ${
            isLgMobile
              ? `${classes['section--no-side-padding']} ${classes['section--highlighted-section-mobile']}`
              : ''
          }`}
        >
          <HighlightedCourseCard
            course={highlightedCoursesData.result[bestSellersIndex]}
            onClick={() =>
              navigate(
                `/courses/${highlightedCoursesData.result[bestSellersIndex].slug}?changes=0`
              )
            }
            hasRating
            hasViewBtn
          />
          {highlightedCoursesData?.result?.length > 1 && (
            <div className={classes['controls']}>
              <div className={classes['controls__dots']}>
                {highlightedCoursesData?.result.map((course, i) => (
                  <div
                    key={course._id}
                    className={`${classes['controls__dots__single']} ${
                      i === bestSellersIndex
                        ? classes['controls__dots__single--active']
                        : ''
                    }`}
                    onClick={() => setBestSellersIndex(i)}
                  />
                ))}
              </div>
              {!isLgMobile && (
                <div className={classes['controls__btns-container']}>
                  <Button
                    variant="neutral"
                    icon={Arrow}
                    iconRotate={-90}
                    isIconBtn
                    onClick={() =>
                      setBestSellersIndex(
                        bestSellersIndex <= 0
                          ? highlightedCoursesData?.result?.length - 1
                          : bestSellersIndex - 1
                      )
                    }
                  />
                  <Button
                    variant="neutral"
                    icon={Arrow}
                    iconRotate={90}
                    isIconBtn
                    onClick={() =>
                      setBestSellersIndex(
                        bestSellersIndex ===
                          highlightedCoursesData?.result.length - 1
                          ? 0
                          : bestSellersIndex + 1
                      )
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* MOST POPULAR COURSES SECTION */}
      {!!mostPopularData?.result?.length && (
        <div
          className={`${classes['section']} ${classes['scrollable-section']}`}
        >
          <CardRow
            title="Most Popular Courses"
            queryData={{
              isLoading: mostPopularLoading,
              error: mostPopularError,
              data: mostPopularData as ICourseData
            }}
            actionBtnText="View All"
            viewAllRoute={`/courses?&page=1&segment=${COURSE_SEGMENT.MOST_POPULAR}`}
            onClick={(slug: string) => navigate(`/courses/${slug}`)}
            onShowMoreClick={(showMoreAmount: number) =>
              setPopularCoursesAmount(popularCoursesAmount + showMoreAmount)
            }
            cardProps={{
              hasPrice: true,
              hasBadge: true,
              badgeText: 'BEST SELLER',
              hideChangesIcon: true
            }}
          />
        </div>
      )}

      {/* TOP CATEGORIES */}
      <div className={`${classes['section']} ${classes['scrollable-section']}`}>
        {categoriesLoading && <Loader />}
        {categoriesError && <Error error={categoriesError} />}
        {!!categoriesData?.result?.length && (
          <div className={classes['section__categories']}>
            <h4 className={classes['u-bold']}>Top Categories</h4>
            <div className={classes['section__categories__body']}>
              {categoriesData.result.map((category) => (
                <div
                  key={category._id}
                  className={classes['card']}
                  onClick={() => navigate(`/category/${category.slug}`)}
                >
                  <div className={classes['card__img-wrapper']}>
                    <img
                      src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + category.icon}
                      alt={`category-${category.name}`}
                    />
                  </div>
                  <div
                    className={`${classes['u-semiBold']} ${classes['u-body1']}`}
                  >
                    {category.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Landing;
