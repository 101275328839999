import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-big.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error-big.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-big.svg';
import Button, { IButtonProps } from 'components/buttons/Button';
import { ModalWrapper } from './common/ModalWrapper';
import Loader from 'components/loader/Loader';
import { popupContext } from 'context/popupContext';
import classes from './GenericPopup.module.scss';

export interface IGenericProps {
  size?: 'sm' | 'md' | 'lg';
  type?: 'success' | 'loading' | 'error' | 'info' | 'warning';
  title?: string;
  msg?: string | React.ReactNode;
  redirectPath?: string;
  icon?: React.ReactNode;
  buttonName?: string;
  buttonVariant?: IButtonProps['variant'];
  buttonAction?: () => void;
  bellowBtnComp?: React.ReactNode;
  hideActionBtn?: boolean;
  clearPopupOnBtnAction?: boolean;
  isClosable?: boolean;
}

const GenericPopup = (props: IGenericProps) => {
  const navigate = useNavigate();
  const { clearPopup, popupIsLoading } = useContext(popupContext);

  const {
    title,
    msg,
    redirectPath,
    icon,
    buttonName,
    buttonVariant,
    buttonAction,
    bellowBtnComp,
    size,
    type,
    hideActionBtn,
    clearPopupOnBtnAction,
    isClosable
  } = props;

  const closeHandler = () => {
    clearPopupOnBtnAction && clearPopup();
    !!redirectPath && navigate(redirectPath);
    !!buttonAction && buttonAction();
  };

  const renderIcon = () => {
    // Use specific icon
    if (icon) return icon;

    // Use icon by type
    let modalIcon;
    switch (type) {
      case 'loading':
        modalIcon = <Loader size="xl" />;
        break;
      case 'error':
        modalIcon = <ErrorIcon />;
        break;
      case 'warning':
        modalIcon = <ErrorIcon />;
        break;
      case 'info':
        modalIcon = <InfoIcon />;
        break;
      default:
        modalIcon = <SuccessIcon />;
    }
    return modalIcon;
  };

  const renderTitle = () => {
    // Use specific title
    if (title) return title;

    // Use title by type
    let modalTitle;
    switch (type) {
      case 'loading':
        modalTitle = 'Loading';
        break;
      case 'error':
        modalTitle = 'Error!';
        break;
      case 'warning':
        modalTitle = 'Warning!';
        break;
      default:
        modalTitle = 'Success!';
    }
    return modalTitle;
  };

  return (
    <ModalWrapper
      onClose={() => clearPopup()}
      size={size}
      isClosable={isClosable}
    >
      <div className={classes['popup-container']}>
        <div className={classes['icon']}>{renderIcon()}</div>
        <div className={classes['content']}>
          <h4 className={classes['u-semiBold']}>{renderTitle()}</h4>
          {msg && <p className={classes['u-body2']}>{msg}</p>}
        </div>
        <div className={classes['popup-container__btns-container']}>
          {!hideActionBtn && (
            <Button
              variant={buttonVariant || 'neutral'}
              onClick={closeHandler}
              minWidth="full"
              isLoading={popupIsLoading}
            >
              {buttonName || 'Close'}
            </Button>
          )}
          {bellowBtnComp}
        </div>
      </div>
    </ModalWrapper>
  );
};

GenericPopup.defaultProps = {
  size: 'sm',
  type: 'success',
  clearPopupOnBtnAction: true,
  isClosable: true
};

export default GenericPopup;
