import React from 'react';
import EditSocialForm from './components/EditSocialForm';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import classes from './Edit.module.scss';

const EditSocial = () => {
  return (
    <div className={classes['wrapper']}>
      <Breadcrumbs skips={[0, 1, 2]} />
      <EditSocialForm />
    </div>
  );
};

export default EditSocial;
