import React, { useState, useContext } from 'react';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import NoCourses from 'assets/images/no-courses.svg';
import { userContext } from 'context/userContext';
import { windowContext } from 'context/windowsContext';
import ErrorComponent from 'components/error/Error';
import EmptyState from 'components/emptyState/EmptyState';
import NoResults from 'components/noResults/NoResults';
import Card from 'components/card/Card';
import Actions from '../Actions';
import Pagination from 'components/pagination/Pagination';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import {
  creatorCoursesDataQuery,
  userCoursesDataQuery
} from 'query/course-module/queries';
import { COURSE_CHANGES, ICourseData } from 'query/course-module/dto';
import { COURSE_STATUS } from 'utils/constants';
import { isCourseStatus } from 'utils/helpers';
import classes from '../Courses.module.scss';

const Published = () => {
  const navigate = useNavigate();
  const { userData } = useContext(userContext);
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);
  const { slug } = useParams();

  const isPublicPage = !!slug;

  const { search: urlParams } = useLocation();
  const { page: pageNumber, search, sort } = queryString.parse(urlParams);
  const pageSize: number = isPublicPage ? 12 : 9;

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}&status=${
    COURSE_STATUS.PUBLISHED
  },${COURSE_STATUS.PUBLISHING},${COURSE_STATUS.PAUSED}&creator_id=${
    isPublicPage ? slug : userData._id
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    isPublicPage
      ? userCoursesDataQuery(queryParams)
      : creatorCoursesDataQuery(queryParams)
  );

  const { result: courses, total_results } = data || {};
  const hasData = courses && courses.length > 0;

  return (
    <div className={classes['wrapper']}>
      {isLgMobile && <h5 className={classes['u-light']}>Published Courses</h5>}
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortValue) ||
        page > 1 ||
        isLoading) && (
        <Actions
          hasSearch
          searchPlaceholder="Search by Course Name"
          searchValue={searchValue}
          passSearchValueUp={setSearchValue}
          hasSort
          sortValue={sortValue}
          passSortValueUp={setSortValue}
          setPage={setPage}
          isInverted
          isDebounced
        />
      )}
      {isLoading && (
        <div
          className={`${classes['cards']} ${
            isPublicPage ? classes['cards--four'] : ''
          }`}
        >
          <CardSkeleton amount={3} variant="course" />
        </div>
      )}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <>
          <div
            className={`${classes['cards']} ${
              isPublicPage ? classes['cards--four'] : ''
            }`}
          >
            {courses.map((course) => {
              return (
                <Card
                  key={course._id}
                  onClick={() =>
                    navigate(
                      `/courses/${course.slug}?changes=${COURSE_CHANGES.NO_CHANGES}`
                    )
                  }
                  hasPrice
                  course={course}
                  hasBadge={
                    course.status === COURSE_STATUS.PUBLISHING ||
                    isCourseStatus('isRepublishing', course) ||
                    isCourseStatus('isTakedown', course)
                  }
                  badgeText={
                    course.status === COURSE_STATUS.PUBLISHING ||
                    isCourseStatus('isRepublishing', course)
                      ? 'PROCESSING'
                      : isCourseStatus('isTakedown', course)
                      ? 'TAKEN DOWN'
                      : ''
                  }
                  badgeType={
                    isCourseStatus('isRepublishing', course)
                      ? 'info'
                      : isCourseStatus('isTakedown', course)
                      ? 'error'
                      : 'info'
                  }
                />
              );
            })}
          </div>
          <Pagination
            currentPage={page}
            totalCount={total_results as number}
            siblingCount={1}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
          />
        </>
      )}
      {!hasData && searchValue && !isLoading && !error && <NoResults />}
      {!hasData && !searchValue && !isLoading && !error && (
        <EmptyState
          icon={NoCourses}
          title={
            !isPublicPage
              ? 'There are no Published Courses at the moment.'
              : 'This user has not published any courses.'
          }
          message={
            !isPublicPage
              ? 'Create your first course. Add videos and create NFT certificate.'
              : ''
          }
          buttonName={!isPublicPage ? 'Create Course' : ''}
          onClick={() => navigate('/courses/create')}
          bellowBtnComp={
            !isPublicPage ? (
              <p>
                Learn more about uploading in the{' '}
                <Link to="/content-guidelines">Content Guidelines</Link>
              </p>
            ) : null
          }
        />
      )}
    </div>
  );
};

export default Published;
