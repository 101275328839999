import { useContext } from 'react';
import { userContext } from 'context/userContext';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { REACT_APP_FILES_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './Avatar.module.scss';

interface IAvatar {
  src?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  isBorderless: boolean;
  onClick: () => void;
  hasNotifications?: boolean;
  name?: string;
}

const Avatar = ({
  src,
  size,
  isBorderless,
  onClick,
  hasNotifications,
  name
}: IAvatar) => {
  const { newCertificates } = useContext(userContext);

  if (!src)
    return (
      <div className={classes['wrapper']}>
        <div
          className={`
          ${classes['c-avatar']} 
          ${classes['c-avatar--default']} 
          ${classes[`c-avatar--${size}`]} 
          ${isBorderless ? classes['c-avatar--borderless'] : ''} 
          ${!!onClick ? classes['u-cursor--pointer'] : ''}
        `}
          onClick={onClick}
        >
          <UserIcon />
        </div>
      </div>
    );
  return (
    <div className={classes['wrapper']}>
      {hasNotifications && !!newCertificates.length && (
        <div className={classes['notification']}>{newCertificates.length}</div>
      )}
      <img
        src={`${REACT_APP_FILES_PUBLIC_DOMAIN}${src}`}
        className={`
        ${classes['c-avatar']} 
        ${classes[`c-avatar--${size}`]} 
        ${isBorderless ? classes['c-avatar--borderless'] : ''} 
        ${!!onClick ? classes['u-cursor--pointer'] : ''}
      `}
        onClick={onClick}
        alt={name}
      />
    </div>
  );
};

Avatar.defaultProps = {
  size: 'md',
  isBorderless: false,
  onClick: null
};

export default Avatar;
