import { useState, useEffect, useRef } from 'react';

export const useComponentVisible = (
  initialIsVisible: boolean,
  isDoubleClick?: boolean
) => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      isDoubleClick ? 'dblclick' : 'click',
      handleClickOutside,
      true
    );
    return () => {
      document.removeEventListener(
        isDoubleClick ? 'dblclick' : 'click',
        handleClickOutside,
        true
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { ref, isComponentVisible, setIsComponentVisible };
};

export default useComponentVisible;
