import React from 'react';
import EditAuthForm from './components/EditAuthForm';
import EditProfileForm from './components/EditProfileForm';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import classes from './Edit.module.scss';

const EditProfile = () => {
  return (
    <div className={classes['wrapper']}>
      <Breadcrumbs skips={[0, 1, 2]} />
      <EditAuthForm />
      <EditProfileForm />
    </div>
  );
};

export default EditProfile;
