import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { windowContext } from 'context/windowsContext';
import Divider from '../divider/Divider';
import classes from './Tabs.module.scss';

interface ITab {
  id: string;
  name: string | React.ReactNode;
  route: string;
  component: () => JSX.Element;
  hasDivider?: boolean;
}

interface ITabs {
  size: 'lg' | 'sm';
  tabs: ITab[];
  activeTabIndex: number;
  isVertical?: boolean;
  isDisabled?: boolean;
}

const Tabs = ({
  size,
  tabs,
  activeTabIndex,
  isVertical,
  isDisabled
}: ITabs) => {
  const navigate = useNavigate();
  const { windowSize } = useContext(windowContext);
  const { isLgMobile, isSmMobile } = windowSize;

  const [activeTab, setActiveTab] = useState<number>(activeTabIndex || 0);

  const isMobile = isSmMobile && !isVertical;

  const renderMainTab = (tab: ITab, i: number) => (
    <div
      key={tab.id}
      className={`
        ${classes['tabs__item-wrapper']} 
        ${isVertical ? classes['tabs__item-wrapper--vertical'] : ''}
      `}
    >
      {tab.hasDivider && <Divider className={classes['divider']} />}
      <div
        key={i}
        className={`${classes['tabs__item']} ${
          classes[activeTab === i ? 'tabs__item--active' : '']
        } ${classes[tab.id === 'rejected' ? 'tabs__item--danger' : '']}`}
        onClick={() => {
          setActiveTab(i);
          navigate(`/${tabs[i].route}`);
        }}
      >
        <span
          className={`
            ${classes['tabs__item__name']}
            ${isMobile ? classes['tabs__item__name--mobile'] : ''}
          `}
        >
          {tab.name}
        </span>
      </div>
    </div>
  );

  useEffect(() => {
    activeTabIndex >= 0 && setActiveTab(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <div
      id="tabs"
      data-size={size}
      className={`${classes['tabs__container']} ${
        isVertical && classes['tabs__container--vertical']
      } ${isDisabled && classes['tabs__container--disabled']}`}
    >
      <div
        className={`${classes['tabs']} ${
          classes[isMobile ? 'tabs-mobile' : '']
        }`}
      >
        {tabs.map((tab, i) => renderMainTab(tab, i))}
      </div>
      {isVertical && (
        <Divider
          dir={isLgMobile ? 'horizontal' : 'vertical'}
          isAuto={!isLgMobile}
        />
      )}
      <div className={classes['tabs__content']}>
        {tabs[activeTab].component()}
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  size: 'lg',
  tabs: [],
  activeTabIndex: 0,
  isVertical: false
};

export default Tabs;
