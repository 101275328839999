import classes from './Divider.module.scss';

interface IDivider {
  dir?: 'vertical' | 'horizontal';
  className?: string;
  isAuto?: boolean;
}

const Divider = ({ dir, className, isAuto }: IDivider) => {
  return (
    <div
      className={`${classes['c-divider']} ${
        classes[
          dir === 'vertical' ? 'c-divider--vertical' : 'c-divider--horizontal'
        ]
      } ${isAuto ? classes['c-divider--vertical--auto'] : ''} ${className}`}
    />
  );
};

export default Divider;
