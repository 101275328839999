import { useContext, useEffect } from 'react';
import { popupContext } from 'context/popupContext';
import useComponentVisible from 'hooks/useComponentVisible';
import classes from './Menu.module.scss';

const Menu = () => {
  const { menuData } = useContext(popupContext);
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(!!menuData);

  useEffect(() => {
    setIsComponentVisible(!!menuData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData]);

  // Remove Menu on Esc key press
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setIsComponentVisible(false);
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    // clean up event listener
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  return (
    <div
      ref={ref}
      className={`
        ${classes['menu']} 
        ${!!isComponentVisible ? classes['menu--open'] : ''}
      `}
    >
      {menuData}
    </div>
  );
};

export default Menu;
