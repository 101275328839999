import { useContext, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import Actions from 'routes/dashboard/profile/components/Actions';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { ReactComponent as RowIcon } from 'assets/icons/table-row.svg';
import { ReactComponent as CardIcon } from 'assets/icons/table-card.svg';
import Table from 'components/table/Table';
import {
  COURSE_CHANGES,
  ICourse,
  ICourseCategoryData,
  ICourseData,
  IFilterData,
  IFilterOptions
} from 'query/course-module/dto';
import { AdminCoursesCols } from 'components/table/AdminCoursesCols';
import Error from 'components/error/Error';
import {
  adminCoursesDataQuery,
  courseCategoriesDataQuery
} from 'query/course-module/queries';
import NoResults from 'components/noResults/NoResults';
import { COURSE_REVIEW_STATUS, COURSE_STATUS } from 'utils/constants';
import { IOption } from 'components/input/InputChoice';
import Button from 'components/buttons/Button';
import Pagination from 'components/pagination/Pagination';
import Card from 'components/card/Card';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { IAdminCourses } from '.';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import { isSafari } from 'utils/helpers';
import classes from './AdminCourses.module.scss';

const filterName = 'Status';

const filterOptions = [
  {
    label: 'Submitted',
    value: COURSE_REVIEW_STATUS.IN_REVIEW.toString()
  },
  {
    label: 'Approved',
    value: COURSE_REVIEW_STATUS.APPROVED.toString()
  },
  {
    label: 'Rejected',
    value: COURSE_REVIEW_STATUS.REJECTED.toString()
  }
] as IOption[];

const categoriesFilter: IFilterData = {
  filterName: 'Category',
  filterOptions: []
};

// const subcategoriesFilter: IFilterData = {
//   filterName: 'Subcategory',
//   filterOptions: []
// };

const Courses = ({ tab }: IAdminCourses) => {
  const { search: urlParams } = useLocation();
  const navigate = useNavigate();
  const {
    page: pageNumber,
    search,
    sort,
    status,
    startDate: startDateParam,
    endDate: endDateParam,
    category
  } = queryString.parse(urlParams);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const pageSize: number = 12;
  const { coursesView, setCoursesView, showFilter, updateFilter } =
    useContext(appContext);

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');
  const [filterValue, setFilterValue] = useState<IOption[]>(
    filterOptions.filter((option) => status?.includes(option.value as string))
  );
  const [categoriesValue, setCategoriesValue] = useState<IOption[]>(
    categoriesFilter.filterOptions.filter((option: IFilterOptions) => {
      return category?.includes(option.value as string);
    })
  );
  // const [subcategoriesValue, setSubcategoriesValue] = useState<IOption[]>(
  //   categoriesFilter.filterOptions.filter((option: IFilterOptions) => {
  //     return subcategory?.includes(option.value as string);
  //   })
  // );

  const [dateRange, setDateRange] = useState<any[]>([
    startDateParam ? new Date(startDateParam as string) : '',
    endDateParam ? new Date(endDateParam as string) : ''
  ]);
  const [startDateRange, endDateRange] = dateRange;

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}${
    tab === 'published'
      ? `&status=${COURSE_STATUS.PUBLISHED},${COURSE_STATUS.PAUSED}`
      : ''
  }${
    tab === 'published'
      ? ''
      : `&review_status=${
          tab === 'drafts'
            ? COURSE_REVIEW_STATUS.INITIAL
            : !!status
            ? `${filterValue.map((filter) => `${filter.value}`).join(',')}`
            : `${COURSE_REVIEW_STATUS.IN_REVIEW},${COURSE_REVIEW_STATUS.APPROVED},${COURSE_REVIEW_STATUS.REJECTED}`
        }`
  }${!!category ? `&category=${category}` : ''}${
    startDateRange && endDateRange
      ? `&applied_date[start]=${new Date(
          startDateRange
        ).getTime()}&applied_date[end]=${new Date(endDateRange).getTime()}`
      : ''
  }${tab === 'submitted' ? `&include_unpublished=true` : ''}&changes=${
    tab === 'published'
      ? COURSE_CHANGES.NO_CHANGES
      : COURSE_CHANGES.ONLY_CHANGES
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    adminCoursesDataQuery(queryParams)
  );
  const { result: coursesData, total_results } = data || {};
  const hasData = !!coursesData && !!coursesData.length;

  const cols = useMemo(() => AdminCoursesCols(), []);

  useQuery<boolean, Error, ICourseCategoryData>({
    ...courseCategoriesDataQuery(),
    onSuccess: (fetchedCategories) => {
      categoriesFilter.filterOptions = fetchedCategories.result
        .filter((category) => category.counter > 0)
        .map((category) => ({
          label: category.name,
          value: category.slug
        }));
      setCategoriesValue(
        categoriesFilter.filterOptions.filter(
          (option: IFilterOptions) => category?.includes(option.value as string)
        )
      );
    }
  });

  // useQuery<boolean, Error, ICourseCategory[]>({
  //   ...courseSubcategoriesDataQuery(category as string),
  //   enabled: !!category,
  //   onSuccess: (fetchedSubCategories) => {
  //     subcategoriesFilter.filterOptions = fetchedSubCategories.map(
  //       (subcat) => ({
  //         label: subcat.name,
  //         value: subcat.slug
  //       })
  //     );
  //     setSubcategoriesValue(
  //       subcategoriesFilter.filterOptions.filter(
  //         (option: IFilterOptions) =>
  //           subcategory?.includes(option.value as string)
  //       )
  //     );
  //   }
  // });

  const displayedFilters = !!categoriesValue.length
    ? [
        { ...categoriesFilter, filterValue: categoriesValue }
        // { ...subcategoriesFilter, filterValue: subcategoriesValue }
      ]
    : [{ ...categoriesFilter, filterValue: categoriesValue }];

  if (tab === 'submitted') {
    displayedFilters.unshift({
      filterOptions,
      filterValue,
      filterName
    });
  }

  const filterHandler = (value: IOption[], name: string) => {
    setPage(1);
    if (!name) {
      setCategoriesValue([]);
      // setSubcategoriesValue([]);
      return;
    }
    if (name === 'Category') {
      // if (value.length === 0) {
      //   setSubcategoriesValue([]);
      // }
      setCategoriesValue(value);
      return;
    }
    // if (name === 'Subcategory') {
    //   setSubcategoriesValue(value);
    //   return;
    // }
    setFilterValue(value);
  };

  const clearSettings = () => {
    setPage(1);
    setSearchValue('');
    setSortValue('');
    setCategoriesValue([]);
    // setSubcategoriesValue([]);
    setFilterValue([]);
  };

  useEffect(() => {
    setFilterValue(
      filterOptions.filter((option) => status?.includes(option.value as string))
    );
  }, [status]);

  useEffect(() => {
    clearSettings();
  }, [tab]);

  return (
    <div
      className={`
      ${classes['admin-courses']} 
      ${showFilter ? classes['admin-courses--grid'] : ''}
    `}
    >
      {!!showFilter && (
        <Actions
          isInverted
          hasSort
          sortValue={sortValue}
          passSortValueUp={setSortValue}
          hasFilter
          passFilterValueUp={filterHandler}
          onFilterClick={updateFilter}
          showFilterMenu={showFilter}
          setPage={setPage}
          filterStartDateRange={startDateRange}
          filterEndDateRange={endDateRange}
          setDateRange={setDateRange}
          filters={displayedFilters}
        />
      )}
      {(!isMdMobile || (isMdMobile && !showFilter)) && (
        <div
          className={`${classes['admin-courses__table-wrapper']} 
          ${isSafari ? classes['safari-support-overflow'] : ''}
        `}
        >
          <Actions
            isInverted
            hasSearch
            passSearchValueUp={setSearchValue}
            searchValue={searchValue}
            searchPlaceholder="Search Courses"
            hasSort={!showFilter}
            passSortValueUp={setSortValue}
            sortValue={sortValue}
            hasFilter={!showFilter}
            onFilterClick={updateFilter}
            setPage={setPage}
            isDebounced
          >
            {!isMdMobile && (
              <div className={classes['admin-courses__action-btns']}>
                <Button
                  className={coursesView === 'cols' ? classes['active'] : ''}
                  variant="neutral"
                  icon={RowIcon}
                  onClick={() => setCoursesView('cols')}
                />
                <Button
                  className={coursesView === 'cards' ? classes['active'] : ''}
                  variant="neutral"
                  icon={CardIcon}
                  onClick={() => setCoursesView('cards')}
                />
              </div>
            )}
          </Actions>
          {isLoading &&
            (coursesView === 'cols' ? (
              <TableSkeleton rows={pageSize} cols={cols.length} isCoursesPage />
            ) : (
              <div className={`${classes['cards']} ${classes['cards--four']}`}>
                <CardSkeleton amount={pageSize + 1} variant="course" />
              </div>
            ))}
          {!!error && <Error error={error} />}
          {!isLoading && !error && hasData && (
            <>
              {coursesView === 'cols' && (
                <Table
                  columns={cols}
                  data={coursesData as ICourse[]}
                  onClickRow={(row: ICourse) =>
                    navigate({
                      pathname: `/admin/courses/${row.slug}`,
                      search: `${createSearchParams({
                        changes: row.is_draft_copy
                          ? COURSE_CHANGES.ONLY_CHANGES
                          : COURSE_CHANGES.NO_CHANGES
                      })}`
                    })
                  }
                  paginationProps={{
                    activePage: page,
                    setActivePage: setPage,
                    pageSize: pageSize,
                    totalCount: total_results as number,
                    siblingCount: 1
                  }}
                />
              )}
              {coursesView === 'cards' && (
                <>
                  <div
                    className={`${classes['cards']} ${classes['cards--four']}`}
                  >
                    {coursesData.map((course, i) => (
                      <Card
                        key={i}
                        onClick={() =>
                          navigate(
                            `/admin/courses/${course.slug}?changes=${
                              course.is_draft_copy
                                ? COURSE_CHANGES.ALL
                                : COURSE_CHANGES.NO_CHANGES
                            }`
                          )
                        }
                        hasBadge
                        course={course}
                        autoBadge
                        hideChangesIcon={
                          course.has_draft_copy &&
                          course.review_status === COURSE_REVIEW_STATUS.INITIAL
                        }
                      />
                    ))}
                  </div>
                  <Pagination
                    currentPage={page}
                    totalCount={total_results as number}
                    siblingCount={1}
                    pageSize={pageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                </>
              )}
            </>
          )}
          {!isLoading && !error && !hasData && <NoResults />}
        </div>
      )}
    </div>
  );
};

export default Courses;
