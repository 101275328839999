import { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { windowContext } from 'context/windowsContext';

function ScrollToTop() {
  const { pathname } = useLocation();
  const { search: urlParams } = useLocation();
  const { page } = queryString.parse(urlParams);
  const { slug, videoId } = useParams();
  const { appWrapperElement } = useContext(windowContext);

  const isUserProfilePage =
    pathname === '/user/about/main-info' ||
    pathname === '/user/about/social-info' ||
    pathname.includes('/user/purchased-courses') ||
    pathname.includes('/user/certificates') ||
    pathname.includes('/user/created-courses');

  const isUserPublicPage = pathname.startsWith('/user/') && !!slug;

  const isVideosTabPage =
    pathname.startsWith(`/courses/${slug}/${videoId}`) &&
    !pathname.split(`/courses/${slug}/${videoId}`)[1].includes('/');

  const isCoursePage =
    (pathname.startsWith('/courses/') ||
      pathname.startsWith('/admin/courses/')) &&
    !pathname.includes('/edit') &&
    !pathname.includes('/create') &&
    !!slug &&
    !isVideosTabPage;

  const scrollPage = !isUserProfilePage && !isUserPublicPage && !isCoursePage;

  useEffect(() => {
    if (scrollPage) appWrapperElement?.scrollTo(0, 0);
  }, [pathname, appWrapperElement, scrollPage, page]);

  return null;
}

export default ScrollToTop;
