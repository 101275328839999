import { useContext } from 'react';
import { useConnect } from 'wagmi';
import MetaMaskIcon from 'assets/images/metamask.svg';
import CoinbaseIcon from 'assets/images/coinbase.png';
import WalletConnectIcon from 'assets/images/walletconnect.png';
import { windowContext } from 'context/windowsContext';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import { ModalWrapper } from './common/ModalWrapper';
import PopupTitleAndMessage from './common/PopupTitleAndMessage';
import { REACT_APP_METAMASK_DEEPLINK } from 'utils/constants';
import classes from './ConnectWalletPopup.module.scss';

const walletIcons = {
  metamask: MetaMaskIcon,
  'coinbase wallet': CoinbaseIcon,
  walletconnect: WalletConnectIcon
} as any;

const ConnectWalletPopup = () => {
  const { clearPopup } = useContext(popupContext);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;

  const { connect, connectors } = useConnect();

  const connectHandler = async (connector: any) => {
    connect({ connector });
    localStorage.setItem('isWalletConnected', 'true');
    clearPopup();
  };

  // If the app is opened on a NON-WEB3 mobile browser, we enable
  // the Metamask connector button and deep link to the metamask app.
  const checkMobileMetamaskConnector = (connectorId: string) => {
    return isLgMobile && connectorId.toLowerCase() === 'metamask';
  };

  return (
    <ModalWrapper size="md">
      <div>
        <PopupTitleAndMessage
          title="Connect Wallet"
          msg="Connect a wallet to get started."
        />
        <div className={classes['providers-container']}>
          {connectors.map((connector) => (
            <Button
              key={connector.id}
              className={classes['provider-btn']}
              variant="neutral"
              isDisabled={
                checkMobileMetamaskConnector(connector.id)
                  ? false
                  : !connector.ready
              }
              onClick={() =>
                checkMobileMetamaskConnector(connector.id) && !connector.ready
                  ? window.open(REACT_APP_METAMASK_DEEPLINK, 'self')
                  : connectHandler(connector)
              }
            >
              <img
                src={walletIcons[connector?.name.toLocaleLowerCase()]}
                alt="wallet-provider"
                width={32}
                height={32}
              />
              <span
                className={`${classes['provider-btn__text']} ${classes['u-text--primary']} ${classes['u-text--small']}`}
              >
                {connector.name}{' '}
                <span
                  className={`${classes['u-body4']} ${classes['u-text--content']}`}
                >
                  {!connector.ready ? '(Not installed)' : ''}
                </span>
              </span>
            </Button>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ConnectWalletPopup;
