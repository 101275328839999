import { useAccount, useContractRead } from 'wagmi';
import { ethers } from 'ethers';
import { currencyContract, paymentContract } from 'contracts/contracts';

const useCurrencyContract = (contractAddress: string) => {
  const { address } = useAccount();

  // Get token decimals
  const readDecimals = useContractRead({
    abi: currencyContract.abi,
    address: contractAddress as `0x${string}`,
    functionName: 'decimals'
  });

  // Get token allowance user --> payment contract
  const readAllowance = useContractRead({
    abi: currencyContract.abi,
    address: contractAddress as `0x${string}`,
    functionName: 'allowance',
    args: [address, paymentContract.address]
  });

  // Get user balance --> payment contract
  const readBalance = useContractRead({
    abi: currencyContract.abi,
    address: contractAddress as `0x${string}`,
    functionName: 'balanceOf',
    args: [address]
  });

  const refetchAllowanceHandler = () => {
    readAllowance.refetch();
  };

  const balanceFormatted =
    !!readBalance?.data &&
    !!readDecimals?.data &&
    (+ethers.formatUnits(
      readBalance.data as any,
      readDecimals.data as any
    )).toFixed(6);

  const allowanceFormatted =
    !!readAllowance?.data &&
    !!readDecimals?.data &&
    +ethers.formatUnits(readAllowance.data as any, readDecimals.data as any);

  return {
    decimals: (readDecimals.data as number) || 0,
    balance: (readBalance.data as number) || 0,
    balanceFormatted: balanceFormatted,
    allowance: (readAllowance.data as number) || 0,
    allowanceFormatted: allowanceFormatted || 0,
    isFetching: readAllowance.isFetching || readDecimals.isFetching,
    refetchAllowance: refetchAllowanceHandler
  };
};

export default useCurrencyContract;
