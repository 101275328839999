import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeHiddenIcon } from 'assets/icons/eye-hidden.svg';
import { ReactComponent as Web } from 'assets/icons/web.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Ig } from 'assets/icons/ig.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { IInputWrapperProps } from './InputWrapper';
import classes from './InputWrapper.module.scss';

export const validateAndOnChange = (
  value: string,
  onChange: (value: string) => void,
  limit?: number
) => {
  // If the input length is bigger than the character limit
  if (!!limit && value.length > limit) return;
  // If the input does NOT start with an empty space trigger the onChange handler
  if (!/^\s/.test(value)) {
    return onChange(value);
  }
};

interface IInputFieldProps
  extends Pick<
    IInputWrapperProps,
    | 'variant'
    | 'type'
    | 'onChange'
    | 'onBlur'
    | 'value'
    | 'isDisabled'
    | 'isDebounced'
    | 'autoFocus'
    | 'placeholder'
    | 'error'
    | 'name'
    | 'limit'
  > {
  onFocus: () => void;
  onBlur: (e: React.FocusEvent<HTMLElement>) => void;
  onClear?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isNotEmpty?: boolean;
  isClearable?: boolean;
}

export const InputField = ({
  variant,
  type,
  onClear,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  value,
  isDisabled,
  isNotEmpty,
  isDebounced,
  isClearable,
  autoFocus,
  placeholder,
  error,
  name,
  limit
}: IInputFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const PassIcon = showPassword ? EyeHiddenIcon : EyeIcon;

  return (
    <>
      <div className={classes['input__field-wrapper']}>
        {type === 'search' && <SearchIcon className={classes['input__icon']} />}
        {type === 'password' && (
          <PassIcon
            className={`${classes['input__icon']} ${classes['input__icon--right']}`}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
        {name === 'website' && <Web className={classes['input__icon']} />}
        {name === 'twitter' && <Twitter className={classes['input__icon']} />}
        {name === 'ig' && <Ig className={classes['input__icon']} />}
        {name === 'facebook' && <Facebook className={classes['input__icon']} />}
        {!isDebounced && (
          <input
            name={name}
            className={`
              ${classes['input__field']} 
              ${classes[`input__field--${variant}`]}        
              ${isNotEmpty ? classes['input-not-empty'] : ''}    
              ${
                type === 'search' ||
                name === 'website' ||
                name === 'twitter' ||
                name === 'ig' ||
                name === 'facebook'
                  ? classes['input__field--p-left']
                  : ''
              }
              ${type === 'password' ? classes['input__field--p-right'] : ''}
              ${!!error ? classes['input__field--error'] : ''}
            `}
            type={
              type === 'password' ? (showPassword ? 'text' : 'password') : type
            }
            onChange={(e) =>
              validateAndOnChange(e.target.value, onChange, limit)
            }
            onBlur={(e) => onBlur(e)}
            disabled={isDisabled}
            value={value as string}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onWheel={(e) => e.currentTarget.blur()}
          />
        )}
        {isDebounced && (
          <DebounceInput
            debounceTimeout={300}
            name={name}
            className={`
              ${classes['input__field']} 
              ${classes[`input__field--${variant}`]}        
              ${isNotEmpty ? classes['input-not-empty'] : ''}    
              ${
                type === 'search' ||
                name === 'website' ||
                name === 'twitter' ||
                name === 'ig' ||
                name === 'facebook'
                  ? classes['input__field--p-left']
                  : ''
              }
              ${type === 'password' ? classes['input__field--p-right'] : ''}
              ${!!error ? classes['input__field--error'] : ''}
            `}
            type={
              type === 'password' ? (showPassword ? 'text' : 'password') : type
            }
            onChange={(e) =>
              validateAndOnChange(e.target.value, onChange, limit)
            }
            onBlur={(e) => onBlur(e)}
            disabled={isDisabled}
            value={value as string}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onFocus={onFocus}
            onWheel={(e: any) => e.currentTarget.blur()}
            onKeyDown={onKeyDown}
          />
        )}
        {isClearable && (
          <CloseIcon
            className={`${classes['input__icon']} ${classes['input__icon--right']}`}
            onClick={onClear}
          />
        )}
      </div>
      {!!error && <div className={classes['input__error']}>{error}</div>}
    </>
  );
};

InputField.defaultProps = {
  variant: 'neutral',
  type: 'text',
  value: '',
  isDisabled: false,
  autoFocus: false,
  placeholder: '',
  error: ''
};
