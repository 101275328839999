import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalizeFirstLetter, renderPic } from 'utils/format';
import {
  deleteCategory,
  deleteSubcategory
} from 'query/course-module/mutations';
import { ICourseCategory } from 'query/course-module/dto';
import Button from 'components/buttons/Button';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import classes from './DeleteCategoryPopup.module.scss';

interface ICategoryPopup {
  categoryId?: string;
  category: ICourseCategory;
  type: 'category' | 'subcategory';
}

const DeleteCategoryPopup = ({
  categoryId,
  category,
  type
}: ICategoryPopup) => {
  const { clearPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const queryClient = useQueryClient();

  const { isLoading, mutate: submitHandler } = useMutation({
    mutationKey: [`delete-${type}`],
    mutationFn: async () => {
      switch (type) {
        case 'category':
          await deleteCategory(category._id).mutationFn();
          break;
        case 'subcategory':
          await deleteSubcategory(categoryId || '', category._id).mutationFn();
          break;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['course-categories-data']);
      queryClient.invalidateQueries([
        'course-subcategories-data',
        { ids: categoryId }
      ]);
      clearPopup();
      setToast({
        type: 'success',
        position: 'top',
        title: `Successfully deleted`,
        msg: `You have successfully deleted ${type}`,
        autoClose: true
      });
    },
    onError: async (err: Error) => {
      clearPopup();
      setToast({
        type: 'error',
        position: 'top',
        title: `${capitalizeFirstLetter(type)} Failed`,
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  return (
    <ModalWrapper size="sm" onClose={() => clearPopup()}>
      <div className={classes['popup-container']}>
        <h4 className={classes['u-semiBold']}>{`Remove ${capitalizeFirstLetter(
          type
        )}`}</h4>
        <div className={classes['content']}>
          <h4 className={classes['u-body2']}>
            {`Are you sure you want to remove this ${type}? Removing this
            ${type}, would lead to a diminished quality of the search
            experience for the users.`}
          </h4>

          <div className={classes['image-wrapper']}>
            {type === 'category' && (
              <div className={classes['image-component']}>
                <div
                  style={{
                    backgroundImage: `url(${renderPic(
                      category?.icon || '',
                      true
                    )})`
                  }}
                />
              </div>
            )}
            <h5 className={`${classes['u-body1']} ${classes['u-semiBold']}`}>
              {category.name}
            </h5>
          </div>
        </div>
        <div className={classes['buttons-wrapper']}>
          <Button
            minWidth="full"
            variant="contrast"
            isLoading={isLoading}
            onClick={() => submitHandler()}
          >
            {`Remove ${capitalizeFirstLetter(type)}`}
          </Button>
          <Button size="sm" variant="link-neutral" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeleteCategoryPopup;
