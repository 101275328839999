import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Loader from 'components/loader/Loader';
import ErrorComponent from 'components/error/Error';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import { IResourceVideo, ISingleCourseVideo } from 'query/course-module/dto';
import {
  adminCourseVideoDataQuery,
  courseVideoDataQuery
} from 'query/course-module/queries';
import Player from 'components/player/Player';
import classes from './PlayVideoPopup.module.scss';

interface IPlayVideoPopup {
  courseId: IResourceVideo['course_id'];
  videoId: IResourceVideo['_id'];
  isAdmin: boolean;
}

const PlayVideoPopup = ({ courseId, videoId, isAdmin }: IPlayVideoPopup) => {
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ISingleCourseVideo
  >(
    isAdmin
      ? adminCourseVideoDataQuery(courseId, videoId, `?&changes=${changes}`)
      : courseVideoDataQuery(courseId, videoId, `?&changes=${changes}`)
  );

  const hasData = data && !!data._id;

  return (
    <ModalWrapper>
      <div className={classes['wrapper']}>
        <h4 className={`${classes['u-semiBold']} ${classes['u-text--center']}`}>
          Review Video
        </h4>
        {isLoading && <Loader size="lg" hasText withPadding />}
        {error && <ErrorComponent error={error} />}
        {hasData && <Player src={data.path} isPlaying hasControls />}
      </div>
    </ModalWrapper>
  );
};

export default PlayVideoPopup;
