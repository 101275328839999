import React, { ReactNode } from 'react';
import Label from './Label';
import classes from './InputWrapper.module.scss';

export interface IInputGroupProps {
  className?: string;
  type: '1-2' | '1-1' | '2-1';
  isDisabled?: boolean;
  isRequired?: boolean;
  title?: string;
  subtitle?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  tooltipContent?: React.ReactNode;
  name: string;
  children?: ReactNode;
}

export const InputGroup = ({
  className,
  type,
  isDisabled,
  isRequired,
  title,
  name,
  subtitle,
  hasTooltip,
  tooltipText,
  tooltipContent,
  children
}: IInputGroupProps) => {
  return (
    <div className={classes['input__group']}>
      {!!title && (
        <Label
          name={name}
          title={title}
          subtitle={subtitle}
          isRequired={isRequired}
          hasTooltip={hasTooltip}
          tooltipText={!isDisabled ? tooltipText || '' : ''}
          tooltipContent={!isDisabled ? tooltipContent : null}
          isDisabled={isDisabled}
        />
      )}
      <div className={`${classes[`input__group--flex-${type}`]} ${className}`}>
        {children}
      </div>
    </div>
  );
};

InputGroup.defaultProps = {
  type: '1-1',
  isDisabled: false,
  isRequired: false,
  name: '',
  title: '',
  subtitle: '',
  hasTooltip: false,
  tooltipText: '',
  tooltipContent: null
};
