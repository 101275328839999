import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { IInputWrapperProps } from './InputWrapper';
import { ISelectOption } from 'query/course-module/dto';
import Label from './Label';
import { colors } from 'utils/styles';
import classes from './InputWrapper.module.scss';

interface IInputChoiceProps
  extends Pick<
    IInputWrapperProps,
    | 'name'
    | 'isDisabled'
    | 'isRequired'
    | 'error'
    | 'title'
    | 'subtitle'
    | 'tooltipContent'
    | 'tooltipText'
    | 'hasTooltip'
  > {
  type: 'checkbox' | 'radio';
  verifiedStatus?: 'success' | 'error';
  isCheckedInputBordered?: boolean;
  isMulti?: boolean;
  options: IOption[];
  selectedOptions: IOption[];
  onChange: (values: IOption[]) => void;
}

export interface IOption extends ISelectOption {
  desc?: string;
  isDefaultChecked?: boolean;
}

export const InputChoice = ({
  type,
  name,
  title,
  subtitle,
  options,
  onChange,
  isDisabled,
  isRequired,
  isMulti,
  selectedOptions,
  error,
  hasTooltip,
  tooltipContent,
  tooltipText,
  isCheckedInputBordered,
  verifiedStatus
}: IInputChoiceProps) => {
  const handleClick = (clickedOption: ISelectOption) => {
    const copyValue = [...selectedOptions];

    if (!isMulti) {
      return onChange([clickedOption]);
    }

    if (
      selectedOptions.some((option) => option.value === clickedOption.value)
    ) {
      const index = selectedOptions.findIndex(
        (option) => option.value === clickedOption.value
      );
      copyValue.splice(index, 1);
      return onChange(copyValue);
    }
    copyValue.push(clickedOption);
    return onChange(copyValue);
  };

  const isSelected = ({ value }: ISelectOption) => {
    return (
      !!selectedOptions.length &&
      selectedOptions.some((option) => option.value === value)
    );
  };

  return (
    <div className={classes['input__group']}>
      {!!title && (
        <Label
          name={name}
          title={title}
          subtitle={subtitle}
          isRequired={isRequired}
          hasTooltip={hasTooltip}
          tooltipText={!isDisabled ? tooltipText || '' : ''}
          tooltipContent={!isDisabled ? tooltipContent : null}
          isDisabled={isDisabled}
        />
      )}
      {options.map((opt, i) => (
        <label
          key={i}
          className={`
            ${classes['input__field--checkbox']}
            ${classes[`input__field--checkbox--${type}`]}
            ${isDisabled ? classes['input__field--checkbox--disabled'] : ''}
            ${
              isCheckedInputBordered && isSelected(opt)
                ? classes['input__field--checkbox--checked']
                : ''
            }
            ${
              isSelected(opt)
                ? verifiedStatus === 'success'
                  ? classes['input__field--checkbox--success']
                  : verifiedStatus === 'error'
                  ? classes['input__field--checkbox--error']
                  : ''
                : ''
            }
          `}
          onClick={(e) => {
            e.preventDefault();
            handleClick({ label: opt.label, value: opt.value });
          }}
        >
          <div>
            {isSelected(opt) && verifiedStatus === 'success' && (
              <CheckIcon fill={colors.success40} />
            )}
            {isSelected(opt) && verifiedStatus === 'error' && (
              <CloseIcon fill={colors.error40} />
            )}
            {(!isSelected(opt) || (isSelected(opt) && !verifiedStatus)) && (
              <input
                type={type}
                value={opt.value}
                name={name}
                defaultChecked={opt.isDefaultChecked}
                disabled={isDisabled}
                checked={isSelected(opt)}
                readOnly
              />
            )}
            <span className={classes['input__field-checkbox__mark']} />
            {opt.label}
          </div>
          {opt.desc && <p>{opt.desc}</p>}
          {verifiedStatus && isSelected(opt) && (
            <h6 className={classes['input__field--checkbox--status']}>
              {`${verifiedStatus === 'success' ? 'Correct' : 'Wrong'} answer`}
            </h6>
          )}
        </label>
      ))}
      {!!error && <div className={classes['input__error']}>{error}</div>}
    </div>
  );
};

InputChoice.defaultProps = {
  type: 'checkbox',
  name: '',
  options: [],
  isDisabled: false,
  error: ''
};
