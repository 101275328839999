import { useContext, useEffect, useState } from 'react';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { appContext } from 'context/appContext';
import Button from 'components/buttons/Button';
import SwitchInput from 'components/buttons/SwitchInput';
import Tabs from 'components/tabs/Tabs';
import Badge from 'components/badge/Badge';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Accordion from 'components/accordion/Accordion';
import Card from 'components/card/Card';
import Progress from 'components/progress/Progress';
import Rating from 'components/rating/Rating';
import { InputWrapper } from 'components/input/InputWrapper';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { InputMedia } from 'components/input/InputMedia';
import { toastContext } from 'context/toastContext';
import { IOption, InputChoice } from 'components/input/InputChoice';
import { ICourse } from 'query/course-module/dto';
import classes from './style-guide.module.scss';

const StyleGuide = () => {
  const { theme } = useContext(appContext);
  const { setToast } = useContext(toastContext);
  const [isOn, setIsOn] = useState(false);
  const [value, setValue] = useState('');
  const [inputChoiceValue1, setInputChoiceValue1] = useState<IOption[]>([]);
  const [inputChoiceValue2, setInputChoiceValue2] = useState<IOption[]>([]);

  useEffect(() => {
    setToast({
      type: 'error',
      msg: 'An error occured while trying to log in. Please try again.'
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['page-container']}>
      <div className={classes['components-container']}>
        <h3 className={classes['u-semiBold']}>Buttons</h3>
        <Button onClick={() => {}} icon={Plus}>
          Contrast
        </Button>
        <Button onClick={() => {}} icon={Plus} isDisabled>
          Contrast Disabled
        </Button>
        <Button onClick={() => {}} icon={Plus} isLoading>
          Contrast
        </Button>
        <Button onClick={() => {}} icon={Plus} size="sm">
          Contrast Small
        </Button>
        <Button onClick={() => {}} icon={Plus} isIconBtn />
        <Button onClick={() => {}} icon={Plus} isIconBtn size="sm" />
        <Button onClick={() => {}} icon={Plus} variant="neutral">
          Neutral
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="neutral" isDisabled>
          Neutral Disabled
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="neutral" isLoading>
          Neutral
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="neutral" size="sm">
          Neutral Small
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="neutral" isIconBtn />
        <Button
          onClick={() => {}}
          icon={Plus}
          variant="neutral"
          isIconBtn
          size="sm"
        />

        <Button onClick={() => {}} icon={Plus} variant="outline">
          Outline
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="outline" isDisabled>
          Outline Disabled
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="outline" isLoading>
          Outline
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="outline" size="sm">
          Outline Small
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="outline" isIconBtn />
        <Button
          onClick={() => {}}
          icon={Plus}
          variant="outline"
          isIconBtn
          size="sm"
        />

        <Button onClick={() => {}} icon={Plus} variant="error">
          Error
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="error" isDisabled>
          Error Disabled
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="error" isLoading>
          Error
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="error" size="sm">
          Error Small
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="error" isIconBtn />
        <Button
          onClick={() => {}}
          icon={Plus}
          variant="error"
          isIconBtn
          size="sm"
        />

        <Button onClick={() => {}} icon={Plus} variant="success">
          Success
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="success" isDisabled>
          Success Disabled
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="success" isLoading>
          Success
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="success" size="sm">
          Success Small
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="success" isIconBtn />
        <Button
          onClick={() => {}}
          icon={Plus}
          variant="success"
          isIconBtn
          size="sm"
        />

        <Button onClick={() => {}} icon={Plus} variant="link-contrast">
          Link Contrast
        </Button>
        <Button
          onClick={() => {}}
          icon={Plus}
          variant="link-contrast"
          isDisabled
        >
          Link Contrast
        </Button>
        <Button onClick={() => {}} icon={Plus} variant="link-neutral">
          Link Neutral
        </Button>
        <Button
          onClick={() => {}}
          icon={Plus}
          variant="link-neutral"
          isDisabled
        >
          Link Neutral
        </Button>

        <h3 className={classes['u-semiBold']}>Switch</h3>
        <SwitchInput
          label="Large Switch"
          onClick={() => setIsOn(!isOn)}
          value={isOn}
        />
        <SwitchInput
          label="Large Switch Disabled"
          onClick={() => setIsOn(!isOn)}
          value={isOn}
          isDisabled
        />
        <SwitchInput
          label="Small Switch"
          onClick={() => setIsOn(!isOn)}
          value={isOn}
          size="sm"
        />

        <h3 className={classes['u-semiBold']}>Input field</h3>
        <InputWrapper
          name="test0"
          type="password"
          variant="outline"
          onChange={setValue}
          value={value}
          title="Password label"
          subtitle="Input Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter password ..."
          isRequired
        />
        <InputWrapper
          name="test1"
          type="search"
          variant="outline"
          onChange={setValue}
          value={value}
          title="Label Text"
          subtitle="Input Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isRequired
        />
        <InputWrapper
          name="test2"
          type="text"
          variant="outline"
          onChange={setValue}
          value={value}
          title="Error Label Text"
          subtitle="Input Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isRequired
          error="Field error"
        />
        <InputWrapper
          name="test3"
          type="text"
          variant="outline"
          onChange={setValue}
          value={value}
        />
        <InputWrapper
          name="test4"
          type="text"
          variant="outline"
          onChange={setValue}
          value={value}
          title="Disabled Label Text"
          subtitle="Input Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isDisabled
        />
        <InputWrapper
          name="test5"
          type="text"
          onChange={setValue}
          value={value}
          title="Label Text"
          subtitle="Input Description"
          hasTooltip
          tooltipContent={
            <ul>
              <li>ABC</li>
              <li>123</li>
            </ul>
          }
          placeholder="Enter text ..."
        />
        <InputWrapper
          name="test6"
          type="text"
          onChange={setValue}
          value={value}
          title="Error Label Text"
          subtitle="Input Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isRequired
          error="Field error"
        />
        <InputWrapper
          name="test7"
          type="text"
          onChange={setValue}
          value={value}
          title="Disabled Label Text"
          subtitle="Input Description"
          hasTooltip
          tooltipContent={
            <ul>
              <li>ABC</li>
              <li>123</li>
            </ul>
          }
          placeholder="Enter text ..."
          isDisabled
        />
        <h3 className={classes['u-semiBold']}>Textarea</h3>
        <InputWrapper
          name="test8"
          type="textarea"
          variant="outline"
          onChange={setValue}
          value={value}
          title="Label Text"
          subtitle="Input Description"
          placeholder="Enter text ..."
        />
        <InputWrapper
          name="test9"
          type="textarea"
          onChange={setValue}
          value={value}
          title="Label Text"
          subtitle="Input Description"
          placeholder="Enter text ..."
        />
        <h3 className={classes['u-semiBold']}>Select</h3>
        <ReactSelectWrapper
          name="select1"
          title="Select Empty"
          subtitle="Select Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Select"
          isRequired
          options={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 },
            { label: 'Option 3', value: 3 }
          ]}
          isSearchable
          value={[]}
          onChange={() => {}}
          onBlur={() => {}}
        />
        <ReactSelectWrapper
          name="select2"
          title="Select Single"
          subtitle="Select Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isRequired
          options={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 },
            { label: 'Option 3', value: 3 }
          ]}
          isSearchable
          value={[{ label: 'Option 1', value: 1 }]}
          onChange={() => {}}
          onBlur={() => {}}
        />
        <ReactSelectWrapper
          name="select3"
          title="Select Multi"
          subtitle="Select Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isRequired
          options={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 },
            { label: 'Option 3', value: 3 }
          ]}
          isSearchable
          isMulti
          value={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 }
          ]}
          onChange={() => {}}
          onBlur={() => {}}
        />
        <ReactSelectWrapper
          name="select4"
          title="Select Disabled"
          subtitle="Select Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isRequired
          options={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 },
            { label: 'Option 3', value: 3 }
          ]}
          isDisabled
          value={[]}
          onChange={() => {}}
          onBlur={() => {}}
        />
        <ReactSelectWrapper
          name="select5"
          title="Select Error"
          subtitle="Select Description"
          hasTooltip
          tooltipText="Tooltip Description"
          placeholder="Enter text ..."
          isRequired
          options={[
            { label: 'Option 1', value: 1 },
            { label: 'Option 2', value: 2 },
            { label: 'Option 3', value: 3 }
          ]}
          value={[]}
          onChange={() => {}}
          onBlur={() => {}}
          error="Field Error"
        />
        <h3 className={classes['u-semiBold']}>Checkbox Group</h3>
        <InputChoice
          name="test10"
          type="checkbox"
          onChange={setInputChoiceValue1}
          title="Label Text"
          subtitle="Input Description"
          options={[
            {
              value: 'Option 1',
              label: 'Checkbox Option 1'
            },
            {
              value: 'Option 2',
              label: 'Checkbox Option 2'
            },
            {
              value: 'Option 3',
              label: 'Checkbox Option 3'
            }
          ]}
          selectedOptions={inputChoiceValue1}
          isMulti
        />
        <h3 className={classes['u-semiBold']}>Radio Group</h3>
        <InputChoice
          name="test11"
          type="radio"
          onChange={setInputChoiceValue2}
          title="Label Text"
          subtitle="Input Description"
          options={[
            {
              value: 'Option 1',
              label: 'Radio Option 1'
            },
            {
              value: 'Option 2',
              label: 'Radio Option 2'
            },
            {
              value: 'Option 3',
              label: 'Radio Option 3'
            }
          ]}
          selectedOptions={inputChoiceValue2}
        />
        <h3 className={classes['u-semiBold']}>File Input Group</h3>
        <InputWrapper
          type="file"
          name="test12"
          onChange={(e) => console.log(e)}
          title="Document 1"
          subtitle="File not uploaded"
          isRequired
          hasTooltip
          tooltipText="Tooltip Description"
        />
        <InputWrapper
          type="file"
          fileName="some-file.csv"
          name="test13"
          onChange={(e) => console.log(e)}
          title="Document 1"
          subtitle="File uploaded"
          isRequired
          hasTooltip
          tooltipText="Tooltip Description"
        />
        <InputMedia
          name="test14"
          onChange={(e) => console.log(e)}
          onClear={() => {}}
          title="Profile Picture"
          subtitle="This is the subtitle"
          hasTooltip
          tooltipText="Tooltip Description"
          instructions={<div>Upload Image</div>}
          isRequired
          isDisabled
        />
        <InputMedia
          name="test15"
          onChange={(e) => console.log(e)}
          onClear={() => {}}
          title="Profile Picture"
          subtitle="This is the subtitle"
          hasTooltip
          tooltipContent={<div>Tooltip Content</div>}
          error="Image too big (12.02MB). Please upload an image up to 10MB."
          pic="https://www.befunky.com/images/wp/wp-2021-01-linkedin-profile-picture-after.jpg?auto=avif,webp&format=jpg&width=944"
          isSquare
          isRequired
        />
        <InputMedia
          name="test16"
          onChange={(e) => console.log(e)}
          onClear={() => {}}
          title="Course Video Preview"
          subtitle="This is the subtitle"
          hasTooltip
          tooltipContent={<div>Tooltip Content</div>}
          isSquare
          isRequired
          isVideo
        />
      </div>
      <div>
        Theme is <b>{theme}</b>
      </div>

      <h3 className={classes['u-semiBold']}>Tabs</h3>
      <Tabs
        tabs={[
          {
            id: 'tab1',
            name: 'Tab 1',
            route: '/tab-1',
            component: () => <div>Tab 1 Content</div>
          },
          {
            id: 'tab2',
            name: 'Tab 2',
            route: '/tab-2',
            component: () => <div>Tab 2 Content</div>
          },
          {
            id: 'ta3',
            name: 'Tab 3',
            route: '/tab-3',
            component: () => <div>Tab 3 Content</div>
          }
        ]}
        size={'sm'}
      />
      <Tabs
        tabs={[
          {
            id: 'tab1',
            name: 'Tab 1',
            route: '/tab-1',
            component: () => <div>Tab 1 Content</div>
          },
          {
            id: 'tab2',
            name: 'Tab 2',
            route: '/tab-2',
            component: () => <div>Tab 2 Content</div>
          },
          {
            id: 'tab3',
            name: 'Tab 3',
            route: '/tab-3',
            component: () => <div>Tab 3 Content</div>
          }
        ]}
      />
      <h3 className={classes['u-semiBold']}>Badge</h3>
      <Badge text="Tag" />
      <Badge text="Tag" size="sm" />
      <Badge text="Tag" variant="outline" />
      <Badge text="Tag" size="sm" variant="outline" />
      <h3 className={classes['u-semiBold']}>Breadcrumbs</h3>
      <Breadcrumbs />
      <h3 className={classes['u-semiBold']}>Accordion</h3>
      <Accordion
        items={[
          {
            title: 'Title 1',
            content: 'Content 1'
          },
          {
            title: 'Title 2',
            content: 'Content 2'
          },
          {
            title: 'Title 3',
            content: <div>Content 3 Component</div>
          }
        ]}
      />
      <h3 className={classes['u-semiBold']}>Card Normal</h3>
      <div style={{ width: '300px' }}>
        <Card course={{} as ICourse} />
      </div>
      <h3 className={classes['u-semiBold']}>Card Clickable</h3>
      <div style={{ width: '300px' }}>
        <Card course={{} as ICourse} onClick={() => {}} />
      </div>
      <h3 className={classes['u-semiBold']}>Card Disabled</h3>
      <div style={{ width: '300px' }}>
        <Card course={{} as ICourse} isDisabled />
      </div>
      <h3 className={classes['u-semiBold']}>Progress</h3>
      <Progress progress={0} />
      <Progress progress={30} />
      <Progress progress={65} />
      <Progress progress={100} />
      <h3 className={classes['u-semiBold']}>Rating</h3>
      <Rating rating={0} count={{ 1: 5, 2: 5, 3: 5, 4: 5, 5: 5 }} />
      <Rating rating={2.5} count={{ 1: 5, 2: 5, 3: 5, 4: 5, 5: 5 }} readOnly />
      <Rating rating={3.5} count={{ 1: 5, 2: 5, 3: 5, 4: 5, 5: 5 }} readOnly />
      <Rating rating={5} count={{ 1: 5, 2: 5, 3: 5, 4: 5, 5: 5 }} />
      <h3 className={classes['u-semiBold']}>Typography</h3>
      <h1 className={classes['u-light']}>H1/Poppins/Light</h1>
      <h1>H1/Poppins/Regular</h1>
      <h1 className={classes['u-medium']}>H1/Poppins/Medium</h1>
      <h1 className={classes['u-semiBold']}>H1/Poppins/SemiBold</h1>
      <h1 className={classes['u-bold']}>H1/Poppins/Bold</h1>
      <h2 className={classes['u-light']}>H2/Poppins/Light</h2>
      <h2>H2/Poppins/Regular</h2>
      <h2 className={classes['u-medium']}>H2/Poppins/Medium</h2>
      <h2 className={classes['u-semiBold']}>H2/Poppins/SemiBold</h2>
      <h2 className={classes['u-bold']}>H2/Poppins/Bold</h2>
      <h3 className={classes['u-light']}>H3/Poppins/Light</h3>
      <h3>H3/Poppins/Regular</h3>
      <h3 className={classes['u-medium']}>H3/Poppins/Medium</h3>
      <h3 className={classes['u-semiBold']}>H3/Poppins/SemiBold</h3>
      <h3 className={classes['u-bold']}>H3/Poppins/Bold</h3>
      <h4 className={classes['u-light']}>H4/Poppins/Light</h4>
      <h4>H4/Poppins/Regular</h4>
      <h4 className={classes['u-medium']}>H4/Poppins/Medium</h4>
      <h4 className={classes['u-semiBold']}>H4/Poppins/SemiBold</h4>
      <h4 className={classes['u-bold']}>H4/Poppins/Bold</h4>
      <h5 className={classes['u-light']}>H5/Poppins/Light</h5>
      <h5>H5/Poppins/Regular</h5>
      <h5 className={classes['u-medium']}>H5/Poppins/Medium</h5>
      <h5 className={classes['u-semiBold']}>H5/Poppins/SemiBold</h5>
      <h5 className={classes['u-bold']}>H5/Poppins/Bold</h5>
      <h6 className={classes['u-light']}>H6/Poppins/Light</h6>
      <h6>H6/Poppins/Regular</h6>
      <h6 className={classes['u-medium']}>H6/Poppins/Medium</h6>
      <h6 className={classes['u-semiBold']}>H6/Poppins/SemiBold</h6>
      <h6 className={classes['u-bold']}>H6/Poppins/Bold</h6>
      <p className={classes['u-body1']}>
        Body 1/Regular:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body1']} ${classes['u-medium']}`}>
        Body 1/Medium:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body1']} ${classes['u-semiBold']}`}>
        Body 1/Semi bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body1']} ${classes['u-bold']}`}>
        Body 1/Bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={classes['u-body2']}>
        Body 2/Regular:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body2']} ${classes['u-medium']}`}>
        Body 2/Medium:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body2']} ${classes['u-semiBold']}`}>
        Body 2/Semi bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body2']} ${classes['u-bold']}`}>
        Body 2/Bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={classes['u-body3']}>
        Body 3/Regular:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body3']} ${classes['u-medium']}`}>
        Body 3/Medium:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body3']} ${classes['u-semiBold']}`}>
        Body 3/Semi bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body3']} ${classes['u-bold']}`}>
        Body 3/Bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={classes['u-body4']}>
        Body 4/Regular:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body4']} ${classes['u-medium']}`}>
        Body 4/Medium:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body4']} ${classes['u-semiBold']}`}>
        Body 4/Semi bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={`${classes['u-body4']} ${classes['u-bold']}`}>
        Body 4/Bold:
        <br /> Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p className={classes['u-text--primary']}>Text primary</p>
      <p className={classes['u-text--content']}>Text content</p>
      <p className={classes['u-text--error']}>Text error</p>
      <p className={classes['u-text--success']}>Text success</p>
    </div>
  );
};

export default StyleGuide;
