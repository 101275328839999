import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/Routes';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowShort.svg';
import classes from './Breadcrumbs.module.scss';

interface IBreadcrumbs {
  skips?: number[];
}

const Breadcrumbs = ({ skips }: IBreadcrumbs) => {
  const navigate = useNavigate();
  const breadcrumbs = useReactRouterBreadcrumbs(routes());
  let filteredCrumbs = breadcrumbs.filter(function (value, index) {
    return skips?.indexOf(index) === -1;
  });

  return (
    <>
      {filteredCrumbs.length > 1 && (
        <div className={classes['breadcrumbs__wrapper']}>
          {filteredCrumbs?.map((item, i) => {
            return (
              <div
                key={i}
                className={`${classes['breadcrumb__item']} ${
                  i === filteredCrumbs.length - 1
                    ? classes['breadcrumb__item--active']
                    : ''
                }`}
              >
                <span
                  onClick={() =>
                    navigate(`${item.match.pathname}${item.location.search}`)
                  }
                >
                  {item.breadcrumb}
                </span>
                {i < filteredCrumbs.length - 1 && (
                  <ArrowIcon className={classes['breadcrumb__item__arrow']} />
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

Breadcrumbs.defaultProps = {
  skips: []
};

export default Breadcrumbs;
