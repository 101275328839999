import { useQuery } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { ISinglePageData } from 'query/course-module/dto';
import { singlePageDataQuery } from 'query/course-module/queries';
import classes from './Legal.module.scss';

interface ISinglePageProps {
  slug: string;
}

const Legal = ({ slug }: ISinglePageProps) => {
  const { data } = useQuery<boolean, Error, ISinglePageData>({
    ...singlePageDataQuery(slug),
    enabled: !!slug
  });

  // DOMPurify sanitizes HTML and prevents XSS attacks.
  const cleanCode = DOMPurify.sanitize(data?.content || '');

  return (
    <div className={classes['single']}>
      <div className={classes['single__container']}>
        <div className={classes['single__container__section']}>
          <h3 className={classes['u-semiBold']}>{data?.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: cleanCode }} />
        </div>
      </div>
    </div>
  );
};

export default Legal;
