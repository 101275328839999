import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import { IGenericProps } from 'components/popup/GenericPopup';
import Card from 'components/card/Card';
import { InputWrapper } from 'components/input/InputWrapper';
import { ICourse } from 'query/course-module/dto';
import classes from './ApprovePopup.module.scss';

interface IApprovePopupProps
  extends Omit<IGenericProps, 'type' | 'buttonAction'> {
  course: ICourse;
  type: 'approve' | 'reject';
  buttonAction: (reason?: string) => void;
}

const ApprovePopup = ({
  title,
  size,
  isClosable,
  msg,
  buttonVariant,
  buttonName,
  buttonAction,
  hideActionBtn,
  bellowBtnComp,
  clearPopupOnBtnAction,
  redirectPath,
  course,
  type
}: IApprovePopupProps) => {
  const navigate = useNavigate();
  const { clearPopup, popupIsLoading } = useContext(popupContext);
  const [reason, setReason] = useState('');

  const closeHandler = () => {
    clearPopupOnBtnAction && clearPopup();
    redirectPath && navigate(redirectPath);
    !!buttonAction && type === 'reject' ? buttonAction(reason) : buttonAction();
  };

  return (
    <ModalWrapper
      onClose={() => clearPopup()}
      size={size}
      isClosable={isClosable}
    >
      <div className={classes['popup-container']}>
        <div className={classes['content']}>
          <h4 className={classes['u-semiBold']}>{title}</h4>
          {msg && <p className={classes['u-body2']}>{msg}</p>}
        </div>
        {type === 'approve' && (
          <div className={classes['card']}>
            <Card course={course} />
          </div>
        )}
        {type === 'reject' && (
          <div className={classes['reason']}>
            <div
              className={`${classes['u-body3']} ${classes['u-text--content']}`}
            >
              Please enter a reason for the rejection of this course. This
              message should inform the creator about the changes they need to
              make.
            </div>
            <InputWrapper
              type="textarea"
              onChange={setReason}
              value={reason}
              placeholder="Enter a reason for the rejection"
              title="Reason"
              limit={500}
              subtitle={`${reason.length} of 500 characters used.`}
            />
          </div>
        )}
        {!hideActionBtn && (
          <Button
            variant={buttonVariant || 'neutral'}
            onClick={closeHandler}
            isLoading={popupIsLoading}
            isDisabled={type === 'reject' && !reason.length}
            minWidth="full"
          >
            {buttonName || 'Close'}
          </Button>
        )}
        {bellowBtnComp}
      </div>
    </ModalWrapper>
  );
};

ApprovePopup.defaultProps = {
  type: 'approve'
};

export default ApprovePopup;
