import React, { useEffect, useState } from 'react';
import { InputMedia } from 'components/input/InputMedia';
import SwitchInput from 'components/buttons/SwitchInput';
import Divider from 'components/divider/Divider';
import { IFormik } from './CourseForm';
import {
  getBase64FromUrl,
  handleMediaUpload,
  handleVideoUpload
} from 'utils/processing';
import classes from './Form.module.scss';

interface IStep2
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
  > {}

export const Step2 = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError
}: IStep2) => {
  const [isThumbnailGenerated, setIsThumbnailGenerated] = useState(
    !!values.thumbnail_is_generated
  );
  const [isVideoPreviewGenerated, setIsVideoPreviewGenerated] = useState(
    !!values.video_preview_is_generated
  );
  useEffect(() => {
    if (!values.video_preview) setFieldValue('hover_play', false);
  }, [values.video_preview]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <InputMedia
        name="thumbnail"
        title="Course Thumbnail"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleMediaUpload(
            e,
            'thumbnail',
            setFieldValue,
            setFieldTouched,
            setFieldError
          );
          setFieldValue('isThumbnailPlaceholder', false);
          setFieldValue('thumbnail_is_generated', false);
        }}
        onClear={async () => {
          setFieldValue('thumbnail', '');
          setFieldValue('isThumbnailPlaceholder', true);
          setFieldValue('thumbnail_is_generated', isThumbnailGenerated);
        }}
        hasTooltip
        tooltipText={
          'Upload Course Thumbnail image. It will be visible in the Course Cards on the explore page.'
        }
        isRequired
        pic={values.thumbnail}
        error={errors.thumbnail && touched.thumbnail ? errors.thumbnail : ''}
        tip={
          values.isThumbnailPlaceholder
            ? 'A thumbnail will be automatically generated from the first video added to the course if you leave this placeholder. You can change it from this screen later. '
            : ''
        }
        isChangeOnly={!!values.isThumbnailPlaceholder}
      />
      <InputMedia
        name="activation_nft_image"
        title="Activation NFT"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleMediaUpload(
            e,
            'activation_nft_image',
            setFieldValue,
            setFieldTouched,
            setFieldError
          );
        }}
        onClear={() => setFieldValue('activation_nft_image', '')}
        pic={values.activation_nft_image}
        hasTooltip
        tooltipText="Activation NFT is the NFT that the user buys in order to active the course. If an image is not selected, the Course Thumbnail will be used as an Activation NFT image."
        error={
          errors.activation_nft_image && touched.activation_nft_image
            ? errors.activation_nft_image
            : ''
        }
        isChangeOnly
      />
      <div>
        <div className={classes['hide-video-preview-wrapper']}>
          <SwitchInput
            size="sm"
            value={values.show_video_preview}
            label="Show video preview"
            onClick={(e) => setFieldValue('show_video_preview', e)}
            error={
              errors.show_video_preview && touched.show_video_preview
                ? errors.show_video_preview
                : ''
            }
          />
        </div>
        {values.show_video_preview && (
          <>
            <InputMedia
              name="video_preview"
              title="Course Video Preview"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleVideoUpload(
                  e,
                  'video_preview',
                  setFieldValue,
                  setFieldTouched,
                  setFieldError
                );
                setFieldValue('video_preview_is_generated', false);
              }}
              onClear={() => {
                setFieldValue('video_preview', '');
                setFieldValue(
                  'video_preview_is_generated',
                  isVideoPreviewGenerated
                );
              }}
              pic={values.video_preview}
              isVideo
              hasTooltip
              tooltipText="The Course Video preview can be a video that briefly introduces the content of the course. This video can be also played when the user hovers on the course card."
              error={
                errors.video_preview && touched.video_preview
                  ? errors.video_preview
                  : ''
              }
              tip={
                !values.video_preview
                  ? 'A video preview is automatically generated from the first video added to the course. You can change it from this screen later.'
                  : ''
              }
            />
            <SwitchInput
              label="Play video on card hover."
              onClick={(e) => setFieldValue('hover_play', e)}
              value={values.hover_play}
              size="sm"
              isDisabled={!values.video_preview}
              error={
                errors.hover_play && touched.hover_play ? errors.hover_play : ''
              }
            />
          </>
        )}
      </div>
      <Divider dir="horizontal" />
    </>
  );
};

export default IStep2;
