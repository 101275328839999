import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { appContext } from 'context/appContext';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import ScrollToTop from 'components/scroll-to-top/ScrollToTop';
import classes from './MainLayout.module.scss';

interface IMainLayout {
  isSimple: boolean;
  withPadding: boolean;
  withoutFooter: boolean;
  children: React.ReactNode;
}

const MainLayout = ({
  isSimple,
  withPadding,
  withoutFooter,
  children
}: IMainLayout) => {
  const { slug, videoId } = useParams();
  const { pathname } = useLocation();
  const isVideoPage = !!videoId;
  const { isTheatreMode } = useContext(appContext);

  const omitFooter =
    pathname === '/register' ||
    pathname === '/courses/create' ||
    pathname.includes(`/courses/${slug}/edit`) ||
    pathname === '/user/about/main-info/edit' ||
    pathname === '/user/about/social-info/edit';

  return (
    <div className={classes['wrapper']}>
      <Header isSimple={isSimple} />
      <ScrollToTop />
      <div
        className={`
          ${classes['container']}         
          ${!withPadding ? classes['container--no-padding'] : ''}
          ${
            isTheatreMode && isVideoPage ? classes['container--full-width'] : ''
          }
        `}
      >
        {children}
      </div>
      {!withoutFooter && !omitFooter && <Footer />}
    </div>
  );
};

MainLayout.defaultProps = {
  isSimple: false,
  withPadding: true,
  withoutFooter: false
};

export default MainLayout;
