import { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';
import Button from 'components/buttons/Button';
import ContextMenu from 'components/buttons/ContextMenu';
import ApprovePopup from 'components/popup/ApprovePopup';
import { popupContext } from 'context/popupContext';
import GenericPopup from 'components/popup/GenericPopup';
import { COURSE_CHANGES, ICourse } from 'query/course-module/dto';
import {
  approveCourseMutation,
  rejectCourseMutation,
  highlightCourseMutation,
  takedownCourseMutation
} from 'query/course-module/mutations';
import { isCourseStatus } from 'utils/helpers';
import { COURSE_STATUS } from 'utils/constants';
import classes from './Buttons.module.scss';

interface IAdminButtonsProps {
  course: ICourse;
}

const AdminButtons = ({ course }: IAdminButtonsProps) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);

  const queryClient = useQueryClient();

  const isSubmitted = isCourseStatus('isSubmitted', course);
  const isPublished = isCourseStatus('isPublished', course);
  const isPaused = isCourseStatus('isPaused', course);
  const isSubmittedDraft = isCourseStatus('isSubmittedDraft', course);

  const { status, is_draft_copy, _id, is_highlighted } = course;

  const { mutate: approveMutation } = useMutation({
    ...approveCourseMutation(_id as string, setPopupLoading),
    onSuccess: (id: string) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg="You have successfully approved the course. The creator can now publish it."
          buttonVariant="contrast"
          buttonName="Go to Course"
          buttonAction={() =>
            navigate(
              `/admin/courses/${slug}?&changes=${
                status === COURSE_STATUS.DRAFT
                  ? COURSE_CHANGES.NO_CHANGES
                  : COURSE_CHANGES.ALL
              }`
            )
          }
          bellowBtnComp={
            <>
              <Button
                variant="outline"
                onClick={() => navigate('/admin/dashboard')}
                minWidth="full"
              >
                Return to Dashboard
              </Button>
              <Button
                variant="link-neutral"
                onClick={clearPopup}
                minWidth="full"
                withPadding={false}
              >
                Close
              </Button>
            </>
          }
        />
      );
      // Invalidate original published course
      queryClient.invalidateQueries([
        'admin-courses-data',
        {
          id: slug,
          changes: changes
        }
      ]);
      // Remove query of draft-of-published, as it no longer exists
      queryClient.removeQueries([
        'admin-courses-data',
        {
          id: _id,
          searchParams: `?&changes=${COURSE_CHANGES.ONLY_CHANGES}`
        }
      ]);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const { mutate: rejectMutation } = useMutation({
    ...rejectCourseMutation(_id as string, setPopupLoading),
    onSuccess: (id: string) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg="You have successfully rejected the course."
          buttonVariant="contrast"
          buttonName="Go to Course"
          buttonAction={() =>
            navigate(`/admin/courses/${slug}?&changes=${changes}`)
          }
          bellowBtnComp={
            <Button
              variant="link-neutral"
              onClick={clearPopup}
              minWidth="full"
              withPadding={false}
            >
              Close
            </Button>
          }
        />
      );
      queryClient.invalidateQueries([
        'admin-courses-data',
        {
          id: slug,
          changes: changes
        }
      ]);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const { mutate: takedownMutation } = useMutation({
    ...takedownCourseMutation({
      id: _id as string,
      isTakedown: isPaused ? false : true,
      setPopupLoading
    }),
    onSuccess: (data: { id: string; isTakedown: boolean }) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg={`You have successfully ${
            data.isTakedown ? 'taken down' : 'restored'
          } the course. It is ${
            data.isTakedown
              ? 'no longer publicly listed.'
              : 'now visible in the Explore Courses page.'
          }`}
          buttonAction={clearPopup}
        />
      );
      queryClient.invalidateQueries([
        'admin-courses-data',
        {
          id: slug,
          changes: changes
        }
      ]);
      queryClient.invalidateQueries(['admin-course-history']);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const {
    // eslint-disable-next-line
    isLoading: highlightCourseLoading,
    // eslint-disable-next-line
    error: highlightCourseError,
    mutate: highlightCourse
  } = useMutation({
    ...highlightCourseMutation(),
    onSuccess: () => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg={
            is_highlighted
              ? 'This course is no longer highlighted and is removed from the landing page.'
              : 'This course is now highlighted and visible on the landing page!'
          }
          buttonVariant="contrast"
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
      queryClient.invalidateQueries(['courses-data']);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const approveActionHandler = (
    action: 'approve' | 'reject',
    secondAction?: 'takedown' | 'restore'
  ) => {
    const isRestoreCourse =
      action === 'approve' && secondAction === 'restore' && isPaused;
    const isApproveCourse =
      action === 'approve' && (isSubmittedDraft || isPublished || isPaused);
    const isTakeDownCourse =
      action === 'reject' &&
      secondAction === 'takedown' &&
      isPublished &&
      changes === COURSE_CHANGES.NO_CHANGES;

    const text = isRestoreCourse
      ? 'Restore'
      : isApproveCourse
      ? 'Approve'
      : isTakeDownCourse
      ? 'Take down'
      : 'Reject';

    setPopup(
      <ApprovePopup
        size="md"
        buttonVariant="contrast"
        title={`${text} Course`}
        buttonName={`${text} Course`}
        buttonAction={(reason?: string) =>
          isRestoreCourse
            ? takedownMutation(_id as string)
            : isApproveCourse
            ? approveMutation(_id as string)
            : isTakeDownCourse
            ? takedownMutation(reason as string)
            : rejectMutation(reason as string)
        }
        course={course}
        type={action}
      />
    );
  };

  const showContextMenu =
    // Highlighted courses
    (isPublished && changes === COURSE_CHANGES.NO_CHANGES) ||
    // Initially submited courses
    (isSubmitted &&
      ((!isPublished && !isPaused) ||
        // Submitted changes for published course
        ((isPublished || isPaused) && changes === COURSE_CHANGES.ALL))) ||
    // Published course without changes
    ((isPublished || isPaused) &&
      changes === COURSE_CHANGES.NO_CHANGES &&
      !is_draft_copy);

  return (
    <div className={classes['btns-row']}>
      <div className={classes['btns-row__primary']}>
        <Button
          onClick={() =>
            navigate(`/admin/courses/${slug}/review?changes=${changes}`)
          }
          minWidth="full"
        >
          Preview Course Info
        </Button>
      </div>
      {showContextMenu && (
        <span>
          <ContextMenu
            children={
              <>
                {isPublished && changes === COURSE_CHANGES.NO_CHANGES && (
                  <Button
                    variant="link-neutral"
                    onClick={() =>
                      highlightCourse({
                        id: _id,
                        is_highlighted: !is_highlighted
                      })
                    }
                  >
                    {is_highlighted ? 'Remove from' : 'Add to'} Highlighted
                    Courses
                  </Button>
                )}
                {/* Initially submitted courses */}
                {isSubmitted &&
                  ((!isPublished && !isPaused) ||
                    // Submitted changes for published course
                    ((isPublished || isPaused) &&
                      changes === COURSE_CHANGES.ALL)) && (
                    <>
                      <Button
                        variant="link-success"
                        className={`${classes['btns-row__btn']} ${classes['btns-row__btn--success']}`}
                        onClick={() => approveActionHandler('approve')}
                      >
                        Approve Course
                      </Button>
                      <Button
                        variant="link-error"
                        className={`${classes['btns-row__btn']} ${classes['btns-row__btn--error']}`}
                        onClick={() => approveActionHandler('reject')}
                      >
                        Reject Course
                      </Button>
                    </>
                  )}
                {/* Published course without changes */}
                {(isPublished || isPaused) &&
                  changes === COURSE_CHANGES.NO_CHANGES &&
                  !is_draft_copy && (
                    <>
                      {isPaused ? (
                        <Button
                          className={`${classes['btns-row__btn']} ${classes['btns-row__btn--success']}`}
                          variant="link-neutral"
                          onClick={() =>
                            approveActionHandler('approve', 'restore')
                          }
                        >
                          Restore Course
                        </Button>
                      ) : (
                        <Button
                          className={`${classes['btns-row__btn']} ${classes['btns-row__btn--error']}`}
                          variant="link-error"
                          onClick={() =>
                            approveActionHandler('reject', 'takedown')
                          }
                        >
                          Take Down
                        </Button>
                      )}
                    </>
                  )}
              </>
            }
          />
        </span>
      )}
    </div>
  );
};

export default AdminButtons;
