import { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { useAccount } from 'wagmi';
import { Web3Context } from 'context/web3Context';
import { popupContext } from 'context/popupContext';
import { userContext } from 'context/userContext';
import Tooltip from 'components/tooltip/Tooltip';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import GenericPopup from 'components/popup/GenericPopup';
import ContextMenu from 'components/buttons/ContextMenu';
import Button from 'components/buttons/Button';
import ActivatePopup from '../../components/popups/ActivatePopup';
import VerifyAccountPopup from 'components/popup/VerifyAccountPopup';
import BuyActivationPopup from '../../components/popups/BuyActivationPopup';
import BuyActivationPopupNoQuoter from '../../components/popups/BuyActivationPopupNoQuoter';
import { tokenPairsDataQuery } from 'query/course-module/queries';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import { requestCertificateMutation } from 'query/course-module/mutations';
import {
  COMPLETION_STATUS,
  COURSE_RELATIONSHIP,
  ICourse,
  ITokenPairsData
} from 'query/course-module/dto';
import { REACT_APP_CHAIN_ID } from 'utils/constants';
import classes from './Buttons.module.scss';

interface IUserButtonsProps {
  course: ICourse;
}

const UserButtons = ({ course }: IUserButtonsProps) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { changes, is_public_view } = queryString.parse(urlParams);
  const { isWalletUnique, isCorrectChain } = useContext(Web3Context);
  const {
    isTokenExpired,
    userData: { kyc_status }
  } = useContext(userContext);
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const { isConnected } = useAccount();

  const {
    _id,
    course_relationship,
    purchased,
    progress,
    exams_count,
    template_id
  } = course;

  const isCreatorPublicView = is_public_view === 'true';

  // Get token pairs
  const {
    isLoading: tokenPairsLoading,
    error: tokenPairsError,
    data: tokenPairsData
  } = useQuery<boolean, Error, ITokenPairsData>(tokenPairsDataQuery());

  const {
    // eslint-disable-next-line
    isLoading: requestCertificateIsLoading,
    // eslint-disable-next-line
    error: requestCertificateError,
    mutate: requestCertificate
  } = useMutation({
    ...requestCertificateMutation(),
    onSuccess: () => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg="Your certificate request was submitted!"
          buttonVariant="contrast"
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  if (tokenPairsLoading) return <Loader size="lg" hasText withPadding />;
  if (tokenPairsError) return <Error error={tokenPairsError} />;

  const renderBuyButton = (isBuyAgain: boolean) => (
    <Tooltip
      id={_id as string}
      text={
        isTokenExpired || !isConnected || !isCorrectChain || !isWalletUnique
          ? `In order to buy Activation NFT, please 
    ${
      isTokenExpired
        ? 'log in first.'
        : !isConnected
        ? 'connect your wallet first.'
        : !isCorrectChain
        ? 'switch to the Polygon network.'
        : !isWalletUnique
        ? 'switch your wallet. This one is associated with another account.'
        : ''
    }`
          : !purchased?.active && !!purchased?.buy_tx_started_at
          ? "You'll be able to access the course content shortly."
          : ''
      }
    >
      <Button
        className={!isBuyAgain ? classes['u-m8-bot'] : ''}
        onClick={() => {
          /* 
            Use popup with quoter only on prod, since amoy
            doesn't have quoter contracts deployed
          */
          setPopup(
            kyc_status === USER_KYC_STATUS.APPROVED ? (
              +REACT_APP_CHAIN_ID === 137 ? (
                <BuyActivationPopup data={course} tokenPairs={tokenPairsData} />
              ) : (
                <BuyActivationPopupNoQuoter
                  data={course}
                  tokenPairs={tokenPairsData}
                />
              )
            ) : (
              <VerifyAccountPopup />
            )
          );
        }}
        variant={isBuyAgain ? 'link-neutral' : 'contrast'}
        minWidth="full"
        isDisabled={
          !purchased?.active &&
          (isTokenExpired || !!purchased?.buy_tx_started_at)
        }
        isWeb3
      >
        {!purchased?.active
          ? !purchased?.buy_tx_started_at
            ? 'Buy Activation NFT'
            : 'Processing Payment...'
          : 'Buy As Gift'}
      </Button>
    </Tooltip>
  );

  const renderContextMenu = () => {
    return (
      <span>
        <ContextMenu
          children={
            <>
              {/* ================= BUY AS A GIFT BUTTON ================= */}
              {
                <Button
                  variant="neutral"
                  onClick={() => {
                    /* 
                      Use popup with quoter only on prod, since amoy
                      doesn't have quoter contracts deployed
                    */
                    setPopup(
                      +REACT_APP_CHAIN_ID === 137 ? (
                        <BuyActivationPopup
                          data={course}
                          tokenPairs={tokenPairsData}
                          isBuyAsGiftOnly
                        />
                      ) : (
                        <BuyActivationPopupNoQuoter
                          data={course}
                          tokenPairs={tokenPairsData}
                          isBuyAsGiftOnly
                        />
                      )
                    );
                  }}
                  isWeb3
                >
                  Buy as a Gift
                </Button>
              }

              {/* ========= Claim CERTIFICATE BUTTON ========= */}
              {purchased?.active &&
                purchased.completion_status === COMPLETION_STATUS.INITIAL &&
                exams_count === 0 &&
                !!template_id && (
                  <Button
                    variant="link-neutral"
                    minWidth="full"
                    onClick={() => requestCertificate(_id)}
                    isLoading={requestCertificateIsLoading}
                  >
                    Claim Certificate
                  </Button>
                )}

              {/* ========= VIEW CERTIFICATE BUTTON ========= */}
              {purchased?.completion_status === COMPLETION_STATUS.MINTED && (
                <Button
                  variant="link-neutral"
                  onClick={() =>
                    navigate(`/courses/${slug}/certificate?&changes=0`, {
                      state: { autoFocus: true }
                    })
                  }
                >
                  View Certificate
                </Button>
              )}
            </>
          }
        />
      </span>
    );
  };

  return (
    <div className={classes['btns-row']}>
      <div className={classes['btns-row__primary']}>
        {/* Logged and non-logged in users see the Buy button */}
        {(course_relationship !== COURSE_RELATIONSHIP.PURCHASED ||
          isCreatorPublicView) &&
          renderBuyButton(false)}
        {!purchased?.active && !!purchased?.buy_tx_confirmed_at && (
          <Tooltip
            id={_id as string}
            text={
              isTokenExpired ||
              !isConnected ||
              !isCorrectChain ||
              !isWalletUnique
                ? `In order to activate your course, please
              ${
                isTokenExpired
                  ? 'log in first.'
                  : !isConnected
                  ? 'connect your wallet first.'
                  : !isCorrectChain
                  ? 'switch to the Polygon network.'
                  : !isWalletUnique
                  ? 'switch your wallet. This one is associated with another account.'
                  : ''
              }`
                : !!purchased.activate_tx_started_at
                ? "You'll be able to access the course content shortly."
                : ''
            }
          >
            <Button
              className={classes['u-m8-bot']}
              onClick={() => {
                // If course is received as gift, the user must be able to activate and watch the course
                if (purchased.purchased_by_user_id !== purchased.user_id)
                  return setPopup(<ActivatePopup data={course} />);

                setPopup(
                  kyc_status === USER_KYC_STATUS.APPROVED ? (
                    <ActivatePopup data={course} />
                  ) : (
                    <VerifyAccountPopup />
                  )
                );
              }}
              variant="contrast"
              minWidth="full"
              isDisabled={
                isTokenExpired ||
                !!purchased.activate_tx_started_at ||
                !!purchased.refund_tx_started_at
              }
              isWeb3
            >
              {!purchased.activate_tx_started_at
                ? 'Activate Course'
                : 'Processing Activation'}
            </Button>
          </Tooltip>
        )}
        {purchased?.active && (
          <Button
            onClick={() =>
              navigate(
                `/courses/${_id}/${purchased?.last_watched_video}?changes=${changes}`
              )
            }
            minWidth="full"
          >
            {!!Object.keys(purchased?.videos_checkpoint)?.length
              ? progress === 100
                ? 'Watch Again'
                : 'Resume Course'
              : 'Start Course'}
          </Button>
        )}
      </div>
      {course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
        renderContextMenu()}
    </div>
  );
};

export default UserButtons;
