import Book from 'assets/images/book.png';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import classes from './PublishCourseCTAPopup.module.scss';
import Button from 'components/buttons/Button';

interface IPublishCourseCTAPopupProps {
  onClick: () => void;
}

const PublishCourseCTAPopup = ({ onClick }: IPublishCourseCTAPopupProps) => {
  return (
    <ModalWrapper size="lg">
      <div className={classes['popup']}>
        <img src={Book} alt="Make course public" />
        <h3 className={classes['popup__title']}>Make your course public</h3>
        <div className={classes['popup__msg']}>
          In order to make your course public, you need to publish your course.
        </div>
        <Button minWidth="md" onClick={onClick}>
          Publish
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default PublishCourseCTAPopup;
