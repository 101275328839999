import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InputWrapper } from 'components/input/InputWrapper';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { IFormik } from './CourseForm';
import {
  courseCategoriesDataQuery,
  courseSubcategoriesDataQuery
} from 'query/course-module/queries';
import { ICourseCategoryData, ICourseCategory } from 'query/course-module/dto';

interface IStep1
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldError'
    | 'handleBlur'
  > {}

export const Step1 = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur
}: IStep1) => {
  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ICourseCategoryData
  >(courseCategoriesDataQuery());

  // const {
  //   isLoading: subcategoryIsLoading,
  //   error: subcategoryError,
  //   data: subcategoryData
  // } = useQuery<boolean, Error, ICourseCategory[]>({
  //   ...courseSubcategoriesDataQuery(values.category?.value as string),
  //   enabled: !!values.category?.value
  // });

  useEffect(() => {
    if (!!error) setFieldError('category', error.message);
    // if (!!subcategoryError)
    //   setFieldError('subcategory', subcategoryError.message);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildOptions = (options: ICourseCategory[]) => {
    return options.map((category) => ({
      label: category.name,
      value: category._id
    }));
  };

  return (
    <>
      <InputWrapper
        name="name"
        type="text"
        variant="outline"
        onChange={(e) => setFieldValue('name', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.name}
        title="Course Name"
        placeholder="Enter Course Name"
        isRequired
        error={errors.name && touched.name ? errors.name : ''}
        limit={60}
        subtitle={`${values.name?.length} of 60 characters used`}
      />
      <ReactSelectWrapper
        name="category"
        title="Course Category"
        placeholder="Select Category"
        isRequired
        hasTooltip
        tooltipText="Selecting a course category and subcategory will make it easier for you course to be found."
        options={data ? buildOptions(data.result) : []}
        isLoading={isLoading}
        isSearchable
        onChange={(e) => {
          setFieldValue('category', e);
          setFieldValue('subcategory', null);
        }}
        onBlur={(e) => handleBlur(e)}
        error={
          errors.category && touched.category ? (errors.category as string) : ''
        }
        value={values.category}
        isClearable
      />
      {/* <ReactSelectWrapper
        name="subcategory"
        title="Course Subcategory"
        placeholder="Select Subcategory"
        hasTooltip
        tooltipText="Selecting a course category and subcategory will make it easier for you course to be found."
        options={
          !!subcategoryData && values.category
            ? buildOptions(subcategoryData)
            : []
        }
        isLoading={!!values.category && subcategoryIsLoading}
        isSearchable
        isDisabled={!values.category}
        onChange={(e) => setFieldValue('subcategory', e)}
        onBlur={(e) => handleBlur(e)}
        error={
          errors.subcategory && touched.subcategory
            ? (errors.subcategory as string)
            : ''
        }
        value={values.subcategory}
        isClearable
      /> */}
      <InputWrapper
        name="short_description"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('short_description', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.short_description}
        title="Course Short Description"
        subtitle={`${values.short_description?.length} of 200 characters used`}
        placeholder="Enter Course Short Description"
        hasTooltip
        tooltipText="A short description of your Course. This description will be visible on hover state of your Course card."
        error={
          errors.short_description && touched.short_description
            ? errors.short_description
            : ''
        }
        limit={200}
      />
      <InputWrapper
        name="description"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('description', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.description}
        title="Course Description"
        placeholder="Enter Course Description"
        hasTooltip
        tooltipText="A longer description of your Course. This description will be visible on your Course detailed page."
        error={
          errors.description && touched.description ? errors.description : ''
        }
        limit={500}
        subtitle={`${values.description?.length} of 500 characters used`}
      />
    </>
  );
};

export default IStep1;
