import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { ethers } from 'ethers';
import Actions from 'routes/dashboard/profile/components/Actions';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import { popupContext } from 'context/popupContext';
import Error from 'components/error/Error';
import Table from 'components/table/Table';
import EmailPopup from 'components/popup/EmailPopup';
import NoResults from 'components/noResults/NoResults';
import { IOption } from 'components/input/InputChoice';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { AdminUserCols } from 'components/table/AdminUserCols';
import AdminUsersActionPopup from './components/AdminUsersActionPopup';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import { IPurchasedItemsData } from 'query/course-module/dto';
import { adminUsersDataQuery } from 'query/course-module/queries';
import { isSafari } from 'utils/helpers';
import classes from './AdminUsers.module.scss';

const sortOptions = [
  { label: 'Name A-Z', value: 'first_name' },
  { label: 'Name Z-A', value: '-first_name' },
  { label: 'Email - ASC', value: 'email' },
  { label: 'Email - DESC', value: '-email' },
  { label: 'Oldest', value: 'date_created' },
  { label: 'Newest', value: '-date_created' }
];

const filterName = 'KYC Status';

const filterOptions = [
  {
    label: 'Approved',
    value: USER_KYC_STATUS.APPROVED.toString()
  },
  {
    label: 'Rejected',
    value: USER_KYC_STATUS.REJECTED.toString()
  },
  {
    label: 'Started',
    value: USER_KYC_STATUS.CREATED.toString()
  },
  {
    label: 'Not Started',
    value: USER_KYC_STATUS.NOT_STARTED.toString()
  },
  {
    label: 'Proccesing',
    value: USER_KYC_STATUS.PROCESSED.toString()
  },
  {
    label: 'Updated',
    value: USER_KYC_STATUS.UPDATED.toString()
  }
];

const AdminUsers = () => {
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    kyc_status
  } = queryString.parse(urlParams);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const { setPopup } = useContext(popupContext);
  const pageSize: number = 9;
  const { showFilter, updateFilter } = useContext(appContext);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [filterValue, setFilterValue] = useState<IOption[]>(
    filterOptions.filter((option) => kyc_status?.includes(option.value))
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1
      ? ethers.isAddress(searchValue)
        ? `&search[wallet_edu]=${searchValue}`
        : `&search[first_name]=${searchValue}&search[last_name]=${searchValue}&search[email]=${searchValue}`
      : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}${
    !!kyc_status
      ? `&kyc_status=${filterValue
          .map((filter) => `${filter.value}`)
          .join(',')}`
      : ''
  }`;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    IPurchasedItemsData
  >(adminUsersDataQuery(queryParams));

  const hasData = !!data && !!data?.result.length;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedUserStatus, setSelectedUserStatus] = useState('');
  const userCols = useMemo(() => AdminUserCols({ isSelectable: true }), []);

  const filterHandler = (value: IOption[]) => {
    setPage(1);
    setFilterValue(value);
  };

  return (
    <div
      className={`
      ${classes['admin-users']} 
      ${showFilter ? classes['admin-users--grid'] : ''}
    `}
    >
      {!!showFilter && (
        <Actions
          hasSort
          hasFilter
          isInverted
          setPage={setPage}
          sortValue={sortValue}
          sortOptions={sortOptions}
          showFilterMenu={showFilter}
          onFilterClick={updateFilter}
          passSortValueUp={setSortValue}
          passFilterValueUp={filterHandler}
          filters={[
            {
              filterOptions,
              filterValue,
              filterName
            }
          ]}
        />
      )}
      {(!isMdMobile || (isMdMobile && !showFilter)) && (
        <div
          className={`${classes['admin-users__table-wrapper']} 
          ${isSafari ? classes['safari-support-overflow'] : ''}`}
        >
          <Actions
            hasSearch
            isInverted
            isDebounced
            setPage={setPage}
            hasSort={!showFilter}
            sortValue={sortValue}
            hasFilter={!showFilter}
            sortOptions={sortOptions}
            searchValue={searchValue}
            onFilterClick={updateFilter}
            passSortValueUp={setSortValue}
            searchPlaceholder="Search Users"
            passSearchValueUp={setSearchValue}
          />
          {isLoading && (
            <TableSkeleton rows={pageSize} cols={userCols.length} />
          )}
          {!!data?.result.length && (
            <Table
              columns={userCols}
              data={data.result}
              islandProps={{
                primBtnText: 'Send Email',
                primBtnAction: (selectedRows) =>
                  setPopup(
                    <EmailPopup
                      users={selectedRows.map((row: any) => row.original)}
                    />
                  ),
                dynamicBtnText: (selectedRows) => {
                  return selectedRows[0].original.is_banned
                    ? 'Activate'
                    : 'Ban';
                },
                secBtnAction: (selectedRows) => {
                  setSelectedUserStatus(
                    selectedRows[0].original.is_banned ? 'Activate' : 'Ban'
                  );
                  setPopup(
                    <AdminUsersActionPopup
                      action={
                        selectedRows[0].original.is_banned ? 'activate' : 'ban'
                      }
                      users={selectedRows.map((row: any) => row.original)}
                    />
                  );
                }
              }}
              rowHeight={94}
              paginationProps={{
                activePage: page,
                setActivePage: setPage,
                pageSize: pageSize,
                totalCount: data.total_results as number,
                siblingCount: 1
              }}
            />
          )}
          {error && <Error error={error} />}
          {!hasData && !isLoading && !error && <NoResults />}
        </div>
      )}
    </div>
  );
};

export default AdminUsers;
