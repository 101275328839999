import { Helmet } from 'react-helmet-async';
import { REACT_APP_SEO_LARGE_IMAGE } from 'utils/constants';

interface ISEOProps {
  type: 'website' | 'profile' | 'product';
  title?: string;
  desc: string;
  image?: string;
  creatorName?: string;
}

const defaultSeoTitle = 'Opus Continuing Education ';
const defaultSeoDescription = 'Expand your skills and knowledge with OpusEDU';

const SEO = ({ type, title, desc, image, creatorName }: ISEOProps) => {
  const titleOptimisedForSEO = !!title
    ? `${title.slice(0, 59)} - ${defaultSeoTitle}`
    : defaultSeoTitle;
  const descOptimisedForSEO = desc.slice(0, 150);

  return (
    <Helmet defaultTitle={titleOptimisedForSEO}>
      {/* Standard metadata tags */}
      <title>{titleOptimisedForSEO}</title>
      <meta name="description" content={desc} />
      {/* End standard metadata tags */}

      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={titleOptimisedForSEO} />
      <meta property="og:description" content={descOptimisedForSEO} />
      <meta property="og:site_name" content={titleOptimisedForSEO} />
      <meta property="og:image" content={REACT_APP_SEO_LARGE_IMAGE} />
      <meta property="og:url" content={window.location.href} />
      {/* End Facebook tags */}

      {/* Twitter tags */}
      {!!creatorName && <meta name="twitter:creator" content={creatorName} />}
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={titleOptimisedForSEO} />
      <meta name="twitter:description" content={descOptimisedForSEO} />
      <meta name="twitter:site" content={titleOptimisedForSEO} />
      <meta name="twitter:card" content={REACT_APP_SEO_LARGE_IMAGE} />
      {/* End Twitter tags */}
    </Helmet>
  );
};

SEO.defaultProps = {
  type: 'website',
  desc: defaultSeoDescription
};

export default SEO;
