import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoCourses from 'assets/images/no-courses.svg';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import Error from 'components/error/Error';
import Loader from 'components/loader/Loader';
import GenericPopup from 'components/popup/GenericPopup';
import EmptyState from 'components/emptyState/EmptyState';
import DragAndDrop from './HighlightedDnD';
import {
  highlightCourseMutation,
  reorderCourseMutation
} from 'query/course-module/mutations';
import { adminCoursesDataQuery } from 'query/course-module/queries';
import { ICourse, ICourseData } from 'query/course-module/dto';
import classes from './HighlightedCourses.module.scss';

const Highlighted = () => {
  const navigate = useNavigate();
  const { setPopup, clearPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    adminCoursesDataQuery('?is_highlighted=true&sort=highlight_order')
  );

  const {
    // eslint-disable-next-line
    isLoading: highlightCourseLoading,
    // eslint-disable-next-line
    error: highlightCourseError,
    mutate: highlightCourse
  } = useMutation({
    ...highlightCourseMutation(),
    onSuccess: () => {
      setPopup(
        <GenericPopup
          msg="This course was removed from the highlighted courses!"
          buttonVariant="contrast"
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
      queryClient.invalidateQueries(['admin-courses-data']);
    },
    onError: (e: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const {
    // eslint-disable-next-line
    isLoading: reordereLoading,
    // eslint-disable-next-line
    error: reorderError,
    mutate: reorderCourse
  } = useMutation({
    ...reorderCourseMutation(),
    onSuccess: () => {
      setToast({
        type: 'success',
        msg: 'Highlighted order changed successfully',
        autoClose: true
      });
      queryClient.invalidateQueries(['admin-courses-data']);
    },
    onError: (err: Error) => {
      setToast({
        type: 'error',
        msg: err.message
      });
    }
  });

  const { result: coursesData } = data || {};
  const hasData = !!coursesData?.length;

  if (isLoading) return <Loader />;
  if (error) return <Error error={error} />;

  return (
    <div className={classes['highlighted']}>
      {hasData && (
        <DragAndDrop
          data={coursesData as ICourse[]}
          onOrderChange={reorderCourse}
          onClick={(course: ICourse) =>
            navigate(`/admin/courses/${course.slug}?changes=0`)
          }
          onRemove={(course: ICourse) =>
            highlightCourse({
              id: course._id,
              is_highlighted: false
            })
          }
        />
      )}
      {!hasData && (
        <EmptyState
          title="No Hightlighted Courses"
          message="You have not highlighted any courses."
          icon={NoCourses}
          buttonName="Add Course"
          onClick={() => navigate('/admin/courses')}
        />
      )}
    </div>
  );
};

export default Highlighted;
