import React from 'react';
import AccordionItem from './AccordionItem';
import classes from './Accordion.module.scss';

interface IAccordionProps {
  items: IAccordionItemProps[];
}

export interface IAccordionItemProps {
  title: string;
  content: string | React.ReactNode;
}

const Accordion = ({ items }: IAccordionProps) => {
  return (
    <div className={classes['accordion']}>
      {items.map((item, i) => (
        <div key={i} className={classes['accordion__el']}>
          <AccordionItem title={item.title} content={item.content} />
        </div>
      ))}
    </div>
  );
};

export default Accordion;
