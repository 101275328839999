import React, { useState } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowShort.svg';
import { IAccordionItemProps } from './Accordion';
import classes from './Accordion.module.scss';

const AccordionItem = ({ title, content }: IAccordionItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <div
        className={classes['accordion__title-wrapper']}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={classes['accordion__title']}>{title}</span>
        <ArrowIcon
          className={`${classes['accordion__arrow']} ${
            classes[`accordion__arrow--${isOpen ? 'up' : 'down'}`]
          }`}
        />
      </div>
      <div
        className={`${classes['accordion__content']} ${
          isOpen ? classes['accordion__content--open'] : ''
        }`}
      >
        {content}
      </div>
    </>
  );
};

export default AccordionItem;
