import { useContext, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { OpusTemplateApp } from '@LimeChain/opus-template-app';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import GenericPopup from 'components/popup/GenericPopup';
import { popupContext } from 'context/popupContext';
import { appContext } from 'context/appContext';
import { toastContext } from 'context/toastContext';
import { windowContext } from 'context/windowsContext';
import { ICreateTemplateDto, ITemplateDto } from 'query/course-module/dto';
import { fetchApi } from 'utils/requests';
import { singleTemplateDataQuery } from 'query/course-module/queries';
import {
  REACT_APP_DEFAULT_TEMPLATE_ID,
  REACT_APP_FILES_PUBLIC_DOMAIN
} from 'utils/constants';
import { getBase64FromUrl } from 'utils/processing';

interface ITemplateAppProps {
  isEdit?: boolean;
}

const TemplateApp = ({ isEdit }: ITemplateAppProps) => {
  const { state: locationState } = useLocation();
  const { theme } = useContext(appContext);
  const { setPopup, setPopupLoading } = useContext(popupContext);
  const { setToast, toastData } = useContext(toastContext);
  const { windowSize } = useContext(windowContext);
  const isMobile = windowSize.isLgMobile;

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { id } = useParams();

  const [resetFormData, setResetFormData] = useState(false);
  // NOTE: Use if preview course, student name, etc fields are used
  // const [previewCourse, setPreviewCourse] = useState('');

  /* GET: Template data */
  const {
    isLoading: templateIsLoading,
    error: templateError,
    data: templateData
  } = useQuery<boolean, Error, ITemplateDto>({
    ...(isEdit
      ? singleTemplateDataQuery(id as string)
      : singleTemplateDataQuery(REACT_APP_DEFAULT_TEMPLATE_ID))
  });

  /* MUTATION: Create template */
  const { isLoading: createIsLoading, mutate: createTemplateHandler } =
    useMutation({
      mutationKey: ['create-template'],
      mutationFn: async (data: ICreateTemplateDto) => {
        let prepData = { ...data };
        // When creating a new template, overwrite the background value fetched
        // from the BE with the base64 of the default template background
        if (prepData.background?.includes(REACT_APP_DEFAULT_TEMPLATE_ID)) {
          const defaultTemplateInBase64 = await getBase64FromUrl(
            `${REACT_APP_FILES_PUBLIC_DOMAIN}template/${templateData?._id}.png`
          );
          prepData.background = defaultTemplateInBase64;
        }
        return await fetchApi('courses', '/template', {
          method: 'POST',
          auth: true,
          data: prepData
        });
      },
      onSuccess: (data: { id: string }) => {
        queryClient.refetchQueries(['templates-data']);
        setPopup(
          <GenericPopup
            msg="You have successfully created a certificate template!"
            buttonAction={() => {
              if (!!locationState?.savedFormData) {
                return navigate('/courses/create', {
                  state: {
                    savedFormData: {
                      ...locationState.savedFormData,
                      template_id: [
                        {
                          label: 'The newly created template',
                          value: data.id
                        }
                      ],
                      step: 3
                    }
                  }
                });
              } else return navigate('/user/created-courses/certificates');
            }}
            buttonName={
              !!locationState?.savedFormData
                ? `Go back to ${locationState.savedFormData.prevRoute.name}`
                : 'Go to certificates'
            }
          />
        );
        setResetFormData(true);
      },
      onError: (err: Error) => {
        setPopup(<GenericPopup type="error" msg={err.message} />);
      }
    });

  /* MUTATION: Edit template */
  const { isLoading: editIsLoading, mutate: editTemplateHandler } = useMutation(
    {
      mutationKey: ['edit-template', { id: templateData?._id }],
      mutationFn: async (data: ITemplateDto) => {
        setPopupLoading(true);
        const checkedData = { ...data };
        if (checkedData?.background?.includes('https://')) {
          delete checkedData.background;
        }
        // data?._id is only used in the create flow and it is populated
        // from the response of a successful duplicate query.
        const id = data?._id || templateData?._id;
        return await fetchApi('courses', `/template/${id}`, {
          method: 'PUT',
          auth: true,
          data: checkedData
        });
      },
      onSuccess: (data: { _id: string }) => {
        setPopupLoading(false);
        queryClient.invalidateQueries({
          queryKey: ['single-template', { id: templateData?._id }]
        });
        queryClient.refetchQueries(['templates-data']);
        setPopup(
          <GenericPopup
            msg="A certificate template was successfully edited!"
            buttonAction={() => navigate('/user/created-courses/certificates')}
            buttonName="Go to certificates"
          />
        );
      },
      onError: (err: Error) => {
        setPopupLoading(false);
        setPopup(<GenericPopup type="error" msg={err.message} />);
      }
    }
  );

  // MUTATION: Delete template
  const {
    isLoading: deleteIsLoading,
    // eslint-disable-next-line
    error: deleteError,
    mutate: deleteMutation
  } = useMutation({
    mutationKey: ['delete-template', { id: templateData?._id }],
    mutationFn: async (id: string) => {
      setPopupLoading(true);
      await fetchApi('courses', `/template/${id}`, {
        method: 'DELETE',
        auth: true
      });
    },
    onSuccess: () => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg="The certificate template was successfully deleted."
          buttonAction={() => navigate('/user/created-courses/certificates')}
          isClosable={false}
        />
      );
      setResetFormData(true);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          title="Something went wrong!"
          msg={e.message}
        />
      );
    }
  });

  const showEditTemplatePopup = (data: ITemplateDto) => {
    setPopup(
      <GenericPopup
        type="info"
        title="Update NFT certificate"
        msg="Do you wish to update the NFT certificate template. NFTs that are already minted won’t be affected."
        buttonName="Update NFT certificate template"
        buttonVariant="contrast"
        buttonAction={() => editTemplateHandler(data)}
        clearPopupOnBtnAction={false}
      />
    );
  };

  const showConfirmDeletePopup = () => {
    setPopup(
      <GenericPopup
        type="info"
        title="Delete Template"
        msg="Are you sure that you want to delete this certificate template?"
        buttonName="Confirm"
        buttonAction={() => deleteMutation(templateData?._id as string)}
        clearPopupOnBtnAction={false}
      />
    );
  };

  if (isMobile) {
    !toastData &&
      setToast({
        type: 'info',
        title: 'Available only on desktop',
        msg: 'The template creator is only available on desktop devices.',
        autoClose: true
      });
    return <Navigate to="/user/created-courses/certificates" />;
  }

  return (
    <OpusTemplateApp
      projectId={2}
      isEdit={isEdit}
      onSubmit={isEdit ? showEditTemplatePopup : createTemplateHandler}
      onDelete={() => showConfirmDeletePopup()}
      // NOTE: Use if preview course, student name, etc fields are used
      // onGetCourseStudents={(courseId: string) => setPreviewCourse(courseId)}
      templateQuery={{
        isLoading: templateIsLoading,
        error: templateError,
        data: templateData
      }}
      // Not used for Opus Edu
      courseQuery={{
        isLoading: false,
        error: null,
        data: null
      }}
      // Not used for Opus Edu
      studentsQuery={{
        isLoading: false,
        error: null,
        data: null
      }}
      theme={theme as 'light' | 'dark'}
      isMutationLoading={createIsLoading || editIsLoading || deleteIsLoading}
      resetFormData={resetFormData}
      setResetFormData={(reset: boolean) => setResetFormData(reset)}
      // NOTE: Use if preview course, student name, etc fields are used
      // onClearPreviewCourse={() => setPreviewCourse('')}
      showDeleteBtn={isEdit}
      filesDomain={REACT_APP_FILES_PUBLIC_DOMAIN}
    />
  );
};

export default TemplateApp;
