import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Actions from 'routes/dashboard/profile/components/Actions';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Table from 'components/table/Table';
import { IOption } from 'components/input/InputChoice';
import NoResults from 'components/noResults/NoResults';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { AdminTaxReportCols } from 'components/table/AdminTaxReportCols';
import { adminReportQuery } from 'query/course-module/queries';
import {
  IAdminTaxReport,
  IFilterData,
  IFilterOptions,
  IReport
} from 'query/course-module/dto';
import classes from './AdminTaxReport.module.scss';

const countriesFilter: IFilterData = {
  filterName: 'Country',
  filterOptions: []
};

const statesFilter: IFilterData = {
  filterName: 'State',
  filterOptions: []
};

const AdminTaxReport = () => {
  const { search: urlParams } = useLocation();
  const {
    startDate: startDateParam,
    endDate: endDateParam,
    country,
    state
  } = queryString.parse(urlParams);

  const [countriesValue, setCountriesValue] = useState<IOption[]>(
    countriesFilter.filterOptions?.filter(
      (option: IFilterOptions) => country?.includes(option.value as string)
    )
  );
  const [statesValue, setStatesValue] = useState<IOption[]>(
    statesFilter.filterOptions?.filter(
      (option: IFilterOptions) => state?.includes(option.value as string)
    )
  );
  const [dateRange, setDateRange] = useState<any[]>([
    startDateParam ? new Date(startDateParam as string) : '',
    endDateParam ? new Date(endDateParam as string) : ''
  ]);
  const [startDateRange, endDateRange] = dateRange;

  const queryParams = `?group_by=user_country${
    !!country ? `&locations_list=${country}` : ''
  }${
    startDateRange && endDateRange
      ? `&buy_tx_confirmed_at[start]=${new Date(
          startDateRange
        ).getTime()}&buy_tx_confirmed_at[end]=${new Date(
          endDateRange
        ).getTime()}`
      : ''
  }`;

  const { isLoading: reportLoading, data: reportData } = useQuery<
    boolean,
    Error,
    IAdminTaxReport
  >({
    ...adminReportQuery('countries-query', queryParams),
    onSuccess: (data: IAdminTaxReport) => {
      if (!countriesFilter.filterOptions.length) {
        // Update country filter options only on the first request
        countriesFilter.filterOptions =
          data?.available_countries?.map((countryName) => ({
            label: countryName,
            value: countryName
          })) || [];
        // Update states filter options
        const filteredData = data?.result?.filter(
          (report: IReport) => report._id === 'US'
        )[0];
        statesFilter.filterOptions =
          filteredData?.available_states?.sort().map((state: string) => ({
            label: state,
            value: state
          })) || [];
        setCountriesValue(
          countriesFilter?.filterOptions?.filter(
            (option: IFilterOptions) =>
              country?.includes(option.value as string)
          )
        );
        setStatesValue(
          statesFilter?.filterOptions?.filter(
            (option: IFilterOptions) => state?.includes(option.value as string)
          )
        );
      }
    }
  });

  const statesQueryParams = `?group_by=user_state${
    !!state ? `&locations_list=${state}` : ''
  }${
    startDateRange && endDateRange
      ? `&buy_tx_confirmed_at[start]=${new Date(
          startDateRange
        ).getTime()}&buy_tx_confirmed_at[end]=${new Date(
          endDateRange
        ).getTime()}`
      : ''
  }`;

  const { isLoading: statesLoading, data: statesData } = useQuery<
    boolean,
    Error,
    IAdminTaxReport
  >(adminReportQuery('states-query', statesQueryParams));

  const filterHandler = (value: IOption[], name: string) => {
    if (!name) {
      setCountriesValue([]);
      setStatesValue([]);
      return;
    }
    if (name === 'Country') {
      setCountriesValue(value);
      return;
    }
    if (name === 'State') {
      setStatesValue(value);
      return;
    }
  };

  const taxReportCountryCols = useMemo(
    () => AdminTaxReportCols({ type: 'country' }),
    []
  );
  const taxReportStateCols = useMemo(
    () => AdminTaxReportCols({ type: 'state' }),
    []
  );

  const hasData = !!reportData?.result?.length;

  // Show states only if the selected country is US
  const displayedFilters =
    !!country && country === 'US'
      ? [
          { ...countriesFilter, filterValue: countriesValue },
          { ...statesFilter, filterValue: statesValue }
        ]
      : [{ ...countriesFilter, filterValue: countriesValue }];

  return (
    <div className={classes['admin-tax-report']}>
      <div className={classes['admin-tax-report__actions-wrapper']}>
        <Actions
          hasFilter
          showFilterMenu
          hideFilterButton
          filters={displayedFilters}
          setDateRange={setDateRange}
          passFilterValueUp={filterHandler}
          filterEndDateRange={endDateRange}
          filterStartDateRange={startDateRange}
        />
      </div>
      <div className={classes['admin-tax-report__tables-wrapper']}>
        {hasData && (
          <>
            <Table
              columns={taxReportCountryCols}
              data={reportData?.result as IReport[]}
            />
            {!!country && country === 'US' && !!statesData?.result?.length && (
              <Table
                columns={taxReportStateCols}
                data={statesData?.result as IReport[]}
              />
            )}
          </>
        )}
        {reportLoading && (
          <TableSkeleton size="sm" cols={taxReportCountryCols.length} />
        )}
        {statesLoading && (
          <TableSkeleton size="sm" cols={taxReportStateCols.length} />
        )}
      </div>
      {!hasData && !reportLoading && (
        <div className={classes['admin-tax-report__no-results']}>
          <NoResults />
        </div>
      )}
    </div>
  );
};

export default AdminTaxReport;
