import classes from './NavButton.module.scss';

interface INavButton {
  name: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconRotate?: number;
  iconPosition?: 'left' | 'right';
  iconFill?: 'primary' | 'content';
  active: boolean;
  onClick?: () => void;
  className?: string;
}

const NavButton = ({
  name,
  icon,
  iconRotate,
  iconPosition,
  iconFill,
  active,
  onClick,
  className
}: INavButton) => {
  const Icon = icon;
  const renderIcon = () =>
    !!Icon && (
      <Icon
        className={classes[`icon--${iconFill}`]}
        width={24}
        height={24}
        style={{
          transform: iconRotate ? `rotate(${iconRotate}deg)` : ''
        }}
      />
    );
  return (
    <div
      className={`
      ${classes['btn']} 
      ${active ? classes['btn--active'] : ''} 
      ${iconPosition === 'right' ? classes['btn--space-between'] : ''}
      ${className}`}
      onClick={onClick}
    >
      {!!Icon && iconPosition === 'left' && renderIcon()}
      <span>{name}</span>
      {!!Icon && iconPosition === 'right' && renderIcon()}
    </div>
  );
};

NavButton.defaultProps = {
  iconPosition: 'left',
  iconFill: 'primary'
};

export default NavButton;
