import React from 'react';
import { IInputWrapperProps } from './InputWrapper';
import { validateAndOnChange } from './InputField';
import classes from './InputWrapper.module.scss';

interface ITextareaProps
  extends Pick<
    IInputWrapperProps,
    | 'name'
    | 'variant'
    | 'onChange'
    | 'value'
    | 'isDisabled'
    | 'autoFocus'
    | 'placeholder'
    | 'error'
    | 'limit'
  > {
  onFocus?: () => void;
  onBlur: (e: React.FocusEvent<HTMLElement>) => void;
  isNotEmpty?: boolean;
}

export const Textarea = ({
  name,
  variant,
  onFocus,
  onBlur,
  onChange,
  value,
  isDisabled,
  isNotEmpty,
  autoFocus,
  placeholder,
  error,
  limit
}: ITextareaProps) => {
  return (
    <>
      <div className={classes['input__field-wrapper']}>
        <textarea
          name={name}
          className={`
          ${classes['input__field']} 
          ${classes['input__field--textarea']} 
          ${classes[`input__field--${variant}`]}
          ${isNotEmpty ? classes['input-not-empty'] : ''}
          ${!!error ? classes['input__field--error'] : ''}
        `}
          onBlur={(e) => onBlur(e)}
          onChange={(e) => validateAndOnChange(e.target.value, onChange, limit)}
          disabled={isDisabled}
          value={value as string}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onFocus={onFocus}
          rows={5}
        />
      </div>
      {!!error && <div className={classes['input__error']}>{error}</div>}
    </>
  );
};

Textarea.defaultProps = {
  variant: 'neutral',
  value: '',
  isDisabled: false,
  autoFocus: false,
  placeholder: '',
  error: ''
};
