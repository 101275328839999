import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-toast.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { appContext } from './appContext';
import Toast from 'components/toast/Toast';
import { colors } from 'utils/styles';

export interface IToastData {
  type: 'info' | 'success' | 'warning' | 'error';
  position?: 'top' | 'bottom';
  title?: string;
  msg: string;
  linkMsg?: string;
  linkTo?: string;
  autoClose?: boolean;
  keepOnPathChange?: boolean;
}

const initialState = {
  toastData: {
    type: 'info',
    position: 'top',
    title: '',
    msg: '',
    linkMsg: '',
    linkTo: '',
    autoClose: true,
    keepOnPathChange: false
  } as IToastData,
  setToast: (type: IToastData | null) => {},
  clearToast: () => {}
};

interface IAppCtx {
  toastData: IToastData | null;
  setToast: (type: IToastData) => void;
  clearToast: () => void;
}

export const toastContext = createContext<IAppCtx>(initialState);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const { theme } = useContext(appContext);

  const toastIcon = useMemo(
    () => ({
      info: (
        <InfoIcon
          style={{ display: 'flex', flexShrink: '0' }}
          fill={theme === 'dark' ? colors.white10 : colors.black100}
        />
      ),
      success: (
        <CheckIcon
          style={{ display: 'flex', flexShrink: '0' }}
          fill={colors.success40}
        />
      ),
      error: (
        <CloseIcon
          style={{ display: 'flex', flexShrink: '0' }}
          fill={colors.error40}
        />
      ),
      warning: (
        <WarningIcon
          style={{ display: 'flex', flexShrink: '0' }}
          fill={colors.warning40}
        />
      )
    }),
    [theme]
  );

  const setToastHandler = useCallback(
    (toastData: IToastData) => {
      toast[toastData.type](
        <Toast
          type={toastData.type}
          title={toastData.title}
          msg={toastData.msg}
          linkMsg={toastData.linkMsg}
          linkTo={toastData.linkTo}
          position={toastData.position}
        />,
        {
          toastId: toastData.msg,
          autoClose: toastData.autoClose ? 5000 : false,
          position: `${toastData.position || 'top'}-right`,
          theme: theme,
          icon: () => toastIcon[toastData.type]
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const clearToast = () => {
    toast.dismiss();
  };

  return (
    <toastContext.Provider
      value={{ toastData: null, setToast: setToastHandler, clearToast }}
    >
      {children}
    </toastContext.Provider>
  );
}
