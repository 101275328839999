import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import Button from 'components/buttons/Button';
import classes from './PopupTitleAndMessage.module.scss';

interface IPopupTitleAndMessage {
  title: string;
  msg?: string;
  hasBackBtn?: boolean;
  onBackBtnClick?: () => void;
}

const PopupTitleAndMessage = ({
  title,
  msg,
  hasBackBtn,
  onBackBtnClick
}: IPopupTitleAndMessage) => {
  return (
    <div className={classes['wrapper']}>
      {hasBackBtn && (
        <Button
          onClick={onBackBtnClick}
          variant="link-neutral"
          size="md"
          isIconBtn
          icon={ArrowIcon}
          iconRotate={180}
        />
      )}
      <h5
        className={`${classes['u-text--primary']} ${classes['u-bold']} ${
          classes['u-text--medium']
        } ${hasBackBtn ? classes['u-text--center'] : ''}`}
      >
        {title}
      </h5>
      {msg && (
        <p
          className={`${classes['u-text--content']} ${classes['u-text--small']}`}
        >
          {msg}
        </p>
      )}
    </div>
  );
};

export default PopupTitleAndMessage;
