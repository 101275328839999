import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import { InputWrapper } from 'components/input/InputWrapper';
import { InputMedia } from 'components/input/InputMedia';
import Button from 'components/buttons/Button';
import { IEditProfileFormFields, EditProfileSchema } from 'utils/yupSchemas';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import { handleMediaUpload } from 'utils/processing';
import { popupContext } from 'context/popupContext';
import GenericPopup from 'components/popup/GenericPopup';
import { renderPic } from 'utils/format';
import { editProfileMutation } from 'query/acc-module/mutations';
import { userContext } from 'context/userContext';
import classes from './Form.module.scss';

const initialValues: IEditProfileFormFields = {
  first_name: '',
  last_name: '',
  about: '',
  pic: '',
  banner: ''
};

const EditProfileForm = () => {
  const { setPopup, clearPopup } = useContext(popupContext);
  const { userData, userDataIsLoading, userDataError } =
    useContext(userContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading: submitIsLoading, mutate: submitHandler } = useMutation({
    ...editProfileMutation(),
    onSuccess: () => {
      setPopup(
        <GenericPopup
          title="Profile Updated!"
          msg="Your profile info has been updated"
          buttonName="Go To Profile"
          buttonAction={() => navigate('/user')}
        />
      );
      queryClient.invalidateQueries({ queryKey: ['me-data'] });
    },
    onError: (e: Error) =>
      setPopup(<GenericPopup type="error" title="Error" msg={e.message} />)
  });

  return (
    <div className={classes['form']}>
      {userDataIsLoading && <Loader />}
      {userDataError && <Error error={userDataError} />}
      {userData.email && (
        <Formik
          initialValues={{
            ...initialValues,
            first_name: userData.first_name,
            last_name: userData.last_name,
            pic: userData.pic as string,
            about: userData.additional_info
              ? userData.additional_info.about
              : '',
            banner: userData.additional_info
              ? userData.additional_info.banner
              : ''
          }}
          validationSchema={EditProfileSchema}
          onSubmit={(values) => {
            if (submitIsLoading) return;
            submitHandler(values);
          }}
          validateOnMount
        >
          {({
            errors,
            touched,
            values,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            submitForm,
            handleBlur,
            isValid
          }) => (
            <Form className={classes['form']}>
              <InputWrapper
                name="first_name"
                type="text"
                variant="outline"
                onChange={(e) => setFieldValue('first_name', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.first_name}
                title="First Name"
                placeholder="Enter First Name"
                hasTooltip
                tooltipText="Fill in your first name."
                isRequired
                error={
                  errors.first_name && touched.first_name
                    ? errors.first_name
                    : ''
                }
                limit={50}
                subtitle={`${values.first_name?.length} of 50 characters used`}
              />
              <InputWrapper
                name="last_name"
                type="text"
                variant="outline"
                onChange={(e) => setFieldValue('last_name', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.last_name}
                title="Last Name"
                placeholder="Enter Last Name"
                hasTooltip
                tooltipText="Fill in your last name."
                isRequired
                error={
                  errors.last_name && touched.last_name ? errors.last_name : ''
                }
                limit={50}
                subtitle={`${values.last_name?.length} of 50 characters used`}
              />
              <InputWrapper
                name="about"
                type="textarea"
                variant="outline"
                onChange={(e) => setFieldValue('about', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.about}
                title="About"
                subtitle={`${values.about?.length || 0} of 150 characters used`}
                placeholder="Enter About"
                hasTooltip
                tooltipText="Fill in your about info."
                error={errors.about && touched.about ? errors.about : ''}
                limit={150}
              />
              <InputMedia
                name="pic"
                title="Profile Picture"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleMediaUpload(
                    e,
                    'pic',
                    setFieldValue,
                    setFieldTouched,
                    setFieldError
                  );
                }}
                onClear={() =>
                  setPopup(
                    <GenericPopup
                      type="warning"
                      title="Confirm Action"
                      msg="Do you want to delete your profile picture?"
                      buttonAction={() => setFieldValue('pic', '')}
                      buttonName="Confirm"
                      bellowBtnComp={
                        <Button
                          variant="link-neutral"
                          minWidth="md"
                          withPadding={false}
                          onClick={clearPopup}
                        >
                          Cancel
                        </Button>
                      }
                    />
                  )
                }
                pic={renderPic(values.pic)}
                error={errors.pic && touched.pic ? errors.pic : ''}
                hasTooltip
                tooltipText="Select your profile picture."
                isSquare
              />
              <InputMedia
                name="banner"
                title="Banner Image"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleMediaUpload(
                    e,
                    'banner',
                    setFieldValue,
                    setFieldTouched,
                    setFieldError
                  );
                }}
                onClear={() =>
                  setPopup(
                    <GenericPopup
                      type="warning"
                      title="Confirm Action"
                      msg="Do you want to delete your banner image?"
                      buttonAction={() => setFieldValue('banner', '')}
                      buttonName="Confirm"
                      bellowBtnComp={
                        <Button
                          variant="link-neutral"
                          minWidth="md"
                          withPadding={false}
                          onClick={clearPopup}
                        >
                          Cancel
                        </Button>
                      }
                    />
                  )
                }
                pic={renderPic(values.banner)}
                error={errors.banner && touched.banner ? errors.banner : ''}
                hasTooltip
                tooltipText="Select your profile banner image."
              />
              <div
                className={`${classes['btns-wrapper']} ${classes['btns-wrapper--single']}`}
              >
                <Button
                  type="submit"
                  isDisabled={!isValid}
                  isLoading={submitIsLoading}
                  icon={CheckIcon}
                  iconPosition="left"
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditProfileForm;
