import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransactionReceipt } from 'ethers';
import { useAccount } from 'wagmi';
import { useMutation } from '@tanstack/react-query';
import { Formik, useFormikContext } from 'formik';
import matic from 'assets/images/matic.svg';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Shield } from 'assets/icons/shield.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Pen } from 'assets/icons/edit.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { useEthersSigner } from 'hooks/useEthersSigner';
import useCurrencyContract from 'hooks/useCurrencyContract';
import useComponentVisible from 'hooks/useComponentVisible';
import { useQuote } from 'hooks/useQuote';
import { paymentContract, getContractInstance } from 'contracts/contracts';
import { appContext } from 'context/appContext';
import { Web3Context } from 'context/web3Context';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import { windowContext } from 'context/windowsContext';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import InfoBox from 'components/info-box/InfoBox';
import GenericPopup from 'components/popup/GenericPopup';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { InputWrapper } from 'components/input/InputWrapper';
import { InputGroup } from 'components/input/InputGroup';
import Loader from 'components/loader/Loader';
import SwitchInput from 'components/buttons/SwitchInput';
import Tooltip from 'components/tooltip/Tooltip';
import CopyButton from 'components/buttons/CopyButton';
import {
  COURSE_RELATIONSHIP,
  IBuyCourseDto,
  ICourse,
  ITokenPairsData
} from 'query/course-module/dto';
import {
  buyCourseMutation,
  rejectBuyMutation
} from 'query/course-module/mutations';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_CURRENCY_CONTRACT_ADDRESS,
  REACT_APP_DEFAULT_SLIPPAGE
} from 'utils/constants';
import {
  formatCurrencyAmount,
  formatPrice,
  formatTokenDecimals
} from 'utils/format';
import { submitTx } from 'utils/requests';
import { IGiftRecepientsFields, IGiftRecepientsSchema } from 'utils/yupSchemas';
import classes from './BuyActivationPopup.module.scss';

interface IBuyActivationPopupProps {
  data: ICourse;
  tokenPairs: ITokenPairsData;
  isBuyAsGiftOnly?: boolean;
}

interface IBuyAsGiftForm {
  passGiftReceiversUp: (receivers: string[]) => void;
  passHasInvalidAddressUp: (areValid: boolean) => void;
}

const BuyAsGiftForm = ({
  passGiftReceiversUp,
  passHasInvalidAddressUp
}: IBuyAsGiftForm) => {
  const { values, setFieldValue, errors } =
    useFormikContext<IGiftRecepientsFields>();

  useEffect(
    () => {
      passGiftReceiversUp(values.giftReceivers);
      passHasInvalidAddressUp(
        !!errors.giftReceivers ||
          !values.giftReceivers.filter((receiver) => !!receiver).length
      );
    },
    // eslint-disable-next-line
    [values.giftReceivers, errors.giftReceivers]
  );

  return (
    <>
      {values.giftReceivers.map((receiver: string, i: number) => (
        <InputGroup
          key={i}
          className={
            classes[
              `group${
                !!errors.giftReceivers && !!errors?.giftReceivers[i]
                  ? '--error'
                  : ''
              }`
            ]
          }
          type="2-1"
        >
          <InputWrapper
            name={`receiver-${i}`}
            title={`Wallet Address ${i + 1}`}
            placeholder="Enter Wallet Address"
            value={values.giftReceivers[i]}
            onChange={(e) => {
              const receivers = [...values.giftReceivers];
              receivers[i] = e;
              setFieldValue('giftReceivers', receivers);
            }}
            error={
              !!errors.giftReceivers?.length ? errors.giftReceivers[i] : ''
            }
          />
          <Button
            variant="neutral"
            isIconBtn
            icon={Trash}
            onClick={() => {
              const receivers = [...values.giftReceivers];
              receivers.splice(i, 1);
              setFieldValue('giftReceivers', receivers);
            }}
          />
        </InputGroup>
      ))}
      <Button
        className={classes['btn']}
        variant="neutral"
        size="sm"
        icon={Plus}
        iconPosition="left"
        onClick={() =>
          setFieldValue('giftReceivers', [...values.giftReceivers, ''])
        }
        minWidth="sm"
      >
        Add More
      </Button>
    </>
  );
};

const BuyActivationPopup = ({
  data,
  tokenPairs,
  isBuyAsGiftOnly
}: IBuyActivationPopupProps) => {
  // Token pair options for select component
  const tokenPairsOptions = [
    {
      label: tokenPairs.default.tokenName,
      value: tokenPairs.default.tokenAddress,
      poolFee: tokenPairs.default.poolFee,
      symbol: tokenPairs.default.tokenLogo || matic,
      decimals: tokenPairs.default.tokenDecimals
    },
    ...tokenPairs.tokens.map((token) => ({
      label: token.tokenName,
      value: token.tokenAddress,
      poolFee: token.poolFee,
      symbol: token.tokenLogo || matic,
      decimals: token.tokenDecimals
    }))
  ];
  const { setToast } = useContext(toastContext);
  const { clearPopup, setPopup } = useContext(popupContext);
  const {
    windowSize: { isSmMobile }
  } = useContext(windowContext);
  const { increasePendingTransactions, decreasePendingTransactions } =
    useContext(Web3Context);
  const { signer } = useEthersSigner();
  const { address } = useAccount();

  const { course_relationship, price: coursePrice } = data;

  // Buy as a gift states
  const [isBuyGift, setIsBuyGift] = useState(
    !!data?.purchased?.activate_tx_started_at || isBuyAsGiftOnly
  );
  const [giftReceivers, setGiftReceivers] = useState(['']);
  const [hasInvalidAddress, setHasInvalidAddress] = useState(true);

  // Slippage-related states
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  // TODO: show slippage options if a tx fails due to slippage
  const [showSlippageBtn, setShowSlippageBtn] = useState(false);
  const [slippage, setSlippage] = useState<number>(REACT_APP_DEFAULT_SLIPPAGE);
  const [isSlipInputEnabled, setIsSlipInputEnabled] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState(
    tokenPairsOptions[0]
  );

  // USDC contract info
  const { decimals: usdcDecimals } = useCurrencyContract(
    REACT_APP_CURRENCY_CONTRACT_ADDRESS
  );
  // Selected currency contract info
  const { balance, allowance, refetchAllowance, isFetching, balanceFormatted } =
    useCurrencyContract(selectedCurrency.value);

  // Course price in USDC, in wei
  const requiredAmountOut = !!usdcDecimals
    ? +coursePrice * Math.pow(10, usdcDecimals)
    : 0;

  // Amount of selected currency in decimal
  const { amountIn, amountInFormatted, quoteIsLoading } = useQuote(
    selectedCurrency,
    requiredAmountOut
  );

  // Calculate pair rate
  const pairRate = useMemo(
    () => {
      if (quoteIsLoading) return <Loader size="sm" />;
      return (
        <div className={classes['swap__info__pairs']}>
          1 {selectedCurrency.label}
          <CopyButton text={selectedCurrency.value} size="sm" />
          {formatTokenDecimals(+coursePrice / amountInFormatted, true)}
          {tokenPairs.default.tokenName}
          <CopyButton text={tokenPairs.default.tokenAddress} size="sm" />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amountInFormatted, quoteIsLoading]
  );

  const isAllowanceSufficient = BigInt(allowance) > BigInt(amountIn);
  const isBalanceSufficient =
    !!balance && !!amountIn
      ? // When isBuyGift, calculate if the user's balance is sufficient
        isBuyGift
        ? BigInt(balance) >= BigInt(amountIn) * BigInt(giftReceivers.length)
        : // When !isBuyGift, compare balance to amountIn
          BigInt(balance) >= BigInt(amountIn)
      : '';
  const isBuyBtnDisabled =
    // quote or token data is fetching --> disable btn
    quoteIsLoading || isFetching
      ? true
      : // If balance is not sufficient --> disable btn
      !isBalanceSufficient
      ? true
      : // required to increase allowance --> enable btn
      !isAllowanceSufficient
      ? false
      : isBuyGift && hasInvalidAddress
      ? true
      : false;

  const price = isBuyGift ? +data.price * giftReceivers.length : +data.price;
  const currency = data.currency.toLocaleUpperCase();
  const {
    platformSettings: { platform_fee }
  } = useContext(appContext);
  const platformEarning = (platform_fee / 100) * price;

  const {
    isLoading: approveIsLoading,
    // eslint-disable-next-line
    error: approveError,
    mutate: approveHandler
  } = useMutation({
    mutationKey: ['approve'],
    mutationFn: async () => {
      // Currency Contract Instance
      const contract = getContractInstance(
        'currency',
        signer,
        selectedCurrency.value
      );

      // Request max allowance
      const allowance = BigInt(Math.pow(2, 255));
      const txData = [paymentContract.address, allowance];

      increasePendingTransactions();
      await submitTx(contract, 'approve', txData);
    },
    onSuccess: () => {
      setToast({
        type: 'success',
        title: 'Successful Transaction',
        msg: 'Spending funds was approved.',
        position: 'top',
        autoClose: true
      });
      refetchAllowance();
      decreasePendingTransactions();
    },
    onError: (e: any) => {
      decreasePendingTransactions();
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  const {
    isLoading,
    // eslint-disable-next-line
    error,
    mutate: purchaseCourseHandler
  } = useMutation({
    mutationKey: ['purchase-course'],
    mutationFn: async () => {
      let filteredRecepients = giftReceivers.filter((receiver) => !!receiver);
      const recepients = isBuyGift ? filteredRecepients : [address as string];

      const amount = isBuyGift
        ? BigInt(amountIn) * BigInt(+recepients.length)
        : amountIn;

      const addedSlippageValue =
        (BigInt(amount) * BigInt(slippage)) / BigInt(100);

      const amountWithSlippage = (
        slippage > 3 ? BigInt(amount) + addedSlippageValue : amount
      ).toString();

      // Prepare data for BE
      const prepDate: IBuyCourseDto = {
        buy_data: {
          recepients: recepients
        },
        payment_data: {
          token: selectedCurrency.value,
          amount: amountWithSlippage,
          allowedSlippage: slippage,
          sqrtPriceLimitX96: 0,
          fee: selectedCurrency.poolFee
        }
      };

      increasePendingTransactions();
      const response = await buyCourseMutation(data._id, prepDate).mutationFn();
      const contract = getContractInstance('payment', signer);

      const txData = [
        response.buyData.itemId,
        response.paymentData,
        response.buyData.recepients,
        response.sigExpiry,
        response.signature
      ];

      const receipt = await submitTx(contract, 'buy', txData);
      return receipt as TransactionReceipt;
    },
    onSuccess: (receipt: TransactionReceipt) => {
      clearPopup();
      decreasePendingTransactions();
      setToast({
        type: 'success',
        title: 'Successful Transaction',
        msg: 'Successfully purchased. View',
        linkMsg: 'transaction',
        linkTo: `${REACT_APP_BLOCK_EXPLORER_URL}/tx/${receipt.hash}`,
        position: 'top',
        autoClose: true
      });
    },
    onError: async (err: any) => {
      const filteredRecepients = giftReceivers.filter((receiver) => !!receiver);
      const recepients = isBuyGift ? filteredRecepients : [address as string];
      decreasePendingTransactions();
      setToast({
        type: 'error',
        position: 'top',
        title: 'Transaction Failed',
        msg: err.message || '',
        autoClose: true
      });
      await rejectBuyMutation(data._id, recepients).mutationFn();
      if (err.cause !== 4001) {
        setShowSlippageBtn(true);
      }
    }
  });

  useEffect(() => {
    if (!isSlipInputEnabled) return setSlippage(3);
  }, [isSlipInputEnabled]);

  const amount = Number(
    isBuyGift
      ? slippage > 3
        ? ((1 + slippage / 100) * amountInFormatted).toFixed(4)
        : +amountInFormatted * +giftReceivers.length
      : slippage > 3
      ? ((1 + slippage / 100) * amountInFormatted).toFixed(4)
      : amountInFormatted
  );

  return (
    <ModalWrapper size="md">
      <div className={classes['wrapper']}>
        <h4 className={classes['u-semiBold']}>Buy Activation NFT</h4>
        <div className={classes['content']}>
          <div className={classes['swap']}>
            <div className={classes['swap__container']}>
              <div className={classes['swap__container__value']}>
                <h3>
                  {formatCurrencyAmount(amount, selectedCurrency.decimals)}
                </h3>
              </div>
              <div className={classes['swap__container__controls']}>
                <ReactSelectWrapper
                  name="currency"
                  placeholder="Select"
                  isRequired
                  options={tokenPairsOptions}
                  isCurrency
                  isSearchable
                  onChange={setSelectedCurrency}
                  onBlur={(e) => {}}
                  value={selectedCurrency}
                  width={isSmMobile ? 'auto' : '200px'}
                />
                {/* TODO: Show only when a tx fails due to slippage */}
                {showSlippageBtn && (
                  <Button
                    variant="link-contrast"
                    withPadding={false}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                    icon={Pen}
                    iconPosition="left"
                  >
                    Slippage
                  </Button>
                )}
                {isComponentVisible && (
                  <div className={classes['slippage-menu']} ref={ref}>
                    <div className={classes['slippage-menu__row']}>
                      <div
                        className={`${classes['u-text--content']} ${classes['u-flex']} ${classes['u-flex-align-center']} ${classes['u-gap-4']}`}
                      >
                        Default Slippage
                        <Tooltip
                          id="slippage"
                          text={`Slippage up to ${REACT_APP_DEFAULT_SLIPPAGE}% is covered by the platform. Costs associated with slippage larger than ${REACT_APP_DEFAULT_SLIPPAGE}% is covered by the user.`}
                        >
                          <Question
                            className={`${classes['u-fill-content']} ${classes['u-flex']}`}
                            width={20}
                            height={24}
                          />
                        </Tooltip>
                      </div>
                      <div className={classes['u-bold']}>
                        {REACT_APP_DEFAULT_SLIPPAGE}%
                      </div>
                    </div>
                    <Divider />
                    <InputGroup type="2-1">
                      <div
                        className={`${classes['u-flex']} ${classes['u-flex-align-center']} ${classes['u-gap-4']}`}
                      >
                        Custom Amount
                        <SwitchInput
                          onClick={(e) =>
                            setIsSlipInputEnabled(!isSlipInputEnabled)
                          }
                          value={isSlipInputEnabled}
                          size="sm"
                        />
                      </div>

                      <InputWrapper
                        variant="outline"
                        type="number"
                        onChange={(e: number) => {
                          if (e >= 0 && e <= 100) {
                            setSlippage(+e);
                          }
                        }}
                        value={slippage}
                        isDisabled={!isSlipInputEnabled}
                      />
                    </InputGroup>
                    {isSlipInputEnabled && (
                      <div className={classes['slippage-menu__warning']}>
                        <Info
                          className={`${classes['u-fill-primary']} ${classes['u-flex']}`}
                          width={20}
                          height={24}
                        />
                        Please note that slippage costs above{' '}
                        {REACT_APP_DEFAULT_SLIPPAGE}% are covered by the user
                        and not the platform.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={classes['swap__info']}>
              <div>
                {selectedCurrency.value !==
                  REACT_APP_CURRENCY_CONTRACT_ADDRESS && <div>{pairRate}</div>}
              </div>
              <div>Balance: {formatPrice(+balanceFormatted)}</div>
            </div>
          </div>
          <div className={classes['content']}>
            <div className={classes['price-box']}>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Course Price</div>
                <div className={classes['price-box__value']}>
                  {formatPrice(+data.price) + ' ' + currency}
                </div>
              </div>
              {isBuyGift && (
                <>
                  <div className={classes['price-box__row']}>
                    <div className={classes['price-box__title']}>
                      Tickets Amount
                    </div>
                    <div className={classes['price-box__value']}>
                      {giftReceivers.length}x
                    </div>
                  </div>
                  <Divider />
                  <div className={classes['price-box__row']}>
                    <div className={classes['price-box__title']}>Total</div>
                    <div className={classes['price-box__value']}>
                      {giftReceivers.length * +coursePrice} USDC
                    </div>
                  </div>
                </>
              )}
              <Divider dir="horizontal" />
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Platform Fee</div>
                <div className={classes['price-box__value']}>
                  {`${platform_fee}%`}
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Platform Earning
                </div>
                <div className={classes['price-box__value']}>
                  {formatPrice(platformEarning) + ' ' + currency}
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Creator Earning
                </div>
                <div className={classes['price-box__value']}>
                  {formatPrice(price - platformEarning) + ' ' + currency}
                </div>
              </div>
            </div>
            <div className={classes['content__gift']}>
              <SwitchInput
                className={classes['switch']}
                size="sm"
                label="Buy as a gift for somebody else."
                value={isBuyGift}
                onClick={() =>
                  course_relationship !== COURSE_RELATIONSHIP.PURCHASED &&
                  setIsBuyGift(!isBuyGift)
                }
              />
              {isBuyGift && (
                <div className={classes['content__gift__receivers']}>
                  <Divider />
                  <div className={classes['content__gift__receivers__inputs']}>
                    <Formik
                      initialValues={{ giftReceivers: giftReceivers }}
                      onSubmit={() => {}}
                      validationSchema={IGiftRecepientsSchema}
                    >
                      <BuyAsGiftForm
                        passGiftReceiversUp={setGiftReceivers}
                        passHasInvalidAddressUp={setHasInvalidAddress}
                      />
                    </Formik>
                  </div>
                </div>
              )}
            </div>
            {course_relationship === COURSE_RELATIONSHIP.NONE && (
              <>
                {!isBalanceSufficient && (
                  <InfoBox
                    type="warning"
                    msg={`You cannot purchase this course, because your ${selectedCurrency.label} balance is insufficient.`}
                  />
                )}
                {!isAllowanceSufficient && isBalanceSufficient && (
                  <InfoBox
                    msg={
                      <div className={classes['allowance-info-box']}>
                        <Shield />
                        <span>
                          You need to first grant access to withdrawal from your
                          personal account by setting the allowance.
                        </span>
                        <Check />
                        <span>Specify the maximum that can be withdrawn.</span>
                      </div>
                    }
                  />
                )}
                {isAllowanceSufficient && isBalanceSufficient && (
                  <InfoBox
                    type="info"
                    msg={
                      <div>
                        Find out more about our{' '}
                        <Link to="/refund-policy" target="_blank">
                          refund policy
                        </Link>
                        .
                      </div>
                    }
                  />
                )}
                {showSlippageBtn && (
                  <InfoBox
                    type="warning"
                    msg={`Your transaction possibly failed due to low slippage. You can try increasing the slippage and submitting it again. Please note that slippage costs above ${REACT_APP_DEFAULT_SLIPPAGE}% are not paid by the platform.`}
                  />
                )}
              </>
            )}
            <Button
              onClick={() =>
                !isAllowanceSufficient
                  ? approveHandler()
                  : purchaseCourseHandler()
              }
              isLoading={isLoading || approveIsLoading}
              variant="contrast"
              minWidth="md"
              isDisabled={isBuyBtnDisabled}
              isWeb3
            >
              {!isBalanceSufficient
                ? 'Insufficient Balance'
                : !isAllowanceSufficient
                ? 'Approve Spending'
                : 'Buy Activation NFT'}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default BuyActivationPopup;
