import { useState, Fragment, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { IUser } from 'context/userContext';
import { popupContext } from 'context/popupContext';
import GenericPopup from './GenericPopup';
import { ModalWrapper } from './common/ModalWrapper';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import { fetchApi } from 'utils/requests';
import { formatNames } from 'utils/format';
import classes from './EmailPopup.module.scss';

interface IEmailPopupProps {
  users: IUser[];
}

const EmailPopup = ({ users }: IEmailPopupProps) => {
  const { setPopup, clearPopup } = useContext(popupContext);

  const [subject, setSubject] = useState('');
  const [msg, setMsg] = useState('');

  const { isLoading, mutate: sendEmail } = useMutation({
    mutationKey: ['admin-send-email'],
    mutationFn: async () => {
      await fetchApi('courses', '/admin/user/emails', {
        method: 'POST',
        auth: true,
        data: {
          id_list: users.map((user) => user._id),
          subject,
          body: msg
        }
      });
    },
    onSuccess: () => {
      setPopup(<GenericPopup title="Email Sent!" icon={<Email />} />);
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          title="Something went wrong!"
          msg={err.message}
        />
      );
    }
  });

  return (
    <ModalWrapper size="md">
      <div className={classes['email']}>
        <div className={classes['email__header']}>
          <h4 className={classes['u-bold']}>Send Email</h4>
        </div>
        <div className={classes['email__subheader']}>
          <span className={classes['u-semiBold']}>To:</span>
          {users.slice(0, 2).map((user, i) => (
            <Fragment key={user._id}>
              {`${formatNames(user.first_name + ' ' + user.last_name)}${
                i === 0 && users.length > 1 ? ', ' : ' '
              }`}
              {i >= 1 && users.length > 2 && (
                <span className={classes['u-semiBold']}>
                  +{users.length - 2} other{users.length > 3 && 's'}.
                </span>
              )}
            </Fragment>
          ))}
        </div>
        <InputWrapper
          variant="outline"
          title="Subject"
          placeholder="Subject"
          value={subject}
          onChange={setSubject}
        />
        <InputWrapper
          variant="outline"
          type="textarea"
          title="Message"
          value={msg}
          onChange={setMsg}
          placeholder="Enter Message"
        />
        <div className={classes['email__btns-container']}>
          <Button
            size="md"
            isDisabled={!msg.length || !subject.length}
            onClick={() => sendEmail()}
            isLoading={isLoading}
          >
            Send Email
          </Button>
          <Button variant="link-neutral" size="md" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default EmailPopup;
