import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Sun } from 'assets/icons/sun.svg';
import { ReactComponent as Moon } from 'assets/icons/moon.svg';
import { userContext } from 'context/userContext';
import { appContext } from 'context/appContext';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import Wallet from 'components/header/wallet/Wallet';
import Button from 'components/buttons/Button';
import Notifications from '../menus/Notifications';
import { UserMenu } from '../menus/UserMenu';
import classes from './HeaderButtons.module.scss';

const HeaderButtons = () => {
  const navigate = useNavigate();
  const { theme, switchTheme } = useContext(appContext);
  const { userData, userDataIsLoading, userDataError, isTokenExpired } =
    useContext(userContext);

  // CASE 1: The userData is in loading state and the access token has expired (or is misssing)
  if ((userDataIsLoading || !userDataIsLoading) && isTokenExpired)
    return (
      <div className={classes['header__buttons__wrapper']}>
        <div
          className={`${classes['header__buttons']} ${classes['header__buttons--equal']}`}
        >
          <Button onClick={() => navigate('/login')} variant="neutral">
            Log In
          </Button>
          <Button onClick={() => navigate('/register')} variant="contrast">
            Sign Up
          </Button>
        </div>
        <Button
          icon={theme === 'dark' ? Sun : Moon}
          onClick={switchTheme}
          variant="neutral"
          isIconBtn
        />
      </div>
    );

  // CASE 2: The userData is loading and there is a valid access token
  // Return loader until the data is fetched successfully
  if (userDataIsLoading && !isTokenExpired)
    return (
      <div
        className={`${classes['header__buttons']} ${
          classes[!userData ? 'header__buttons--equal' : '']
        }`}
      >
        <Loader size="sm" />
      </div>
    );

  if (userDataError) return <Error error={userDataError} />;

  // CASE 3: Data was fetched
  return (
    <div className={classes['header__buttons']}>
      {!!userData && (
        <>
          <UserMenu />
          {!userData.isAdmin && <Notifications />}
          <Wallet />
        </>
      )}
    </div>
  );
};

export default HeaderButtons;
