import { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { popupContext } from 'context/popupContext';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import Button from 'components/buttons/Button';
import { SingleChange } from './SingleChange';
import { FilesChanges } from './FilesChanges';
import { IExtendedResourceVideo } from '../../videos/components/DragAndDrop';
import ExamChanges from './ExamChanges';
import {
  FILE_CHANGE,
  ICourse,
  ISingleCourseVideo,
  IVideoFile
} from 'query/course-module/dto';
import { adminCourseVideoDataQuery } from 'query/course-module/queries';
import { COURSE_STATUS, VIDEO_EDIT_STATUS } from 'utils/constants';
import classes from './CourseChangesMenu.module.scss';

interface IVideoChangesMenuProps {
  course: ICourse;
  video: IExtendedResourceVideo;
}
export const VideoChangesMenu = ({ course, video }: IVideoChangesMenuProps) => {
  const { clearMenu } = useContext(popupContext);
  const isNewCourse = course.status === COURSE_STATUS.DRAFT;
  const isNewVideo = video.edit_status === VIDEO_EDIT_STATUS.NEW;
  const isDeletedVideo = video.edit_status === VIDEO_EDIT_STATUS.DELETED;
  const isNewOrDeletedVideo = isNewVideo || isDeletedVideo;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ISingleCourseVideo
  >({
    ...adminCourseVideoDataQuery(
      course.slug,
      video._id as string,
      isNewCourse ? '?changes=0' : '?changes=2'
    )
  });

  const { files: currentFiles } = video.files[0];

  const changes = useMemo(() => {
    if (!data) return [];

    const fields = {
      path: {
        title: 'Video',
        oldValue: data?.original?.path as string,
        newValue: data.path,
        valueType: 'video'
      },
      title: {
        title: 'Title',
        oldValue: data?.original?.title as string,
        newValue: video.title as string,
        valueType: 'text'
      },
      description: {
        title: 'Description',
        oldValue: data?.original?.description as string,
        newValue: video.description as string,
        valueType: 'text'
      },
      thumbnail: {
        title: 'Thumbnail',
        oldValue: data?.original?.thumbnail as string,
        newValue: video.thumbnail as string,
        valueType: 'image'
      }
    } as any;

    const changedFields: any = [];

    // 1. New or deleted video, there are no changes --> show data.
    if (isNewOrDeletedVideo || isNewCourse) {
      Object.keys(fields).map((key) => {
        return changedFields.push({
          ...fields[key],
          fieldKey: key,
          videoPath: data.path
        });
      });
    }
    // 2. Edited video --> show changes
    else {
      Object.keys(fields).map((key) => {
        if (!!(course?.diffs?.videos_diff[video._id] as any)[key]) {
          return changedFields.push({
            ...fields[key],
            fieldKey: key,
            videoPath: data.path
          });
        }
        return null;
      });
    }
    const returnFields = changedFields.filter(
      (field: any) => field !== undefined
    );

    return returnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fileChanges: IVideoFile[] = useMemo(() => {
    if (!currentFiles) return [];

    // 1. New or deleted video, there are no changes --> show all files.
    if (isNewOrDeletedVideo || isNewCourse) {
      return currentFiles.map((file) => {
        // If video has files changes, show file_change status
        if (video.file_changes) {
          return { ...file, file_change: video.file_changes[file._id] };
        }
        // Newly added files
        return { ...file, file_change: FILE_CHANGE.ADD };
      });
    }

    // 2. Edited video --> show changed files.
    return currentFiles
      .filter((file) => !!video.file_changes[file._id])
      .map((file) => ({
        ...file,
        file_change: video.file_changes[file._id]
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFiles]);

  if (isLoading) return <Loader />;
  if (error) return <Error error={error} />;

  const renderExams = () => {
    if (isNewOrDeletedVideo || isNewCourse)
      return !!video?.exam?.length && <ExamChanges newExam={video.exam} />;

    return (
      !!video?.exam &&
      !!data?.original?.exam &&
      !!course?.diffs?.videos_diff[video._id].exam && (
        <ExamChanges newExam={video.exam} oldExam={data.original.exam} />
      )
    );
  };

  return (
    <div className={classes['menu__wrapper']}>
      <Button
        className={classes['menu__close-btn']}
        variant="neutral"
        isIconBtn
        icon={Cross}
        size="md"
        onClick={clearMenu}
      />
      <h4 className={classes['u-semiBold']}>Updates</h4>
      {changes.map((change: any, i: number) => (
        <SingleChange
          key={i}
          change={change}
          allChanges={changes}
          isFocused={false}
          areAllOpen={true}
          isOnlyCurrent={isNewOrDeletedVideo || isNewCourse}
        />
      ))}
      {!!fileChanges.length && <FilesChanges files={fileChanges} />}
      {renderExams()}
    </div>
  );
};
