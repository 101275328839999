import { useState } from 'react';
import { ChangeHeader } from './SingleChange';
import Badge from 'components/badge/Badge';
import Divider from 'components/divider/Divider';
import { IExam } from 'query/course-module/dto';
import classes from './ExamChanges.module.scss';

interface IExamChangesProps {
  newExam: IExam[];
  oldExam?: IExam[];
}

const ExamChanges = ({ newExam, oldExam }: IExamChangesProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const hasOldExam = !!oldExam;
  const exams =
    hasOldExam && oldExam.length > newExam.length ? oldExam : newExam;

  return (
    <div className={classes['menu']}>
      <ChangeHeader
        onClick={() => setIsOpen(!isOpen)}
        title="Test"
        isOpen={isOpen}
      />
      {isOpen && (
        <div className={classes['menu__body']}>
          {exams.map((exam, i) => (
            <div key={i} className={classes['menu__body__change__container']}>
              <div className={`${classes['u-semiBold']}`}>Question {i + 1}</div>
              <div key={i} className={classes['menu__body__change']}>
                <div className={classes['menu__body__change__list']}>
                  <div className={classes['menu__body__change__row']}>
                    <div className={classes['menu__body__change__row__badge']}>
                      <Badge badgeType="primary" text="Current" />
                      {i <= exams.length - 1 && (
                        <Divider
                          dir="vertical"
                          className={`
                            ${classes['divider']}
                            ${classes['divider--full-height']}
                          `}
                        />
                      )}
                    </div>
                    {newExam[i] ? (
                      <div className={classes['exam']}>
                        <div className={classes['exam__question']}>
                          {`${i + 1}. `}
                          {newExam[i].label}
                        </div>
                        {newExam[i].answers.map((answ) => (
                          <div
                            key={answ.id}
                            className={`${classes['exam__answer']} ${
                              newExam[i].correct_answer === answ.id
                                ? classes['exam__answer--correct']
                                : ''
                            }`}
                          >
                            {answ.value}
                          </div>
                        ))}
                      </div>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>

                <Divider dir="vertical" className={classes['divider']} />

                <div className={classes['menu__body__change__row']}>
                  <div className={classes['menu__body__change__row__badge']}>
                    <Badge badgeType="disabled" text="Previous" />
                  </div>
                  {hasOldExam && !!oldExam[i] ? (
                    <div className={classes['exam']}>
                      <div className={classes['exam__question']}>
                        {`${i + 1}. `}
                        {oldExam[i] ? oldExam[i].label : '-'}
                      </div>
                      {!!oldExam[i].answers &&
                        oldExam[i].answers.map((answ) => (
                          <div
                            key={answ.id}
                            className={`${classes['exam__answer']} ${
                              oldExam[i].correct_answer === answ.id
                                ? classes['exam__answer--correct']
                                : ''
                            }`}
                          >
                            {answ.value}
                          </div>
                        ))}
                    </div>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExamChanges;
