import React from 'react';
import classes from '../input/InputWrapper.module.scss';

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, isDisabled, status, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || (defaultRef as any);

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className={classes['input__group']}>
        <label
          className={`
            ${classes['input__field--checkbox']}
            ${classes[`input__field--checkbox--checkbox`]}
            ${isDisabled ? classes['input__field--checkbox--disabled'] : ''}
           `}
        >
          <input
            className={classes['checkbox']}
            type="checkbox"
            ref={resolvedRef}
            disabled={isDisabled}
            {...rest}
          />
          <span className={classes['input__field-checkbox__mark']} />
        </label>
      </div>
    );
  }
);
