import { createSearchParams, useNavigate } from 'react-router-dom';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import { ReactComponent as UploadCloud } from 'assets/icons/upload-cloud.svg';
import Button from 'components/buttons/Button';
import { colors } from 'utils/styles';
import { COURSE_CHANGES } from 'query/course-module/dto';
import classes from './PublishedChangesMsg.module.scss';

interface IPublishedChangesMsg {
  courseSlug: string;
  withButton?: boolean;
  msg: string;
}

export const PublishedChangesMsg = ({
  courseSlug,
  withButton,
  msg
}: IPublishedChangesMsg) => {
  const navigate = useNavigate();

  return (
    <div className={classes['draft-msg']}>
      <div>
        <UploadCloud fill={colors.purple50} />
        <span className={classes['u-text--primary']}>{msg}</span>
      </div>
      {withButton && (
        <Button
          variant="link-neutral"
          icon={Check}
          iconPosition="left"
          withPadding={false}
          onClick={() => {
            navigate({
              pathname: `/courses/${courseSlug}`,
              search: `${createSearchParams({
                changes: COURSE_CHANGES.ONLY_CHANGES
              })}`
            });
          }}
        >
          Review
        </Button>
      )}
    </div>
  );
};
