import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as DndIcon } from 'assets/icons/drag-and-drop.svg';
// import { ReactComponent as Heart } from 'assets/icons/heart-outline.svg';
// import RatingComponent from 'components/rating/Rating';
import { windowContext } from 'context/windowsContext';
import Button from 'components/buttons/Button';
import Badge from 'components/badge/Badge';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import RatingComponent from 'components/rating/Rating';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { ICourse } from 'query/course-module/dto';
import classes from './HighlightedCourseCard.module.scss';

interface IHighlightedCourseCardProps {
  course: ICourse;
  hasRemoveBtn?: boolean;
  hasViewBtn?: boolean;
  hasRating?: boolean;
  onRemove?: (course: ICourse) => void;
  onClick: (course: ICourse) => void;
  isDnd?: boolean;
  size?: 'sm' | 'lg';
}

const HighlightedCourseCard = ({
  course,
  hasRemoveBtn,
  hasViewBtn,
  hasRating,
  onRemove,
  onClick,
  isDnd,
  size
}: IHighlightedCourseCardProps) => {
  const navigate = useNavigate();
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  const { thumbnail, name, description } = course;

  return (
    <div
      className={`${classes['highlighted']} ${classes[`highlighted--${size}`]}`}
      onClick={() => onClick(course)}
    >
      <div className={classes['highlighted__img-col']}>
        {isDnd && <DndIcon className={classes['dnd-icon']} />}
        <div
          className={`${classes['highlighted__img-col__thumbnail']} ${
            classes[`highlighted__img-col__thumbnail--${size}`]
          }`}
          style={{
            backgroundImage: `url(${
              REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
            })`,
            backgroundPosition: 'center'
          }}
        >
          <div className={classes['highlighted__img-col__badge']}>
            <Badge text="BEST SELLER" />
          </div>
        </div>
        {/* TODO: add when wishlist logic is implemented */}
        {/* <Button
          className={classes['highlighted__img-col__fav-btn']}
          variant="neutral"
          isIconBtn
          icon={Heart}
        /> */}
      </div>
      <div className={classes['highlighted__info-col']}>
        <div>
          {isLgMobile ? (
            <h5 className={classes['u-bold']}>{name}</h5>
          ) : size === 'sm' ? (
            <h4 className={classes['u-bold']}>{name}</h4>
          ) : (
            <h3 className={classes['u-bold']}>{name}</h3>
          )}
          <div className={classes['highlighted__info-col__desc']}>
            <TextWithCharLimit
              text={description}
              limit={isLgMobile ? 80 : 250}
              hideBtn
            />
          </div>
          {/* TODO: Add rating logic when available */}
          {hasRating && (
            <div className={classes['highlighted__info-col__rating']}>
              <RatingComponent
                rating={course.avg_rating}
                count={course.ratings}
                readOnly
              />
            </div>
          )}
        </div>
        <div className={classes['highlighted__info-col__btns-container']}>
          {hasViewBtn && (
            <Button
              className={classes['view']}
              onClick={() => navigate(`/courses/${course.slug}?changes=0`)}
              minWidth="lg"
              iconPosition="left"
            >
              View Course
            </Button>
          )}
          {hasRemoveBtn && (
            <Button
              className={classes['remove']}
              variant="error"
              onClick={(e) => {
                e.stopPropagation();
                !!onRemove && onRemove(course);
              }}
              minWidth="md"
              icon={Trash}
              iconPosition="left"
            >
              Remove
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

HighlightedCourseCard.defaultProps = {
  size: 'lg',
  hasRating: false,
  hasViewBtn: false
};

export default HighlightedCourseCard;
