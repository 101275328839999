import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import Button from 'components/buttons/Button';
import { InputChoice } from 'components/input/InputChoice';
import InfoBox from 'components/info-box/InfoBox';
import { IFormik } from './VideoForm';
import { QuestionBox } from './QuestionBox';
import { ISelectOption } from 'query/course-module/dto';
import classes from './Form.module.scss';

interface IStep2
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldError'
    | 'handleBlur'
  > {
  currentStep: number;
  setStep: (step: number) => void;
  renderPrevBtn: () => {};
}

export const buildCompletionScoreOptions: () => ISelectOption[] = () =>
  Array.from(Array.from(Array(Math.ceil(10)).keys()), (x) => {
    const value = 10 + x * 10;
    return {
      label: `${value !== 100 ? '>' : ''} ${value}%`,
      value: value
    };
  });

export const testOptions = [
  {
    value: 'true',
    label: 'I want to attach a test to this video.'
  }
];

export const Step2 = ({
  currentStep,
  setStep,
  renderPrevBtn,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur
}: IStep2) => {
  const ref: any = useRef();
  const questions = Object.keys(values).filter((v) =>
    v.startsWith('exam_question')
  );
  const [questionsState, setQuestionsState] = useState(questions);

  const renderQuestionGroup = () => {
    return questionsState.map((fieldKey, i) => (
      <QuestionBox
        key={i}
        index={i + 1}
        questionFieldKey={fieldKey}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        handleBlur={handleBlur}
        onRemoveQuestion={() => {
          setQuestionsState(questionsState.filter((a) => a !== fieldKey));
        }}
      />
    ));
  };

  useEffect(() => {
    const scrollContainer = ref.current;
    if (!!scrollContainer)
      scrollContainer.scrollTop = scrollContainer?.scrollHeight;
  }, [questionsState.length]);

  return (
    <>
      <div className={classes['fields-wrapper']}>
        <div className={classes['info-box-wrapper']}>
          <InfoBox
            className={classes['box']}
            msg={
              <div>
                <div>
                  Please note, that the user must pass all tests included in the
                  course in order receive their completion certificate.
                </div>
                <div>
                  <b> Adding a test is optional.</b>
                </div>
              </div>
            }
          />
          <InputChoice
            name="has_test"
            type="checkbox"
            onChange={(value) => setFieldValue('has_test', value)}
            options={testOptions}
            selectedOptions={values?.has_test || []}
            isMulti
          />
        </div>

        {!!values?.has_test?.length && (
          <>
            <div
              className={`${classes['flex']} ${classes['test-success-rate-wrapper']}`}
            >
              <Button
                variant="link-neutral"
                size="sm"
                icon={Plus}
                iconPosition="left"
                onClick={() => {
                  // Find last question key index and set the new one with the next index
                  const lastQuestionKey =
                    questionsState[questionsState.length - 1];
                  const splited = lastQuestionKey
                    ? lastQuestionKey.split('_')
                    : [];
                  const lastQuestionKeyIndex = parseInt(
                    splited[splited.length - 1]
                  );
                  // Register new question field
                  setQuestionsState([
                    ...questionsState,
                    `exam_question_${
                      lastQuestionKeyIndex ? lastQuestionKeyIndex + 1 : 1
                    }`
                  ]);
                  // Register at least two answer fields
                  setFieldValue(
                    `exam_answer_${
                      lastQuestionKeyIndex ? lastQuestionKeyIndex + 1 : 1
                    }_1`,
                    ''
                  );
                  setFieldValue(
                    `exam_answer_${
                      lastQuestionKeyIndex ? lastQuestionKeyIndex + 1 : 1
                    }_2`,
                    ''
                  );
                  // Register first answer as correct answer by default
                  setFieldValue(
                    `exam_correctAnswer_${
                      lastQuestionKeyIndex ? lastQuestionKeyIndex + 1 : 1
                    }`,
                    '1'
                  );
                }}
              >
                Add Test Question
              </Button>
              {!!questionsState.length && (
                <ReactSelectWrapper
                  name="completion_score"
                  title="Test Success Rate"
                  placeholder="Select"
                  hasTooltip
                  tooltipText="The percentage rate for successfully passing the test and continuing to the next video or finishing a course."
                  options={buildCompletionScoreOptions()}
                  isSearchable
                  isClearable
                  isRow
                  value={values.completion_score || null}
                  onChange={(e) => setFieldValue('completion_score', e)}
                  onBlur={(e) => handleBlur(e)}
                  error={
                    errors.completion_score && touched.completion_score
                      ? (errors.completion_score as string)
                      : ''
                  }
                />
              )}
            </div>

            {!!questionsState.length && (
              <div ref={ref} className={classes['question-box__wrapper']}>
                {renderQuestionGroup()}
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes['btns-wrapper']}>
        <>
          {renderPrevBtn()}
          <Button
            type="button"
            onClick={() => setStep(currentStep + 1)}
            icon={Arrow}
            iconRotate={90}
            // isDisabled={
            //     hasErrors(formErrors) ||
            //     !formData.email.length ||
            //     !formData.password.length
            // }
            // isFetching={isLoading}
          >
            Next Step
          </Button>
        </>
      </div>
    </>
  );
};

export default IStep2;
