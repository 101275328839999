import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as EyeHidden } from 'assets/icons/eye-hidden.svg';
import Button from 'components/buttons/Button';
import Tooltip from 'components/tooltip/Tooltip';
import { editProfileVisibilityMutation } from 'query/acc-module/mutations';
import { IHiddenFields } from 'query/acc-module/dto';
import { toastContext } from 'context/toastContext';

interface IVisibilityActionProps {
  tooltipId: string;
  field: keyof IHiddenFields;
  isPublic: boolean;
  isDisabled?: boolean;
}

const VisibilityAction = ({
  tooltipId,
  field,
  isPublic,
  isDisabled
}: IVisibilityActionProps) => {
  const { setToast } = useContext(toastContext);
  const queryClient = useQueryClient();

  const { isLoading, mutate: editVisibility } = useMutation({
    ...editProfileVisibilityMutation(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me-data'] });
      setToast({
        type: 'success',
        msg: 'Field visibility has been updated',
        autoClose: true
      });
    },
    onError: (e: Error) =>
      setToast({ type: 'error', msg: e.message, autoClose: true })
  });

  return (
    <Tooltip id={tooltipId} text={`Make ${isPublic ? 'private' : 'public'}`}>
      <Button
        onClick={() =>
          editVisibility({
            field,
            isPublic
          })
        }
        icon={isPublic ? Eye : EyeHidden}
        isIconBtn
        variant="link-neutral"
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </Tooltip>
  );
};

export default VisibilityAction;
