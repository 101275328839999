import { useContext } from 'react';
import { Link } from 'react-router-dom';
import NoCourses from 'assets/images/no-courses-gray.svg';
import { windowContext } from 'context/windowsContext';
import { userContext } from 'context/userContext';
import Error from 'components/error/Error';
import Card, { ICard } from './Card';
import CardSkeleton from '../skeleton/CardSkeleton';
import EmptyState from 'components/emptyState/EmptyState';
import Button from 'components/buttons/Button';
import { ICourse, ICourseData } from 'query/course-module/dto';
import { COURSE_REVIEW_STATUS, COURSE_STATUS } from 'utils/constants';

import classes from './CardRow.module.scss';

interface ICardRow {
  title?: string;
  actionBtnText: string;
  queryData: { isLoading: boolean; error: Error | null; data: ICourseData };
  onClick: (slug: string) => void;
  onAction?: (course: ICourse) => void;
  onShowMoreClick?: (showMoreAmount: number) => void;
  viewAllRoute?: string;
  showMoreAmount?: number;
  cardProps: Omit<ICard, 'course'>;
}

const CardRow = ({
  title,
  queryData,
  actionBtnText,
  onClick,
  onAction,
  viewAllRoute,
  showMoreAmount,
  onShowMoreClick,
  cardProps
}: ICardRow) => {
  const {
    userData: { isAdmin }
  } = useContext(userContext);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;
  const { isLoading, error, data } = queryData;
  const { result: courseData } = data || {};
  const hasData = !!courseData && !!courseData.length;

  const divideAndRoundToNextWholeNumber = (number: number) => {
    let result = number / 4;
    if (result % 1 !== 0) {
      // Check if result is not a whole number
      // Round up to the next whole number
      result = Math.ceil(result);
    }
    return result;
  };

  if (queryData.error) return <Error error={queryData.error} />;

  return (
    <div className={classes['wrapper']}>
      <div className={classes['card-row']}>
        {title && (
          <div className={classes['card-row__title']}>
            <h4 className={classes['u-bold']}>{title}</h4>
            {!!viewAllRoute && (
              <Link className={classes['u-text--content']} to={viewAllRoute}>
                {actionBtnText}
              </Link>
            )}
          </div>
        )}
        <div
          className={`${classes['card-row__row-container']} ${
            !hasData ? classes['card-row__row-container--empty'] : ''
          }`}
          style={{
            gridTemplateColumns: isLgMobile
              ? `repeat(${courseData?.length}, 320px)`
              : `repeat(4, 1fr)`,
            gridTemplateRows: isLgMobile
              ? '1fr'
              : `repeat(${divideAndRoundToNextWholeNumber(
                  courseData?.length
                )}, auto)`
          }}
        >
          {queryData.isLoading && <CardSkeleton amount={4} variant="course" />}
          {hasData &&
            courseData.map((course) => (
              <Card
                key={course._id}
                hasActionBtn={cardProps.hasActionBtn}
                actionBtnText={cardProps.actionBtnText}
                hasPrice={cardProps.hasPrice}
                hasBadge={cardProps.hasBadge}
                badgeText={cardProps.badgeText}
                hideChangesIcon={cardProps.hideChangesIcon}
                onClick={() =>
                  onClick(
                    course.slug +
                      `${
                        // Published courses with review status approved/submitted/rejected
                        course.status === COURSE_STATUS.PUBLISHED &&
                        course.review_status !== COURSE_REVIEW_STATUS.INITIAL &&
                        // missing on public pages
                        course.review_status !== undefined
                          ? isAdmin
                            ? '?changes=2'
                            : '?changes=1'
                          : // Published courses with review status initial
                            '?changes=0'
                      }`
                  )
                }
                action={() =>
                  !!onAction
                    ? onAction(course)
                    : onClick(
                        course.slug +
                          `${
                            course.status === COURSE_STATUS.PUBLISHED &&
                            course.review_status !==
                              COURSE_REVIEW_STATUS.INITIAL &&
                            // missing on public pages
                            course.review_status !== undefined
                              ? '?changes=1'
                              : '?changes=0'
                          }`
                      )
                }
                course={course}
              />
            ))}
        </div>
        {!!showMoreAmount && data?.total_results > courseData?.length && (
          <div>
            <Button
              variant="outline"
              size="sm"
              minWidth="sm"
              onClick={() =>
                !!onShowMoreClick && onShowMoreClick(showMoreAmount)
              }
            >
              Show{' '}
              {showMoreAmount <= data?.total_results - courseData?.length
                ? showMoreAmount
                : data?.total_results - courseData?.length}{' '}
              More
            </Button>
          </div>
        )}
      </div>
      {!hasData && !isLoading && !error && (
        <EmptyState
          icon={NoCourses}
          title="All Done!"
          message="There are no submitted courses at the moment."
        />
      )}
    </div>
  );
};

export default CardRow;
