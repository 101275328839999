import classes from './CustomCardSkeleton.module.scss';

const CustomCardSkeleton = () => {
  return (
    <div className={classes['card']}>
      <div className={classes[`card__img-wrapper`]} />
      <div className={classes[`card__content`]}>
        <div className={classes[`card__content__category`]} />
        <div className={classes['card__content__title']} />
        <div className={classes['card__content__badge']} />
        <div className={classes['card__content__progress']} />
        <div className={classes['card__content__button']} />
      </div>
    </div>
  );
};

export default CustomCardSkeleton;
