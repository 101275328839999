import { Column } from 'react-table';
import { IReport } from 'query/course-module/dto';
import { ReactComponent as USDC } from 'assets/images/usdc.svg';
import Tooltip from 'components/tooltip/Tooltip';
import { tableColumnsWidths } from './utils/table-utils';
import classes from './AdminTaxReportCols.module.scss';

interface IAdminTaxReportCols {
  type: 'country' | 'state';
}

export const AdminTaxReportCols = ({ type }: IAdminTaxReportCols) => {
  const cols: Column[] = [
    {
      Header: type[0].toUpperCase() + type.slice(1),
      accessor: type,
      minWidth: tableColumnsWidths.reportDataMin,
      maxWidth: tableColumnsWidths.reportDataMax,
      Cell: ({ row }: { row: { original: IReport } }) => (
        <div className={classes['report']}>{row.original?._id}</div>
      )
    },
    {
      Header: 'Total Revenue',
      accessor: 'total_revenue',
      Cell: ({ row }: { row: { original: IReport } }) => (
        <div className={classes['report']}>
          {!!row.original.total_revenue && (
            <Tooltip id="total_revenue" text="USDC">
              <USDC height={24} width={24} />
            </Tooltip>
          )}
          {row.original.total_revenue.toFixed(2) || '-'}
        </div>
      )
    },
    {
      Header: 'Platform Fee',
      accessor: 'platform_fee',
      Cell: ({ row }: { row: { original: IReport } }) => (
        <div className={classes['report']}>
          {!!row.original.platform_fee && (
            <Tooltip id="platform_fee" text="USDC">
              <USDC height={24} width={24} />
            </Tooltip>
          )}
          {row.original.platform_fee.toFixed(2) || '-'}
        </div>
      )
    },
    {
      Header: 'Total Sold',
      accessor: 'total_sold',
      minWidth: tableColumnsWidths.reportTotalSoldMin,
      maxWidth: tableColumnsWidths.reportTotalSoldMax,
      Cell: ({ row }: { row: { original: IReport } }) => (
        <div className={classes['report']}>{row.original?.total_sold}</div>
      )
    }
  ];

  return cols;
};
