import { useContext } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import EditStaticPagePopup from 'components/popup/EditStaticPagePopup';
import { ISinglePageData } from 'query/course-module/dto';
import classes from './AdminStaticPagesCols.module.scss';

export const AdminStaticPagesCols = () => {
  const cols: Column[] = [
    {
      Header: 'Page Name',
      accessor: 'page_name',
      Cell: ({ row }: { row: { original: ISinglePageData } }) => {
        return (
          <div className={classes['page']}>
            <Link to={`/${row.original.slug}`}>{row.original.title}</Link>
          </div>
        );
      }
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }: { row: { original: ISinglePageData } }) => {
        const { setPopup } = useContext(popupContext);

        return (
          <Button
            minWidth="sm"
            variant="neutral"
            onClick={() =>
              setPopup(<EditStaticPagePopup data={row.original} />)
            }
          >
            Edit Page
          </Button>
        );
      }
    }
  ];
  return cols;
};
