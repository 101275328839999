import { useContext } from 'react';
import { useAccount } from 'wagmi';
import { popupContext } from 'context/popupContext';
import { Web3Context } from 'context/web3Context';
import { userContext } from 'context/userContext';
import Loader from 'components/loader/Loader';
import ConnectWalletPopup from 'components/popup/ConnectWalletPopup';
import classes from './Button.module.scss';

export interface IButtonProps {
  className: string;
  type: 'button' | 'submit' | 'reset';
  variant:
    | 'contrast'
    | 'neutral'
    | 'outline'
    | 'error'
    | 'success'
    | 'link-contrast'
    | 'link-neutral'
    | 'link-success'
    | 'link-error';
  size: 'sm' | 'md' | 'lg';
  minWidth: 'sm' | 'md' | 'lg' | 'full';
  onClick: (e: React.MouseEvent) => void;
  isDisabled: boolean;
  isLoading: boolean;
  isIconBtn: boolean;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconPosition: 'left' | 'right';
  iconRotate: number;
  align: 'left' | 'center';
  withPadding: boolean;
  children: React.ReactNode;
  isWeb3?: boolean;
}

const Button = ({
  className,
  type,
  variant,
  size,
  minWidth,
  onClick,
  isDisabled,
  isLoading,
  isIconBtn,
  icon,
  iconPosition,
  iconRotate,
  align,
  withPadding,
  children,
  isWeb3
}: IButtonProps) => {
  const { isWalletUnique, isCorrectChain } = useContext(Web3Context);
  const { setPopup } = useContext(popupContext);
  const { isTokenExpired } = useContext(userContext);
  const { isConnected } = useAccount();

  const isWeb3Disabled = (!isWalletUnique || !isCorrectChain) && isConnected;
  const isWeb3ConnectWalletBtn = isWeb3 && !isConnected;
  const isBtnDisabled = isDisabled || (isWeb3 && isWeb3Disabled);

  const Icon = icon;
  const renderIcon = () => (
    <Icon
      width={size === 'sm' ? 20 : size === 'lg' ? 48 : 24}
      height={size === 'sm' ? 20 : size === 'lg' ? 48 : 24}
      style={{
        transform: iconRotate ? `rotate(${iconRotate}deg)` : ''
      }}
    />
  );

  if (isWeb3 && !isWeb3Disabled && isWeb3ConnectWalletBtn && !isTokenExpired) {
    return (
      <button
        type={type}
        className={`
          ${classes['button']} 
          ${classes[variant]}
          ${classes[`button--${size}`]} 
          ${minWidth ? classes[`min-width--${minWidth}`] : ''} 
          ${isIconBtn ? classes['button--icon'] : ''}
          ${!withPadding ? classes['button--no-padding'] : ''}
          ${className || ''}
        `}
        onClick={(e) => {
          e.stopPropagation();
          setPopup(<ConnectWalletPopup />);
        }}
      >
        <div
          className={`${classes['text-wrapper']} ${
            classes[`text-wrapper--${align}`]
          }`}
        >
          <div>Connect Wallet</div>
        </div>
      </button>
    );
  }

  return (
    <button
      type={type}
      className={`
        ${classes['button']} 
        ${classes[variant]}
        ${classes[`button--${size}`]} 
        ${minWidth ? classes[`min-width--${minWidth}`] : ''} 
        ${isIconBtn ? classes['button--icon'] : ''}
        ${!withPadding ? classes['button--no-padding'] : ''}
        ${className || ''}
      `}
      onClick={(e) => {
        e.stopPropagation();
        !isLoading && onClick(e);
      }}
      disabled={isBtnDisabled}
    >
      {isLoading ? (
        <Loader
          size="sm"
          loaderColor={
            variant === 'contrast'
              ? 'white'
              : variant === 'success'
              ? 'success'
              : variant === 'error'
              ? 'error'
              : 'neutral'
          }
        />
      ) : (
        <div
          className={`${classes['text-wrapper']} ${
            classes[`text-wrapper--${align}`]
          }`}
        >
          {iconPosition === 'left' && !!icon && renderIcon()}
          {children && <div>{children}</div>}
          {iconPosition === 'right' && !!icon && renderIcon()}
        </div>
      )}
    </button>
  );
};

Button.defaultProps = {
  className: null,
  type: 'button',
  variant: 'contrast',
  size: 'md',
  minWidth: null,
  onClick: () => {},
  isDisabled: false,
  isLoading: false,
  isIconBtn: false,
  icon: null,
  iconPosition: 'right',
  iconRotate: 0,
  align: 'center',
  withPadding: true,
  children: null
};

export default Button;
