import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import { popupContext } from 'context/popupContext';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import Error from 'components/error/Error';
import Loader from 'components/loader/Loader';
import GenericPopup from 'components/popup/GenericPopup';
import { EditSocialSchema } from 'utils/yupSchemas';
import { editProfileSocialsMutation } from 'query/acc-module/mutations';
import { userContext } from 'context/userContext';
import classes from './Form.module.scss';

const EditSocialForm = () => {
  const { setPopup } = useContext(popupContext);
  const { userData, userDataIsLoading, userDataError } =
    useContext(userContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading: handleEditIsLoading, mutate: handleEdit } = useMutation({
    ...editProfileSocialsMutation(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me-data'] });
      setPopup(
        <GenericPopup
          title="Profile Updated!"
          msg="Your profile info has been updated"
          buttonName="Go To Profile"
          buttonAction={() => navigate('/user/about/social-info')}
        />
      );
    },
    onError: (e: Error) =>
      setPopup(<GenericPopup type="error" title="Error" msg={e.message} />)
  });

  return (
    <div className={classes['form']}>
      <h4 className={classes['u-light']}>Edit Social Media Links</h4>
      {userDataIsLoading && <Loader />}
      {userDataError && <Error error={userDataError} />}
      {userData.email && (
        <Formik
          initialValues={{
            website: userData.additional_info.website,
            twitter: userData.additional_info.twitter,
            instagram: userData.additional_info.instagram,
            facebook: userData.additional_info.facebook
          }}
          validationSchema={EditSocialSchema}
          onSubmit={(values) => {
            if (handleEditIsLoading) return;
            handleEdit(values);
          }}
          validateOnMount
        >
          {({
            errors,
            touched,
            values,
            setFieldValue,
            submitForm,
            handleBlur,
            isValid
          }) => (
            <Form className={classes['form']}>
              <InputWrapper
                name="website"
                type="text"
                variant="outline"
                onChange={(e) => setFieldValue('website', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.website}
                title="Website"
                placeholder="Enter Website"
                error={errors.website && touched.website ? errors.website : ''}
              />
              <InputWrapper
                name="twitter"
                type="text"
                variant="outline"
                onChange={(e) => setFieldValue('twitter', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.twitter}
                title="Twitter"
                placeholder="Enter Twitter Handle"
                error={errors.twitter && touched.twitter ? errors.twitter : ''}
              />
              <InputWrapper
                name="ig"
                type="text"
                variant="outline"
                onChange={(e) => setFieldValue('instagram', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.instagram}
                title="Instagam"
                placeholder="Enter Instagam Handle"
                error={
                  errors.instagram && touched.instagram ? errors.instagram : ''
                }
              />
              <InputWrapper
                name="facebook"
                type="text"
                variant="outline"
                onChange={(e) => setFieldValue('facebook', e)}
                onBlur={(e) => handleBlur(e)}
                value={values.facebook}
                title="Facebook"
                placeholder="Enter Facebook Handle"
                error={
                  errors.facebook && touched.facebook ? errors.facebook : ''
                }
              />
              <div
                className={`${classes['btns-wrapper']} ${classes['btns-wrapper--single']}`}
              >
                <Button
                  type="submit"
                  isDisabled={!isValid}
                  isLoading={handleEditIsLoading}
                  icon={CheckIcon}
                  iconPosition="left"
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditSocialForm;
