import React, { useEffect, useState } from 'react';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import { ReactComponent as Circle } from 'assets/icons/circle.svg';
import { InputWrapper } from 'components/input/InputWrapper';
import Tooltip from 'components/tooltip/Tooltip';
import Button from 'components/buttons/Button';
import { IFormik } from './VideoForm';
import classes from './Form.module.scss';

interface IQuestionBox
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldError'
    | 'handleBlur'
  > {
  index: number;
  // Leave it `any` to prevent strange typescript error
  questionFieldKey: any;
  onRemoveQuestion: () => void;
}

export const QuestionBox = ({
  index,
  questionFieldKey,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  handleBlur,
  onRemoveQuestion
}: IQuestionBox) => {
  const [questionFieldKeyIndex] = questionFieldKey.charAt(
    questionFieldKey.length - 1
  );

  const [answersState, setAnswersState] = useState(
    Object.keys(values).filter((v) =>
      v.startsWith(`exam_answer_${questionFieldKeyIndex}`)
    )
  );

  useEffect(() => {
    setAnswersState(
      Object.keys(values).filter((v) =>
        v.startsWith(`exam_answer_${questionFieldKeyIndex}`)
      )
    );
  }, [questionFieldKeyIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderAnswersGroup = () => {
    return answersState.map((fieldKey: any, i: number) => {
      return (
        <div key={fieldKey} className={classes['answer-box']}>
          {values[`exam_correctAnswer_${questionFieldKeyIndex}`] &&
          values[`exam_correctAnswer_${questionFieldKeyIndex}`].toString() ===
            (i + 1).toString() ? (
            <div className={classes['icon--marker--wrapper']}>
              <Tooltip
                id={`${i}-${fieldKey}`}
                text={`Answer ${i + 1} marked as correct!`}
              >
                <Check
                  className={`${classes['icon--marker']} ${classes['icon--marker--active']}`}
                />
              </Tooltip>
            </div>
          ) : (
            <div className={classes['icon--marker--wrapper']}>
              <Circle
                className={classes['icon--marker']}
                onClick={() =>
                  setFieldValue(
                    `exam_correctAnswer_${questionFieldKeyIndex}`,
                    (i + 1).toString()
                  )
                }
              />
            </div>
          )}
          <InputWrapper
            key={fieldKey}
            name={fieldKey}
            type="text"
            variant="outline"
            onChange={(value) => setFieldValue(fieldKey, value)}
            onBlur={(e) => handleBlur(e)}
            value={values[fieldKey]}
            placeholder={`Enter Answer ${i + 1}`}
            error={
              errors[fieldKey] && touched[fieldKey] ? errors[fieldKey] : ''
            }
          />
          {answersState.length > 2 && (
            <Button
              variant="link-neutral"
              size="sm"
              icon={Trash}
              iconPosition="left"
              onClick={() => {
                setAnswersState(answersState.filter((a) => a !== fieldKey));
                setFieldValue(fieldKey, undefined);
                // If deleted answer was selected as correct answer, change correct answer automatically to the previous one
                if (
                  values[`exam_correctAnswer_${questionFieldKeyIndex}`] ===
                  (i + 1).toString()
                )
                  setFieldValue(
                    `exam_correctAnswer_${questionFieldKeyIndex}`,
                    '1'
                  );
              }}
              className={classes['btn--trash']}
            />
          )}
        </div>
      );
    });
  };

  return (
    <div className={classes['question-box']}>
      {/* Show tooltip only if correct answer not marked */}
      <Tooltip
        id={`tooltip-${questionFieldKey}`}
        text={
          !values[`exam_correctAnswer_${index}`]
            ? 'Mark the Correct Answer in order to proceed with test creation.'
            : ''
        }
      >
        <InputWrapper
          name={questionFieldKey}
          type="text"
          variant="outline"
          onChange={(value) => setFieldValue(questionFieldKey, value)}
          onBlur={(e) => handleBlur(e)}
          value={values[questionFieldKey]}
          placeholder={`Enter Question ${index}`}
          error={
            errors[questionFieldKey] && touched[questionFieldKey]
              ? errors[questionFieldKey]
              : ''
          }
        />
      </Tooltip>
      {renderAnswersGroup()}
      <div className={`${classes['flex']} ${classes['answer-buttons']}`}>
        <Button
          variant="link-neutral"
          size="sm"
          icon={Plus}
          iconPosition="left"
          onClick={() => {
            // Find last answer key index and set the new one with the next index
            const lastAnswerKey = answersState[answersState.length - 1];
            const splited = lastAnswerKey ? lastAnswerKey.split('_') : [];
            const lastAnswerKeyIndex = parseInt(splited[splited.length - 1]);
            setAnswersState([
              ...answersState,
              `exam_answer_${index}_${
                lastAnswerKeyIndex ? lastAnswerKeyIndex + 1 : 1
              }`
            ]);
          }}
        >
          Add Answer
        </Button>
        <Button
          variant="link-neutral"
          size="sm"
          icon={Trash}
          iconPosition="left"
          onClick={() => {
            onRemoveQuestion();
            // Unregister question field
            setFieldValue(questionFieldKey, undefined);
            // Unregister correct answer field
            setFieldValue(
              `exam_correctAnswer_${questionFieldKeyIndex}`,
              undefined
            );
            // Unregister answers fields
            for (let ansIndex = 0; ansIndex < answersState.length; ansIndex++) {
              setFieldValue(
                `exam_answer_${questionFieldKeyIndex}_${ansIndex + 1}`,
                undefined
              );
            }
          }}
        >
          Remove Question
        </Button>
      </div>
    </div>
  );
};

export default IQuestionBox;
