import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import NoCourses from 'assets/images/no-courses.svg';
import Creator from 'assets/images/hero-image-2.png';
import { ICreator } from 'query/acc-module/dto';
import { IUsersData } from 'query/course-module/dto';
import { usersQuery } from 'query/course-module/queries';
import Button from 'components/buttons/Button';
import UserCard from 'components/card/UserCard';
import Actions from '../components/Actions';
import EmptyState from 'components/emptyState/EmptyState';
import SEO from 'components/SEO/SEO';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import { navigateWithParams } from 'utils/format';
import classes from './Users.module.scss';

const sortOptions = [
  { label: 'Name A-Z', value: 'first_name' },
  { label: 'Name Z-A', value: '-first_name' }
];

const Users = () => {
  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();
  const { search, sort, limit } = queryString.parse(urlParams);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');
  const [usersAmount, setUsersAmount] = useState<number>(Number(limit) || 8);
  const queryParams = `?&limit=${usersAmount}&page=1${
    searchValue.length > 1
      ? `&search[name]=${searchValue}&search[first_name]=${searchValue}&search[last_name]=${searchValue}`
      : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}`;

  const { isLoading, data } = useQuery<boolean, Error, IUsersData>({
    ...usersQuery(queryParams)
  });

  const { result: creatorsData, total_results } = data || {};
  const hasCreatorsData = !!creatorsData?.length;

  useEffect(() => {
    navigateWithParams(
      urlParams,
      {
        limit: usersAmount
      },
      navigate,
      pathname
    );
    localStorage.setItem('usersAmount', usersAmount.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAmount]);

  return (
    <div className={classes['browse-users']}>
      <SEO title="Explore Creators" image={Creator} />
      <Actions
        isInverted
        hasSearch
        passSearchValueUp={setSearchValue}
        searchValue={searchValue}
        searchPlaceholder="Search User by Name"
        passSortValueUp={setSortValue}
        sortValue={sortValue}
        sortOptions={sortOptions}
        isDebounced
        hasSort
      />
      <div className={classes['browse-users__section']}>
        <div>
          <h4 className={classes['u-bold']}>Content Creators</h4>
        </div>
        <div className={classes['cards']}>
          {isLoading && <CardSkeleton amount={usersAmount} variant="creator" />}
          {hasCreatorsData && (
            <>
              {creatorsData?.map((user: ICreator) => (
                <UserCard key={user._id} user={user} />
              ))}
              {!!total_results && total_results > usersAmount && (
                <div className={classes['browse-users__section__button']}>
                  <Button
                    variant="outline"
                    size="sm"
                    minWidth="sm"
                    onClick={() => setUsersAmount(usersAmount + 4)}
                  >
                    Show More
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!hasCreatorsData && !isLoading && (
        <EmptyState icon={NoCourses} title="No results found." />
      )}
    </div>
  );
};

export default Users;
