import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { polygon } from 'wagmi/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { PopupProvider } from 'context/popupContext';
import { AppProvider } from 'context/appContext';
import { UserProvider } from 'context/userContext';
import { WindowProvider } from './context/windowsContext';
import { ToastProvider } from 'context/toastContext';
import { Web3Provider } from 'context/web3Context';
import {
  REACT_APP_PLANET2_ID,
  REACT_APP_PLANET_ID,
  polygonAmoy
} from 'utils/constants';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.scss';

// Required for wagmi
// Fixes the followig bug:
// If a user click on connect with coinbase wallet, but does not have
// the extension, an error is thrown and the QR popup link to
// the coinbase wallet website is not displayed.
window.Buffer = window.Buffer || require('buffer').Buffer;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygonAmoy, polygon],
  [alchemyProvider({ apiKey: REACT_APP_PLANET_ID }), publicProvider()]
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'OpusEDU'
      }
    }),
    new WalletConnectConnector({
      chains: [polygon],
      options: {
        projectId: REACT_APP_PLANET2_ID
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <WagmiConfig config={config}>
          <AppProvider>
            <WindowProvider>
              <ToastProvider>
                <UserProvider>
                  <Web3Provider>
                    <PopupProvider>
                      <App />
                    </PopupProvider>
                  </Web3Provider>
                </UserProvider>
              </ToastProvider>
            </WindowProvider>
          </AppProvider>
        </WagmiConfig>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
