import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { publicProfileDataQuery } from 'query/acc-module/queries';
import { IUser, userContext } from 'context/userContext';
import { windowContext } from 'context/windowsContext';
import Loader from 'components/loader/Loader';
import ErrorComponent from 'components/error/Error';
import Tabs from 'components/tabs/Tabs';
import Published from '../components/created/Published';
import About from '../components/about/About';
import TopSection from '../components/TopSection';
import { IProfile } from '../index';
import SEO from 'components/SEO/SEO';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import { formatNames } from 'utils/format';
import classes from './PublicProfie.module.scss';

const PublicProfile = ({ tab, subTab }: IProfile) => {
  const { slug } = useParams();
  const { appWrapperElement } = useContext(windowContext);
  const {
    userData: { isAdmin }
  } = useContext(userContext);

  const { isLoading, error, data } = useQuery<boolean, Error, IUser>({
    ...publicProfileDataQuery(slug as string)
  });

  useEffect(() => {
    appWrapperElement?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loader size="lg" hasText />;
  if (error) return <ErrorComponent error={error} />;

  const renderBreadcrumbs = () => {
    const crumbs = [
      { name: 'Home', link: '/' },

      {
        name: formatNames(data.first_name + ' ' + data.last_name),
        link: '/users'
      }
    ];

    if (isAdmin) {
      crumbs.splice(1, 0, { name: 'Users', link: '/admin/users' });
    } else {
      const usersAmount = localStorage.getItem('usersAmount');
      crumbs.splice(1, 0, {
        name: 'Explore Creators',
        link: usersAmount ? `/users?limit=${usersAmount}` : '/users'
      });
    }

    return <CustomBreadcrumbs className={classes['crumbs']} crumbs={crumbs} />;
  };

  const tabs = [
    {
      id: 'about',
      name: 'About',
      route: `user/${slug}/about/main-info`,
      component: () => <About data={data} subTab={subTab} isPublic />
    }
  ];

  if (!data.is_edu_admin)
    tabs.push({
      id: 'published-courses',
      name: 'Published Courses',
      route: `user/${slug}/published-courses`,
      component: () => <Published />
    });

  return (
    <>
      {renderBreadcrumbs()}
      <SEO
        type="profile"
        title={formatNames(data.first_name + ' ' + data.last_name)}
        desc={data.additional_info.about}
        image={data.pic}
        creatorName={formatNames(data.first_name + ' ' + data.last_name)}
      />
      <TopSection data={data} isPublic />
      <Tabs
        tabs={tabs}
        activeTabIndex={tabs.findIndex((t) => t.id === tab)}
        size={'sm'}
      />
    </>
  );
};

export default PublicProfile;
