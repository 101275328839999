import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Actions from 'routes/dashboard/profile/components/Actions';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { IUsersData } from 'query/course-module/dto';
import { userPurchasesDataQuery } from 'query/course-module/queries';
import Table from 'components/table/Table';
import Error from 'components/error/Error';
import NoResults from 'components/noResults/NoResults';
import { IOption } from 'components/input/InputWrapper';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { PurchasesCols } from 'components/table/PurchasesCols';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import { TRANSACTION_STATUS } from 'utils/constants';
import { isSafari } from 'utils/helpers';
import classes from './Refunds.module.scss';

const filterName = 'Status';

const filterOptions = [
  {
    label: 'Refund',
    value: TRANSACTION_STATUS.REFUND.toString()
  },
  {
    label: 'Refund Completed',
    value: TRANSACTION_STATUS.REFUNDED.toString()
  }
];

const sortOptions = [
  { label: 'Name A-Z', value: 'course_name' },
  { label: 'Name Z-A', value: '-course_name' },
  { label: 'Price ascending', value: 'course_price' },
  { label: 'Price descending', value: '-course_price' },
  { label: 'Date Purchased - ASC', value: 'buy_tx_confirmed_at' },
  { label: 'Date Purchased - DESC', value: '-buy_tx_confirmed_at' }
];

const Refunds = () => {
  const { search: urlParams } = useLocation();
  const { showFilter, updateFilter } = useContext(appContext);
  const {
    page: pageNumber,
    search,
    sort,
    status
  } = queryString.parse(urlParams);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const pageSize: number = 9;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [filterValue, setFilterValue] = useState<IOption[]>(
    filterOptions.filter((option) => status?.includes(option.value))
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1
      ? `&search[course_name]=${searchValue}&search[course_creator_name]=${searchValue}`
      : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}${
    !!status
      ? `&purchase_action=${filterValue
          .map((filter) => `${filter.value}`)
          .join(',')}`
      : ''
  }`;

  const { isLoading, error, data } = useQuery<boolean, Error, IUsersData>(
    userPurchasesDataQuery(queryParams)
  );
  const hasData = !!data && !!data?.result.length;
  const purchasesCols = useMemo(
    () => PurchasesCols({ hideActionBtn: false, isPurchasesPage: true }),
    []
  );

  const filterHandler = (value: IOption[]) => {
    setPage(1);
    setFilterValue(value);
  };

  return (
    <div
      className={`
      ${classes['refund']} 
      ${showFilter ? classes['refund--grid'] : ''}
      `}
    >
      {!!showFilter && (
        <Actions
          hasSort
          hasFilter
          isInverted
          setPage={setPage}
          sortValue={sortValue}
          sortOptions={sortOptions}
          showFilterMenu={showFilter}
          onFilterClick={updateFilter}
          passSortValueUp={setSortValue}
          passFilterValueUp={filterHandler}
          filters={[
            {
              filterOptions,
              filterValue,
              filterName
            }
          ]}
        />
      )}
      {(!isMdMobile || (isMdMobile && !showFilter)) && (
        <div
          className={`${classes['refund__table-wrapper']} 
          ${isSafari ? classes['safari-support-overflow'] : ''}`}
        >
          <Actions
            hasSearch
            isInverted
            isDebounced
            setPage={setPage}
            hasSort={!showFilter}
            sortValue={sortValue}
            hasFilter={!showFilter}
            sortOptions={sortOptions}
            searchValue={searchValue}
            onFilterClick={updateFilter}
            passSortValueUp={setSortValue}
            searchPlaceholder="Search Courses or Creators"
            passSearchValueUp={setSearchValue}
          ></Actions>
          {!!data?.result.length && (
            <Table
              columns={purchasesCols}
              data={data.result}
              rowHeight={94}
              paginationProps={{
                activePage: page,
                setActivePage: setPage,
                pageSize: pageSize,
                totalCount: data.total_results as number,
                siblingCount: 1
              }}
            />
          )}
          {isLoading && (
            <TableSkeleton rows={pageSize} cols={purchasesCols.length} />
          )}
          {error && <Error error={error} />}
          {!hasData && !isLoading && !error && <NoResults />}
        </div>
      )}
    </div>
  );
};

export default Refunds;
