import React from 'react';
import moment from 'moment';
import placeholder from 'assets/images/certificate-placeholder.png';
import { ITemplateDto } from 'query/course-module/dto';
import { REACT_APP_FILES_PUBLIC_DOMAIN } from 'utils/constants';
import { dateFormatUrl } from 'utils/static';
import classes from './Certificate.module.scss';

export interface ICertificate {
  isImgOnly?: boolean;
  data?: ITemplateDto;
  onClick?: (id: string) => void;
  isOriginalSize?: boolean;
  withDate?: boolean;
}

const Certificate = ({
  isImgOnly,
  data,
  onClick,
  isOriginalSize,
  withDate
}: ICertificate) => {
  return (
    <div
      className={classes['certificate']}
      onClick={() => !!data && !!onClick && onClick(data?._id)}
    >
      <div
        className={`
          ${classes['certificate__img-wrapper']}  
          ${
            isOriginalSize
              ? classes['certificate__img-wrapper--not-cropped']
              : ''
          }
        `}
      >
        <img
          src={
            data?.thumbnail
              ? REACT_APP_FILES_PUBLIC_DOMAIN + data?.thumbnail
              : placeholder
          }
          alt={data?.name}
        />
      </div>
      {!isImgOnly && (
        <div className={classes['certificate__content']}>
          <div className={classes['certificate__title']}>
            {data?.name || 'Course Template Name'}
          </div>
          {withDate && (
            <div className={classes['certificate__text']}>
              Creation on: {moment(data?.date_created).format(dateFormatUrl)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Certificate;
