import { useContext } from 'react';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import { SingleChange } from './SingleChange';
import classes from './CourseChangesMenu.module.scss';

export interface ICourseFieldChanges {
  valueType: 'image' | 'video' | 'text' | 'certificate' | 'price' | 'file';
}

export interface IChangesObject {
  fieldKey: string;
  title: string;
  oldValue: string;
  newValue: string;
  valueType: ICourseFieldChanges['valueType'];
}

export interface IChangesMenuProps {
  changes: IChangesObject[];
  focusChange?: string;
}

const CourseChangesMenu = ({ changes, focusChange }: IChangesMenuProps) => {
  const { clearMenu } = useContext(popupContext);

  const actualChanges = changes.filter(
    (changes) => changes.newValue !== changes.oldValue
  );

  return (
    <div className={classes['menu__wrapper']}>
      <Button
        className={classes['menu__close-btn']}
        variant="neutral"
        isIconBtn
        icon={Cross}
        size="md"
        onClick={clearMenu}
      />
      <h4 className={classes['u-semiBold']}>Updates</h4>
      {actualChanges.map((change, i) => (
        <SingleChange
          key={i}
          change={change}
          allChanges={changes}
          isFocused={focusChange === change.fieldKey}
          areAllOpen={!focusChange}
        />
      ))}
    </div>
  );
};

export default CourseChangesMenu;
