import { mediaFileFormats } from 'components/input/InputMedia';
import { IAttachedFile, IModifyFile } from '../query/course-module/dto';

export async function mediaToBase64(
  files: Blob[],
  onLoadHandler: (e: ProgressEvent<FileReader>) => void
) {
  let readers = [];

  for (let i = 0; i < files.length; i++) {
    const reader = new FileReader();
    reader.onload = onLoadHandler;
    readers.push(reader.readAsDataURL(files[i]));
  }

  return Promise.all(readers);
}

export const handleMediaUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  fieldName: string,
  setFieldValue: (fieldName: string, value: string) => void,
  setFieldTouched: (fieldName: string, value: boolean) => void,
  setFieldError: (fieldName: string, value: string) => void
) => {
  const onLoadHandler = (e: ProgressEvent<FileReader>) => {
    if (!e.target || !e.target.result) return;
    const value = e.target.result as string;

    // If there is an error, set the error and exit function
    if (!!sizeError) {
      setFieldError(fieldName, sizeError);
      return;
    }

    // No error, set the field value
    setFieldValue(fieldName, value);
  };

  const files = [];

  // If there is not file, exit the function
  if (!e.currentTarget.files) return;

  for (let image of e.currentTarget.files) {
    files.push(image);
  }

  // If a file is not uploaded, exit the function
  if (!files.length) return;

  let sizeInMb = +(files[0].size / Math.pow(10, 6)).toFixed(2);
  let isFileTooBig = sizeInMb > +mediaFileFormats.image.size;
  let sizeError = '';

  // Check if uploaded image is bigger than the image size limit
  if (isFileTooBig) {
    sizeError = mediaFileFormats.image.error(sizeInMb);
    setFieldValue(fieldName, '');
  }

  setFieldTouched(fieldName, true);
  mediaToBase64(files, onLoadHandler);
};

export const handleFileUpload = async (
  e: any,
  fieldName: string,
  setFieldValue: (fieldName: string, value: any[]) => void,
  setFieldTouched: (fieldName: string, value: boolean) => void,
  setFieldError: (fieldName: string, value: string) => void,
  values?: (IAttachedFile | IModifyFile)[]
) => {
  const length = e.target.files.length;

  let arr = values && values.length > 0 ? [...values] : [];
  let promises = [];
  for (let index = 0; index < length; index++) {
    let filePromise = new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[index]);
      reader.onload = () =>
        resolve({ name: e.target.files[index].name, data: reader.result });
    });
    promises.push(filePromise);
  }

  let sizeInMb = +(e.target.files[0].size / Math.pow(10, 6)).toFixed(2);
  let isFileTooBig = sizeInMb > mediaFileFormats.file.size;
  let sizeError = '';

  // Check if uploaded image is bigger than the image size limit
  if (isFileTooBig) {
    sizeError = mediaFileFormats.file.error(sizeInMb);
    // setFieldValue(fieldName, []);
    setFieldError(fieldName, sizeError);
    return;
  } else {
    await Promise.all(promises).then((fileContents: any) => {
      arr.push(...fileContents);
    });
    setFieldTouched(fieldName, true);
    setFieldValue(fieldName, arr);
  }
};

export const getBase64FromUrl = async (url: string): Promise<string> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      // Handle the case where result is ArrayBuffer (unexpected in this context)
      if (reader.result instanceof ArrayBuffer) {
        return reject(new Error('Unexpected result type: ArrayBuffer'));
      }
      // Handle the case where result is null (error during reading)
      if (reader.result === null) {
        return reject(new Error('Error reading the blob'));
      }
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const handleImageUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: (value: string) => void,
  setError: (error: string) => void
) => {
  const onLoadHandler = (e: ProgressEvent<FileReader>) => {
    if (!e.target || !e.target.result) return;
    const value = e.target.result as string;

    // If there is an error, set the error and exit function
    if (!!sizeError) {
      setError(sizeError);
      return;
    } else {
      setError('');
    }

    // No error, set the field value
    setValue(value);
  };

  const files = [];

  // If there is not file, exit the function
  if (!e.currentTarget.files) return;

  for (let image of e.currentTarget.files) {
    files.push(image);
  }

  // If a file is not uploaded, exit the function
  if (!files.length) return;

  let sizeInMb = +(files[0].size / Math.pow(10, 6)).toFixed(2);
  let isFileTooBig = sizeInMb > +mediaFileFormats.image.size;
  let sizeError = '';

  // Check if uploaded image is bigger than the image size limit
  if (isFileTooBig) {
    sizeError = mediaFileFormats.image.error(sizeInMb);
    setValue('');
  }

  mediaToBase64(files, onLoadHandler);
};

export const handleVideoUpload = (
  e: React.ChangeEvent<HTMLInputElement>,
  fieldName: string,
  setFieldValue: (fieldName: string, value: string) => void,
  setFieldTouched: (fieldName: string, value: boolean) => void,
  setFieldError: (fieldName: string, value: string) => void
) => {
  const onLoadHandler = (e: ProgressEvent<FileReader>) => {
    if (!e.target || !e.target.result) return;
    const value = e.target.result as string;

    // If there is an error, set the error and exit function
    if (!!videoError) {
      setFieldError(fieldName, videoError);
      return;
    }

    // No error, set the field value
    setFieldValue(fieldName, value);
  };

  const files: File[] = [];
  let videoError = '';

  // If there is not file, exit the function
  if (!e.currentTarget.files) return;

  for (let video of e.currentTarget.files) {
    files.push(video);
  }

  // If a file is not uploaded, exit the function
  if (!files.length) return;

  const url = URL.createObjectURL(files[0]);
  const video = document.createElement('video');
  video.src = url;

  video.onloadedmetadata = () => {
    let sizeInMb = +(files[0].size / Math.pow(10, 6)).toFixed(2);
    const maxDuration = 30;
    const isFileTooBig = sizeInMb > +mediaFileFormats.video.size;
    const isVideoTooLong = video.duration > maxDuration;

    // Check if the video is smaller than the size limit
    // or longer than the time limit
    if (isFileTooBig || isVideoTooLong) {
      videoError = isFileTooBig
        ? mediaFileFormats.video.sizeError(sizeInMb)
        : mediaFileFormats.video.lengthError(+video.duration.toFixed(0));
      setFieldValue(fieldName, '');
    }

    setFieldTouched(fieldName, true);
    mediaToBase64(files, onLoadHandler);
  };
};

export const getBase64FileSize = (base64String: string) => {
  // Remove data URL prefix if present
  const base64Content = base64String.split(',')[1];

  // Decode Base64 string to binary data
  const binaryData = atob(base64Content);

  // Calculate the size of the binary data
  const fileSizeBytes = binaryData.length;

  // Optionally, convert bytes to other units like KB or MB
  const fileSizeKB = fileSizeBytes / 1024; // Convert bytes to KB
  const fileSizeMB = fileSizeKB / 1024; // Convert KB to MB

  return {
    bytes: fileSizeBytes,
    kilobytes: fileSizeKB,
    megabytes: fileSizeMB
  };
};
