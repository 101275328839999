import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { userContext } from 'context/userContext';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import Tabs from 'components/tabs/Tabs';
import Videos from '../videos';
import { ISingleCourseProps } from '..';
import TestQuestions from '../test-questions/TestQuestions';
import Documents from '../documents/Documents';
import Certificate from '../certificate/Certificate';
import Reviews from '../reviews/Reviews';
import VideoView from '../views/VideoView';
import CourseHistory from '../history/CourseHistory';
import { isCourseStatus } from 'utils/helpers';
import {
  COURSE_RELATIONSHIP,
  ICourse,
  ICourseResourcesData
} from 'query/course-module/dto';
import classes from './Resources.module.scss';

export interface IResources {
  tab: ISingleCourseProps['tab'];
  courseProps: {
    course: ICourse;
    isAdminPage: boolean;
    isPublic: boolean;
    isCourseActivated: boolean;
    isVideoPage: boolean;
  };
  resourcesData: ICourseResourcesData;
  setIsVideoUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const Resources = ({
  tab,
  courseProps,
  resourcesData,
  setIsVideoUploaded
}: IResources) => {
  const { slug, videoId } = useParams();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { userData, newCertificates } = useContext(userContext);
  const { isTheatreMode } = useContext(appContext);
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  const { course, isAdminPage, isVideoPage } = courseProps;
  const { course_relationship } = course;
  const showEmptyStateBtn =
    course_relationship === COURSE_RELATIONSHIP.CREATOR &&
    !isCourseStatus('isSubmitted', course) &&
    !isCourseStatus('isApprovedInitial', course) &&
    !isCourseStatus('isPublishing', course);

  const { videos, tests, files, videos_checkpoint, videos_watched } =
    resourcesData || {};

  const tabs = [
    {
      id: 'videos',
      name: 'Videos',
      route: isVideoPage
        ? `courses/${slug}/${videoId}?&changes=${changes}`
        : isAdminPage
        ? `admin/courses/${slug}?&changes=${changes}`
        : `courses/${slug}?&changes=${changes}`,
      component: () => (
        <Videos
          data={{
            videosData: videos,
            videosCheckpoints: videos_checkpoint,
            videosWatched: videos_watched,
            files: files,
            tests: tests
          }}
          courseProps={courseProps}
          setIsVideoUploaded={setIsVideoUploaded}
        />
      )
    },
    {
      id: 'test-questions',
      name: 'Test Questions',
      route: isVideoPage
        ? `courses/${slug}/${videoId}/test-questions?&changes=${changes}`
        : isAdminPage
        ? `admin/courses/${slug}/test-questions?&changes=${changes}`
        : `courses/${slug}/test-questions?&changes=${changes}`,
      component: () => (
        <TestQuestions
          tests={tests}
          showEmptyStateBtn={showEmptyStateBtn}
          courseProps={courseProps}
        />
      )
    },
    {
      id: 'documents',
      name: 'Documents',
      route: isVideoPage
        ? `courses/${slug}/${videoId}/documents?&changes=${changes}`
        : isAdminPage
        ? `admin/courses/${slug}/documents?&changes=${changes}`
        : `courses/${slug}/documents?&changes=${changes}`,
      component: () => (
        <Documents
          resources={files}
          courseProps={courseProps}
          showEmptyStateBtn={showEmptyStateBtn}
        />
      )
    },
    {
      id: 'reviews',
      name: 'Reviews',
      route: isVideoPage
        ? `courses/${slug}/${videoId}/reviews?&changes=${changes}`
        : isAdminPage
        ? `admin/courses/${slug}/reviews?&changes=${changes}`
        : `courses/${slug}/reviews?&changes=${changes}`,
      component: () => <Reviews course={course} />
    }
  ];

  // Add certificate tab for logged in users
  if (!!userData?._id)
    tabs.splice(3, 0, {
      id: 'certificate',
      name: (
        <>
          {!!newCertificates.some((cert) => cert.course.slug === slug) && (
            <div className={classes['notification-circle']} />
          )}
          Certificate
        </>
      ) as any,
      route: isVideoPage
        ? `courses/${slug}/${videoId}/certificate?&changes=${changes}`
        : isAdminPage
        ? `admin/courses/${slug}/certificate?&changes=${changes}`
        : `courses/${slug}/certificate?&changes=${changes}`,
      component: () => (
        <Certificate course={course} showEmptyStateBtn={showEmptyStateBtn} />
      )
    });

  if (isAdminPage) {
    tabs.push({
      id: 'history',
      name: 'History',
      route: `admin/courses/${slug}/history?&changes=${changes}`,
      component: () => <CourseHistory course={course} />
    });
  }
  if (
    isVideoPage &&
    !isAdminPage &&
    course.course_relationship !== COURSE_RELATIONSHIP.CREATOR
  ) {
    tabs[0] = {
      id: 'videos',
      name: 'Videos',
      route: `courses/${slug}/${videoId}?&changes=${changes}`,
      component: () => (
        <VideoView
          courseData={course}
          view={isLgMobile ? 'video-list' : 'description'}
          isAdminPage={false}
          videosListData={resourcesData.videos}
        />
      )
    };
  }

  return (
    <div
      className={`
        ${classes['resources']} 
        ${
          isVideoPage && isTheatreMode ? classes['resources--theatre-mode'] : ''
        }
      `}
    >
      <Tabs
        tabs={tabs}
        activeTabIndex={tabs.findIndex((t) => t.id === tab)}
        size={'sm'}
      />
      {/* {isVideoPage && isTheatreMode && (
        <VideoView courseData={courseProps.course} view="description" />
      )} */}
    </div>
  );
};

export default Resources;
