import { useContext } from 'react';
import PlayBtn from 'assets/images/play-btn.png';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import { popupContext } from 'context/popupContext';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import Button from 'components/buttons/Button';
import classes from './ContentPopup.module.scss';

interface IContentPopupProps {
  onAddVideos: () => void;
}

const ContentPopup = ({ onAddVideos }: IContentPopupProps) => {
  const { clearPopup } = useContext(popupContext);

  return (
    <ModalWrapper size="lg">
      <div className={classes['wrapper']}>
        <img src={PlayBtn} alt="add-content" />
        <h4>Add content to your Course</h4>
        <p>
          Your course is now setup and you can start adding videos, and attach
          files and exams to them.
        </p>
        <Button
          minWidth="md"
          icon={Upload}
          onClick={() => {
            onAddVideos();
            clearPopup();
          }}
        >
          Add Videos
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default ContentPopup;
