import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Error404 from 'assets/images/404.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import logoLight from 'assets/images/logo-light.svg';
import logoDark from 'assets/images/logo-dark.svg';
import { appContext } from 'context/appContext';
import Button from 'components/buttons/Button';
import classes from './404.module.scss';

const Page404 = () => {
  const { theme } = useContext(appContext);
  const navigate = useNavigate();
  return (
    <div className={classes['wrapper']}>
      <div className={classes['container']}>
        <img
          className={classes['logo']}
          src={theme === 'dark' ? logoLight : logoDark}
          height={56}
          onClick={() => navigate('/')}
          alt="Logo"
        />
        <img src={Error404} width={300} alt="404" />
        <div>
          <h1 className={classes['u-light']}>
            <span className={classes['u-bold']}>404</span> Page Not Found
          </h1>
          <p className={classes['u-body1']}>
            This page doesn't exist or was removed! We suggest you back home
          </p>
        </div>
        <Button
          onClick={() => navigate('/')}
          variant="contrast"
          icon={ArrowIcon}
          iconPosition="left"
          iconRotate={270}
          minWidth="lg"
        >
          Go Back to Home
        </Button>
      </div>
    </div>
  );
};

export default Page404;
