import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { appContext } from 'context/appContext';
import logoLight from 'assets/images/logo-light.svg';
import logoDark from 'assets/images/logo-dark.svg';
import StaticImg from 'assets/images/login-static-img.svg';
import { ReactComponent as Sun } from 'assets/icons/sun.svg';
import { ReactComponent as Moon } from 'assets/icons/moon.svg';
import Header from 'components/header/Header';
import Button from 'components/buttons/Button';
import classes from './Login.module.scss';

interface ILogin {
  children: React.ReactNode;
}

const Login = (props: ILogin) => {
  const { theme, switchTheme } = useContext(appContext);
  const navigate = useNavigate();
  return (
    <div className={classes['wrapper']}>
      <div
        className={`${classes['static-container']} ${
          classes[`static-container--${theme}`]
        }`}
      >
        <img
          className={classes['logo']}
          src={theme === 'dark' ? logoLight : logoDark}
          height={56}
          onClick={() => navigate('/')}
          alt="Logo"
        />
        <div className={classes['static-container__content']}>
          <img
            className={classes['padlock-img']}
            src={StaticImg}
            width={160}
            height={160}
            alt="Carousel"
          />
          <h4 className={`${classes['u-bold']} ${classes['u-uppercase']}`}>
            Expand your knowledge <br />
            with OpusEDU
          </h4>
          <p className={classes['u-body2']}>
            Learn from the best instructors in the world, or start your journey
            as a content creator with OpusEDU!
          </p>
        </div>
      </div>
      <div className={classes['dynamic-container']}>
        <Button
          icon={theme === 'dark' ? Sun : Moon}
          onClick={switchTheme}
          variant="neutral"
          isIconBtn
          className={classes['c-btn--switch']}
        />
        <div className={classes['header']}>
          <Header isSimple />
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Login;
