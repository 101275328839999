import React from 'react';
import { IVideoFormFields } from 'utils/yupSchemas';
import classes from './Form.module.scss';

interface IExam {
  label: string;
  answers: string[];
  correct_answer: number;
}

export interface IVideoFormFieldsExtended extends IVideoFormFields {
  exam: IExam[];
}

interface ISummary {
  data: IVideoFormFieldsExtended;
}

const Summary = ({ data }: ISummary) => {
  const {
    title,
    description,
    exam,
    completion_score,
    attached_files,
    hidden_files,
    thumbnail
  } = data;
  // Files to display:
  // - previously attached
  // - newly attached
  let files = [];
  if (hidden_files && hidden_files.length) files.push(...hidden_files);
  if (attached_files && attached_files.length) files.push(...attached_files);
  return (
    <div className={classes['summary-wrapper']}>
      <div className={classes['row']}>
        <div className={classes['row__title']}>Video Name</div>
        <div className={classes['row__desc']}>{title}</div>
      </div>
      <div className={classes['row']}>
        <div className={classes['row__title']}>Video Description</div>
        <div className={classes['row__desc']}>{description || '-'}</div>
      </div>
      <div className={classes['row']}>
        <div className={classes['row__title']}>Video Test</div>
        <div className={classes['row__desc']}>
          {exam.length > 0
            ? `${exam.length} Question${exam.length > 1 ? 's' : ''} Test`
            : 'No Questions'}
        </div>
        {completion_score ? (
          <div
            className={classes['row__desc']}
          >{`Test Success Rate > ${completion_score.value}% `}</div>
        ) : null}
      </div>
      <div className={classes['row']}>
        <div className={classes['row__title']}>Attached Files</div>
        {!!files.length
          ? files.map((file, i) => (
              <div key={`${file.name}-${i}`} className={classes['row__desc']}>
                {file.name}
              </div>
            ))
          : '-'}
      </div>
      <div className={classes['row']}>
        <div className={classes['row__title']}>Video Thumbnail</div>
        {!!thumbnail ? (
          <div className={classes['row--flex']}>
            <div className={classes['row__img-wrapper']}>
              <div
                style={{
                  backgroundImage: `url(${thumbnail})`
                }}
                className={classes['row__img']}
              />
            </div>
          </div>
        ) : (
          '-'
        )}
      </div>
    </div>
  );
};

export default Summary;
