import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Verified } from 'assets/icons/verified.svg';
import { popupContext } from 'context/popupContext';
import { userContext } from 'context/userContext';
import { toastContext } from 'context/toastContext';
import useBrowserDetect from 'hooks/useBrowserDetect';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import Button from 'components/buttons/Button';
import Avatar from 'components/avatar/Avatar';
import Badge from 'components/badge/Badge';
import Loader from 'components/loader/Loader';
import { verifyUserMutationQuery } from 'query/acc-module/mutations';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import { formatNames } from 'utils/format';
import classes from './VerifyAccountPopup.module.scss';

const VerifyAccountPopup = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { kyc } = queryString.parse(search);
  const { userData } = useContext(userContext);
  const { first_name, last_name, pic, kyc_status } = userData;
  const { clearPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const [isStarted, setIsStarted] = useState(!!kyc);
  const browser = useBrowserDetect();

  const { isLoading, mutate: verifyUserHandler } = useMutation({
    mutationKey: ['verify-user'],
    mutationFn: async () => await verifyUserMutationQuery().mutationFn(),
    onSuccess: (data: { link: string }) => {
      setIsStarted(true);
      window.open(data.link, '_blank');
    }
  });

  const handleVerification = () => {
    if (browser === 'Chrome' || browser === 'Firefox') {
      return verifyUserHandler();
    } else {
      setToast({
        type: 'warning',
        title: 'Unsupported browser',
        msg: 'Verification is not supported on this browser. Please use Chrome or Firefox.',
        autoClose: true
      });
    }
  };

  return (
    <ModalWrapper size="md">
      <div className={classes['wrapper']}>
        <div className={classes['wrapper__header']}>
          <h4 className={classes['u-semiBold']}>Verify Account</h4>
          <h5 className={classes['u-body2']}>
            Identity verification involves gathering and verifying information
            about your identity.
          </h5>
        </div>
        <div className={classes['content']}>
          <div className={classes['content__user']}>
            <div className={classes['content__user__avatar']}>
              <Avatar size="xl" src={pic} />
              {kyc_status === USER_KYC_STATUS.APPROVED && (
                <Verified className={classes['verified__badge']} />
              )}
            </div>
            <h3 className={classes['u-semiBold']}>
              {formatNames(first_name + ' ' + last_name)}
            </h3>
            {kyc_status === USER_KYC_STATUS.REJECTED && (
              <Badge
                text="Profile not Verified"
                badgeType="error"
                type="kyc"
                onClick={() =>
                  navigate('/user/about/main-info', {
                    state: {
                      isVerifyOpened: true
                    }
                  })
                }
              />
            )}
          </div>
          {(kyc_status === USER_KYC_STATUS.NOT_STARTED ||
            kyc_status === USER_KYC_STATUS.CREATED ||
            kyc_status === USER_KYC_STATUS.REJECTED) && (
            <Button
              minWidth="sm"
              variant="contrast"
              isLoading={isLoading}
              onClick={handleVerification}
            >
              {kyc_status === USER_KYC_STATUS.CREATED
                ? 'Continue Verification'
                : 'Start Verification'}
            </Button>
          )}
          {(kyc_status === USER_KYC_STATUS.PROCESSED ||
            kyc_status === USER_KYC_STATUS.UPDATED) && (
            <Button
              minWidth="sm"
              variant="neutral"
              className={classes['content__pending']}
            >
              <Loader size="sm" loaderColor={'neutral'} />
              Verification Pending
            </Button>
          )}
          {kyc_status === USER_KYC_STATUS.APPROVED && (
            <Button
              minWidth="sm"
              variant="success"
              icon={Check}
              onClick={clearPopup}
            >
              Successfully Verified
            </Button>
          )}
          <Button size="sm" variant="link-neutral" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default VerifyAccountPopup;
