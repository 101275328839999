import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalizeFirstLetter, renderPic } from 'utils/format';
import {
  createCategory,
  createSubcategory,
  updateCategory,
  updateSubcategory
} from 'query/course-module/mutations';
import Button from 'components/buttons/Button';
import { InputMedia } from 'components/input/InputMedia';
import { InputWrapper } from 'components/input/InputWrapper';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import { handleImageUpload } from 'utils/processing';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import { ICourseCategory } from 'query/course-module/dto';
import classes from './CategoryPopup.module.scss';

interface ICategoryPopup {
  categoryId?: string;
  category?: ICourseCategory;
  action: 'create' | 'update';
  type: 'category' | 'subcategory';
}

const CategoryPopup = ({
  categoryId,
  category,
  type,
  action
}: ICategoryPopup) => {
  const { clearPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const queryClient = useQueryClient();
  const [categoryName, setCategoryName] = useState(category?.name || '');
  const [categoryIcon, setCategoryIcon] = useState(category?.icon || '');
  const [error, setError] = useState('');

  const { isLoading, mutate: submitHandler } = useMutation({
    mutationKey: [`${action}-${type}`],
    mutationFn: async () => {
      switch (action) {
        case 'create':
          type === 'category'
            ? await createCategory(categoryName, categoryIcon).mutationFn()
            : await createSubcategory(
                categoryId || '',
                categoryName
              ).mutationFn();
          break;
        case 'update':
          type === 'category'
            ? categoryIcon !== category?.icon
              ? await updateCategory(
                  category?._id || '',
                  categoryName,
                  categoryIcon
                ).mutationFn()
              : await updateCategory(
                  category?._id || '',
                  categoryName
                ).mutationFn()
            : await updateSubcategory(
                categoryId || '',
                category?._id || '',
                categoryName
              ).mutationFn();
          break;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['course-categories-data']);
      queryClient.invalidateQueries([
        'course-subcategories-data',
        { ids: categoryId }
      ]);
      clearPopup();
      setToast({
        type: 'success',
        position: 'top',
        title: `Successfully ${capitalizeFirstLetter(action)}d`,
        msg: `You have successfully ${action}d ${type}`,
        autoClose: true
      });
    },
    onError: async (err: Error) => {
      clearPopup();
      setToast({
        type: 'error',
        position: 'top',
        title: `${capitalizeFirstLetter(action)} Failed`,
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  return (
    <ModalWrapper size="sm" onClose={() => clearPopup()}>
      <div className={classes['popup-container']}>
        <h4 className={classes['u-semiBold']}>{`${
          action === 'create' ? 'Add New' : 'Edit'
        } ${capitalizeFirstLetter(type)}`}</h4>
        <div className={classes['content']}>
          <InputWrapper
            name="name"
            type="text"
            variant="outline"
            value={categoryName}
            placeholder="Enter Name"
            title={`${capitalizeFirstLetter(type)} Name`}
            onChange={(e) => setCategoryName(e)}
            isRequired
          />
          {type === 'category' && (
            <div className={classes['img-wrapper']}>
              <InputMedia
                size="sm"
                title={`${capitalizeFirstLetter(type)} Icon`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleImageUpload(e, setCategoryIcon, setError);
                }}
                error={error || ''}
                pic={renderPic(categoryIcon, true)}
                onClear={() => setCategoryIcon('')}
                isImageCentered
              />
            </div>
          )}
        </div>
        <div className={classes['buttons-wrapper']}>
          <Button
            minWidth="full"
            variant="contrast"
            onClick={() => submitHandler()}
            isLoading={isLoading}
            isDisabled={
              (type === 'category'
                ? (action === 'update' &&
                    categoryName === category?.name &&
                    categoryIcon === category?.icon) ||
                  !categoryName ||
                  !categoryIcon
                : !categoryName) ||
              categoryName.length < 3 ||
              !!error
            }
          >
            {`${
              action === 'create' ? 'Create' : 'Save'
            } ${capitalizeFirstLetter(type)}`}
          </Button>
          <Button size="sm" variant="link-neutral" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CategoryPopup;
