import React, { useContext, useState } from 'react';
import { currencyObj } from 'routes/dashboard/courses/single';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as PlayArrow } from 'assets/icons/play-arrow.svg';
import { ReactComponent as HeartOutline } from 'assets/icons/heart-outline.svg';
import { ReactComponent as HeartFill } from 'assets/icons/heart-fill.svg';
import { userContext } from 'context/userContext';
import Badge from '../badge/Badge';
import Button from '../buttons/Button';
import Progress from '../progress/Progress';
import Tooltip from 'components/tooltip/Tooltip';
import { RenderBadge } from 'components/badge/RenderBadge';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { ICourse } from 'query/course-module/dto';
import Player from 'components/player/Player';
import { getPriceInUsdc } from 'utils/helpers';
import RatingComponent from 'components/rating/Rating';
import { formatNames } from 'utils/format';
import classes from './Card.module.scss';

export interface ICard {
  onClick?: () => void;
  isDisabled?: boolean;
  hasBadge?: boolean;
  badgeText?: string;
  badgeType?:
    | 'info'
    | 'warning'
    | 'success'
    | 'error'
    | 'primary'
    | 'contrast'
    | 'disabled';
  hasWishlistBtn?: boolean;
  hasProgressBar?: boolean;
  hasRating?: boolean;
  hasPrice?: boolean;
  hasActionBtn?: boolean;
  actionBtnText?: string;
  action?: () => void;
  course: ICourse;
  autoBadge?: boolean;
  hideChangesIcon?: boolean;
}

const Card = ({
  onClick,
  isDisabled,
  hasBadge,
  badgeText,
  badgeType,
  hasWishlistBtn,
  hasProgressBar,
  hasRating,
  hasPrice,
  hasActionBtn,
  actionBtnText,
  action,
  course,
  autoBadge,
  hideChangesIcon
}: ICard) => {
  const { userData } = useContext(userContext);
  const [isHover, setIsHover] = useState(false);
  const isCreatorOrAdmin =
    userData?._id === course.creator_id || userData.isAdmin;

  const {
    name,
    price,
    creator,
    _id: id,
    category,
    currency,
    thumbnail,
    show_video_preview,
    short_description
  } = course || {};

  return (
    <div
      onClick={() => onClick && onClick()}
      className={`${classes['card']} ${
        onClick ? classes['card--clickable'] : ''
      } ${isDisabled ? classes['card--disabled'] : ''}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Tooltip id={`course-${id}`} text={short_description || ''}>
        <div
          className={`${classes['card__img-wrapper']} ${
            !thumbnail ? classes['card__img-wrapper--empty'] : ''
          }`}
        >
          {!(course?.hover_play && course?.video_preview && isHover) && (
            <div className={classes['card__img-wrapper--gradient']}>
              <div className={classes['icons-container']}>
                {!!course?.video_preview &&
                  course?.hover_play &&
                  show_video_preview && <PlayArrow />}
              </div>
              {isCreatorOrAdmin &&
                course?.has_draft_copy &&
                !hideChangesIcon && (
                  <div className={classes['changes-label']}>Changes</div>
                )}
            </div>
          )}
          {hasBadge &&
            (!!course && autoBadge ? (
              <RenderBadge course={course} />
            ) : (
              <Badge text={badgeText} badgeType={badgeType} size="lg" />
            ))}
          {hasWishlistBtn && (
            <Button
              onClick={() => {}}
              variant="neutral"
              icon={1 ? HeartOutline : HeartFill}
              isIconBtn
            />
          )}
          {show_video_preview &&
          isHover &&
          !!course?.video_preview &&
          course?.hover_play ? (
            <Player
              src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + course.video_preview}
              isPlaying
              hasControls
              isCard
              isMuted
            />
          ) : (
            <>
              {thumbnail ? (
                <img
                  src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail}
                  alt={!!name ? name : 'Course Thumbnail'}
                />
              ) : (
                <Image />
              )}
            </>
          )}
        </div>
      </Tooltip>
      <div className={classes['card__content']}>
        <Badge
          text={category?.name || 'Category'}
          size="sm"
          variant="outline"
        />
        <div className={classes['card__title']}>
          {name || '(Untitled Course)'}
        </div>
        {!!creator && (
          <div className={classes['card__text']}>
            {formatNames(creator.first_name + ' ' + creator.last_name)}
          </div>
        )}
        {hasProgressBar && <Progress progress={30} />}
        {hasRating && (
          <RatingComponent
            rating={course.avg_rating}
            count={course.ratings}
            readOnly
          />
        )}
        {hasPrice && currency && price && (
          <div className={classes['card__price']}>
            <img
              src={currencyObj[currency]?.symbol}
              alt={currency ? currencyObj[currency].name : 'USDC'}
              width={32}
              height={32}
            />
            <span>
              <span className={classes['card__price--number']}>
                {' '}
                {getPriceInUsdc(+price)}
              </span>{' '}
              {currency ? currencyObj[currency]?.name : ''}
            </span>
          </div>
        )}
        {hasActionBtn && (
          <Button onClick={action} variant="neutral">
            {actionBtnText}
          </Button>
        )}
      </div>
    </div>
  );
};

Card.defaultProps = {
  onClick: null,
  isDisabled: false,
  hasBadge: false,
  badgeText: 'Best Seller',
  badgeType: 'info',
  hasWishlistBtn: false,
  hasProgressBar: false,
  hasPrice: false,
  hasActionBtn: false,
  actionBtnText: '',
  action: () => {}
};

export default Card;
