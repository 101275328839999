import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Actions from 'routes/dashboard/profile/components/Actions';
import { IPurchasedItem, IPurchasedItemsData } from 'query/course-module/dto';
import { adminPurchasesDataQuery } from 'query/course-module/queries';
import Error from 'components/error/Error';
import Table from 'components/table/Table';
import Button from 'components/buttons/Button';
import NoResults from 'components/noResults/NoResults';
import { IOption } from 'components/input/InputChoice';
import { PurchasesCols } from 'components/table/PurchasesCols';
import ClaimFundsPopup from 'components/popup/ClaimFundsPopup';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import { popupContext } from 'context/popupContext';
import { TRANSACTION_STATUS } from 'utils/constants';
import { isSafari } from 'utils/helpers';
import classes from './Transactions.module.scss';

const sortOptions = [
  { label: 'Name A-Z', value: 'course_name' },
  { label: 'Name Z-A', value: '-course_name' },
  { label: 'Price ascending', value: 'course_price' },
  { label: 'Price descending', value: '-course_price' },
  { label: 'Date Purchased - ASC', value: 'buy_tx_confirmed_at' },
  { label: 'Date Purchased - DESC', value: '-buy_tx_confirmed_at' }
];

const filterName = 'Status';

const filterOptions = [
  {
    label: 'Claimed',
    value: TRANSACTION_STATUS.CLAIMED.toString()
  },
  {
    label: 'Ready To Claim',
    value: TRANSACTION_STATUS.CLAIM.toString()
  },
  {
    label: 'Refunded',
    value: TRANSACTION_STATUS.REFUNDED.toString()
  },
  {
    label: 'Manually Refunded',
    value: TRANSACTION_STATUS.MANUALLY_REFUNDED.toString()
  },
  {
    label: 'Locked',
    value: TRANSACTION_STATUS.LOCKED.toString()
  }
];

const Transactions = () => {
  const { search: urlParams } = useLocation();
  const {
    page: pageNumber,
    search,
    sort,
    status
  } = queryString.parse(urlParams);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const { setPopup } = useContext(popupContext);
  const pageSize: number = 9;
  const { showFilter, updateFilter } = useContext(appContext);
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [filterValue, setFilterValue] = useState<IOption[]>(
    filterOptions.filter((option) => status?.includes(option.value))
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1
      ? `&search[course_name]=${searchValue}&search[course_creator_name]=${searchValue}`
      : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}${
    !!status
      ? `&purchase_action=${filterValue
          .map((filter) => `${filter.value}`)
          .join(',')}`
      : ''
  }`;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    IPurchasedItemsData
  >(adminPurchasesDataQuery(queryParams));

  const hasData = !!data && !!data?.result.length;
  const purchasesCols = useMemo(
    () =>
      PurchasesCols({
        hideActionBtn: false,
        isSelectable: true,
        isAdmin: true
      }),
    []
  );

  const filterHandler = (value: IOption[]) => {
    setPage(1);
    setFilterValue(value);
  };

  return (
    <div
      className={`
      ${classes['admin-transactions']} 
      ${showFilter ? classes['admin-transactions--grid'] : ''}
    `}
    >
      {!!showFilter && (
        <Actions
          hasSort
          hasFilter
          isInverted
          setPage={setPage}
          sortValue={sortValue}
          sortOptions={sortOptions}
          showFilterMenu={showFilter}
          onFilterClick={updateFilter}
          passSortValueUp={setSortValue}
          passFilterValueUp={filterHandler}
          filters={[
            {
              filterOptions,
              filterValue,
              filterName
            }
          ]}
        />
      )}
      {(!isMdMobile || (isMdMobile && !showFilter)) && (
        <div
          className={`${classes['admin-transactions__table-wrapper']} 
          ${isSafari ? classes['safari-support-overflow'] : ''}`}
        >
          <Actions
            hasSearch
            isInverted
            isDebounced
            setPage={setPage}
            hasSort={!showFilter}
            sortValue={sortValue}
            hasFilter={!showFilter}
            sortOptions={sortOptions}
            searchValue={searchValue}
            onFilterClick={updateFilter}
            passSortValueUp={setSortValue}
            searchPlaceholder="Search Courses or Creators"
            passSearchValueUp={setSearchValue}
          >
            <div className={classes['admin-courses__action-btns']}>
              <Button
                variant="contrast"
                minWidth="md"
                isDisabled={
                  !data?.result?.filter(
                    (purchase) =>
                      purchase.action === TRANSACTION_STATUS.CLAIM &&
                      // Claiming state
                      !(
                        !!purchase?.admin_fee_collect_tx_started_at &&
                        !purchase?.admin_fee_collect_tx_confirmed_at
                      )
                  ).length
                }
                onClick={() =>
                  setPopup(
                    <ClaimFundsPopup
                      data={
                        data?.result?.filter(
                          (purchase) =>
                            purchase.action === TRANSACTION_STATUS.CLAIM &&
                            // Claiming state
                            !(
                              !!purchase?.admin_fee_collect_tx_started_at &&
                              !purchase?.admin_fee_collect_tx_confirmed_at
                            )
                        ) as IPurchasedItem[]
                      }
                      isAdminPage
                    />
                  )
                }
              >
                Claim All
              </Button>
            </div>
          </Actions>
          {!!data?.result.length && (
            <Table
              columns={purchasesCols}
              data={data.result}
              islandProps={{
                primBtnText: 'Claim Selected',
                primBtnAction: (selectedRows) =>
                  setPopup(
                    <ClaimFundsPopup
                      isAdminPage
                      data={selectedRows.map((row: any) => row.original)}
                    />
                  )
              }}
              rowHeight={94}
              paginationProps={{
                activePage: page,
                setActivePage: setPage,
                pageSize: pageSize,
                totalCount: data.total_results as number,
                siblingCount: 1
              }}
            />
          )}
          {isLoading && (
            <TableSkeleton rows={pageSize} cols={purchasesCols.length} />
          )}
          {error && <Error error={error} />}
          {!hasData && !isLoading && !error && <NoResults />}
        </div>
      )}
    </div>
  );
};

export default Transactions;
