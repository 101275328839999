import { useNavigate } from 'react-router-dom';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as Verified } from 'assets/icons/verified.svg';
import { ICreator } from 'query/acc-module/dto';
import { REACT_APP_FILES_PUBLIC_DOMAIN } from 'utils/constants';
import Button from '../buttons/Button';
import Avatar from 'components/avatar/Avatar';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { formatNames } from 'utils/format';
import classes from './UserCard.module.scss';

export interface ICard {
  user: ICreator;
}

const UserCard = ({ user }: ICard) => {
  const navigate = useNavigate();
  const { pic, slug, first_name, last_name, additional_info, is_creator } =
    user || {};
  const { banner, about } = additional_info || {};

  return (
    <div
      className={classes['card']}
      onClick={() => navigate(`/user/${slug}/about/main-info`)}
    >
      <div className={classes['card__img-wrapper']}>
        {banner ? (
          <div
            className={classes['cover']}
            style={{
              background: `url(${REACT_APP_FILES_PUBLIC_DOMAIN}${banner}) no-repeat center`,
              backgroundSize: 'cover'
            }}
          />
        ) : (
          <div className={`${classes['cover']} ${classes['cover--empty']}`}>
            <Image />
          </div>
        )}
      </div>
      <div className={classes['card__content']}>
        <div className={classes['info']}>
          <Avatar src={pic} size="lg" />
          <div className={classes['name']}>
            {!!is_creator && <Verified />}
            <h5 className={`${classes['u-semiBold']} ${classes['u-body1']}`}>
              {formatNames(first_name + ' ' + last_name)}
            </h5>
          </div>
        </div>
        <h5 className={classes['u-body2']}>
          {about && <TextWithCharLimit text={about} limit={40} hideBtn />}
        </h5>

        <Button
          variant="neutral"
          onClick={() => navigate(`/user/${slug}/about/main-info`)}
        >
          View Profile
        </Button>
      </div>
    </div>
  );
};

export default UserCard;
