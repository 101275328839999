import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import Button from 'components/buttons/Button';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { ICourseForm, IFormik } from './CourseForm';
import Certificate from 'components/certificate/Certificate';
import { ITemplateDto, ITemplatesListDto } from 'query/course-module/dto';
import { allTemplatesDataQuery } from 'query/course-module/queries';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import { InputChoice } from 'components/input/InputChoice';
import classes from './Form.module.scss';

interface IStep4
  extends Pick<
    IFormik,
    'values' | 'errors' | 'touched' | 'setFieldValue' | 'handleBlur'
  > {
  isEdit: ICourseForm['isEdit'];
}

export const cpeOptions = [
  {
    value: 'true',
    label:
      'I do not wish to provide a certificate for completion of this course'
  }
];

export const Step4 = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  isEdit
}: IStep4) => {
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { slug } = useParams();
  const navigate = useNavigate();

  const [selectedTemplate, setSelectedTemplate] =
    useState<ITemplateDto | null>();

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ITemplatesListDto
  >({ ...allTemplatesDataQuery(), enabled: !!values.cpe });

  const { result: templates } = data || {};

  const templatesOptions = templates?.map((template) => ({
    ...template,
    label: template.name,
    value: template._id
  }));

  useEffect(() => {
    if (!templatesOptions?.length) return;
    if (!!values.cpe.length) {
      setSelectedTemplate(null);
      setFieldValue('template_id', []);
      return;
    }
    // CASE 1: A template_id value was returned by BE
    if (!!values.template_id[0]) {
      const selectedTemplate = templatesOptions.filter(
        (option) => option.value === values.template_id[0].value
      )[0];
      setSelectedTemplate(selectedTemplate);
      setFieldValue('template_id', [
        {
          label: selectedTemplate.name,
          value: selectedTemplate._id,
          img: selectedTemplate.thumbnail
        }
      ]);
      return;
    }
    // CASE 2: A template_id was NOT returned by BE, so set the default
    const defaultTemplate = templatesOptions[templatesOptions.length - 1];
    setSelectedTemplate(defaultTemplate);
    setFieldValue('template_id', [
      {
        label: defaultTemplate.name,
        value: defaultTemplate._id,
        img: defaultTemplate.thumbnail
      }
    ]);
  }, [templatesOptions?.length, values.cpe.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={classes['info-box']}>
        <InputChoice
          name="cpe"
          type="checkbox"
          onChange={(value) => {
            setFieldValue('cpe', value);
            setFieldValue('template_id', []);
          }}
          options={cpeOptions}
          selectedOptions={values.cpe}
          isMulti
        />
      </div>
      {!values.cpe.length && (
        <>
          {!!templatesOptions?.length ? (
            <>
              <div>
                <ReactSelectWrapper
                  name="template"
                  title="Certificate Template"
                  hasTooltip
                  tooltipText="Select a template for the completion certificated of this course."
                  placeholder="Select Template"
                  isRequired
                  options={templatesOptions}
                  value={values.template_id}
                  isSearchable
                  onChange={(e) => {
                    setSelectedTemplate(e);
                    setFieldValue('template_id', [
                      {
                        label: e.name,
                        value: e._id,
                        img: e.thumbnail
                      }
                    ]);
                  }}
                  onBlur={(e) => handleBlur(e)}
                  error={
                    errors.template_id && touched.template_id
                      ? (errors.template_id as string)
                      : ''
                  }
                  isLoading={isLoading}
                />
                <Button
                  onClick={() => {
                    navigate('/templates/create', {
                      state: {
                        savedFormData: {
                          ...values,
                          prevRoute: {
                            name: isEdit ? 'Edit Course' : 'Course Creation',
                            link: isEdit
                              ? `/courses/${slug}/edit?changes=${changes}`
                              : '/courses/create'
                          }
                        }
                      }
                    });
                  }}
                  icon={Plus}
                  iconPosition="left"
                  variant="link-neutral"
                >
                  Create Custom Template
                </Button>
              </div>
              {!!selectedTemplate && (
                <Certificate data={selectedTemplate} isImgOnly isOriginalSize />
              )}
            </>
          ) : (
            <>
              {isLoading && <Loader />}
              {error && <Error error={error} />}
            </>
          )}
          {/*<InputWrapper*/}
          {/*  name="name"*/}
          {/*  type="text"*/}
          {/*  variant="outline"*/}
          {/*  onChange={() => {}}*/}
          {/*  value={''}*/}
          {/*  title="Course Name"*/}
          {/*  placeholder="Enter Course Name"*/}
          {/*  hasTooltip*/}
          {/*  tooltipText="Enter a name of your Course."*/}
          {/*  isRequired*/}
          {/*/>*/}
        </>
      )}
    </>
  );
};

export default IStep4;
