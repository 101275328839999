import { useContext, useEffect } from 'react';
import classes from './ModalWrapper.module.scss';
import { popupContext } from 'context/popupContext';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import Button from '../../buttons/Button';

interface IModalWrapperProps {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  size?: 'sm' | 'md' | 'lg' | 'rectangle';
  isClosable?: boolean;
}

function ModalWrapper({
  onClose,
  size,
  isClosable,
  className,
  children
}: IModalWrapperProps) {
  const { clearPopup } = useContext(popupContext);

  // Remove popup on Esc key press
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        closeHandler();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    // clean up event listener
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  const closeHandler = () => {
    if (isClosable) {
      clearPopup();
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <>
      <div className={`${classes['popup-wrapper']}`}>
        <div className={classes['backdrop']} onClick={closeHandler} />
        <div
          className={`${classes['content-wrapper']} 
          ${size ? classes[size] : ''}
          ${className ? classes[className] : ''}`}
        >
          {isClosable && (
            <Button
              variant="neutral"
              size="sm"
              onClick={closeHandler}
              icon={Close}
              isIconBtn
              className={classes['close-icon']}
            />
          )}
          {children}
        </div>
      </div>
    </>
  );
}

ModalWrapper.defaultProps = {
  isClosable: true
};

export { ModalWrapper };
