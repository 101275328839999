import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import Error from 'components/error/Error';
import Table from 'components/table/Table';
import NoResults from 'components/noResults/NoResults';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import { AdminCourseHistoryCols } from 'components/table/AdminCourseHistoryCols';
import { adminCourseHistory } from 'query/course-module/queries';
import { ICourse, ICourseHistoryData } from 'query/course-module/dto';
import classes from './CourseHistory.module.scss';

interface ICertificateProps {
  course: ICourse;
}

const CourseHistory = ({ course }: ICertificateProps) => {
  const { search: urlParams } = useLocation();
  const { page: pageNumber } = queryString.parse(urlParams);

  const pageSize: number = 5;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);

  const queryParams = `?&limit=${pageSize}&page=${page}`;

  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ICourseHistoryData
  >(adminCourseHistory(course.slug, queryParams));

  const hasData = !!data && !!data?.result?.length;
  const purchasesCols = useMemo(() => AdminCourseHistoryCols(), []);

  return (
    <div className={classes['admin-course-history']}>
      {!!data?.result?.length && (
        <Table
          columns={purchasesCols}
          data={data.result}
          rowHeight={94}
          paginationProps={{
            activePage: page,
            setActivePage: setPage,
            pageSize: pageSize,
            totalCount: data.total_results as number,
            siblingCount: 1
          }}
        />
      )}
      {isLoading && (
        <TableSkeleton rows={pageSize} cols={purchasesCols.length} />
      )}
      {error && <Error error={error} />}
      {!hasData && !isLoading && !error && <NoResults />}
    </div>
  );
};

export default CourseHistory;
