import { COURSE_REVIEW_STATUS, COURSE_STATUS } from 'utils/constants';
import Badge from './Badge';
import { ICourse } from 'query/course-module/dto';
import { isCourseStatus } from 'utils/helpers';

interface IRenderBadgeProps {
  course: ICourse;
}

export const RenderBadge = ({ course }: IRenderBadgeProps) => {
  // "Draft" badge for unfinished drafts, finished drafts and draft-of-published courses with review_status === COURSE_REVIEW_STATUS.INITIAL
  // 1. Unfinished draft
  const isDraft =
    course.status === COURSE_STATUS.DRAFT &&
    course.review_status === COURSE_REVIEW_STATUS.INITIAL &&
    course.not_finished;
  // 2. Finished draft
  const isFinishedDraft =
    course.status === COURSE_STATUS.DRAFT &&
    course.review_status === COURSE_REVIEW_STATUS.INITIAL &&
    !course.not_finished;
  // 3. Draft of published
  const isDraftOfPublished =
    course?.is_draft_copy &&
    course.review_status === COURSE_REVIEW_STATUS.INITIAL;

  // "Submitted" badge for submitted unpublished courses and submitted draft-of-published courses
  // 1. Submitted course
  const isSubmitted = course.review_status === COURSE_REVIEW_STATUS.IN_REVIEW;

  // 2. Submitted draft course
  const isSubmittedDraft =
    course.status === COURSE_STATUS.DRAFT &&
    course.review_status === COURSE_REVIEW_STATUS.IN_REVIEW;

  // 2. Submitted draft of published course
  const isSubmittedForRepublish =
    (course.status === COURSE_STATUS.PUBLISHED ||
      course.status === COURSE_STATUS.PAUSED) &&
    course.review_status === COURSE_REVIEW_STATUS.IN_REVIEW &&
    course?.is_draft_copy;

  // "Approved" badge for APPROVED finished drafts and approved draft-of-published courses
  // 1. Approved draft of NON-published course
  const isApprovedDraft =
    course.status === COURSE_STATUS.DRAFT &&
    course.review_status === COURSE_REVIEW_STATUS.APPROVED;

  // 2. Approved draft of published
  const isApprovedForRepublish =
    course?.is_draft_copy &&
    course.review_status === COURSE_REVIEW_STATUS.APPROVED &&
    (course.status === COURSE_STATUS.PUBLISHED ||
      course.status === COURSE_STATUS.PAUSED);

  // "Rejected" badge for rejected draft and draft of published courses
  // 1. Rejected draft
  const isRejected =
    course.status === COURSE_STATUS.DRAFT &&
    course.review_status === COURSE_REVIEW_STATUS.REJECTED &&
    !course?.is_draft_copy;
  // 2. Rejected draft of published
  const isRejectedDraftOfPublished =
    (course.status === COURSE_STATUS.PUBLISHED ||
      course.status === COURSE_STATUS.PAUSED) &&
    COURSE_REVIEW_STATUS.REJECTED &&
    course?.is_draft_copy;

  const isTakedown =
    course.status === COURSE_STATUS.PAUSED && !course.is_draft_copy;

  // "Published" badge for ANY COURSE_STATUS if review_status is PUBLISHED AND course is not draft of published
  const isPublished =
    course.status === COURSE_STATUS.PUBLISHED && !course?.is_draft_copy;

  if (isPublished) {
    if (isCourseStatus('isRepublishing', course))
      return <Badge text="PROCESSING" badgeType="info" />;
    return <Badge text="PUBLISHED" badgeType="primary" />;
  }
  if (isTakedown) {
    if (isCourseStatus('isRepublishing', course))
      return <Badge text="PROCESSING" badgeType="info" />;
    return <Badge text="TAKEN DOWN" badgeType="error" />;
  }
  if (isDraft || isFinishedDraft || isDraftOfPublished)
    return <Badge text="DRAFT" badgeType="primary" variant="contrast" />;
  if (isSubmitted || isSubmittedDraft || isSubmittedForRepublish)
    return <Badge text="SUBMITTED" badgeType="warning" />;
  if (
    (isApprovedDraft ||
      isApprovedForRepublish ||
      course.status === COURSE_STATUS.PUBLISHING) &&
    (isCourseStatus('isRepublishing', course) ||
      isCourseStatus('isPublishing', course))
  )
    return <Badge text="PROCESSING" badgeType="info" />;
  if (isApprovedDraft || isApprovedForRepublish)
    return <Badge text="APPROVED" badgeType="success" />;
  if (isRejected || isRejectedDraftOfPublished)
    return <Badge text="REJECTED" badgeType="error" />;
  return <></>;
};
