import { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form } from 'formik';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import { IUser, ROLE_FLAG, userContext } from 'context/userContext';
import { ILoginFormFields, LoginSchema } from 'utils/yupSchemas';
import { setTokenInStorage } from 'utils/storage';
import { loginMutation } from 'query/acc-module/mutations';
import { toastContext } from 'context/toastContext';
import { IOption, InputChoice } from 'components/input/InputChoice';
import classes from './Form.module.scss';

const rememberMeOptions: IOption[] = [
  {
    label: 'Remember me',
    value: 'true'
  }
];

const initialValues: ILoginFormFields = {
  email: '',
  password: '',
  remember: rememberMeOptions
};

interface IDecodedToken extends IUser {
  id: string;
  iat: string;
  exp: number;
  is_student: boolean;
}

const LoginForm = () => {
  const { setToast, clearToast } = useContext(toastContext);
  const { userData, setUser } = useContext(userContext);
  const navigate = useNavigate();
  const { search: redirectPathParam } = useLocation();
  const { redirect_url } = queryString.parse(redirectPathParam);

  // Login mutation
  const { isLoading, mutate: handleLogin } = useMutation({
    ...loginMutation(),
    onSuccess: (token: string) => {
      const decodedToken: IDecodedToken = jwt_decode(token);
      const { id, email, role_access, role_flag, exp } = decodedToken;

      setTokenInStorage({
        token: token,
        exp
      });

      setUser({
        ...userData,
        _id: id,
        email,
        role_access,
        role_flag,
        isAdmin: role_flag === ROLE_FLAG.ADMIN
      });

      clearToast();

      if (role_flag === ROLE_FLAG.ADMIN) return navigate('/admin/dashboard');
      else navigate((redirect_url as string) || '/user');
    },
    onError: (err: Error) => {
      setToast({
        position: 'top',
        type: 'error',
        msg: err.message,
        autoClose: true
      });
    }
  });

  return (
    <div className={classes['wrapper']}>
      <h4 className={classes['u-bold']}>Login</h4>
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          if (isLoading) return;
          handleLogin(values);
        }}
        validateOnMount
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          submitForm,
          handleBlur
        }) => (
          <Form className={classes['form']}>
            <InputWrapper
              name="email"
              type="text"
              onChange={(value) => setFieldValue('email', value)}
              onBlur={(e) => handleBlur(e)}
              value={values.email}
              title="E-mail"
              placeholder="Enter E-mail ..."
              isRequired
              error={errors.email && touched.email ? errors.email : ''}
            />
            <InputWrapper
              name="password"
              type="password"
              onChange={(value) => setFieldValue('password', value)}
              onBlur={(e) => handleBlur(e)}
              value={values.password}
              title="Password"
              placeholder="Enter Password ..."
              isRequired
              error={errors.password && touched.password ? errors.password : ''}
            />
            <div className={classes['options']}>
              <InputChoice
                name="remember"
                type="checkbox"
                onChange={(value) => setFieldValue('remember', value)}
                options={rememberMeOptions}
                isMulti
                selectedOptions={values.remember}
              />
              <p>
                <Link to={'/forgot-password'}>Forgot Password?</Link>
              </p>
            </div>
            <Button
              type="submit"
              isDisabled={!!errors.email || !!errors.password}
              isLoading={isLoading}
            >
              Sign In
            </Button>
            <Divider dir="horizontal" />
          </Form>
        )}
      </Formik>
      <p>
        You still don’t have a registration?{' '}
        <Link to={'/register'}>Register Here</Link>
      </p>
    </div>
  );
};

export default LoginForm;
