import React from 'react';
import classes from './SwitchInput.module.scss';

interface ISwitchInput {
  className?: string;
  label: string;
  size: 'lg' | 'sm';
  onClick: (isShow: boolean) => void;
  value: boolean;
  isDisabled: boolean;
  error?: string;
}

const SwitchInput = ({
  className,
  label,
  size,
  onClick,
  value,
  isDisabled,
  error
}: ISwitchInput) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClick(!value);
  };

  return (
    <div>
      <div
        data-size={size}
        className={`${classes['switch__container']} ${className}`}
        onClick={(e) => !isDisabled && handleClick(e)}
      >
        <label className={classes['switch']}>
          <input type="checkbox" checked={value} readOnly />
          <span
            className={`
            ${classes['slider']}
            ${isDisabled ? classes['slider--disabled'] : ''}
            ${value ? classes['slider--active'] : ''}
          `}
          />
        </label>
        <div className={classes['switch__label']}>{label}</div>
      </div>
      {!!error && <div className={classes['input__error']}>{error}</div>}
    </div>
  );
};

SwitchInput.defaultProps = {
  label: '',
  size: 'lg',
  variant: 'contrast',
  onClick: () => {},
  value: null,
  isDisabled: false
};

export default SwitchInput;
