import React from 'react';
import { FormikErrors } from 'formik';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import classes from './FormSteps.module.scss';

interface IFormSteps {
  steps: string[];
  currentStep: number;
  finishedSteps: number;
  setCurrentStep: (step: number) => void;
  errors?: FormikErrors<any>;
  fieldsByStep?: {
    [key: number]: string[];
  };
  onStepClick?: (i: number) => void;
}

const FormSteps = ({
  steps,
  currentStep,
  finishedSteps,
  setCurrentStep,
  errors,
  fieldsByStep,
  onStepClick
}: IFormSteps) => {
  const checkStepForErrors = (step: number) => {
    const hasErrors = JSON.stringify(errors) !== '{}';
    const keys = hasErrors ? Object.keys(errors || {}) : [];
    const stepFields = fieldsByStep ? fieldsByStep[step] : [];
    stepFields.forEach((field: any) => keys.filter((e) => e === field));
    return keys.some((k) => stepFields.includes(k));
  };
  return (
    <div className={classes['wrapper']}>
      <div className={classes['line']} />
      {steps.map((step, i) => {
        const hasStepError = checkStepForErrors(i + 1);
        return (
          <div
            key={i}
            className={`${classes['step']} ${
              onStepClick ? classes['step--clickable'] : ''
            } ${finishedSteps < i ? classes['step--disabled'] : ''} ${
              finishedSteps > i ? classes['step--completed'] : ''
            } ${i === currentStep ? classes['step--active'] : ''} ${
              hasStepError ? classes['step--error'] : ''
            }`}
            onClick={() => {
              onStepClick && onStepClick(i);
              finishedSteps >= i && setCurrentStep(i);
            }}
          >
            <div className={classes['step__number__wrapper']}>
              <div className={classes['step__number']}>
                {finishedSteps < i + 1 ? (
                  i + 1
                ) : hasStepError ? (
                  <Cross />
                ) : (
                  <Check />
                )}
              </div>
            </div>
            <div className={classes['step__title']}>{step}</div>
          </div>
        );
      })}
    </div>
  );
};

export default FormSteps;
