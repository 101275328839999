import { useContext, useState } from 'react';
import { ReactComponent as copyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as checkIcon } from 'assets/icons/check-circle.svg';
import { useAccount } from 'wagmi';
import { windowContext } from 'context/windowsContext';
import { appContext } from 'context/appContext';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import Button from 'components/buttons/Button';
import PopupTitleAndMessage from 'components/popup/common/PopupTitleAndMessage';
import { formatAddress } from 'utils/format';
import classes from './DepositPopup.module.scss';

const DepositPopup = () => {
  const [copied, setCopied] = useState<boolean>(false);
  const { theme } = useContext(appContext);
  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;
  const { address } = useAccount();

  return (
    <ModalWrapper size="md">
      <div data-theme={theme}>
        <div className={classes['deposit-popup']}>
          <PopupTitleAndMessage
            title="How do I get digital currency into my wallet?"
            msg="You can send Matic, ETH or any token from another wallet. Simply
            copy the wallet address connected to your account and go to your
            existing wallet or exchange and send funds to your wallet address.
            You can also request funds from a friend by sharing your wallet
            address."
          />
          <div className={classes['wallet-container']}>
            <div
              className={`${classes['u-text--primary']} ${classes['title']}`}
            >
              <span className={classes['u-text--small']}>
                Your Wallet Address
              </span>
            </div>
            <div className={classes['wallet']}>
              <div>
                {formatAddress(
                  address as string,
                  !isLgMobile ? 10 : 0,
                  !isLgMobile ? -10 : 0
                )}
              </div>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(address as string);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
                iconPosition="right"
                icon={!copied ? copyIcon : checkIcon}
              >
                {!copied ? 'Copy' : 'Copied'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DepositPopup;
