import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { windowContext } from 'context/windowsContext';
import Divider from 'components/divider/Divider';
import Badge from 'components/badge/Badge';
import { IResources } from './Resources';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  IVideo
} from 'query/course-module/dto';
import classes from './VideoList.module.scss';

interface IVideoListCardProps extends Pick<IVideoListProps, 'courseProps'> {
  video: IVideo;
}

const VideoListCard = ({ video, courseProps }: IVideoListCardProps) => {
  const { slug, videoId } = useParams();
  const navigate = useNavigate();

  const { windowSize } = useContext(windowContext);
  const { isLgMobile } = windowSize;

  const { _id: id, thumbnail, title, path, file_count, exam } = video;
  const {
    isCourseActivated,
    course: { course_relationship, purchased }
  } = courseProps;

  const renderBadge = () => {
    if (
      (course_relationship === COURSE_RELATIONSHIP.PURCHASED ||
        course_relationship === COURSE_RELATIONSHIP.NONE) &&
      !!purchased?.videos_watched
    ) {
      if (!!purchased?.videos_watched[id])
        return <Badge text="WATCHED" badgeType="success" size="sm" />;
      if (!!purchased?.videos_checkpoint[id] && !purchased?.videos_watched[id])
        return <Badge text="STARTED" badgeType="warning" size="sm" />;
      return <Badge text="NOT WATCHED" badgeType="disabled" size="sm" />;
    }
  };

  const clickCardHandler = () =>
    navigate(`/courses/${slug}/${id}?&changes=${COURSE_CHANGES.NO_CHANGES}`);

  return (
    <div
      className={`
        ${classes['card']} 
        ${id === videoId ? classes['card--active'] : ''}  
      `}
      onClick={clickCardHandler}
    >
      <div
        className={`
          ${classes['card__img-wrapper']} 
          ${!thumbnail ? classes['card__img-wrapper--empty'] : ''}`}
      >
        <div className={classes['badge-wrapper']}>{renderBadge()}</div>
        {thumbnail ? (
          <img
            src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail}
            alt={`Video thumbnail${title ? `: ${title}` : ''}`}
            className={!isCourseActivated ? classes['disabled'] : ''}
          />
        ) : (
          <Play />
        )}
        {path && isCourseActivated && (
          <span
            className={classes['card__img__action-overlay']}
            onClick={clickCardHandler}
          >
            <Play />
          </span>
        )}
        {!isCourseActivated && (
          <span
            className={`${classes['card__img__action-overlay']} ${classes['card__img__action-overlay--enabled']}`}
          />
        )}
      </div>
      <div className={classes['card__content']}>
        <div className={classes['card__content--top']}>
          <div className={classes['card__title']}>{title}</div>
          <div className={classes['card__text--secondary']}>
            {!!exam?.length && (
              <span>{`${exam.length} Question${
                exam.length > 1 ? 's' : ''
              } Test`}</span>
            )}
            {!isLgMobile && !!exam?.length && !!file_count && (
              <Divider dir="vertical" />
            )}{' '}
            {!!file_count && (
              <span>{`${file_count} Attached Document${
                file_count > 1 ? 's' : ''
              }`}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface IVideoListProps {
  videos: IVideo[];
  courseProps: IResources['courseProps'];
}

const VideoList = ({ videos, courseProps }: IVideoListProps) => {
  return (
    <div className={classes['list__wrapper']}>
      <div className={classes['list']}>
        {videos.map((video) => (
          <VideoListCard
            key={video._id}
            video={video}
            courseProps={courseProps}
          />
        ))}
      </div>
    </div>
  );
};

export default VideoList;
