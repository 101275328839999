import React from 'react';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { REACT_APP_FILES_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './Banner.module.scss';

interface IBanner {
  src?: string;
}

const Banner = (props: IBanner) => {
  const { src } = props;

  if (!src)
    return (
      <div className={`${classes['cover']} ${classes['cover--empty']}`}>
        <Image />
      </div>
    );
  return (
    <div
      className={classes['cover']}
      style={{
        background: `url(${REACT_APP_FILES_PUBLIC_DOMAIN}${src}) no-repeat center`,
        backgroundSize: 'cover'
      }}
    />
  );
};

export default Banner;
