import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Web } from 'assets/icons/web.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Instagram } from 'assets/icons/ig.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import Button from 'components/buttons/Button';
import { IMeDataDto } from 'query/acc-module/dto';
import VisibilityAction from '../VisibilityAction';
import { isFieldPublic, truncateText } from 'utils/format';
import classes from './About.module.scss';

interface ISocialInfoProps
  extends Pick<IMeDataDto, 'additional_info' | 'hidden_fields'> {
  isPublic?: boolean;
}

const SocialInfo = ({
  additional_info,
  hidden_fields,
  isPublic
}: ISocialInfoProps) => {
  const navigate = useNavigate();

  const isWebsitePublic = isFieldPublic(hidden_fields, 'website');
  const isTwitterPublic = isFieldPublic(hidden_fields, 'twitter');
  const isIgPublic = isFieldPublic(hidden_fields, 'instagram');
  const isFacebookPublic = isFieldPublic(hidden_fields, 'facebook');

  const { website, twitter, instagram, facebook } = additional_info || {};

  return (
    <div className={classes['wrapper']}>
      <div className={classes['title']}>
        <h5 className={classes['u-light']}>Social Media Links</h5>
        {!isPublic && (
          <Button
            onClick={() => navigate('/user/about/social-info/edit')}
            icon={Edit}
            iconPosition="left"
            variant="link-neutral"
            withPadding={false}
          >
            Edit
          </Button>
        )}
      </div>
      <div className={classes['details']}>
        <div>
          <div className={classes['details__title']}>
            Website {!isWebsitePublic && <span>(Hidden)</span>}
          </div>
          <div className={classes['details__content']}>
            <Link
              to={website?.includes('http') ? website : `//${website}`}
              target="_blank"
              className={classes['details__content__social-item']}
              onClick={(e) => !website && e.preventDefault()}
              style={{ textDecoration: !!website ? 'underline' : 'none' }}
            >
              <Web />
              {truncateText(website, 20)}
            </Link>
          </div>
        </div>
        {!isPublic && (
          <VisibilityAction
            tooltipId="tooltip-website-btn"
            field="website"
            isPublic={isWebsitePublic}
          />
        )}
      </div>
      <div className={classes['details']}>
        <div>
          <div className={classes['details__title']}>Social Links</div>
          <div className={classes['details__content']}>
            <div className={classes['details__content__social-item-wrapper']}>
              <div>
                <Link
                  to={`https://twitter.com/${twitter}`}
                  target="_blank"
                  className={classes['details__content__social-item']}
                  onClick={(e) => !twitter && e.preventDefault()}
                  style={{ textDecoration: !!twitter ? 'underline' : 'none' }}
                >
                  <Twitter />
                  {truncateText(twitter, 20)}
                </Link>
                {!isTwitterPublic && <span>(Hidden)</span>}
              </div>
              {!isPublic && (
                <VisibilityAction
                  tooltipId="tooltip-twitter-btn"
                  field="twitter"
                  isPublic={isTwitterPublic}
                />
              )}
            </div>
            <div className={classes['details__content__social-item-wrapper']}>
              <div>
                <Link
                  to={`https://www.instagram.com/${instagram}`}
                  target="_blank"
                  className={classes['details__content__social-item']}
                  onClick={(e) => !instagram && e.preventDefault()}
                  style={{ textDecoration: !!instagram ? 'underline' : 'none' }}
                >
                  <Instagram />
                  {truncateText(instagram, 20)}
                </Link>
                {!isIgPublic && <span>(Hidden)</span>}
              </div>
              {!isPublic && (
                <VisibilityAction
                  tooltipId="tooltip-ig-btn"
                  field="instagram"
                  isPublic={isIgPublic}
                />
              )}
            </div>
            <div className={classes['details__content__social-item-wrapper']}>
              <div>
                <Link
                  to={`https://www.facebook.com/${facebook}`}
                  target="_blank"
                  className={classes['details__content__social-item']}
                  onClick={(e) => !facebook && e.preventDefault()}
                  style={{ textDecoration: !!facebook ? 'underline' : 'none' }}
                >
                  <Facebook />
                  {truncateText(facebook, 20)}
                </Link>
                {!isFacebookPublic && <span>(Hidden)</span>}
              </div>
              {!isPublic && (
                <VisibilityAction
                  tooltipId="tooltip-facebook-btn"
                  field="facebook"
                  isPublic={isFacebookPublic}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialInfo;
