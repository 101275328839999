import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, FormikErrors, FormikTouched } from 'formik';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import Button from 'components/buttons/Button';
import Summary, { IVideoFormFieldsExtended } from './Summary';
import GenericPopup from 'components/popup/GenericPopup';
import { editVideoMutation } from 'query/course-module/mutations';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { COURSE_CHANGES, ICourse } from 'query/course-module/dto';
import { IVideoFormFields, VideoSchema } from 'utils/yupSchemas';
import { COURSE_STATUS } from 'utils/constants';
import classes from './Form.module.scss';

export interface IFormik {
  errors: FormikErrors<IVideoFormFields>;
  touched: FormikTouched<IVideoFormFields>;
  values: IVideoFormFields;
  setFieldValue: (
    fieldName: string,
    value: string | boolean | any[] | null | undefined
  ) => void;
  setFieldError: (fieldName: string, value: string) => void;
  setFieldTouched: (fieldName: string, value: boolean) => void;
  submitForm: () => void;
  handleBlur: (e: React.FocusEvent<HTMLElement>) => void;
  isValid: boolean;
}

interface IRenderStep extends IFormik {
  step: number;
}

interface IVideoForm {
  currentStep: number;
  setStep: (step: number) => void;
  course: ICourse;
  videoId: string;
  defaultFormData: IVideoFormFields;
}

const VideoForm = ({
  currentStep,
  setStep,
  course,
  videoId,
  defaultFormData
}: IVideoForm) => {
  const queryClient = useQueryClient();
  const { setPopup, clearPopup } = useContext(popupContext);
  const { setToast, clearToast } = useContext(toastContext);

  const { _id: courseId } = course;

  // Edit video mutation
  const { isLoading: isLoadingCreate, mutate: handleEdit } = useMutation({
    ...editVideoMutation(),
    onError: (err: Error) =>
      setToast({
        type: 'error',
        msg: err.message
      })
  });

  const renderPrevBtn = () => (
    <Button
      type="button"
      variant="neutral"
      onClick={() => {
        clearToast();
        setStep(currentStep - 1);
      }}
      icon={Arrow}
      iconRotate={270}
      iconPosition="left"
    >
      Previous Step
    </Button>
  );

  const renderStep = ({
    step,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleBlur,
    isValid
  }: IRenderStep) => {
    if (step === 0) {
      return (
        <Step1
          setStep={setStep}
          currentStep={currentStep}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
          clearPopup={clearPopup}
        />
      );
    }

    if (step === 1) {
      return (
        <Step2
          setStep={setStep}
          currentStep={currentStep}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          handleBlur={handleBlur}
          renderPrevBtn={renderPrevBtn}
        />
      );
    }

    if (step === 2) {
      return (
        <Step3
          setStep={setStep}
          currentStep={currentStep}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          renderPrevBtn={renderPrevBtn}
        />
      );
    }

    return (
      <>
        <Summary
          data={{
            ...values,
            ...{
              exam:
                !!values?.has_test?.length && buildExamData(values).length > 0
                  ? buildExamData(values)
                  : []
            },
            completion_score:
              !!values?.has_test?.length && buildExamData(values).length > 0
                ? values.completion_score
                : null
          }}
        />
        <div className={classes['btns-wrapper']}>
          {renderPrevBtn()}
          <Button
            type="submit"
            icon={Check}
            isDisabled={!isValid}
            isLoading={isLoadingCreate}
          >
            Finish
          </Button>
        </div>
      </>
    );
  };

  const buildExamData = (values: IVideoFormFields) => {
    const questionGroups = Object.entries(values)
      .filter(([k, _]) => k.includes('exam'))
      .reduce((acc: any, [key, val]) => {
        const [, fieldName, id] = key.split('_');
        if (!acc[id]) acc[id] = {};
        if (val) {
          if (fieldName === 'question') acc[id]['label'] = val;
          if (fieldName === 'answer')
            acc[id]['answers'] =
              acc[id]['answers'] && acc[id]['answers'].length > 0
                ? [...acc[id]['answers'], val]
                : [val];
          if (fieldName === 'correctAnswer') acc[id]['correct_answer'] = val;
        }
        return acc;
      }, {});

    const arr = Object.keys(questionGroups)
      .map((d) => {
        return {
          label: questionGroups[d].label,
          answers: questionGroups[d].answers,
          correct_answer: parseInt(questionGroups[d].correct_answer)
        };
      })
      .filter((q) => q.label && q.answers && q.correct_answer);

    return arr.filter((element) => Object.keys(element).length !== 0);
  };

  return (
    <Formik
      initialValues={defaultFormData}
      validationSchema={VideoSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (isLoadingCreate) return;
        // Files which should be passed:
        // - already attached but modified (e.g. deleted files)
        // - newly attached files
        let files = [];
        if (values.hidden_field && values.hidden_field.length)
          files.push(...values.hidden_field);
        if (values.attached_files && values.attached_files.length)
          files.push(...values.attached_files);

        const data: IVideoFormFieldsExtended = {
          ...values,
          ...{
            exam:
              !!values?.has_test?.length && buildExamData(values).length > 0
                ? buildExamData(values)
                : []
          },
          attached_files: files
        };
        // Filter helper 'exam_' and `hidden_` data fields
        const filtered: any = Object.fromEntries(
          Object.entries(data).filter(
            ([key]) => !key.includes('exam_') && !key.includes('hidden_')
          )
        );
        delete filtered.has_test;
        if (!filtered.exam.length) {
          delete filtered.completion_score;
        }

        handleEdit(
          { data: filtered, courseId, videoId },
          {
            onSuccess: () => {
              clearToast();
              queryClient.invalidateQueries({
                queryKey: ['course-resources-data', { id: courseId }]
              });
              queryClient.invalidateQueries({
                queryKey: ['course-video-data', { courseId, videoId }]
              });
              setPopup(
                <GenericPopup
                  msg="You have successfully edited the video details."
                  redirectPath={`/courses/${course.slug}?&changes=${
                    course.status === COURSE_STATUS.DRAFT
                      ? COURSE_CHANGES.NO_CHANGES
                      : COURSE_CHANGES.ONLY_CHANGES
                  } `}
                  buttonName="Okay"
                  buttonVariant="contrast"
                />
              );
              resetForm();
              setStep(0);
            }
          }
        );
        setSubmitting(false);
      }}
      validateOnBlur
      validateOnMount
      enableReinitialize
    >
      {(props) => (
        <Form className={`${classes['form']} ${classes['form--popup']}`}>
          {renderStep({
            step: currentStep,
            ...props
          })}
        </Form>
      )}
    </Formik>
  );
};

export default VideoForm;
