import React, { useState } from 'react';
import Label from './Label';
import { InputField } from './InputField';
import { Textarea } from './Textarea';
import PasswordTooltip from 'components/tooltip/PasswordTooltip';
import { InputFile } from './InputFile';
import DatepickerRange from './DatepickerRange';
import classes from './InputWrapper.module.scss';

export interface IOption {
  value: string | number;
  label: string;
  desc?: string;
  isDefaultChecked?: boolean;
}

export interface IInputWrapperProps {
  // Functional
  type:
    | 'text'
    | 'number'
    | 'password'
    | 'search'
    | 'textarea'
    | 'email'
    | 'url'
    | 'file'
    | 'datepicker-range';

  onClear?: () => void;
  onChange: (value: any) => void;
  onBlur: (e: React.FocusEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string | boolean | number;
  isDisabled?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  isDebounced?: boolean;
  startDateRange?: number;
  endDateRange?: number;
  customHeader?: React.ReactNode;
  displayValue?: string;
  minDate?: Date | string;
  maxDate?: Date | string;
  autoFocus?: boolean;
  error?: string;

  // Customization of component
  variant?: 'neutral' | 'outline';
  title?: string;
  subtitle?: string;
  placeholder?: string;
  hasTooltip?: boolean;
  tooltipText?: string;
  tooltipContent?: React.ReactNode;
  passwordTooltip: 'default' | 'temporary' | null;
  fileName?: string;

  // Used by useForm hook
  name: string;

  // Styling
  className?: string;

  // Character limit
  limit?: number;

  // Filter
  isFilter?: boolean;
  clearFilter?: () => void;
}

export const InputWrapper = ({
  className,
  variant,
  type,
  onClear,
  onChange,
  onKeyDown,
  onBlur,
  value,
  isDisabled,
  isDebounced,
  isRequired,
  title,
  name,
  subtitle,
  isClearable,
  startDateRange,
  endDateRange,
  customHeader,
  displayValue,
  minDate,
  maxDate,
  hasTooltip,
  tooltipText,
  tooltipContent,
  passwordTooltip,
  fileName,
  autoFocus,
  placeholder,
  error,
  limit,
  isFilter,
  clearFilter
}: IInputWrapperProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const isNotEmpty =
    (typeof value === 'string' && !!value?.length) ||
    (typeof value === 'number' && value > 0);

  return (
    <div className={`${classes['input__group']} ${className}`}>
      {!!title && (
        <Label
          name={name}
          title={title}
          subtitle={subtitle}
          isRequired={isRequired}
          hasTooltip={hasTooltip}
          tooltipText={!isDisabled ? tooltipText || '' : ''}
          tooltipContent={!isDisabled ? tooltipContent : null}
          isDisabled={isDisabled}
        />
      )}
      {(type === 'text' ||
        type === 'search' ||
        type === 'number' ||
        type === 'email' ||
        type === 'url') && (
        <InputField
          name={name}
          variant={variant}
          type={type}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={() => setShowTooltip(true)}
          onBlur={(e) => {
            onBlur && onBlur(e);
            setShowTooltip(false);
          }}
          value={value}
          isDisabled={isDisabled}
          isClearable={isClearable}
          isDebounced={isDebounced}
          isNotEmpty={isNotEmpty}
          autoFocus={autoFocus}
          placeholder={placeholder}
          error={error}
          limit={limit}
          onClear={onClear}
        />
      )}
      {type === 'password' && (
        <div className={classes['password__group']}>
          <InputField
            name={name}
            variant={variant}
            type={type}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={() => setShowTooltip(true)}
            onBlur={(e) => {
              onBlur && onBlur(e);
              setShowTooltip(false);
            }}
            value={value}
            isDisabled={isDisabled}
            isNotEmpty={isNotEmpty}
            autoFocus={autoFocus}
            placeholder={placeholder}
            error={error}
            limit={limit}
          />
          {showTooltip && passwordTooltip && (
            <PasswordTooltip
              value={value as string}
              isTemporaryTooltip={passwordTooltip === 'temporary'}
            />
          )}
        </div>
      )}
      {type === 'textarea' && (
        <Textarea
          name={name}
          variant={variant}
          onChange={onChange}
          onBlur={(e) => {
            onBlur && onBlur(e);
          }}
          value={value}
          isDisabled={isDisabled}
          isNotEmpty={isNotEmpty}
          autoFocus={autoFocus}
          placeholder={placeholder}
          error={error}
          limit={limit}
        />
      )}
      {type === 'file' && (
        <InputFile
          onChange={(e: React.ChangeEvent) => console.log(e)}
          value={value}
          isDisabled={isDisabled}
          fileName={fileName}
        />
      )}
      {type === 'datepicker-range' && (
        <DatepickerRange
          onChange={onChange}
          isDisabled={isDisabled}
          isClearable={isClearable}
          placeholder={placeholder}
          error={error}
          name={name}
          startDate={startDateRange}
          endDate={endDateRange}
          customHeader={customHeader}
          displayValue={displayValue}
          minDate={minDate}
          maxDate={maxDate}
          isFilter={isFilter}
          clearFilter={clearFilter}
        ></DatepickerRange>
      )}
    </div>
  );
};

InputWrapper.defaultProps = {
  variant: 'neutral',
  type: 'text',
  options: [],
  isDisabled: false,
  isRequired: false,
  isClearable: false,
  name: '',
  title: '',
  subtitle: '',
  placeholder: '',
  hasTooltip: false,
  tooltipText: '',
  tooltipContent: null,
  passwordTooltip: null,
  onBlur: () => {}
};
