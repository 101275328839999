import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { colors } from 'utils/styles';
import 'react-circular-progressbar/dist/styles.css';

interface ICircularProggress {
  step: number;
  steps: number;
  percentage: number;
}

export const CircularProggress = ({
  step,
  steps,
  percentage
}: ICircularProggress) => {
  return (
    <div style={{ width: 69, height: 69 }}>
      <CircularProgressbar
        value={percentage}
        text={`${step} of ${steps}`}
        strokeWidth={11}
        styles={buildStyles({
          textColor: colors.black30,
          pathColor: colors.purple50,
          trailColor: colors.white30
        })}
      />
    </div>
  );
};
