import { fetchFile } from '@ffmpeg/util';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { mediaFileFormats } from 'components/input/InputMedia';
import { getBase64FileSize } from './processing';

export const generateVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    // Set the time of thumbnail generation (seconds)
    // video.currentTime = 5;

    video.onloadeddata = () => {
      let ctx = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL('image/jpeg', 95));
    };
  });
};

const ffmpeg = new FFmpeg();

export const generateVideoPreviewFromVideo = async (file: File) => {
  // Create a temporary URL for the video file
  const url = URL.createObjectURL(file);
  const videoElement = document.createElement('video');
  videoElement.src = url;

  return new Promise((resolve, reject) => {
    videoElement.onloadedmetadata = async () => {
      if (videoElement.duration > mediaFileFormats.video.length) {
        if (!ffmpeg.loaded) {
          await ffmpeg.load();
        }

        try {
          // 2GB size
          const ffmpedMaxLimit = 2000000000;

          if (file.size >= ffmpedMaxLimit) {
            reject(
              'Unable to generate short video preview due to exceeded file size limit of 2GB.'
            );
            return;
          }

          // TODO: If the limit is exceeded reduce the size of the file and then generate the short video

          // file.slice might be a solution but it returns new Blob with no data which serves as a pointer to the main one

          // const blob = new Blob([file.slice(0, ffmpedMaxLimit/2)], {type: file.type});
          // file = new File(blob, file.name);

          // Write the file to the FS
          await ffmpeg.writeFile('input.mp4', await fetchFile(file));

          // Run FFmpeg command to trim the video
          await ffmpeg.exec([
            '-ss',
            '00:00:01',
            '-i',
            'input.mp4',
            '-to',
            `00:00:${mediaFileFormats.video.length}`,
            '-c',
            'copy',
            'output.mp4'
          ]);

          // Read the result
          const data = await ffmpeg.readFile('output.mp4');

          // Create a Blob from the result
          const trimmedVideo = new Blob([data], { type: 'video/mp4' });

          // Convert Blob to Base64
          const reader = new FileReader();
          reader.readAsDataURL(trimmedVideo);
          reader.onloadend = () => {
            const base64data = reader.result; // Extract base64 part

            const trimmedVideoIsTooBig =
              getBase64FileSize(base64data as string).megabytes >
              mediaFileFormats.video.size;

            if (trimmedVideoIsTooBig)
              reject(
                `The generated course video preview is larger than the limit of ${mediaFileFormats.video.size}Mb.`
              );

            resolve(base64data);
          };
          reader.onerror = () => {
            reject('Error converting video to Base64.');
          };
        } catch (error: any) {
          reject(`Error processing video: ${error.message}`);
        }
      } else {
        // If no trimming is needed, convert the original file to Base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64data = reader.result; // Extract base64 part

          const trimmedVideoIsTooBig =
            getBase64FileSize(base64data as string).megabytes >
            mediaFileFormats.video.size;

          if (trimmedVideoIsTooBig)
            reject(
              `The generated course video preview is larger than the limit of ${mediaFileFormats.video.size}Mb.`
            );

          resolve(base64data);
        };
        reader.onerror = (e) => {
          console.log(e);
          reject('Error converting video to Base64.');
        };
      }
    };

    videoElement.onerror = () => {
      reject('Error loading video metadata.');
    };
  });
};
