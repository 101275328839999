import { useEffect, useState } from 'react';

type BrowserName =
  | ''
  | 'Edge'
  | 'Chrome'
  | 'Chrome Mobile'
  | 'Brave'
  | 'Firefox'
  | 'Safari'
  | 'Safari Mobile'
  | 'Samsung Internet'
  | 'Unknown';

const useBrowserDetect = () => {
  const [browser, setBrowser] = useState<BrowserName>('');

  useEffect(() => {
    const userAgent = navigator.userAgent;

    switch (true) {
      case /edg/i.test(userAgent):
        setBrowser('Edge');
        break;
      case /chrome|chromium|crios/i.test(userAgent):
        if ((navigator as any)?.brave) {
          setBrowser('Brave');
        } else if (/mobile/i.test(userAgent)) {
          setBrowser('Chrome Mobile');
        } else {
          setBrowser('Chrome');
        }
        break;
      case /firefox|fxios/i.test(userAgent):
        setBrowser('Firefox');
        break;
      case /safari/i.test(userAgent) &&
        !/chrome|chromium|crios|edg/i.test(userAgent):
        if (/mobile/i.test(userAgent)) {
          setBrowser('Safari Mobile');
        } else {
          setBrowser('Safari');
        }
        break;
      case /samsungbrowser/i.test(userAgent):
        setBrowser('Samsung Internet');
        break;
      default:
        setBrowser('Unknown');
        break;
    }
  }, []);

  if (!!browser) return browser;
};

export default useBrowserDetect;
