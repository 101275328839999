import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import moment from 'moment';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import { COURSE_HISTORY_STATUS, ICourseHistory } from 'query/course-module/dto';
import { popupContext } from 'context/popupContext';
import GenericPopup from 'components/popup/GenericPopup';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { dateFormatUrl } from 'utils/static';
import { formatNames } from 'utils/format';
import classes from './AdminCourseHistoryCols.module.scss';

export const AdminCourseHistoryCols = () => {
  const cols: Column[] = [
    {
      Header: 'Date Submitted',
      accessor: 'date_submitted',
      Cell: ({ row }: { row: { original: ICourseHistory } }) => {
        return (
          <div>{moment(row.original?.date_created).format(dateFormatUrl)}</div>
        );
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }: { row: { original: ICourseHistory } }) => {
        const isApprovedOrRestored =
          row.original.action === COURSE_HISTORY_STATUS.APPROVE ||
          row.original.action === COURSE_HISTORY_STATUS.RESTORE;

        return (
          <div
            className={`${classes['status']} ${
              classes[
                isApprovedOrRestored ? 'status__approved' : 'status__rejected'
              ]
            }`}
          >
            <h5 className={`${classes['u-semiBold']} ${classes['u-body3']}`}>
              {row.original.action === COURSE_HISTORY_STATUS.APPROVE
                ? 'Approved'
                : row.original.action === COURSE_HISTORY_STATUS.REJECT
                ? 'Rejected'
                : row.original.action === COURSE_HISTORY_STATUS.RESTORE
                ? 'Restored'
                : 'Taken down'}
            </h5>
            {isApprovedOrRestored && <Check width={16} height={16} />}
            {!isApprovedOrRestored && <Info width={16} height={16} />}
          </div>
        );
      }
    },
    {
      Header: 'Admin',
      accessor: 'admin',
      Cell: ({ row }: { row: { original: ICourseHistory } }) => (
        <Link to={`/user/${row.original.admin.slug}/about/main-info`}>
          {formatNames(
            row.original?.admin.first_name + ' ' + row.original?.admin.last_name
          )}
        </Link>
      )
    },
    {
      Header: 'Rejection Reason',
      accessor: 'rejection_reason',
      Cell: ({ row }: { row: { original: ICourseHistory } }) => {
        const { setPopup } = useContext(popupContext);

        return (
          <TextWithCharLimit
            text={row?.original?.reason || ''}
            limit={30}
            btnAction={() => {
              setPopup(
                <GenericPopup
                  type="info"
                  title="Rejection Reason"
                  msg={row?.original?.reason}
                  size="lg"
                />
              );
            }}
          />
        );
      }
    }
  ];

  return cols;
};
