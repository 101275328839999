import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Question } from 'assets/icons/question.svg';
import { toastContext } from 'context/toastContext';
import Button from 'components/buttons/Button';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import { IAdminTokenPairsData } from 'query/course-module/dto';
import { adminTokenPairsDataQuery } from 'query/course-module/queries';
import { formatPrice } from 'utils/format';
import { fetchApi } from 'utils/requests';
import classes from './Currencies.module.scss';

const Currencies = () => {
  const queryClient = useQueryClient();
  const { setToast } = useContext(toastContext);

  const { data, isLoading, error } = useQuery<
    boolean,
    Error,
    IAdminTokenPairsData
  >(adminTokenPairsDataQuery());

  const { mutate } = useMutation({
    mutationKey: ['set-currency'],
    mutationFn: async (currency: any) => {
      return await fetchApi('courses', '/admin/pairs', {
        method: 'PUT',
        auth: true,
        data: {
          id_list: [currency._id],
          active: !currency.active
        }
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(['admin-token-pairs']);
      setToast({ type: 'success', msg: res.message, autoClose: true });
    },
    onError: (err: Error) => {
      setToast({ type: 'error', msg: err.message, autoClose: true });
    }
  });

  return (
    <div className={classes['currencies__wrapper']}>
      <div className={classes['u-text--content']}>
        The list below shows the accepted currencies when buying a course.
      </div>

      <div className={classes['currencies__list']}>
        {isLoading && <Loader />}
        {error && <Error error={error} />}
        {!!data?.result &&
          data.result.map((currency) => (
            <div key={currency._id} className={classes['currencies__field']}>
              <div className={classes['currencies__field__left-col']}>
                <div className={classes['currencies__field__token']}>
                  {!!currency.tokenLogo &&
                  currency.tokenLogo !== 'missing.png' ? (
                    <img src={currency.tokenLogo} alt={currency._id} />
                  ) : (
                    <Question />
                  )}
                  <div className={classes['currencies__field__name']}>
                    {currency.tokenSymbol}
                  </div>
                </div>
                <div className={classes['currencies__field__liquidity']}>
                  ( Liquidity:{' '}
                  {formatPrice(+currency.last_recorded_liquidity_usd)} )
                </div>
              </div>
              <div className={classes['currencies__field__right-col']}>
                <Button
                  variant={currency.active ? 'error' : 'success'}
                  size="sm"
                  minWidth="sm"
                  icon={currency.active ? Cross : Plus}
                  onClick={() => mutate(currency)}
                >
                  {currency.active ? 'Remove' : 'Add'}
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Currencies;
