import Button from 'components/buttons/Button';
import InfoBox from 'components/info-box/InfoBox';
import classes from './AddVideoIsland.module.scss';

interface IAddVideoIslandProps {
  onAddVideo: () => void;
}

const AddVideoIsland = ({ onAddVideo }: IAddVideoIslandProps) => {
  return (
    <div className={classes['island']}>
      <div className={classes['island__container']}>
        <Button onClick={onAddVideo}>Add Videos</Button>
        <InfoBox
          msg="To ensure the successful publication of your course, you need to upload 1 or more videos."
          type="info"
        />
      </div>
    </div>
  );
};

export default AddVideoIsland;
