import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import Button from 'components/buttons/Button';
import GenericPopup from 'components/popup/GenericPopup';
import { popupContext } from 'context/popupContext';
import { ResetPasswordSchema } from 'utils/yupSchemas';
import { InputWrapper } from 'components/input/InputWrapper';
import { resetPassMutation } from 'query/acc-module/mutations';
import classes from './ResetPassword.module.scss';

const initialValues = {
  new_pass: '',
  confirm_new_pass: ''
};

const ResetPassword = () => {
  const { setPopup } = useContext(popupContext);
  const [searchParams] = useSearchParams();
  const uid = searchParams.get('u_id') || '';
  const hash = searchParams.get('hash') || '';

  const { isLoading, mutate: submitHandler } = useMutation({
    ...resetPassMutation(),
    onSuccess: () => {
      setPopup(
        <GenericPopup
          msg="You have successfully changed your password! You can now log in using your new password!"
          redirectPath="/login"
          isClosable={false}
        />
      );
    },
    onError: (e: Error) => {
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordSchema}
      onSubmit={(values) => {
        if (isLoading) return;
        submitHandler({ ...values, uid, hash });
      }}
      validateOnMount
    >
      {({ errors, touched, values, setFieldValue, submitForm, handleBlur }) => (
        <Form>
          <div
            className={`${classes['container']} ${classes['form']} ${classes['form--sm']}`}
          >
            <div className={classes['container__title']}>
              <h4 className={classes['u-bold']}>Forgot Password?</h4>
              <p>
                Forgot password? Please enter your email address. You will
                receive a link to create a new password via email.
              </p>
            </div>
            <InputWrapper
              type="password"
              title="New Password*"
              name="new_pass"
              placeholder="Enter New Password"
              onChange={(value) => setFieldValue('new_pass', value)}
              onBlur={(e) => handleBlur(e)}
              value={values.new_pass}
              error={errors.new_pass && touched.new_pass ? errors.new_pass : ''}
              tooltipText="This will be the new password you must use to log in."
              passwordTooltip="default"
            />
            <InputWrapper
              type="password"
              title="Confirm Password*"
              name="confirm_new_pass"
              placeholder="Confirm New Password"
              onChange={(value) => setFieldValue('confirm_new_pass', value)}
              onBlur={(e) => handleBlur(e)}
              value={values.confirm_new_pass}
              error={
                errors.confirm_new_pass && touched.confirm_new_pass
                  ? errors.confirm_new_pass
                  : ''
              }
              tooltipText="This will be the new password you must use to log in."
              passwordTooltip="default"
            />
            <Button
              type="submit"
              minWidth="full"
              isDisabled={!!errors.new_pass || !!errors.confirm_new_pass}
              isLoading={isLoading}
            >
              Reset Password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default ResetPassword;
