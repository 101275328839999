import { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import NoCourses from 'assets/images/no-courses.svg';
import { windowContext } from 'context/windowsContext';
import { userContext } from 'context/userContext';
import ErrorComponent from 'components/error/Error';
import EmptyState from 'components/emptyState/EmptyState';
import NoResults from 'components/noResults/NoResults';
import Card from 'components/card/Card';
import Actions from '../Actions';
import Pagination from 'components/pagination/Pagination';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import { creatorCoursesDataQuery } from 'query/course-module/queries';
import { COURSE_REVIEW_STATUS, COURSE_STATUS } from 'utils/constants';
import { COURSE_CHANGES, ICourseData } from 'query/course-module/dto';
import classes from '../Courses.module.scss';

const Rejected = () => {
  const { search: urlParams } = useLocation();
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);
  const { page: pageNumber, search, sort } = queryString.parse(urlParams);
  const pageSize: number = 9;

  const navigate = useNavigate();
  const { userData } = useContext(userContext);

  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}&review_status=${
    COURSE_REVIEW_STATUS.REJECTED
  }&creator_id=${userData._id}&include_unpublished=true&changes=${
    COURSE_CHANGES.ONLY_CHANGES
  }`;
  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>(
    creatorCoursesDataQuery(queryParams)
  );

  const { result: courses, total_results } = data || {};
  const hasData = courses && courses.length > 0;

  return (
    <div className={classes['wrapper']}>
      {isLgMobile && <h5 className={classes['u-light']}>Rejected Courses</h5>}
      {(hasData ||
        (!hasData && searchValue) ||
        (!hasData && sortValue) ||
        page > 1 ||
        isLoading) && (
        <Actions
          hasSearch
          searchPlaceholder="Search by Course Name"
          searchValue={searchValue}
          passSearchValueUp={setSearchValue}
          hasSort
          sortValue={sortValue}
          passSortValueUp={setSortValue}
          setPage={setPage}
          isInverted
          isDebounced
        />
      )}
      {isLoading && (
        <div className={classes['cards']}>
          <CardSkeleton amount={3} variant="course" />
        </div>
      )}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <>
          <div className={classes['cards']}>
            {courses.map((course) => (
              <Card
                key={course._id}
                onClick={() =>
                  navigate(
                    `/courses/${course.slug}?changes=${
                      course.status === COURSE_STATUS.PUBLISHED ||
                      course.status === COURSE_STATUS.PAUSED
                        ? COURSE_CHANGES.ONLY_CHANGES
                        : COURSE_CHANGES.NO_CHANGES
                    }`
                  )
                }
                hasPrice
                hasBadge
                badgeText="REJECTED"
                badgeType="error"
                course={course}
              />
            ))}
          </div>
          <Pagination
            currentPage={page}
            totalCount={total_results as number}
            siblingCount={1}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
          />
        </>
      )}
      {!hasData && searchValue && !isLoading && !error && <NoResults />}
      {!hasData && !searchValue && !isLoading && !error && (
        <EmptyState
          icon={NoCourses}
          title="There are no Rejected Courses at the moment."
          message="Create your first course. Add videos and create NFT certificate."
          buttonName="Create Course"
          onClick={() => navigate('/courses/create')}
          bellowBtnComp={
            <p>
              Learn more about uploading in the{' '}
              <Link to="/content-guidelines">Content Guidelines</Link>
            </p>
          }
        />
      )}
    </div>
  );
};

export default Rejected;
