import { useState } from 'react';
import Badge from 'components/badge/Badge';
import Divider from 'components/divider/Divider';
import DownloadFileButton from 'components/buttons/DownloadFileButton';
import { renderFileIcon } from '../../documents/Documents';
import { ChangeHeader } from './SingleChange';
import { FILE_CHANGE, IVideoFile } from 'query/course-module/dto';
import classes from './FilesChanges.module.scss';

interface IFileChangeProps {
  files: IVideoFile[];
}

export const FilesChanges = ({ files }: IFileChangeProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const renderChange = (file: IVideoFile, isLast: boolean) => {
    return (
      <div key={file.name} className={classes['menu__body__change']}>
        <div className={classes['menu__body__change__row']}>
          <div className={classes['menu__body__change__row__badge']}>
            <Badge
              badgeType={
                file.file_change === FILE_CHANGE.ADD ? 'primary' : 'disabled'
              }
              text={file.file_change === FILE_CHANGE.ADD ? 'Added' : 'Deleted'}
            />
            {!isLast && (
              <Divider dir="vertical" className={classes['divider']} />
            )}
          </div>
          <div className={classes['menu__body__change__row__values']}>
            {renderFileIcon(file.name)}
            <div className={classes['u-semiBold']}>{file.name}</div>
          </div>
          <DownloadFileButton file={file} isAdminPage={true} />
        </div>
        {!isLast && <Divider dir="vertical" className={classes['divider']} />}
      </div>
    );
  };

  return (
    <div className={classes['menu']}>
      <ChangeHeader
        onClick={() => setIsOpen(!isOpen)}
        title="Files"
        isOpen={isOpen}
      />
      {isOpen && (
        <div className={classes['menu__body']}>
          {files.map((file, i) => renderChange(file, i === files.length - 1))}
        </div>
      )}
      <Divider />
    </div>
  );
};
