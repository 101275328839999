import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';
import Diploma from 'assets/images/no-certificates.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Link } from 'assets/icons/link.svg';
import { ReactComponent as OpusNft } from 'assets/icons/opus-nft.svg';
import {
  COMPLETION_STATUS,
  COURSE_RELATIONSHIP,
  ICourse,
  ITemplateDto
} from 'query/course-module/dto';
import { userContext } from 'context/userContext';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import EmptyState from 'components/emptyState/EmptyState';
import Button from 'components/buttons/Button';
import InfoBox from 'components/info-box/InfoBox';
import Tooltip from 'components/tooltip/Tooltip';
import {
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_FILES_PUBLIC_DOMAIN,
  REACT_APP_OPUS_NFT_URL
} from 'utils/constants';
import { fetchApi } from 'utils/requests';
import { singleTemplateDataQuery } from 'query/course-module/queries';
import { updateNotificationMutation } from 'query/course-module/mutations';
import classes from './Certificate.module.scss';

interface ICertificateProps {
  course: ICourse;
  showEmptyStateBtn: boolean;
}

const Certificate = ({ course, showEmptyStateBtn }: ICertificateProps) => {
  const { search: urlParams, state } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { slug } = useParams();
  const navigate = useNavigate();
  const {
    userData: { isAdmin },
    newCertificates
  } = useContext(userContext);
  const queryClient = useQueryClient();

  const { template_id, course_relationship, purchased } = course;

  const isCreatorOrAdmin =
    course_relationship === COURSE_RELATIONSHIP.CREATOR || isAdmin;
  const isNotActivated =
    course_relationship === COURSE_RELATIONSHIP.NONE ||
    (course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
      !purchased.active);
  const isInitial = purchased?.completion_status === COMPLETION_STATUS.INITIAL;
  const isMinting =
    purchased?.completion_status === COMPLETION_STATUS.PENDING_MINT;
  const isCompleted =
    purchased?.completion_status === COMPLETION_STATUS.COMPLETED;
  const isMinted = purchased?.completion_status === COMPLETION_STATUS.MINTED;

  const { isLoading, error, data } = useQuery<boolean, Error, ITemplateDto>({
    ...singleTemplateDataQuery(template_id as string),
    enabled: !!template_id
  });

  const { mutate: markAsSeenMutate } = useMutation(
    updateNotificationMutation(course._id, queryClient)
  );

  const isEmptyState = (!data && !isLoading && !error) || !template_id;

  const downloadCertificateHandler = async () => {
    try {
      const response = await fetch(course.purchased.diploma_link);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${course.slug}-certificate`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // Release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const viewInOpusNftHandler = async () => {
    await fetchApi('courses', '/user/token', {
      method: 'POST',
      auth: true
    }).then((token) => {
      window.open(
        REACT_APP_OPUS_NFT_URL +
          `/login?opusedu_redirect_token=${token.accessToken}&diploma_id=${course.purchased.certificate_token_id}`,
        '_blank'
      );
    });
  };

  useEffect(() => {
    if (state?.autoFocus && !isLoading) {
      const certificateElement = document.getElementById(
        'single-course-certificate'
      );
      certificateElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [state, isLoading]);

  useEffect(() => {
    if (newCertificates.some((cert) => cert.course._id === course._id)) {
      markAsSeenMutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCertificates]);

  if (!!template_id && isLoading) return <Loader />;

  if (error) return <Error error={error} />;

  return (
    <div
      id="single-course-certificate"
      className={`${classes['certificate__list']} ${
        isEmptyState ? classes['certificate__list--center'] : ''
      }`}
    >
      {!!data && !isLoading && !error && (
        <div className={classes['certificate__item']}>
          <>
            {/* 1. USER WHO HAS NOT FINISHED COURSE */}
            {isInitial && (
              <>
                <InfoBox
                  className={classes['certificate__item__info-box']}
                  type="info"
                  msg="To receive your Certificate, it is mandatory that you successfully complete all videos and their corresponding assessments."
                />
                <Button
                  onClick={() =>
                    navigate(`/courses/${slug}?changes=${changes}`)
                  }
                >
                  Back to Videos
                </Button>
              </>
            )}
            {/* 2. USER - pending certificate */}
            {(isMinting || isCompleted) && (
              <>
                <InfoBox
                  className={classes['certificate__item__info-box']}
                  type="info"
                  msg={`Your certificate ${
                    isCompleted ? 'will be minted soon' : 'is being minted'
                  }. You will be notified when it is ready.`}
                />
                {isMinting && <Loader />}
              </>
            )}
            {/* 3. USER - minted certificate */}
            {isMinted && (
              <>
                <img
                  className={classes['certificate__item__thumbnail']}
                  src={course?.purchased.diploma_link}
                  alt={data?.name}
                />
                <div className={classes['certificate__btns-container']}>
                  <Tooltip id="download" text="Download Certificate">
                    <Button
                      variant="neutral"
                      isIconBtn
                      icon={Download}
                      onClick={downloadCertificateHandler}
                    />
                  </Tooltip>
                  <Tooltip id="block-explorer" text="View on block explorer">
                    <Button
                      variant="neutral"
                      isIconBtn
                      icon={Link}
                      onClick={() =>
                        window.open(
                          REACT_APP_BLOCK_EXPLORER_URL +
                            `/tx/${course.purchased.diploma_mint_tx_hash}`,
                          '_blank'
                        )
                      }
                    />
                  </Tooltip>
                  <Tooltip id="opus-nft" text="View in OpusNFT">
                    <Button
                      variant="neutral"
                      isIconBtn
                      icon={OpusNft}
                      onClick={viewInOpusNftHandler}
                    />
                  </Tooltip>
                </div>
              </>
            )}
            {/* See template image unless the certificate is minted. Then we show the certificate image. */}
            {(isCreatorOrAdmin || isNotActivated) && !isMinted && (
              <img
                className={classes['certificate__item__thumbnail']}
                src={REACT_APP_FILES_PUBLIC_DOMAIN + data?.thumbnail}
                alt={data?.name}
              />
            )}
          </>
        </div>
      )}
      {isEmptyState && (
        <EmptyState
          title="This course does not have a completion certificate."
          message={
            course_relationship === COURSE_RELATIONSHIP.CREATOR
              ? 'You can attach a certificate by editing the course details.'
              : ''
          }
          buttonName={showEmptyStateBtn ? 'Edit Course' : ''}
          onClick={() => navigate(`/courses/${slug}/edit?changes=${changes}`)}
          icon={Diploma}
        />
      )}
    </div>
  );
};

export default Certificate;
