import React, { createContext, useEffect, useState } from 'react';

interface IWindowCtx {
  windowSize: {
    width: number;
    height: number;
    isXxlMobile: boolean;
    isXlMobile: boolean;
    isLgMobile: boolean;
    isMdMobile: boolean;
    isSmMobile: boolean;
    isXsMobile: boolean;
  };
  appWrapperElement: HTMLElement | null;
}

const initialValues = {
  windowSize: {
    width: 0,
    height: 0,
    isXxlMobile: false,
    isXlMobile: false,
    isLgMobile: false,
    isMdMobile: false,
    isSmMobile: false,
    isXsMobile: false
  },
  appWrapperElement: null
};

export const windowContext = createContext<IWindowCtx>(initialValues);

export function WindowProvider({ children }: { children: React.ReactNode }) {
  const [windowSize, setWindowSize] = useState<IWindowCtx['windowSize']>({
    width: 0,
    height: 0,
    isXxlMobile: false,
    isXlMobile: false,
    isLgMobile: false,
    isMdMobile: false,
    isSmMobile: false,
    isXsMobile: false
  });
  // This component is used to control scrollY of app
  const appWrapperElement = document.getElementById('app_wrapper');

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isXxlMobile: window.innerWidth <= 1440,
        isXlMobile: window.innerWidth <= 1230,
        isLgMobile: window.innerWidth <= 992,
        isMdMobile: window.innerWidth <= 768,
        isSmMobile: window.innerWidth <= 576,
        isXsMobile: window.innerWidth <= 375
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <windowContext.Provider value={{ windowSize, appWrapperElement }}>
      {children}
    </windowContext.Provider>
  );
}
