import Blockies from 'react-blockies';
import { colors } from 'utils/styles';
import classes from './Blockies.module.scss';

interface IBlockiesComp {
  seed: string;
  size: number;
  scale: number;
}

const BlockiesComp = ({ seed, size, scale }: IBlockiesComp) => {
  return (
    <Blockies
      seed={seed} // the only required prop; determines how the image is generated
      size={size} // number of squares wide/tall the image will be; default = 15
      scale={scale} // width/height of each square in pixels; default = 4
      color={colors.purple50} // normal color; random by default
      bgColor={colors.purple10} // background color; random by default
      spotColor={colors.purple50} // color of the more notable features; random by default
      className={classes['c-blockies']} // optional class name for the canvas element; "identicon" by default
    />
  );
};

export default BlockiesComp;
