import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import GenericPopup from 'components/popup/GenericPopup';
import { popupContext } from 'context/popupContext';
import { clearTokenFromStorage, tokenData, tokenExpired } from 'utils/storage';

export const UnprotectedLayout = () => {
  const location = useLocation();
  const hasExpired = tokenExpired();

  if (hasExpired) clearTokenFromStorage();

  if (
    !hasExpired &&
    // Exclude unprotected routes which make no sense
    // to visit when the user has a valid access token
    (location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/forgot-password')
  ) {
    return <Navigate to="/" replace={true} />;
  }

  return <Outlet />;
};

export const ProtectedLayout = () => {
  const { setPopup, clearPopup } = useContext(popupContext);
  const queryClient = useQueryClient();
  const location = useLocation();

  const tokenData: tokenData = JSON.parse(
    localStorage.getItem('token') as string
  );

  const hasExpired = tokenExpired();

  // Throw timeout popup ONLY IF token is present AND expired
  if (tokenData && tokenData.token && hasExpired)
    setPopup(
      <GenericPopup
        type="info"
        title="Timeout"
        msg="You session has expired."
        buttonAction={() => clearPopup()}
        buttonName="Close"
        buttonVariant="neutral"
      />
    );

  // Clear token and navigate to login if token is not present OR expired
  if (hasExpired) {
    clearTokenFromStorage();
    queryClient.clear();
    return (
      <Navigate
        to={`/login?redirect_url=${location.pathname}`}
        replace={true}
      />
    );
  }

  return <Outlet />;
};
