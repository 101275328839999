import React from 'react';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { InputWrapper } from 'components/input/InputWrapper';
import Button from 'components/buttons/Button';
import { InputMedia } from 'components/input/InputMedia';
import { IFormik } from './VideoForm';
import { handleMediaUpload } from 'utils/processing';
import classes from './Form.module.scss';

interface IStep1
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
    | 'handleBlur'
  > {
  currentStep: number;
  setStep: (step: number) => void;
  clearPopup: () => void;
}

export const Step1 = ({
  setStep,
  currentStep,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleBlur,
  clearPopup
}: IStep1) => {
  return (
    <>
      <div className={classes['fields-wrapper']}>
        <InputWrapper
          name="title"
          type="text"
          variant="outline"
          onChange={(e) => setFieldValue('title', e)}
          onBlur={(e) => handleBlur(e)}
          value={values.title}
          title="Video Name"
          placeholder="Enter Video Name"
          isRequired
          error={errors.title && touched.title ? errors.title : ''}
          limit={60}
          subtitle={`${values.title?.length} of 60 characters used`}
        />
        <InputWrapper
          name="description"
          type="textarea"
          variant="outline"
          onChange={(e) => setFieldValue('description', e)}
          onBlur={(e) => handleBlur(e)}
          value={values.description}
          title="Video Description"
          subtitle={`${values.description?.length} of 300 characters used`}
          placeholder="Enter Video Description"
          error={
            errors.description && touched.description ? errors.description : ''
          }
          limit={300}
        />
        <InputMedia
          name="thumbnail"
          title="Video Thumbnail"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleMediaUpload(
              e,
              'thumbnail',
              setFieldValue,
              setFieldTouched,
              setFieldError
            );
          }}
          onClear={() => setFieldValue('thumbnail', '')}
          pic={values.thumbnail}
          error={errors.thumbnail && touched.thumbnail ? errors.thumbnail : ''}
        />
      </div>
      <div className={classes['btns-wrapper']}>
        <Button type="button" variant="neutral" onClick={clearPopup}>
          Cancel Editing
        </Button>
        <Button
          type="button"
          onClick={() => setStep(currentStep + 1)}
          icon={Arrow}
          iconRotate={90}
          isDisabled={!!errors.title || !!errors.description}
        >
          Next Step
        </Button>
      </div>
    </>
  );
};

export default IStep1;
