import React, { useContext } from 'react';
import { useAccount } from 'wagmi';
import { TransactionReceipt } from 'ethers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IPurchasedItem } from 'query/course-module/dto';
import {
  claimFundsMutation,
  claimFundsResetMutation,
  claimFundsMutationAdmin,
  claimFundsResetMutationAdmin
} from 'query/course-module/mutations';
import { useEthersSigner } from 'hooks/useEthersSigner';
import { Web3Context } from 'context/web3Context';
import { userContext } from 'context/userContext';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import Tooltip from 'components/tooltip/Tooltip';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { getContractInstance } from 'contracts/contracts';
import { submitTx } from 'utils/requests';
import { REACT_APP_BLOCK_EXPLORER_URL } from 'utils/constants';
import classes from './ClaimFundsPopup.module.scss';

interface IClaimFundsPopupProps {
  isAdminPage?: boolean;
  data: IPurchasedItem[];
}

const ClaimFundsPopup = ({ data, isAdminPage }: IClaimFundsPopupProps) => {
  const queryClient = useQueryClient();
  const { setToast } = useContext(toastContext);
  const { clearPopup } = useContext(popupContext);
  const {
    isCorrectChain,
    isWalletUnique,
    increasePendingTransactions,
    decreasePendingTransactions
  } = useContext(Web3Context);
  const { signer } = useEthersSigner();
  const { isConnected } = useAccount();
  const { isTokenExpired } = useContext(userContext);
  const purchaseIds = data.map((course) => course.receipt_id);
  // Total course prices
  const totalAmount = data.reduce(
    (value, course) => value + course.creator_revenue,
    0
  );
  // Admin -> calculating platform fees for each course
  const totalCoursesFees = data.reduce(
    (value, course) => value + course.platform_fee,
    0
  );
  const totalClaimAmount = isAdminPage ? totalCoursesFees : totalAmount;
  const documentIds = data.map((course) => course._id);

  const { mutate: claimMutation } = useMutation({
    ...claimFundsMutation(documentIds),
    onSuccess: () => queryClient.invalidateQueries(['claim-funds'])
  });

  const { mutate: resetMutation } = useMutation({
    ...claimFundsResetMutation(documentIds),
    onSuccess: () => queryClient.invalidateQueries(['claim-funds-reset'])
  });

  const { mutate: claimMutationAdmin } = useMutation({
    ...claimFundsMutationAdmin(documentIds),
    onSuccess: () => queryClient.invalidateQueries(['claim-funds-admin'])
  });

  const { mutate: resetMutationAdmin } = useMutation({
    ...claimFundsResetMutationAdmin(documentIds),
    onSuccess: () => queryClient.invalidateQueries(['claim-funds-reset-admin'])
  });

  const { isLoading, mutate: claimFundsHandler } = useMutation({
    mutationFn: async () => {
      isAdminPage ? claimMutationAdmin() : claimMutation();
      increasePendingTransactions();

      const contract = getContractInstance('payment', signer);
      const txData = [purchaseIds];

      const receipt = await submitTx(
        contract,
        isAdminPage ? 'collectFee' : 'claim',
        txData
      );
      return receipt as TransactionReceipt;
    },
    onSuccess: (receipt: TransactionReceipt) => {
      clearPopup();
      decreasePendingTransactions();
      setToast({
        type: 'success',
        title: 'Successfull Claim',
        msg: 'Successfully claimed. View',
        linkMsg: 'transaction',
        linkTo: `${REACT_APP_BLOCK_EXPLORER_URL}/tx/${receipt.hash}`,
        position: 'top',
        autoClose: true
      });
    },
    onError: async (err: any) => {
      isAdminPage ? resetMutationAdmin() : resetMutation();
      decreasePendingTransactions();
      setToast({
        type: 'error',
        position: 'top',
        title: 'Transaction Failed',
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  return (
    <ModalWrapper size="sm">
      <div className={classes['wrapper']}>
        <h4 className={classes['u-semiBold']}>Claim Funds</h4>
        <h5 className={`${classes['u-body2']} ${classes['subtitle']}`}>
          {`Are your sure you want to claim funds for the selected Course${
            data.length > 1 ? 's' : ''
          }`}
        </h5>
        <div className={classes['content']}>
          {data.map((purchase, index) => {
            const { platform_fee, creator_revenue, course_name } = purchase;
            return (
              <div key={index} className={classes['course-price-box']}>
                <h5
                  className={`${classes['u-body3']} ${classes['u-semiBold']}`}
                >
                  <TextWithCharLimit text={course_name} limit={50} hideBtn />
                </h5>
                <h5 className={classes['u-body2']}>
                  {(isAdminPage ? platform_fee : creator_revenue).toFixed(2)}
                </h5>
              </div>
            );
          })}
          <Divider />
          <div className={classes['course-price-box']}>
            <h5 className={`${classes['u-body3']} ${classes['u-semiBold']}`}>
              TOTAL
            </h5>
            <div className={classes['label-container']}>
              <h5 className={`${classes['u-body2']} ${classes['u-bold']}`}>
                {totalClaimAmount.toFixed(2)}
              </h5>
              <h5 className={classes['u-body2']}>USDC</h5>
            </div>
          </div>
        </div>
        <div className={classes['btns-container']}>
          <Tooltip
            id="tooltip-claim"
            text={
              isTokenExpired ||
              !isConnected ||
              !isCorrectChain ||
              !isWalletUnique
                ? `In order to claim, please 
                  ${
                    isTokenExpired
                      ? 'log in first.'
                      : !isConnected
                      ? 'connect your wallet first.'
                      : !isCorrectChain
                      ? 'switch to the Polygon network.'
                      : !isWalletUnique
                      ? 'switch your wallet. This one is associated with another account.'
                      : ''
                  }`
                : ''
            }
          >
            <Button
              onClick={() => claimFundsHandler()}
              isLoading={isLoading}
              variant="contrast"
              minWidth="full"
              isWeb3
            >
              Claim Funds
            </Button>
          </Tooltip>
          <Button variant="neutral" minWidth="full" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ClaimFundsPopup;
