import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import classes from './Tooltip.module.scss';

type ITooltipProps = {
  children?: React.ReactNode;
  text: string;
  tooltipContent?: React.ReactNode;
  id: string;
  position?: 'left' | 'right' | 'top' | 'bottom';
};

const Tooltip = ({
  children,
  text,
  tooltipContent,
  id,
  position
}: ITooltipProps) => {
  return (
    <div className={classes['tooltip__comp']}>
      <div
        className={classes['tooltip__el']}
        data-tooltip-place={position}
        data-tooltip-id={id}
      >
        {children}
      </div>
      <ReactTooltip
        key={id}
        id={id}
        content={text}
        className={classes['tooltip__wrapper']}
      >
        {tooltipContent}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
