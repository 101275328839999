import CopyButton from 'components/buttons/CopyButton';
import { formatAddress } from 'utils/format';
import classes from './Wallet.module.scss';

interface IWalletAddressProps {
  address: string;
}

const WalletAddress = ({ address }: IWalletAddressProps) => {
  if (!address) return <> - </>;

  return (
    <div className={classes['wallet-address']} onClick={(e) => {}}>
      {formatAddress(address)}
      <CopyButton text={address} size="sm" />
    </div>
  );
};

export default WalletAddress;
