import React, { Fragment } from 'react';
import Divider from 'components/divider/Divider';
import { IRegFormFields } from 'utils/yupSchemas';
import { formatNames } from 'utils/format';
import classes from './Form.module.scss';

interface ISummary {
  data: IRegFormFields;
}

const Summary = ({ data }: ISummary) => {
  const { email, first_name, last_name, pic } = data;

  const accountInfo = [{ label: 'E-mail', value: email }];
  const personalInfo = [
    { label: 'First Name', value: formatNames(first_name) },
    { label: 'Last Name', value: formatNames(last_name) },
    { label: 'Profile Picture', value: pic, isImage: true }
  ];

  return (
    <div className={classes['summary-wrapper']}>
      <h4 className={classes['u-light']}>Account Information</h4>
      {accountInfo.map((field) => {
        return (
          <div key={field.label} className={classes['row']}>
            <div className={classes['row__title']}>{field.label}</div>
            <div className={classes['row__desc']}>{field.value}</div>
          </div>
        );
      })}
      <Divider dir="horizontal" />
      <h4 className={classes['u-light']}>Personal Information</h4>
      {personalInfo.map((field, i) => {
        if (!field.value) return null;
        return (
          <Fragment key={`${field.label}-${i}`}>
            <div key={field.label} className={classes['row']}>
              <div className={classes['row__title']}>{field.label}</div>
              {!field.isImage && (
                <div className={classes['row__desc']}>{field.value}</div>
              )}
              {field.isImage && (
                <img
                  className={classes['row__img--square']}
                  src={field.value}
                  alt="pfp"
                />
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default Summary;
