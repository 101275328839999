import React from 'react';
import Tabs from 'components/tabs/Tabs';
import Activated from './Activated';
import NotActivated from './NotActivated';
import { IProfile } from '../../index';

interface IPurchasedCoursesProps extends Pick<IProfile, 'subTab'> {}

const PurchasedCourses = ({ subTab }: IPurchasedCoursesProps) => {
  const tabs = [
    {
      id: 'activated',
      name: 'Activated Courses',
      route: 'user/purchased-courses/activated',
      component: () => <Activated />
    },
    {
      id: 'not-activated',
      name: 'Not Activated Courses',
      route: 'user/purchased-courses/not-activated',
      component: () => <NotActivated />
    }
  ];
  return (
    <Tabs
      tabs={tabs}
      activeTabIndex={tabs.findIndex((t) => t.id === subTab)}
      size={'sm'}
      isVertical
    />
  );
};

export default PurchasedCourses;
