import { useState } from 'react';
import Button from 'components/buttons/Button';

interface ITextWithCharLimit {
  text: string;
  limit: number;
  hideBtn?: boolean;
  btnAction?: () => void;
}

const TextWithCharLimit = ({
  text,
  limit,
  hideBtn,
  btnAction
}: ITextWithCharLimit) => {
  const [showFulLText, setShowFullText] = useState(false);
  const formattedText = showFulLText ? text : text.substring(0, limit) || '-';

  const formatTextWithNewLines = (text: string) => {
    const divs = text.split('\n');

    const divsWithNewLines = divs.map((line, index) => {
      if (!line) return <div key={index} style={{ height: '1em' }} />;
      // If it is the last line, render btn
      if (index === divs?.length - 1) {
        return (
          <div key={index}>
            <span>
              {line} {text.length >= limit && !showFulLText ? '... ' : ''}
            </span>
            {!hideBtn && text.length >= limit && (
              <Button
                variant="link-contrast"
                size="sm"
                withPadding={false}
                onClick={() =>
                  btnAction ? btnAction() : setShowFullText(!showFulLText)
                }
              >
                {showFulLText ? 'Show Less' : 'Show More'}
              </Button>
            )}
          </div>
        );
      }
      return <div key={index}>{line}</div>;
    });
    return divsWithNewLines;
  };

  return <div>{formatTextWithNewLines(formattedText)}</div>;
};

export default TextWithCharLimit;
