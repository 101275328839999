import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import queryString from 'query-string';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowShort.svg';
import Divider from 'components/divider/Divider';
import Button from 'components/buttons/Button';
import SliderComponent from 'components/slider/Slider';
import { IOption, InputChoice } from 'components/input/InputChoice';
import { InputWrapper } from 'components/input/InputWrapper';
import { navigateWithParams } from 'utils/format';
import { appContext } from 'context/appContext';
import { windowContext } from 'context/windowsContext';
import classes from './FilterMenu.module.scss';

interface IFilter {
  filterValue: IOption[];
  filterOptions: IOption[];
  filterName: string;
}

interface IFilterMenuProps {
  filters: IFilter[];
  passFilterValueUp?: (value: IOption[], filterName: string) => void;
  filterStartDateRange?: number;
  filterEndDateRange?: number;
  setDateRange?: (value: string[]) => void;
  hasPriceFilter?: boolean;
  setPage?: (i: number) => void;
}

const FilterMenu = ({
  filters,
  passFilterValueUp,
  filterStartDateRange,
  filterEndDateRange,
  setDateRange,
  hasPriceFilter,
  setPage
}: IFilterMenuProps) => {
  const navigate = useNavigate();
  const { pathname, search: urlParams } = useLocation();

  const { updateFilter } = useContext(appContext);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const { price } = queryString.parse(urlParams);
  const [isOpen, setIsOpen] = useState([true, true]);
  const [isRendered, setIsRendered] = useState(false);
  const [minValue, setMinValue] = useState(
    price && Number((price as string).split('-')[0].trim())
  );
  const [maxValue, setMaxValue] = useState(
    price && Number((price as string).split('-')[1].trim())
  );
  const [isPriceFilterOpened, setIsPriceFilterOpened] = useState(!!price);

  const defaultPriceValues = {
    min: Number(localStorage.getItem('minCoursePrice')),
    max: Number(localStorage.getItem('maxCoursePrice'))
  };

  document.addEventListener('coursePricesUpdated', (data) => {
    const newMinCoursePrice = Number(localStorage.getItem('minCoursePrice'));
    const newMaxCoursePrice = Number(localStorage.getItem('maxCoursePrice'));

    defaultPriceValues.min = newMinCoursePrice;
    defaultPriceValues.max = newMaxCoursePrice;

    setMinValue(newMinCoursePrice);
    setMaxValue(newMaxCoursePrice);
  });

  const updateOnRender = (index: number) => {
    if (!!filters[index].filterValue.length && !isRendered) {
      const updated: boolean[] = [...isOpen];
      updated[index] = true;
      setIsOpen(updated);
      setIsRendered(true);
    }
  };

  const filterByPrice = (min: number, max: number) => {
    setPage && setPage(1);
    navigateWithParams(
      urlParams,
      {
        price: `${min}-${max}`,
        page: 1
      },
      navigate,
      pathname
    );
  };

  useEffect(() => {
    if (!price) {
      setMinValue(defaultPriceValues.min);
      setMaxValue(defaultPriceValues.max);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  useEffect(() => {
    if (!filters.length && hasPriceFilter) setIsPriceFilterOpened(true);
  }, [filters, hasPriceFilter]);

  return (
    <div className={classes['filter']}>
      {filters?.map((filter: IFilter, index: number) => {
        updateOnRender(index);
        return (
          <div key={filter.filterName} className={classes['filter__body']}>
            <div
              className={classes['filter__title']}
              onClick={() => {
                const updated: boolean[] = [...isOpen];
                updated[index] = !updated[index];
                setIsOpen(updated);
              }}
            >
              <div className={`${classes['u-body2']} ${classes['u-bold']}`}>
                {filter.filterName}
              </div>
              <ArrowIcon
                className={`${classes['filter__arrow']} ${
                  classes[`filter__arrow--${isOpen[index] ? 'up' : 'down'}`]
                }`}
              />
            </div>
            {isOpen[index] && (
              <InputChoice
                name="option"
                type="checkbox"
                onChange={(value) =>
                  passFilterValueUp &&
                  passFilterValueUp(value, filter.filterName)
                }
                options={filter.filterOptions}
                selectedOptions={filter.filterValue}
                isMulti
              />
            )}
            <Divider />
          </div>
        );
      })}
      {hasPriceFilter && (
        <div className={classes['filter__body']}>
          <div
            className={classes['filter__title']}
            onClick={() => setIsPriceFilterOpened(!isPriceFilterOpened)}
          >
            <div className={`${classes['u-body2']} ${classes['u-bold']}`}>
              Price
            </div>
            <ArrowIcon
              className={`${classes['filter__arrow']} ${
                classes[`filter__arrow--${isPriceFilterOpened ? 'up' : 'down'}`]
              }`}
            />
          </div>
          {isPriceFilterOpened && (
            <>
              <div className={classes['filter__slider']}>
                <div>
                  <InputWrapper
                    type="number"
                    variant="outline"
                    value={minValue as number}
                    onChange={(value) => {
                      if (
                        defaultPriceValues &&
                        value <= defaultPriceValues.max
                      ) {
                        setMinValue(value);
                        filterByPrice(
                          value,
                          maxValue || defaultPriceValues.max
                        );
                      }
                    }}
                    isDebounced
                  />
                  {(minValue as number).toString().length < 6 && (
                    <span>USDC</span>
                  )}
                </div>
                <hr />
                <div>
                  <InputWrapper
                    type="number"
                    variant="outline"
                    value={maxValue as number}
                    onChange={(value) => {
                      if (
                        defaultPriceValues &&
                        value <= defaultPriceValues?.max
                      ) {
                        setMaxValue(value);
                        filterByPrice(
                          minValue || defaultPriceValues.min,
                          value
                        );
                      }
                    }}
                    isDebounced
                  />
                  {(maxValue as number).toString().length < 6 && (
                    <span>USDC</span>
                  )}
                </div>
              </div>
              <SliderComponent
                minValue={minValue as number}
                maxValue={maxValue as number}
                onChange={(e: number | number[]) => {
                  setMinValue((e as number[])[0]);
                  setMaxValue((e as number[])[1]);
                }}
                onChangeComplete={(e: number | number[]) =>
                  filterByPrice((e as number[])[0], (e as number[])[1])
                }
              />
            </>
          )}
          <Divider />
        </div>
      )}
      {setDateRange && (
        <InputWrapper
          type="datepicker-range"
          name="date_filter"
          title="Date of application"
          onChange={setDateRange}
          startDateRange={filterStartDateRange}
          endDateRange={filterEndDateRange}
          clearFilter={() => setDateRange(['', ''])}
          isFilter
        />
      )}
      <div className={classes['filter__buttons']}>
        {isMdMobile && (
          <Button
            variant="contrast"
            minWidth="sm"
            size="sm"
            onClick={updateFilter}
          >
            Apply filters
          </Button>
        )}
        <Button
          variant="link-neutral"
          size="sm"
          onClick={() => {
            if (isMdMobile) {
              updateFilter();
            }
            passFilterValueUp && passFilterValueUp([], '');
            setDateRange && setDateRange(['', '']);
            hasPriceFilter && setMinValue(defaultPriceValues?.min as number);
            hasPriceFilter && setMaxValue(defaultPriceValues?.max as number);
            setIsPriceFilterOpened(false);
            navigateWithParams(
              '',
              { page: 1, startDate: '', endDate: '' },
              navigate,
              pathname
            );
          }}
        >
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default FilterMenu;
