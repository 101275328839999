import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';

export const useEthersSigner = () => {
  const { address, connector } = useAccount();
  const [signer, setSigner] = useState<ethers.JsonRpcSigner | string>('');

  const initSigner = async () => {
    let walletProvider;
    await connector?.getProvider().then((provider: any) => {
      walletProvider = provider;
    });

    let provider = new ethers.BrowserProvider(walletProvider as any, 'any');
    const signerResult = await provider.getSigner(address);
    setSigner(signerResult);
  };

  useEffect(() => {
    if (address) initSigner();
    else setSigner('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return { signer };
};
