import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Student from 'assets/images/hero-image-1.png';
import Creator from 'assets/images/hero-image-2.png';
import { windowContext } from 'context/windowsContext';
import Button from 'components/buttons/Button';
import classes from './Carousel.module.scss';

interface ISlideProps {
  title: string;
  subtitle: string;
  buttonName: string;
  buttonAction: () => void;
  img: string;
}

const Slide = ({
  title,
  subtitle,
  buttonName,
  buttonAction,
  img
}: ISlideProps) => {
  const {
    windowSize: { isSmMobile }
  } = useContext(windowContext);

  return (
    <div className={classes['section__hero']}>
      <div className={classes['section__hero__info-col']}>
        {isSmMobile ? (
          <h1 className={`${classes['u-bold']} ${classes['u-h3']}`}>{title}</h1>
        ) : (
          <h1 className={`${classes['u-bold']} ${classes['u-h2']}`}>{title}</h1>
        )}
        <div className={classes['section__hero__info-col__subtitle']}>
          {subtitle}
        </div>
        <Button onClick={buttonAction} minWidth="lg">
          {buttonName}
        </Button>
      </div>
      <div className={classes['section__hero__img-col']}>
        <img className={classes['student-img']} src={img} alt="hero-student" />
      </div>
    </div>
  );
};

const Carousel = () => {
  const navigate = useNavigate();

  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<div class="' + className + '"></div>';
    }
  };

  return (
    <Swiper
      className={classes['swiper']}
      modules={[Autoplay, Pagination]}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 5000 }}
      pagination={pagination}
    >
      <SwiperSlide>
        <Slide
          title="LEARN THE SKILLS YOU NEED TO TAKE THE NEXT STEP"
          subtitle="Start, switch, or advance your career with courses, professional
          certificates and degrees from world-class universities and companies."
          buttonName="Explore Courses"
          buttonAction={() => navigate('/courses')}
          img={Student}
        />
      </SwiperSlide>
      <SwiperSlide>
        <Slide
          title="CREATE AND SHARE YOUR COURSE WITH THE WORLD"
          subtitle="Creators from around the world teach thousands of students on OpusEDU. 
          We provide you the tools to teach what you love."
          buttonName="Create Course"
          buttonAction={() => navigate('/courses/create')}
          img={Creator}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;
