import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as USDC } from 'assets/images/usdc.svg';
import { ReactComponent as Chevron } from 'assets/icons/arrowShort.svg';
import {
  IChangesMenuProps,
  IChangesObject,
  ICourseFieldChanges
} from './CourseChangesMenu';
import Loader from 'components/loader/Loader';
import Error from 'components/error/Error';
import Player from 'components/player/Player';
import Divider from 'components/divider/Divider';
import Badge from 'components/badge/Badge';
import Button from 'components/buttons/Button';
import { ITemplateDto } from 'query/course-module/dto';
import { singleTemplateDataQuery } from 'query/course-module/queries';
import {
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN,
  REACT_APP_FILES_PUBLIC_DOMAIN
} from 'utils/constants';
import classes from './SingleChange.module.scss';

interface ICertificateProps extends IChangesMenuProps {
  isOld: boolean;
}

const Certificate = ({ changes, isOld }: ICertificateProps) => {
  const newCertificateId = changes.find(
    (change) => change.valueType === 'certificate'
  )?.newValue;
  const {
    isLoading: newCertificateLoading,
    error: newCertificateErorr,
    data: newCertificateData
  } = useQuery<boolean, Error, ITemplateDto>({
    ...singleTemplateDataQuery(newCertificateId as string),
    enabled: !!newCertificateId
  });

  const oldCertificateId = changes.find(
    (change) => change.valueType === 'certificate'
  )?.oldValue;
  const {
    isLoading: oldCertificateLoading,
    error: oldCertificateErorr,
    data: oldCertificateData
  } = useQuery<boolean, Error, ITemplateDto>({
    ...singleTemplateDataQuery(oldCertificateId as string),
    enabled: !!oldCertificateId
  });

  if ((isOld && !oldCertificateId) || (!isOld && !newCertificateId))
    return <div>No certificate</div>;
  if ((isOld && oldCertificateLoading) || (!isOld && newCertificateLoading))
    return <Loader />;
  if ((isOld && oldCertificateErorr) || (!isOld && newCertificateErorr))
    return <Error error={oldCertificateErorr || newCertificateErorr} />;
  return (
    <img
      className={classes['thumb']}
      src={
        REACT_APP_FILES_PUBLIC_DOMAIN +
        (isOld ? oldCertificateData?.thumbnail : newCertificateData?.thumbnail)
      }
      alt="thumb"
    />
  );
};

interface ISingleChange {
  change: IChangesObject;
  allChanges: IChangesObject[];
  isFocused: boolean;
  areAllOpen: boolean;
  isOnlyCurrent?: boolean;
}

export const SingleChange = ({
  change,
  allChanges,
  isFocused,
  areAllOpen,
  isOnlyCurrent
}: ISingleChange) => {
  const [isOpen, setIsOpen] = useState(isFocused);

  useEffect(() => {
    // 1. Open all changes
    if (areAllOpen) return setIsOpen(true);
    // 2. Open focused change and scroll into view
    setIsOpen(isFocused);
    if (isFocused) {
      document
        .getElementById(`change-${change.fieldKey}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, areAllOpen]);

  const renderValue = (
    value: string,
    valueType: ICourseFieldChanges['valueType'],
    isOld: boolean,
    fieldKey: string
  ) => {
    if (valueType === 'certificate') {
      return <Certificate changes={allChanges} isOld={isOld} />;
    }
    if (valueType === 'image' && !!value) {
      return (
        <img
          className={classes['thumb']}
          src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + value}
          alt="thumb"
        />
      );
    }
    if (valueType === 'video') {
      if (!value) return '-';
      return (
        <Player
          height="140px"
          src={
            fieldKey === 'video_preview'
              ? REACT_APP_FILES_EDU_PUBLIC_DOMAIN + value
              : value
          }
          hasControls
        />
      );
    }
    if (valueType === 'price') {
      return (
        <div
          className={`${classes['u-flex']} ${classes['u-flex-vertical']} ${classes['u-gap-4']}`}
        >
          <div
            className={`${classes['u-flex']} ${classes['u-flex-align-center']} ${classes['u-gap-4']}`}
          >
            <USDC width={32} height={32} />
            <span>
              <span className={classes['details__content__price--number']}>
                {parseFloat(value).toFixed(2)}
              </span>{' '}
              USDC
            </span>
          </div>
        </div>
      );
    }
    return value || '-';
  };

  return (
    <div id={`change-${change.fieldKey}`} className={classes['menu']}>
      <ChangeHeader
        onClick={() => setIsOpen(!isOpen)}
        title={change.title}
        isOpen={isOpen}
      />
      {isOpen && (
        <div className={classes['menu__body']}>
          <div className={classes['menu__body__change']}>
            <div className={classes['menu__body__change__row']}>
              <div className={classes['menu__body__change__row__badge']}>
                <Badge badgeType="primary" text="Current" />
                {!isOnlyCurrent && (
                  <Divider
                    dir="vertical"
                    className={`${classes['divider']} ${classes['divider--after-badge']}`}
                  />
                )}
              </div>
              <div className={classes['menu__body__change__row__values']}>
                <div className={classes['u-semiBold']}>
                  {renderValue(
                    change.newValue,
                    change.valueType,
                    false,
                    change.fieldKey
                  )}
                </div>
              </div>
            </div>
            {!isOnlyCurrent && (
              <>
                <Divider dir="vertical" className={classes['divider']} />
                <div className={classes['menu__body__change__row']}>
                  <div className={classes['menu__body__change__row__badge']}>
                    <Badge badgeType="disabled" text="Previous" />
                  </div>
                  <div className={classes['menu__body__change__row__values']}>
                    <div className={classes['u-semiBold']}>
                      {renderValue(
                        change.oldValue,
                        change.valueType,
                        true,
                        change.fieldKey
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Divider />
    </div>
  );
};

interface IChangeHeader {
  title: string;
  onClick: () => void;
  isOpen: boolean;
}

export const ChangeHeader = ({ title, onClick, isOpen }: IChangeHeader) => {
  return (
    <div className={classes['menu__header']} onClick={onClick}>
      <h5 className={classes['u-semiBold']}>{title}</h5>
      <Button
        variant="link-neutral"
        size="sm"
        isIconBtn
        icon={Chevron}
        iconRotate={!isOpen ? 180 : 0}
        withPadding={false}
        onClick={onClick}
      />
    </div>
  );
};
