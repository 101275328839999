import React from 'react';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import Button from 'components/buttons/Button';
import { InputFileAttachments } from 'components/input/InputFileAttachments';
import { IFormik } from './VideoForm';
import { handleFileUpload } from 'utils/processing';
import classes from './Form.module.scss';

interface IStep3
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
  > {
  currentStep: number;
  setStep: (step: number) => void;
  renderPrevBtn: () => {};
}

export const Step3 = ({
  currentStep,
  setStep,
  renderPrevBtn,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError
}: IStep3) => {
  return (
    <>
      <div className={classes['fields-wrapper']}>
        <div className={classes['info-box']}>
          <p>
            Attach a file(s) to your video if you want to give the viewers some
            more information or you want to provide an educational materials.
            Attaching files is optional.
          </p>
        </div>
        <InputFileAttachments
          name="attached_files"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleFileUpload(
              e,
              'attached_files',
              setFieldValue,
              setFieldTouched,
              setFieldError,
              values.attached_files
            );
          }}
          onClear={(fileName) => {
            const filteredFiles = values.attached_files?.filter(
              (file) => file.name !== fileName
            );
            setFieldValue('attached_files', filteredFiles);
          }}
          onPrevFileClear={(file) => {
            // Build modified file object
            const modifiedFile = {
              name: file.name,
              modify: {
                id: file._id,
                action: 'delete'
              }
            };
            // Update already attached files list
            setFieldValue(
              'hidden_files',
              !!values.hidden_files
                ? values.hidden_files.filter((f) => f._id !== file._id)
                : []
            );
            // Update modified files list
            setFieldValue(
              'hidden_field',
              values.hidden_field && values.hidden_field.length > 0
                ? [...values.hidden_field, modifiedFile]
                : [modifiedFile]
            );
          }}
          isRequired
          value={values.attached_files as any[]}
          error={errors.attached_files ? errors.attached_files : ''}
          prevAttachments={values.hidden_files}
          onClearError={() => setFieldError('attached_files', '')}
        />
      </div>
      <div className={classes['btns-wrapper']}>
        <>
          {renderPrevBtn()}
          <Button
            type="button"
            onClick={() => setStep(currentStep + 1)}
            icon={Arrow}
            iconRotate={90}
            isDisabled={!!errors.attached_files}
          >
            Next Step
          </Button>
        </>
      </div>
    </>
  );
};

export default IStep3;
