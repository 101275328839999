import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import NoCourses from 'assets/images/no-courses-gray.svg';
import NoUsers from 'assets/images/no-users-gray.svg';
import useComponentVisible from 'hooks/useComponentVisible';
import Avatar from 'components/avatar/Avatar';
import EmptyState from 'components/emptyState/EmptyState';
import CourseCard from '../components/CourseCard';
import Loader from 'components/loader/Loader';
import { InputWrapper } from 'components/input/InputWrapper';
import {
  COURSE_CHANGES,
  ICourse,
  ICourseAndCreatorsData
} from 'query/course-module/dto';
import { topCoursesAndCreatorsQuery } from 'query/course-module/queries';
import { ICreator } from 'query/acc-module/dto';
import { formatNames } from 'utils/format';
import classes from '../Header.module.scss';

const Search = () => {
  const navigate = useNavigate();
  const { isComponentVisible, setIsComponentVisible, ref } =
    useComponentVisible(false);

  const [searchValue, setSearchValue] = useState('');

  const queryParams = `?&limit=3&page=1${
    searchValue.length > 0
      ? `&search[name]=${searchValue}&search[first_name]=${searchValue}&search[last_name]=${searchValue}`
      : ''
  }`;

  const { isLoading, data } = useQuery<boolean, Error, ICourseAndCreatorsData>({
    ...topCoursesAndCreatorsQuery(queryParams),
    enabled: !!searchValue && searchValue.length > 1 && isComponentVisible
  });

  const { courses, creators } = data || {};
  const coursesData = courses?.result;
  const creatorsData = creators?.result;
  const hasCoursesData = !!coursesData?.length;
  const hasCreatorsData = !!creatorsData?.length;

  useEffect(() => {
    if (searchValue?.length > 1) {
      return setIsComponentVisible(true);
    }
    setIsComponentVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue?.length]);

  return (
    <div
      ref={ref}
      className={classes['search-wrapper']}
      onFocus={() => setIsComponentVisible(true)}
    >
      <InputWrapper
        type="search"
        variant="outline"
        name="header-search"
        placeholder="Search OpusEDU"
        value={searchValue}
        onChange={setSearchValue}
        isRequired
        isDebounced
        isClearable={!!searchValue}
        onClear={() => {
          setSearchValue('');
          setIsComponentVisible(false);
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' && searchValue) {
            navigate(`/results?search=${searchValue}`);
            setIsComponentVisible(false);
            setSearchValue('');
          }
        }}
      />
      {isComponentVisible && searchValue?.length > 1 && (
        <div className={classes['search-results-wrapper']}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className={classes['search-results']}>
                <h5
                  className={`${classes['u-body2']} ${classes['u-semiBold']}`}
                >
                  Courses
                </h5>
                {hasCoursesData ? (
                  coursesData.map((course: ICourse) => (
                    <CourseCard
                      key={course._id}
                      course={course}
                      onClick={() => {
                        setSearchValue('');
                        navigate({
                          pathname: `/courses/${course.slug}`,
                          search: `${createSearchParams({
                            changes: COURSE_CHANGES.NO_CHANGES
                          })}`
                        });
                      }}
                    />
                  ))
                ) : (
                  <EmptyState icon={NoCourses} title="No Courses found" />
                )}
              </div>
              <div className={classes['search-results']}>
                <h5
                  className={`${classes['u-body2']} ${classes['u-semiBold']}`}
                >
                  Content Creators
                </h5>
                {hasCreatorsData ? (
                  creatorsData.map((user: ICreator) => (
                    <div
                      key={user?._id}
                      className={classes['result']}
                      onClick={() => {
                        setSearchValue('');
                        navigate(`/user/${user.slug}/about/main-info`);
                      }}
                    >
                      <Avatar size="sm" isBorderless src={user.pic} />
                      <div className={classes['u-semiBold']}>
                        {formatNames(user?.first_name + ' ' + user?.last_name)}
                      </div>
                    </div>
                  ))
                ) : (
                  <EmptyState
                    icon={NoUsers}
                    title="No Content Creators found"
                  />
                )}
              </div>
              {(hasCoursesData || hasCreatorsData) && (
                <h5 className={classes['u-body2']}>
                  Press enter to reveal more items
                </h5>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
