import { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useAccount } from 'wagmi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { popupContext } from 'context/popupContext';
import { userContext } from 'context/userContext';
import { Web3Context } from 'context/web3Context';
import { toastContext } from 'context/toastContext';
import Button from 'components/buttons/Button';
import GenericPopup from 'components/popup/GenericPopup';
import ApprovePopup from 'components/popup/ApprovePopup';
import VerifyAccountPopup from 'components/popup/VerifyAccountPopup';
import Tooltip from 'components/tooltip/Tooltip';
import PublishPopup from '../../components/popups/PublishPopup';
import { USER_KYC_STATUS } from 'query/acc-module/dto';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  ICourse
} from 'query/course-module/dto';
import {
  deleteCourseMutation,
  deleteDraftMutation,
  submitForApprovalMutation
} from 'query/course-module/mutations';
import { COURSE_REVIEW_STATUS, COURSE_STATUS } from 'utils/constants';
import { isCourseStatus } from 'utils/helpers';
import classes from './Buttons.module.scss';

interface ICreatorButtonsProps {
  course: ICourse;
}

const CreatorButtons = ({ course }: ICreatorButtonsProps) => {
  const queryClient = useQueryClient();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { setToast } = useContext(toastContext);
  const { changes, is_public_view } = queryString.parse(urlParams);
  const { setPopup, clearPopup, setPopupLoading } = useContext(popupContext);
  const {
    userData: { kyc_status },
    isTokenExpired
  } = useContext(userContext);
  const { isCorrectChain, isWalletUnique } = useContext(Web3Context);
  const { isConnected } = useAccount();

  const {
    _id,
    course_relationship,
    status,
    has_draft_copy,
    is_draft_copy,
    review_status,
    not_finished,
    list_tx_started_at,
    update_tx_started_at
  } = course;

  const {
    // eslint-disable-next-line
    isLoading: submitsLoading,
    // eslint-disable-next-line
    error: submitError,
    mutate: submitMutation
  } = useMutation({
    ...submitForApprovalMutation(_id as string, setPopupLoading),
    onSuccess: (action: 'submit' | 'withdraw') => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          msg={
            action === 'submit'
              ? 'You have submitted the course for approval. Once the course is approved you will be able to publish it.'
              : 'Request for approval successfully withdrawn.'
          }
          buttonVariant="contrast"
          buttonName={
            action === 'submit'
              ? 'View courses in review'
              : 'View draft courses'
          }
          buttonAction={() =>
            navigate(
              `/user/created-courses/${
                action === 'submit' ? 'submitted' : 'draft'
              } `
            )
          }
          bellowBtnComp={
            <Button
              variant="link-neutral"
              onClick={clearPopup}
              minWidth="full"
              withPadding={false}
            >
              Close
            </Button>
          }
        />
      );
      queryClient.invalidateQueries(['courses-data']);
      queryClient.invalidateQueries(['courses-data', { id: _id }]);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  const isCreatorPublicView = is_public_view === 'true';
  const isDraft = isCourseStatus('isDraft', course);
  const isFinishedDraft = isCourseStatus('isFinishedDraft', course);
  const isSubmitted = isCourseStatus('isSubmitted', course);
  const isApproved = isCourseStatus('isApprovedInitial', course);
  const isApprovedForRepublish = isCourseStatus(
    'isApprovedForRepublish',
    course
  );
  const isPublished = isCourseStatus('isPublished', course);
  const isPublishing = isCourseStatus('isPublishing', course);
  const isRepublishing = isCourseStatus('isRepublishing', course);
  const isDraftOfPublished = isCourseStatus('isDraftOfPublished', course);

  const {
    // eslint-disable-next-line
    isLoading: deleteLoading,
    mutate: deleteMutation
  } = useMutation({
    mutationFn: async () =>
      !!isDraftOfPublished
        ? await deleteDraftMutation(_id).mutationFn()
        : await deleteCourseMutation(_id).mutationFn(),
    onSuccess: () => {
      setPopupLoading(false);
      setToast({
        type: 'success',
        msg: 'Successfully deleted.',
        position: 'top',
        autoClose: true
      });
      navigate('/user/created-courses/draft');
      queryClient.invalidateQueries(['courses-data']);
      queryClient.invalidateQueries(['courses-data', { id: _id }]);
    },
    onError: (e: Error) => {
      setPopupLoading(false);
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  return (
    <>
      {(isFinishedDraft ||
        isDraft ||
        (isDraftOfPublished &&
          review_status === COURSE_REVIEW_STATUS.INITIAL)) && (
        <>
          <div className={classes['btns-row']}>
            <div className={classes['btns-row__primary']}>
              <Tooltip
                id={_id}
                text={
                  isDraft
                    ? 'You have to fill in all required fields before submitting the course for approval.'
                    : ''
                }
              >
                <Button
                  className={classes['btns-row__btn']}
                  onClick={() =>
                    setPopup(
                      kyc_status === USER_KYC_STATUS.APPROVED ? (
                        <ApprovePopup
                          size="md"
                          title="Apply for Approval"
                          msg="Are you sure you want to submit the course for approval?"
                          course={course}
                          buttonVariant="contrast"
                          buttonName="Apply for Approval"
                          buttonAction={() => submitMutation('submit')}
                        />
                      ) : (
                        <VerifyAccountPopup />
                      )
                    )
                  }
                  variant="contrast"
                  isDisabled={not_finished}
                  minWidth="full"
                >
                  Apply for Approval
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className={classes['btns-row']}>
            <div className={classes['btns-row__primary']}>
              <Button
                minWidth="full"
                variant="error"
                isLoading={deleteLoading}
                className={`${classes['btns-row__btn']} ${classes['btns-row__btn--error']}`}
                icon={Cross}
                onClick={() =>
                  setPopup(
                    <ApprovePopup
                      size="md"
                      title="Delete Draft Course"
                      msg="Are you sure you want to delete your draft course?"
                      course={course}
                      buttonVariant="error"
                      buttonName="Delete"
                      buttonAction={() => deleteMutation()}
                    />
                  )
                }
              >
                Delete Draft Course
              </Button>
            </div>
          </div>
        </>
      )}
      {((isApproved && changes === COURSE_CHANGES.NO_CHANGES) ||
        (isApprovedForRepublish &&
          changes === COURSE_CHANGES.ONLY_CHANGES)) && (
        <div className={classes['btns-row']}>
          <Tooltip
            id="tooltip-edit"
            text={
              isTokenExpired ||
              !isConnected ||
              !isCorrectChain ||
              !isWalletUnique
                ? `In order to publish the course, please 
            ${
              isTokenExpired
                ? 'log in first.'
                : !isConnected
                ? 'connect your wallet first.'
                : !isCorrectChain
                ? 'switch to the Polygon network.'
                : !isWalletUnique
                ? 'switch your wallet. This one is associated with another account.'
                : ''
            }`
                : ''
            }
          >
            <Button
              onClick={() =>
                setPopup(
                  kyc_status === USER_KYC_STATUS.APPROVED ? (
                    <PublishPopup data={course} />
                  ) : (
                    <VerifyAccountPopup />
                  )
                )
              }
              variant="contrast"
              minWidth="full"
              isDisabled={!!list_tx_started_at || !!update_tx_started_at}
              isWeb3
            >
              {(!!list_tx_started_at && isApproved) ||
              (!!update_tx_started_at && isApprovedForRepublish) ||
              status === COURSE_STATUS.PUBLISHING
                ? 'Processing...'
                : 'Publish'}
            </Button>
          </Tooltip>
        </div>
      )}
      {/* {isPublished && !isCreatorPublicView && !isDraftOfPublished && (
        <div>
          <Button
            onClick={() => {}}
            variant="neutral"
            minWidth="full"
            isDisabled={isSubmitted || isApproved || isPublishing}
          >
            Pause Course
          </Button>
        </div>
      )} */}
      {!isCreatorPublicView &&
        isSubmitted &&
        (changes === COURSE_CHANGES.ONLY_CHANGES ||
          (changes === COURSE_CHANGES.NO_CHANGES &&
            !has_draft_copy &&
            !is_draft_copy)) && (
          <div className={classes['btns-row']}>
            <Button
              variant="neutral"
              minWidth="full"
              onClick={() =>
                setPopup(
                  <ApprovePopup
                    size="md"
                    title="Withdraw Approval Request"
                    msg="Are you sure you want to withdraw the approval request?"
                    course={course}
                    buttonVariant="contrast"
                    buttonName="Withdraw Approval Request"
                    buttonAction={() => submitMutation('withdraw')}
                  />
                )
              }
            >
              Withdraw Submission
            </Button>
          </div>
        )}
      {!isCreatorPublicView && (
        <Tooltip
          id="tooltip-publish"
          text={
            isPublishing || isApproved || isSubmitted || isApprovedForRepublish
              ? `You cannot edit a course 
            ${
              isPublishing
                ? 'while it is publishing'
                : isApproved || isApprovedForRepublish
                ? 'before resolving current approval'
                : isSubmitted
                ? 'while in review'
                : ''
            }`
              : ''
          }
        >
          <Button
            className={classes['btns-row__btn']}
            onClick={() =>
              navigate(
                `/courses/${slug}/edit?changes=${
                  has_draft_copy || isDraftOfPublished
                    ? COURSE_CHANGES.ONLY_CHANGES
                    : COURSE_CHANGES.NO_CHANGES
                }`
              )
            }
            icon={Edit}
            iconPosition="left"
            variant="link-neutral"
            isDisabled={
              isSubmitted ||
              isApproved ||
              isPublishing ||
              isApprovedForRepublish ||
              isRepublishing
            }
            minWidth="full"
          >
            Edit Course Details
          </Button>
        </Tooltip>
      )}
      {course_relationship === COURSE_RELATIONSHIP['CREATOR'] &&
        isPublished &&
        !isDraftOfPublished &&
        changes === COURSE_CHANGES.NO_CHANGES && (
          <div>
            <Button
              className={classes['btns-row__btn']}
              variant="neutral"
              icon={isCreatorPublicView ? Cross : Eye}
              iconPosition="left"
              onClick={() =>
                navigate(
                  `/courses/${slug}?is_public_view=${
                    is_public_view === 'true' ? 'false' : 'true'
                  }&changes=${changes}`
                )
              }
              minWidth="full"
            >
              {isCreatorPublicView ? 'Close Preview' : 'Preview as Guest'}
            </Button>
          </div>
        )}
    </>
  );
};

export default CreatorButtons;
