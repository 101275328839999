import ReactDOM from 'react-dom';
import { Row } from 'react-table';
import Button from 'components/buttons/Button';
import classes from './SelectedIsland.module.scss';

interface ISelectedIslandProps {
  primBtnText: string;
  primBtnAction: (selectedRows: any) => void;
  secBtnText?: string;
  secBtnAction?: (selectedRows: any) => void;
  selectedFlatRows: Row<{}>[];
  dynamicBtnText?: (selectedRows: any) => void;
}

const SelectedIsland = ({
  primBtnText,
  primBtnAction,
  secBtnText,
  secBtnAction,
  selectedFlatRows,
  dynamicBtnText
}: ISelectedIslandProps) => {
  return ReactDOM.createPortal(
    <div className={classes['wrapper']}>
      <div className={classes['island__container']}>
        <div className={classes['island']}>
          <h5 className={`${classes['u-body2']} ${classes['u-semiBold']}`}>
            {selectedFlatRows.length} selected
          </h5>
          <div className={classes['btns-row']}>
            <Button
              size="sm"
              minWidth="sm"
              onClick={() => primBtnAction(selectedFlatRows)}
            >
              {primBtnText ||
                (dynamicBtnText && (dynamicBtnText(selectedFlatRows) as any))}
            </Button>
            {secBtnAction && (secBtnText || dynamicBtnText) && (
              <Button
                variant="outline"
                size="sm"
                minWidth="sm"
                onClick={() => secBtnAction(selectedFlatRows)}
              >
                {secBtnText ||
                  (dynamicBtnText && (dynamicBtnText(selectedFlatRows) as any))}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>,
    // Target container outside the root element
    document.getElementById('app_wrapper') as HTMLElement
  );
};

export default SelectedIsland;
