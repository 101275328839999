import { useState, useCallback, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import DragAndDropCard from '../../../../../../components/drag-and-drop/DragАndDropCard';
import {
  ICourseResourcesData,
  IEditVideoOrderDto,
  IResourceVideo,
  VIDEO_EDIT_STATUS
} from 'query/course-module/dto';
import { IProgressData, IVideosTabProps } from '../index';
import { isCourseStatus } from 'utils/helpers';
import { IResources } from '../../components/Resources';
import VideoCard from 'components/videoCard/VideoCard';
import classes from './DragAndDrop.module.scss';

export interface IDragAndDropProps {
  data: IVideosTabProps['data'];
  handleVideoChange: (id: string) => void;
  handleVideoOrder: ({ data, courseId }: IEditVideoOrderDto) => void;
  progressData: {
    [key: string]: IProgressData;
  };
  courseProps: IResources['courseProps'];
}

export interface IExtendedResourceVideo extends IResourceVideo {
  progress: IProgressData['progress'];
  videosCheckpoints: ICourseResourcesData['videos_checkpoint'];
  videosWatched: ICourseResourcesData['videos_watched'];
  files: ICourseResourcesData['files'];
  tests: ICourseResourcesData['tests'];
}

export const DragAndDrop = ({
  data,
  handleVideoChange,
  handleVideoOrder,
  progressData,
  courseProps
}: IDragAndDropProps) => {
  const { course, isAdminPage, isPublic, isVideoPage } = courseProps;
  const { _id: courseId } = course;

  const { videosData, videosCheckpoints, videosWatched } = data;

  // In order Drag&Drop to work correctly, data indexes should be updated to start from `0`, not from `1`.
  // For future improvement BE could return indexes from `0` initially.
  const updateDataIndex = (d: IResourceVideo) => ({ ...d, index: d.index - 1 });

  const isMounted = useRef(false);
  const [cards, setCards] = useState(videosData.map(updateDataIndex));

  // Enable drag functionality in following scenarios
  const canDrag =
    !isAdminPage &&
    !isPublic &&
    !isCourseStatus('isSubmitted', course) &&
    !isCourseStatus('isApprovedInitial', course) &&
    !isCourseStatus('isPublishing', course) &&
    !isCourseStatus('isApprovedForRepublish', course) &&
    !isCourseStatus('isRepublishing', course) &&
    !isVideoPage &&
    cards.length > 1;

  // Drag&Drop move card functionality
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }, []);

  // Trigger video order change mutation only on drop
  const onDrop = () => handleVideoOrder({ data: cards, courseId });

  // Update video cards on video add/delete
  useEffect(() => {
    if (videosData.length !== cards.length)
      setCards(videosData.map(updateDataIndex));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // NOTE: avoid automatically mutating videos order on initial render on local environment
    isMounted.current = true;
  }, []);

  // Update cards on data change
  useEffect(() => {
    if (isMounted.current) {
      setCards(videosData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes['wrapper']}>
        {cards
          // User should not see videos marked for deletion. Admin must see them.
          .filter((card) =>
            !isAdminPage ? card.edit_status !== VIDEO_EDIT_STATUS.DELETED : card
          )
          .map((card, i) => {
            return (
              <DragAndDropCard
                key={card._id}
                index={i}
                moveCard={moveCard}
                onDrop={onDrop}
                canDrag={canDrag}
                card={card}
              >
                <VideoCard
                  key={card._id}
                  card={{
                    ...card,
                    progress: progressData[card._id]?.progress,
                    videosCheckpoints: videosCheckpoints,
                    videosWatched: videosWatched,
                    files: !!data?.files
                      ? data?.files?.filter(
                          (files) => files.video_id === card._id
                        )
                      : [],
                    tests: !!data.tests
                      ? data.tests.filter((test) => test.video_id === card._id)
                      : []
                  }}
                  courseProps={courseProps}
                  handleVideoChange={handleVideoChange}
                  canDrag={canDrag}
                />
              </DragAndDropCard>
            );
          })}
      </div>
    </DndProvider>
  );
};

export default DragAndDrop;
