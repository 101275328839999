import React from 'react';
import placeholder from 'assets/images/no-results.png';
import classes from './NoResults.module.scss';

const NoResults = () => {
  return (
    <div className={classes['wrapper']}>
      <img src={placeholder} alt="placeholder" />
      <h5 className={classes['u-semiBold']}>No results found</h5>
    </div>
  );
};

export default NoResults;
