import { useState, useContext } from 'react';
import Editor from 'react-simple-wysiwyg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import Button from 'components/buttons/Button';
import { InputWrapper } from 'components/input/InputWrapper';
import { ModalWrapper } from './common/ModalWrapper';
import { fetchApi } from 'utils/requests';
import { ISinglePageData } from 'query/course-module/dto';
import classes from './EditStaticPagePopup.module.scss';

interface IEditStaticPageProps {
  data: ISinglePageData;
}

const EditStaticPagePopup = ({
  data: { slug, title, content }
}: IEditStaticPageProps) => {
  const navigate = useNavigate();
  const { clearPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const queryClient = useQueryClient();

  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedContent, setUpdatedContent] = useState(content);

  const { isLoading, mutate: sendEmail } = useMutation({
    mutationKey: ['admin-edit-page'],
    mutationFn: async () => {
      await fetchApi('courses', `/admin/page/${slug}`, {
        method: 'PUT',
        auth: true,
        data: {
          title: updatedTitle,
          content: updatedContent
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['all-pages']);
      navigate(`/${slug}`);
      setToast({
        type: 'success',
        msg: 'Successfully Updated.',
        position: 'top',
        autoClose: true
      });
    },
    onError: (err: Error) => {
      setToast({
        type: 'error',
        position: 'top',
        title: 'Update Failed',
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  return (
    <ModalWrapper size="lg">
      <div className={classes['page']}>
        <div className={classes['page__header']}>
          <h4 className={classes['u-bold']}>Edit Page</h4>
        </div>
        <InputWrapper
          variant="outline"
          title="Title"
          placeholder="Title"
          value={updatedTitle}
          onChange={setUpdatedTitle}
        />
        <div className={classes['page__content']}>
          <h5 className={`${classes['u-semiBold']} ${classes['u-body3']}`}>
            Content
          </h5>
          <Editor
            value={updatedContent}
            containerProps={{
              style: { resize: 'vertical', overflow: 'scroll' }
            }}
            onChange={(e) => setUpdatedContent(e.target.value)}
          />
        </div>
        <div className={classes['page__btns-container']}>
          <Button
            size="md"
            isDisabled={!updatedTitle.length || !updatedContent.length}
            onClick={() => sendEmail()}
            isLoading={isLoading}
          >
            Confirm
          </Button>
          <Button variant="link-neutral" size="md" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default EditStaticPagePopup;
