import { Column } from 'react-table';
import { ReactComponent as Image } from 'assets/icons/image.svg';
// import { IndeterminateCheckbox } from './InterdeterminateCheckbox';
import { RenderBadge } from 'components/badge/RenderBadge';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { ICourse } from 'query/course-module/dto';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { tableColumnsWidths } from './utils/table-utils';
import { formatNames } from 'utils/format';
import classes from './AdminCoursesCols.module.scss';

export const AdminCoursesCols = () => {
  const cols: Column[] = [
    {
      Header: 'Course Name',
      accessor: 'course_name',
      maxWidth: tableColumnsWidths.courseNameAndPicMax,
      minWidth: tableColumnsWidths.courseNameAndPicMin,
      width: tableColumnsWidths.courseNameAndPic,
      Cell: ({ row }: { row: { original: ICourse } }) => {
        return (
          <div className={classes['course']}>
            <div
              className={`${classes['course__img-container']} ${
                !row.original.thumbnail
                  ? classes['course__img-container--empty']
                  : ''
              }`}
            >
              <div className={classes['badge']}>
                <RenderBadge course={row.original} />
              </div>
              {!!row.original.thumbnail && (
                <img
                  src={
                    REACT_APP_FILES_EDU_PUBLIC_DOMAIN + row.original.thumbnail
                  }
                  alt={`thumb-${row.original.name}`}
                />
              )}
              {!row.original.thumbnail && <Image />}
            </div>
            <div className={`${classes['u-semiBold']} ${classes['u-body1']}`}>
              <TextWithCharLimit
                text={row.original.name || '(Untitled Course)'}
                limit={50}
                hideBtn
              />
            </div>
          </div>
        );
      }
    },
    {
      Header: 'Creator',
      accessor: 'creator',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <>
          {formatNames(
            row.original.creator.first_name +
              ' ' +
              row.original.creator?.last_name
          )}
        </>
      )
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <div>
          <span>{row.original.price ? row.original.price : ' - '}</span>{' '}
          {!!row.original.price && (
            <span className={classes['u-semiBold']}>
              {row.original.currency?.toUpperCase()}
            </span>
          )}
        </div>
      )
    },
    {
      Header: 'Videos',
      accessor: 'videos_amount',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <>{row.original.video_count || '0'}</>
      )
    }
  ];

  // // // Add an element to the beginning of columns
  // cols.unshift({
  //   id: 'selection',
  //   // The header can use the table's getToggleAllRowsSelectedProps method
  //   // to render a checkbox
  //   Header: (tableProps) => {
  //     return (
  //       <IndeterminateCheckbox
  //         {...tableProps.getToggleAllRowsSelectedProps()}
  //       />
  //     );
  //   },
  //   maxWidth: tableColumnsWidths.selectionMax,
  //   minWidth: tableColumnsWidths.selectionMin,
  //   width: tableColumnsWidths.selection,
  //   //   // The cell can use the individual row's getToggleRowSelectedProps method
  //   //   // to the render a checkbox
  //   Cell: (tableProps) => {
  //     return (
  //       <IndeterminateCheckbox
  //         {...tableProps.row.getToggleRowSelectedProps()}
  //       />
  //     );
  //   }
  // });

  return cols;
};
