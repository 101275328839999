import { useCallback, useContext, useRef, useState, useEffect } from 'react';
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { Portal } from 'react-portal';
import queryString from 'query-string';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Upload } from 'assets/icons/upload.svg';
import NoVideos from 'assets/images/no-videos.svg';
import { popupContext } from 'context/popupContext';
import { toastContext } from 'context/toastContext';
import useBrowserDetect from 'hooks/useBrowserDetect';
import EmptyState from 'components/emptyState/EmptyState';
import Button from 'components/buttons/Button';
import EditVideoDetailsPopup from './add/EditVideoDetailsPopup';
import GenericPopup from 'components/popup/GenericPopup';
import DragAndDrop from './components/DragAndDrop';
import { IResources } from '../components/Resources';
import AddVideoIsland from '../components/AddVideoIsland';
import ContentPopup from './popups/ContentPopup';
import {
  createVideoDocMutation,
  editVideoMutation,
  editVideoOrderMutation,
  postVideoMutation,
  postVideoProgressMutation,
  createDraftMutation,
  getVideoUploadLinkMutation,
  postFileChunks,
  finalizeUploadMutation
} from 'query/course-module/mutations';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  ICourseResourcesData,
  IResourceVideo
} from 'query/course-module/dto';
import { generateVideoThumbnail } from 'utils/generators';
import {
  REACT_APP_FILE_UPLOAD_CHUNK_SIZE_MB,
  VIDEO_EDIT_STATUS
} from 'utils/constants';
import { isCourseStatus, isSafari } from 'utils/helpers';
import classes from './Videos.module.scss';

export interface IProgressData {
  // Raw bytes size
  bytesUploaded: number;
  // Calculated progress
  progress: number;
  // Pass `stream` in order to cancel it in `VideoCard` component
}

export interface IVideosTabProps {
  data: {
    videosData: IResourceVideo[];
    videosCheckpoints: ICourseResourcesData['videos_checkpoint'];
    videosWatched: ICourseResourcesData['videos_watched'];
    files: ICourseResourcesData['files'];
    tests: ICourseResourcesData['tests'];
  };
  courseProps: IResources['courseProps'];
  setIsVideoUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const Videos = ({ data, courseProps, setIsVideoUploaded }: IVideosTabProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state: locationState, search } = useLocation();
  const { changes } = queryString.parse(search);
  const { setPopup } = useContext(popupContext);
  const { setToast } = useContext(toastContext);
  const browser = useBrowserDetect();
  const inputFileRef: any = useRef();

  const [videoDocId, setVideoDocId] = useState<string>(
    locationState?.videoDocId || ''
  );

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [progressData, setProgressData] = useState<{
    [key: string]: IProgressData;
  }>({});

  const { course, isAdminPage, isPublic, isVideoPage } = courseProps;
  const { _id: courseId, is_draft_copy, has_draft_copy, slug } = course;

  const { videosData } = data;

  // Get upload video link
  const { mutate: handleGetVideoUploadLink } = useMutation({
    ...getVideoUploadLinkMutation(),
    onSuccess: (res: { url: string }) => {
      // handleStream(res._id);
      handleVideoUpload(res.url);
      queryClient.invalidateQueries([
        'course-resources-data',
        { changes, id: courseId }
      ]);
    },
    onError: (err: Error) =>
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
          isClosable={false}
        />
      )
  });

  // Create video doc mutation
  const { isLoading: isLoadingCreateVideoDoc, mutate: handleCreateVideoDoc } =
    useMutation({
      ...createVideoDocMutation(),
      onSuccess: (res: IResourceVideo) => {
        // Save the newly created video doc id. Use it to attach video file and video details on next steps.
        setVideoDocId(res._id);
        handleGetVideoUploadLink({ courseId, videoId: res._id as string });
        queryClient.invalidateQueries([
          'course-resources-data',
          { changes, id: courseId }
        ]);
      },
      onError: (err: Error) =>
        setPopup(
          <GenericPopup
            type="error"
            msg={err.message}
            buttonName="Close"
            buttonVariant="neutral"
            isClosable={false}
          />
        )
    });

  // Post video to already created video doc mutation
  const { mutate: handleFinalizeUpload } = useMutation({
    ...finalizeUploadMutation(videoDocId, courseId),
    onSuccess: () => {
      setIsEdit(false);
      queryClient.invalidateQueries({
        queryKey: ['course-resources-data', { id: courseId }]
      });
      queryClient.invalidateQueries({
        queryKey: ['course-video-data', { courseId, videoId: videoDocId }]
      });
    },
    onError: (err: Error) => {
      setPopup(
        <GenericPopup
          type="error"
          msg={err.message}
          buttonName="Close"
          buttonVariant="neutral"
          isClosable={false}
        />
      );
    }
  });

  // Post video to already created video doc mutation
  const { mutate: handlePostVideo } = useMutation({
    ...postVideoMutation(videoDocId, courseId),
    onSuccess: () => {
      setIsEdit(false);
      queryClient.invalidateQueries({
        queryKey: ['course-resources-data', { id: courseId }]
      });
      queryClient.invalidateQueries({
        queryKey: ['course-video-data', { courseId, videoId: videoDocId }]
      });
    },
    onError: (err: Error) => {}
    // setPopup(
    //   <GenericPopup
    //     type="error"
    //     msg={err.message}
    //     buttonName="Close"
    //     buttonVariant="neutral"
    //     isClosable={false}
    //   />
    // )
  });

  // Edit video mutation
  const { mutate: handleEdit } = useMutation({
    ...editVideoMutation(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'course-video-data',
          { changes, courseId, videoId: videoDocId }
        ]
      });
      queryClient.invalidateQueries({
        queryKey: ['course-resources-data', { changes, id: course._id }]
      });
    }
  });

  // Track video upload progress
  const { mutate: handleProgress } = useMutation({
    ...postVideoProgressMutation(videoDocId, courseId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['course-resources-data', { id: courseId }]
      });
      queryClient.invalidateQueries({
        queryKey: ['course-video-data', { courseId, videoId: videoDocId }]
      });
      setIsVideoUploaded(true);
      // Notify user when video uploaded
      setToast({
        type: 'success',
        msg: 'Video uploaded successfully',
        autoClose: true
      });
    },
    onError: (err: Error) => console.log(err.message)
  });

  // Create course draft mutation
  const { mutate: createDraft } = useMutation({
    ...createDraftMutation(courseId as string),
    onSuccess: () => handleRenavigate(true),
    onError: (err: Error) =>
      setToast({
        type: 'error',
        title: 'Error',
        msg: err.message,
        autoClose: true
      })
  });

  // Edit video order mutation
  const { mutate: handleEditOrder } = useMutation({
    ...editVideoOrderMutation(courseId),
    onSuccess: () => {
      // On changing the video order of published course, which does not have a draft copy
      // or a published course which has a draft of copy, but isn't the draft of copy (!isDraftCopy)
      // then navigate to the draft of copy of that course
      if (
        (isCourseStatus('isPublished', course) && !has_draft_copy) ||
        (isCourseStatus('isPublished', course) &&
          has_draft_copy &&
          !is_draft_copy)
      ) {
        navigate({
          pathname: `/courses/${slug}`,
          search: `${createSearchParams({
            changes: COURSE_CHANGES.ONLY_CHANGES
          })}`
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['course-resources-data', { id: courseId }]
      });
      setToast({
        type: 'success',
        msg: 'Videos order changed successfully',
        autoClose: true
      });
    },
    onError: (err: Error) =>
      setToast({
        type: 'error',
        msg: err.message
      })
  });

  // Trigger when a file is selected through the file explorer
  const onFileChangeCapture = async (e: any) => {
    // Currently allowed single selection of a video file
    const file = e.target.files[0];

    if (!file) {
      console.log('Please select a video file');
      return;
    }

    try {
      if (!isEdit) handleCreateVideoDoc(courseId);
      else handleGetVideoUploadLink({ courseId, videoId: videoDocId });

      // Notify user for starting video upload
      setToast({
        type: 'success',
        msg: 'Video upload started',
        autoClose: true
      });
    } catch (e: any) {
      setToast({
        type: 'error',
        title: 'Upload failed',
        msg: e.message || 'Something went wrong...',
        autoClose: true
      });
    }
  };

  // Triggered by Change Video button on video card
  const handleVideoChange = useCallback(
    (id: string) => {
      // If Change Video button is clicked while on a published/paused course page
      // but the course does NOT have a draft copy, or has a draft copy but changes=0
      if (
        isCourseStatus('isPublished', course) ||
        isCourseStatus('isPaused', course)
      ) {
        if (!course.has_draft_copy) return createDraft();
        if (course.has_draft_copy && !course.is_draft_copy) {
          return handleRenavigate(false);
        }
      }
      setVideoDocId(id);
      setIsEdit(true);
      inputFileRef.current.click();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVideoDocId, setIsEdit, course]
  );

  // Triggered by Upload Video buttons
  const handleUploadClick = () => {
    setIsEdit(false);
    // If a video is uploaded for a published course, create and navigate the user
    // to the draft-of-published course. The state passed in the navigate
    // triggers a useEffect, which will open the file explorer.
    if (
      (isCourseStatus('isPublished', course) ||
        isCourseStatus('isRejected', course)) &&
      changes === COURSE_CHANGES.NO_CHANGES
    ) {
      // If the course is published / rejected and does NOT have a draft copy, create it
      // and on successful creation navigate the user to the draft.
      if (!course.has_draft_copy) createDraft();
      else handleRenavigate(true);
    } else {
      // Trigger file explorer
      inputFileRef.current.click();
    }
  };

  const handleRenavigate = (withTriggerCreate: boolean) => {
    // navigate to draft-of-published course
    navigate(`/courses/${slug}?changes=${COURSE_CHANGES.ONLY_CHANGES}`, {
      state: { triggerCreateVideo: withTriggerCreate }
    });

    // Show the toast only when changing the video from the original published course
    // Do not show the toast when changing the video of the draft-of-published course
    if (!is_draft_copy)
      setToast({
        type: 'warning',
        title: 'Redirecting...',
        msg: 'Navigated to draft course.',
        autoClose: true
      });
  };

  const handleVideoUpload = async (url: string) => {
    const file = inputFileRef.current.files[0];

    // Get file props
    const { size } = file || {};

    const chunkSize = REACT_APP_FILE_UPLOAD_CHUNK_SIZE_MB * 1024 * 1024;
    const totalChunks = Math.ceil(size / chunkSize);
    const chunkProgress = 100 / totalChunks;
    let chunkNumber = 0;
    let start = 0;

    queryClient.invalidateQueries([
      'course-resources-data',
      { changes, id: courseId }
    ]);
    const thumbnail = !isSafari ? await generateVideoThumbnail(file) : '';

    const uploadNextChunk = async () => {
      if (start <= size) {
        const chunk = file.slice(start, start + chunkSize);
        const bytesUploaded = (chunkNumber + 1) / totalChunks;

        if (url) {
          await postFileChunks(url, start, file.size, chunk);
        }

        setProgressData((prevState) => ({
          ...prevState,
          [videoDocId]: {
            bytesUploaded: bytesUploaded,
            progress: Number((chunkNumber + 1) * chunkProgress)
          }
        }));

        chunkNumber++;
        start = start + chunkSize;

        uploadNextChunk();
      } else {
        const updatedProgressData = { ...progressData };
        delete updatedProgressData[videoDocId];
        setProgressData(updatedProgressData);

        handleEdit({
          data: {
            thumbnail
          },
          courseId,
          videoId: videoDocId
        });

        handleFinalizeUpload({ courseId, videoId: videoDocId });

        setIsVideoUploaded(true);

        // Notify user when video uploaded
        setToast({
          type: 'success',
          msg: 'Video uploaded successfully',
          autoClose: true
        });

        inputFileRef.current.files = null;
        inputFileRef.current.value = '';
      }
    };
    uploadNextChunk();

    // Open edit video form only on initial video upload
    if (!isEdit)
      setPopup(
        <EditVideoDetailsPopup
          videoId={videoDocId}
          course={courseProps.course}
          generatedThumbnail={thumbnail}
          isInitial
        />
      );
  };

  // ReadableStream is not supported on Safari/Mozila

  // const handleStream = async (videoId: string) => {
  //   const videoDocId = videoId;
  //   const file = inputFileRef.current.files[0];

  //   // Get file props
  //   const { name, size, type } = file || {};

  //   queryClient.invalidateQueries([
  //     'course-resources-data',
  //     { changes, id: courseId }
  //   ]);

  //   const thumbnail = await generateVideoThumbnail(file);

  //   // The ReadableStream interface of the Streams API  represents a readable stream of byte data.
  //   // The Fetch API offers a concrete instance of a ReadableStream through the body property of a Response object.
  //   // ReadableStream is a transferable object .
  //   const readStream = file.stream();

  //   // The ReadableStream() constructor creates and returns a readable stream object from the given handlers.
  //   const stream = new ReadableStream({
  //     start(controller) {
  //       const reader = readStream.getReader();

  //       function read() {
  //         reader
  //           .read()
  //           .then(({ done, value }: { done: boolean; value: any }) => {
  //             if (done) {
  //               controller.close();
  //               return;
  //             }

  //             controller.enqueue(value);
  //             read();
  //           });
  //       }

  //       read();
  //     }
  //   });

  //   // Use a custom TransformStream to track upload progress
  //   // The TransformStream() constructor creates a new TransformStream object
  //   // which represents a pair of streams: a WritableStream representing the writable side,
  //   // and a ReadableStream representing the readable side.
  //   const progressTrackingStream = new TransformStream({
  //     transform(chunk, controller) {
  //       controller.enqueue(chunk);

  //       // Update state `progressData` for every single uploaded file.
  //       // If the file already in progress, sum up progress bytes.
  //       // If new file uploaded, start bytes from `0`.
  //       setProgressData((prevState) => ({
  //         ...prevState,
  //         [videoDocId]: prevState[videoDocId]
  //           ? {
  //               bytesUploaded:
  //                 prevState[videoDocId].bytesUploaded + chunk.byteLength,
  //               progress: Math.ceil(
  //                 ((prevState[videoDocId].bytesUploaded + chunk.byteLength) /
  //                   size) *
  //                   100
  //               ),
  //               stream
  //             }
  //           : {
  //               bytesUploaded: chunk.byteLength,
  //               progress: Math.ceil((chunk.byteLength / size) * 100),
  //               stream
  //             }
  //       }));
  //     },
  //     flush(controller) {
  //       // STREAM COMPLETED

  //       // 1. Remove video doc from progressData
  //       const updatedProgressData = { ...progressData };
  //       delete updatedProgressData[videoDocId];
  //       setProgressData(updatedProgressData);
  //       handleEdit({
  //         data: {
  //           thumbnail
  //         },
  //         courseId,
  //         videoId: videoDocId
  //       });

  //     }
  //   });

  //   handleProgress({
  //     // The pipeThrough() method of the ReadableStream interface provides
  //     // a chainable way of piping the current stream through a transform stream or
  //     // any other writable/readable pair.
  //     // Piping a stream will generally lock it for the duration of the pipe,
  //     // preventing other readers from locking it.
  //     data: stream.pipeThrough(progressTrackingStream),
  //     courseId,
  //     videoId: videoDocId
  //   });

  //   handlePostVideo({
  //     file: {
  //       stream,
  //       size,
  //       name,
  //       type
  //     },
  //     courseId,
  //     videoId: videoDocId
  //   });

  //   // Open edit video form only on initial video upload
  //   if (!isEdit)
  //     setPopup(
  //       <EditVideoDetailsPopup
  //         videoId={videoDocId}
  //         course={courseProps.course}
  //         generatedThumbnail={thumbnail}
  //         isInitial
  //       />
  //     );

  //   // Clear the input's value
  //   inputFileRef.current.files = null;
  //   inputFileRef.current.value = '';
  // };

  const hasData =
    data &&
    videosData.length > 0 &&
    videosData.filter(
      (video) => video.edit_status !== VIDEO_EDIT_STATUS.DELETED
    ).length > 0;

  useEffect(() => {
    document.getElementById('app_wrapper')?.scrollTo(0, 0);
  }, []);

  // In case of video upload in progress trigger `beforeunload` event
  // when the user attempts to navigate away from the page, close the window, or refresh the page.
  // Prompt a confirmation dialog to the user to prevent accidental loss of data.
  useEffect(() => {
    const videoUploadInProgress = Object.keys(progressData).length
      ? Object.keys(progressData).filter(
          (key: string) => progressData[key].progress < 100
        )
      : [];

    const alertUser = (e: any) => {
      e.preventDefault();
      e.returnValue = '';
    };

    if (!!videoUploadInProgress.length) {
      window.addEventListener('beforeunload', alertUser);
      return () => window.removeEventListener('beforeunload', alertUser);
    }
  }, [progressData]);

  // Open file explorer after being renavigated from published version
  useEffect(() => {
    if (locationState?.triggerCreateVideo) {
      inputFileRef.current.click();
    }
  }, [locationState?.triggerCreateVideo]);

  // Show AddContent popup after finished course creation form
  useEffect(() => {
    if (locationState?.showContentPopup) {
      setPopup(
        <ContentPopup onAddVideos={() => inputFileRef.current.click()} />
      );
      document
        .getElementById('tabs')
        ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState?.showContentPopup]);

  return (
    <>
      {/* Hidden input type file which should be triggered automatically on video upload */}
      <input
        style={{ visibility: 'hidden', position: 'absolute' }}
        type="file"
        accept="video/mp4"
        ref={inputFileRef}
        onChangeCapture={onFileChangeCapture}
      />
      {courseProps.course.video_count === 0 &&
        courseProps.course.course_relationship ===
          COURSE_RELATIONSHIP.CREATOR && (
          <Portal
            node={
              document && document.getElementById('single-course-page-wrapper')
            }
          >
            <AddVideoIsland onAddVideo={handleUploadClick} />
          </Portal>
        )}
      {hasData ? (
        <div className={classes['wrapper']}>
          {isCourseStatus('isSubmitted', course) && !isAdminPage && (
            <p className={classes['u-text--error']}>
              You cannot edit a course while in review
            </p>
          )}
          {isCourseStatus('isApprovedInitial', course) && !isAdminPage && (
            <p className={classes['u-text--error']}>
              You cannot edit a course before resolving current approval
            </p>
          )}
          {!isPublic && !isVideoPage && (
            <Button
              onClick={handleUploadClick}
              icon={Upload}
              iconPosition="left"
              isLoading={isLoadingCreateVideoDoc}
              minWidth="md"
              size="md"
              isDisabled={
                isCourseStatus('isSubmitted', course) ||
                isCourseStatus('isApprovedInitial', course) ||
                isCourseStatus('isPublishing', course) ||
                isCourseStatus('isApprovedForRepublish', course) ||
                isCourseStatus('isRepublishing', course)
              }
            >
              Upload Video
            </Button>
          )}
          <DragAndDrop
            data={data}
            handleVideoChange={handleVideoChange}
            handleVideoOrder={handleEditOrder}
            progressData={progressData}
            courseProps={courseProps}
          />
        </div>
      ) : (
        <EmptyState
          icon={NoVideos}
          title={
            isPublic ||
            isCourseStatus('isSubmitted', course) ||
            isCourseStatus('isApprovedInitial', course)
              ? 'There are no videos at the moment.'
              : 'Choose video files to upload'
          }
          buttonName={
            isPublic ||
            isCourseStatus('isSubmitted', course) ||
            isCourseStatus('isApprovedInitial', course) ||
            isCourseStatus('isPublishing', course)
              ? ''
              : 'Upload Video'
          }
          onClick={handleUploadClick}
          bellowBtnComp={
            !isPublic &&
            !isCourseStatus('isSubmitted', course) &&
            !isCourseStatus('isApprovedInitial', course) && (
              <p>
                Learn more about uploading in the{' '}
                <Link to="/content-guidelines">Content Guidelines</Link>
              </p>
            )
          }
        />
      )}
    </>
  );
};

export default Videos;
