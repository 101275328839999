import React, { useContext, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReactComponent as BanIcon } from 'assets/icons/ban.svg';
import { ReactComponent as ActivateIcon } from 'assets/icons/activate.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { IUser } from 'context/userContext';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import Button from 'components/buttons/Button';
import Avatar from 'components/avatar/Avatar';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import {
  activateUserMutation,
  banUserMutation
} from 'query/course-module/mutations';
import { formatNames } from 'utils/format';
import classes from './AdminUsersActionPopup.module.scss';

interface IAdminUsersActionPopupProps {
  users: IUser[];
  action: 'ban' | 'activate';
}

const AdminUsersActionPopup = ({
  users,
  action
}: IAdminUsersActionPopupProps) => {
  const queryClient = useQueryClient();
  const { setToast } = useContext(toastContext);
  const { clearPopup } = useContext(popupContext);
  const [usersData, setUsersData] = useState(users);

  const { isLoading: isLoadingBan, mutate: banMutation } = useMutation({
    ...banUserMutation([...usersData.map((user) => user._id)]),
    onSuccess: () => {
      setToast({
        type: 'success',
        title: 'Successfull Ban',
        msg: 'Successfully banned.',
        position: 'top',
        autoClose: true
      });
      clearPopup();
      queryClient.invalidateQueries(['admin-users-data']);
      queryClient.invalidateQueries(['profile-data', { id: users[0].slug }]);
    },
    onError: async (err: any) => {
      clearPopup();
      setToast({
        type: 'error',
        position: 'top',
        title: 'Ban Failed',
        msg: err.message || '',
        autoClose: true
      });
    }
  });

  const { isLoading: isLoadingActivate, mutate: activateMutation } =
    useMutation({
      ...activateUserMutation([...usersData.map((user) => user._id)]),
      onSuccess: () => {
        setToast({
          type: 'success',
          title: 'Successfull Activate',
          msg: 'Successfully activated.',
          position: 'top',
          autoClose: true
        });
        clearPopup();
        queryClient.invalidateQueries(['admin-users-data']);
        queryClient.invalidateQueries(['profile-data', { id: users[0].slug }]);
      },
      onError: async (err: any) => {
        setToast({
          type: 'error',
          position: 'top',
          title: 'Activation Failed',
          msg: err.message || '',
          autoClose: true
        });
      }
    });

  return (
    <ModalWrapper size="md">
      <div className={classes['wrapper']}>
        <h4 className={classes['u-semiBold']}>{`${
          action.charAt(0).toUpperCase() + action.slice(1)
        } User${usersData.length > 1 ? 's' : ''}`}</h4>
        {action === 'ban' && <BanIcon />}
        {action === 'activate' && <ActivateIcon />}
        <h5 className={`${classes['u-body2']} ${classes['subtitle']}`}>
          {`Are your sure you want to ${action} the selected user${
            usersData.length > 1 ? 's?' : '?'
          }`}
        </h5>
        <div className={classes['content']}>
          {usersData.map((user, index) => {
            const { pic, first_name, last_name } = user;
            return (
              <div key={index} className={classes['user-container']}>
                <div className={classes['user-data']}>
                  <Avatar src={pic} />
                  <h5 className={classes['u-body2']}>
                    {formatNames(first_name + ' ' + last_name)}
                  </h5>
                </div>
                {usersData.length > 1 && (
                  <DeleteIcon
                    width={24}
                    height={24}
                    fill="black"
                    cursor="pointer"
                    onClick={() => {
                      const updatedUsers = [...usersData];
                      updatedUsers.splice(index, 1);
                      setUsersData(updatedUsers);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className={classes['btns-container']}>
          <Button
            variant="contrast"
            minWidth="full"
            isLoading={action === 'ban' ? isLoadingBan : isLoadingActivate}
            onClick={() => {
              action === 'ban' && banMutation();
              action === 'activate' && activateMutation();
            }}
          >
            Confirm
          </Button>
          <Button variant="link-neutral" minWidth="full" onClick={clearPopup}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AdminUsersActionPopup;
