import { Outlet, useLocation, Navigate } from 'react-router-dom';
import MainLayout from 'layouts/main';

const Dashboard = () => {
  const location = useLocation();
  return (
    <MainLayout>
      {location.pathname === '/' ? (
        <Navigate to="/school" replace />
      ) : (
        <Outlet />
      )}
    </MainLayout>
  );
};
export default Dashboard;
