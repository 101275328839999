import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import Error from 'components/error/Error';
import Table from 'components/table/Table';
import Loader from 'components/loader/Loader';
import { AdminStaticPagesCols } from 'components/table/AdminStaticPagesCols';
import { ISinglePageDto } from 'query/course-module/dto';
import { allPagesDataQuery } from 'query/course-module/queries';
import classes from './AdminStaticPages.module.scss';

const AdminStaticPages = () => {
  const { data, isLoading, error } = useQuery<boolean, Error, ISinglePageDto>(
    allPagesDataQuery()
  );

  const hasData = !!data && !!data.result.length;

  const userCols = useMemo(() => AdminStaticPagesCols(), []);

  return (
    <div className={classes['admin-static-pages']}>
      <h4 className={classes['u-bold']}>Static Pages</h4>
      {isLoading && <Loader />}
      {!!hasData && <Table columns={userCols} data={data.result} />}
      {error && <Error error={error} />}
    </div>
  );
};

export default AdminStaticPages;
