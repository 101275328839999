import { createContext, useContext, useEffect, useState } from 'react';
import { useAccount, useBalance, useNetwork } from 'wagmi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REACT_APP_CHAIN_ID } from 'utils/constants';
import { getExchangeRate } from 'utils/requests';
import { connectWalletMutationQuery } from 'query/acc-module/mutations';
import { toastContext } from './toastContext';
import { userContext } from './userContext';

interface IWeb3Context {
  balanceInUsd: number;
  isCorrectChain: boolean;
  isWalletUnique: boolean;
  pendingTransactions: number;
  increasePendingTransactions: () => void;
  decreasePendingTransactions: () => void;
}

const web3InitialValues = {
  balanceInUsd: 0,
  isCorrectChain: false,
  isWalletUnique: false,
  pendingTransactions: 0,
  increasePendingTransactions: () => {},
  decreasePendingTransactions: () => {}
};

export const Web3Context = createContext<IWeb3Context>(web3InitialValues);

export const Web3Provider = (props: any) => {
  const queryClient = useQueryClient();
  const { setToast, clearToast } = useContext(toastContext);
  const { isTokenExpired } = useContext(userContext);
  const [usdPrice, setUsdPrice] = useState(0);
  const [balanceInUsd, setBalanceInUsd] = useState(0);
  const { address, isConnected } = useAccount();
  const { data: balanceData } = useBalance({ address });
  const { chain } = useNetwork();
  const [isCorrectChain, setIsCorrectChain] = useState(
    (chain?.id && +chain?.id === +REACT_APP_CHAIN_ID) || false
  );
  const [isWalletUnique, setIsWalletUnique] = useState(true);
  const [pendingTransactions, setPendingTransactions] = useState(0);

  const { mutate: connectWalletMutation } = useMutation({
    ...connectWalletMutationQuery(),
    onSuccess: () => {
      setIsWalletUnique(true);
      clearToast();
      queryClient.invalidateQueries(['me-data']);
    },
    onError: (e: Error) => {
      setIsWalletUnique(false);
      setToast({
        type: 'error',
        msg: e.message,
        autoClose: true,
        position: 'top'
      });
    }
  });

  // Fetch MATIC price (in USD)
  useEffect(() => {
    const fetchUsdPrice = async () => {
      await getExchangeRate()?.then((res) => {
        if (!!res) setUsdPrice(res['matic-network'].usd);
      });
    };
    fetchUsdPrice();
  }, []);

  // Update balance on switching wallet accounts
  useEffect(
    () => {
      if (!!address && !!balanceData) {
        const maticInUsd = +balanceData?.formatted * usdPrice;
        setBalanceInUsd(+maticInUsd.toFixed(2));
      }
      if (!address) setBalanceInUsd(0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address, balanceData, isConnected]
  );

  useEffect(() => {
    setIsCorrectChain(
      (chain?.id && +chain?.id === +REACT_APP_CHAIN_ID) || false
    );
  }, [chain?.id]);

  useEffect(
    () => {
      if (!!address && !isTokenExpired)
        connectWalletMutation(address as string);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address, isTokenExpired]
  );

  const increasePendingTransactions = () => {
    setPendingTransactions((pendTransactions) => pendTransactions + 1);
  };

  const decreasePendingTransactions = () => {
    setPendingTransactions((pendTransactions) => pendTransactions - 1);
  };

  return (
    <Web3Context.Provider
      value={{
        balanceInUsd,
        isCorrectChain,
        isWalletUnique,
        pendingTransactions,
        increasePendingTransactions,
        decreasePendingTransactions
      }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};
