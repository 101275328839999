import React, { useContext, useEffect, useState } from 'react';
import { windowContext } from 'context/windowsContext';
import FormSteps from 'components/forms/formSteps/FormSteps';
import RegisterForm from './components/RegisterForm';
import classes from './Register.module.scss';

const formSteps = [
  'Account Information',
  'Personal Information',
  // 'Documentation',
  'Summary'
];

const Register = () => {
  const { appWrapperElement } = useContext(windowContext);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [finishedSteps, setFinishedSteps] = useState<number>(0);

  useEffect(() => {
    appWrapperElement?.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <div className={classes['wrapper']}>
      <div className={classes['container']}>
        <h3 className={`${classes['u-semiBold']} ${classes['u-text--center']}`}>
          Register
        </h3>
        <FormSteps
          steps={formSteps}
          currentStep={currentStep}
          finishedSteps={finishedSteps}
          setCurrentStep={(step: number) => {
            setCurrentStep(step);
            setFinishedSteps(step === 0 ? 0 : step);
          }}
        />
        <RegisterForm
          steps={formSteps}
          currentStep={currentStep}
          setStep={(step: number) => {
            setCurrentStep(step);
            setFinishedSteps(step === 0 ? 0 : step);
          }}
        />
      </div>
    </div>
  );
};

export default Register;
