import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { userContext } from 'context/userContext';
import Button from 'components/buttons/Button';
import { ISingleCourseVideo } from 'query/course-module/dto';
import classes from './Player.module.scss';

interface IPlayerProps {
  src: string;
  height?: string;
  isPlaying?: boolean;
  hasControls?: boolean;
  isCard?: boolean;
  onProgress?: (data: { playedSeconds: number; is_finished: boolean }) => void;
  checkpoint?: number;
  nextVideoId?: string;
  videoData?: ISingleCourseVideo;
  isExamFinished?: boolean;
  isMuted?: boolean;
}

const Player = ({
  src,
  height,
  isPlaying,
  hasControls,
  isCard,
  onProgress,
  checkpoint,
  nextVideoId,
  videoData,
  isExamFinished,
  isMuted
}: IPlayerProps) => {
  const { slug, videoId } = useParams();
  const navigate = useNavigate();
  const { search: urlParams, state } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const ref = useRef(null) as any;
  const { showContinueWatching, setShowContinueWatching } =
    useContext(userContext);
  const [nextVideo, setNextVideo] = useState('');
  const [playNextIn, setPlayNextIn] = useState(5);
  const [showExamCta, setShowExamCta] = useState(false);

  const nextVideoLink = !!nextVideoId
    ? `/courses/${slug}/${nextVideoId}?changes=${changes}`
    : '';

  const onProgressHandler = (playedSeconds: number) => {
    // Do not save progress if the video has ended.
    // The progress is set back to 0 for a WATCHED video.
    if (playedSeconds === ref.current.getDuration()) return;
    onProgress &&
      onProgress({
        playedSeconds,
        is_finished:
          ref.current.getCurrentTime() > 0.9 * ref.current.getDuration()
      });
  };

  const clearCta = () => {
    setPlayNextIn(5);
    setNextVideo('');
    setShowExamCta(false);
  };

  useEffect(
    () => {
      if (playNextIn <= -1) {
        navigate(nextVideoLink, { state: { autoPlay: true } });
        clearCta();
        return;
      }
      if (!!nextVideo) {
        const timer = setInterval(() => {
          setPlayNextIn(playNextIn - 1);
        }, 1000);

        // Cleanup function to clear the interval after 5 seconds
        setTimeout(() => {
          clearInterval(timer);
        }, 5000);

        // Cleanup function executed on unmount
        return () => clearInterval(timer);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nextVideo, playNextIn]
  );

  // If the user navigates to another video while
  // the CTA component is visualised, then
  // clear the next video and hide CTA
  useEffect(() => {
    clearCta();
  }, [videoId]);

  const renderPlayer = () => {
    /* 1. InputMedia.tsx --> width: auto, height: 100%. The player's height is set to 192px by the height prop. */
    /* 2. Summary.tsx -->  width: auto, height: 100%. The player's height is set to 128px by the height prop. */
    if (!!height && !isCard) {
      return (
        <ReactPlayer
          url={src}
          height={height}
          width="auto"
          playing={isPlaying}
          controls={hasControls}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          muted={isMuted}
        />
      );
    }

    /* 3. Single course / Video page / Card.tsx */
    return (
      <div className={!isCard ? classes['player__wrapper--responsive'] : ''}>
        {/* {!isLgMobile && isVideoPage && (
          <Button
            className={classes['theatre-btn']}
            variant="link-neutral"
            icon={isTheatreMode ? Shrink : Expand}
            isIconBtn
            withPadding={false}
            onClick={() => setIsTheatreMode(!isTheatreMode)}
          />
        )} */}
        {!!nextVideo && (
          <div className={classes['player__wrapper__cta']}>
            <Button
              className={classes['play-next-btn']}
              variant="link-neutral"
              isIconBtn
              icon={Play}
              withPadding={false}
              onClick={() => {
                navigate(nextVideoLink, { state: { autoPlay: true } });
                clearCta();
              }}
            />
            <div>The next video will play in {playNextIn}...</div>
            <Button variant="neutral" size="sm" onClick={clearCta}>
              Cancel
            </Button>
          </div>
        )}
        {showExamCta && (
          <div className={classes['player__wrapper__cta']}>
            <div className={classes['u-body1']}>
              Now test your knowledge by taking the exam
            </div>
            <Button
              size="sm"
              onClick={() =>
                navigate(
                  `/courses/${slug}/${videoId}/test-questions?changes=${changes}`,
                  {
                    state: { openTest: true, nextVideoLink }
                  }
                )
              }
            >
              Take Test
            </Button>
          </div>
        )}
        <ReactPlayer
          ref={ref}
          className={classes['react-player']}
          url={src}
          height={isCard ? '192px' : '100%'}
          width="100%"
          playing={isPlaying || !!state?.autoPlay}
          controls={hasControls}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          progressInterval={5000} // 5 sec
          onProgress={(value: OnProgressProps) =>
            !!value.played && onProgressHandler(value.playedSeconds)
          }
          onSeek={(value) => {
            onProgressHandler(value);
          }}
          onStart={() => !!checkpoint && ref.current.seekTo(checkpoint)}
          onEnded={() => {
            // Show exam if video has non-completed exam
            if (!!videoData && !!videoData?.exam?.length && !isExamFinished) {
              return setShowExamCta(true);
            }
            // Show next video countdown
            setNextVideo(nextVideoLink);
            onProgressHandler(1);
          }}
          onPlay={() => {
            clearCta();
            if (!showContinueWatching) {
              localStorage.setItem('showContinueWatching', 'true');
              setShowContinueWatching(true);
            }
          }}
          disableDeferredLoading={true}
          muted={isMuted}
        />
      </div>
    );
  };

  return (
    <div
      className={`
        ${classes['player__wrapper']} 
        ${isCard ? classes['player__wrapper__card'] : ''}
      `}
    >
      {renderPlayer()}
    </div>
  );
};

Player.defaultProps = {
  isCard: false
};

export default Player;
