import React from 'react';
import Button from 'components/buttons/Button';
import classes from './EmptyState.module.scss';

interface IEmptyState {
  icon: string;
  title: string;
  message?: string;
  buttonName?: string;
  onClick?: () => void;
  bellowBtnComp?: React.ReactNode;
}

const EmptyState = (props: IEmptyState) => {
  const { icon, title, message, buttonName, onClick, bellowBtnComp } = props;

  return (
    <div className={classes['wrapper']}>
      <img className={classes['logo']} src={icon} alt="logo" />
      <div className={classes['text']}>
        <h5 className={classes['u-semiBold']}>{title}</h5>
        {message && <p>{message}</p>}
      </div>
      {buttonName && (
        <Button onClick={onClick} minWidth="md">
          {buttonName}
        </Button>
      )}
      {bellowBtnComp}
    </div>
  );
};

export default EmptyState;
